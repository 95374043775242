/*
 NOTE: BASE_URL / ASSETS_URL / SERVER_URL are all references that should be
 changed / removed. This is a temporary solution to help Umpqua run the project
 without gulp.
*/
const BASE_URL = window.location.origin;
const ASSETS_URL = `${BASE_URL}/assets/toolkit/`;
const ANIMATION_TIMEOUT = 500;
export const GLOBAL_CONSTANTS = {
  ANIMATION_TIMEOUT,
  GSAP_TIMEOUT: ANIMATION_TIMEOUT / 2 / 1000,
  ANIMATION: {
    DEFAULT: "fadeIn",
    TYPES: {
      FADE_IN: "fadeIn",
      FADE_OUT: "fadeOut",
      FADE_IN_UP: "fadeInUp",
      FADE_IN_DOWN: "fadeInDown",
    },
    OFFSET: 100,
    GSAP_DELAY: 0.5,
    SLOW_ANIMATION_DURATION: 675,
  },
  BASE_IMAGE_URL: `${ASSETS_URL}images/`,
  SERVER_URL: "https://umpqua-render.wrkbench.in",
  BASE_URL,
  COLORS: {
    WHITE: "#FFFFFF",
    AQUA: "#14CCCC",
    GRAY: "#D4D4D4",
    LIGHTGREEN: "#009C82",
    DARKGREEN: "#00635B",
  },
  EVENTS: {
    ADJUST_GRID: "adjust-grid",
    APPLY_FILTER: "filter/apply-filter",
    RESIZE: "resize",
    SCROLL: "scroll",
    ORIENTATION_CHANGE: "orientation-change",
    MODAL_OPEN: "modal-open",
    MODAL_MAP_OPEN: "modal-map-open",
    MODAL_CLOSE: "modal-close",
    MODAL_FOCUS: "modal-focus",
    START_ANIMATION: "animation/start",
    END_ANIMATION: "animation/end",
    PRIORITY_FINISHED: "SCROLL_ANIMATION/PRIORITY_FINISHED",
    PAGE_LOAD_COMPLETE: "ANIMATION/PAGE_LOAD_COMPLETE",
    STOP_STICKY_NAV: "shy-nav/stop",
    START_STICKY_NAV: "shy-nav/start",
    DECISION_TREE_SHOW_CARDS: "decision-tree__show-cards",
    DECISION_TREE_HIDE_CARDS: "decision-tree__hide-cards",
    COLLAGE: {
      FORWARD: "fade-in--forward",
      REVERSE: "fade-in--reverse",
    },
    MAP: {
      LOAD_MARKERS: "map/load-markers",
      INPUT_KEYUP: "map/search-keyup",
      LOAD_LOCATION: "map/load-location",
      CLEAR: "map/clear",
    },
    STEPPER: {
      SKIP: "stepper/skip-to-step",
      BACK: "stepper/go-back",
      RESTARTED: "stepper/step-0",
    },
    SEARCH: {
      POPULATE: "search/populate",
    },
  },
  ERRORS: {
    EMAIL: "Please enter a valid email address",
    REQUIRED: "Field required",
  },
  UMPQUA_EASE: "power1(0.5, 0, 0.15)",
  KEY_CODES: {
    ESCAPE: 27,
  },
  CLASSES: {
    ACTIVE: "-active",
    FIXED: "-fixed",
    HOVER: "-hover",
    NO_CLIP: "no-clip-path",
    INACTIVE: "-inactive",
    ERRORS: "-has-errors",
  },
  BREAKPOINTS: {
    XSMALL: 420,
    SMALL: 768,
    MEDIUM: 1024,
    LARGE: 1440,
  },
  THEMES: {
    PERSONAL: "personal",
    BUSINESS: "business",
  },
  NAV: {
    MOBILE: "mobile",
    DESKTOP: "desktop",
    TOTAL_MARGINS: 120,
    SUB_MENU_MARGIN: 36,
  },
};
