import { autobind } from "core-decorators";
import Flickity from "flickity";

export class TestimonialCards {
  $testimonialsSection: HTMLDivElement;
  $testimonialCardsContainer: HTMLDivElement;
  $testimonialCards: Array<HTMLDivElement>;

  $handle: HTMLElement;

  classes = {
    testimonialCard: ".js-testimonial-card",
    testimonialCardContainer: "testimonial-card-container",
    testimonialCardContent: "testimonial-card-content",
  };

  events = {
    resize: "resize",
    readyStateChange: "readystatechange",
  };

  ids = {
    testimonialsSectionId: "testimonials-section-id",
    testimonialCardsContainerId: "testimonial-cards-container-id",
  };

  pixels = {
    cardsSidesMargin: 20,
    cardSeparator: 20,
    desktopWidth: 1025,
    flickityButtonWidth: 44,
    maxCardWidth: 410,
    minCardWidth: 236,
  };

  originalCardCount = 0;
  desktopRepeatedCardCount = 0;

  flickity: typeof Flickity;
  flickityMaxCellHeight = 0;

  maxCardsVisibleAtOnce = 3;
  hasResized = false;

  /**
   *   Initialize TestimonialCards class
   */
  constructor() {
    this.$testimonialsSection = document.getElementById(
      this.ids.testimonialsSectionId,
    ) as HTMLDivElement;
    this.$testimonialCardsContainer = document.getElementById(
      this.ids.testimonialCardsContainerId,
    ) as HTMLDivElement;
    this.$testimonialCards = new Array<HTMLDivElement>();

    const cards = this.$testimonialCardsContainer.querySelectorAll(
      this.classes.testimonialCard,
    );

    if (cards !== null && typeof cards !== "undefined" && cards.length > 0) {
      for (let index = 0; index < cards.length; index++) {
        const card = cards[index] as HTMLDivElement;
        this.$testimonialCards.push(card);
      }
    }

    this.originalCardCount = this.cardCount;
    const groupsExtraCards =
      this.originalCardCount % this.maxCardsVisibleAtOnce;
    this.desktopRepeatedCardCount =
      groupsExtraCards === 0
        ? 0
        : this.maxCardsVisibleAtOnce - groupsExtraCards;
    this.bindEvents();
  }

  /**
   *   Bind events to handle resizing method
   */
  bindEvents() {
    document.addEventListener(this.events.resize, this.handleResize, false);
    document.addEventListener(
      this.events.readyStateChange,
      this.handleResize,
      false,
    );

    let resizeId: number;
    window.addEventListener(this.events.resize, () => {
      if (resizeId !== null && resizeId !== undefined) {
        clearTimeout(resizeId);
      }
      resizeId = window.setTimeout(() => {
        this.handleResize();
      }, 500);
    });
  }

  /**
   *   getter Properties for commonly used functions
   */
  get screenWidth(): number {
    return Math.floor(this.$testimonialsSection.getBoundingClientRect().width);
  }

  get containerWidth(): number {
    return Math.floor(
      this.$testimonialCardsContainer.getBoundingClientRect().width,
    );
  }

  get isMobileView(): boolean {
    return this.screenWidth < this.pixels.desktopWidth;
  }

  get cardCount(): number {
    return this.$testimonialCards.length;
  }

  get repeatedCardCount(): number {
    return this.cardCount - this.originalCardCount;
  }

  get hasRepeatableCards(): boolean {
    return this.repeatedCardCount > 0;
  }

  get noSeparatorMargin(): string {
    return "0";
  }

  get standardSeparatorMargin(): string {
    return `${this.pixels.cardSeparator}px`;
  }

  /**
   *   Manages the addition of testimonial cards to maintain the number of cards is a
   *   multiple of 3 for desktop view and removes any added cards for mobile view
   */
  @autobind
  manageRepeatableCards() {
    if (this.isMobileView) {
      if (this.hasRepeatableCards) {
        this.removeCards();
      }
      return;
    }

    if (
      this.originalCardCount < 4 ||
      this.desktopRepeatedCardCount < 1 ||
      this.hasRepeatableCards
    ) {
      return;
    }

    if (this.desktopRepeatedCardCount === 1) {
      const cardToRepeat = this.$testimonialCards[this.originalCardCount - 3];
      const repeatableCard = this.createRepeatableCard(cardToRepeat);
      this.$testimonialCards.splice(this.cardCount - 2, 0, repeatableCard);
      cardToRepeat.parentNode.insertBefore(
        repeatableCard,
        cardToRepeat.nextElementSibling,
      );
    } else {
      if (this.desktopRepeatedCardCount === 2) {
        let cardToRepeat = this.$testimonialCards[this.originalCardCount - 3];
        let repeatableCard = this.createRepeatableCard(cardToRepeat);
        this.$testimonialCards.splice(this.cardCount - 1, 0, repeatableCard);
        cardToRepeat.parentNode.insertBefore(
          repeatableCard,
          cardToRepeat.nextElementSibling.nextElementSibling,
        );
        cardToRepeat = this.$testimonialCards[this.originalCardCount - 2];
        repeatableCard = this.createRepeatableCard(cardToRepeat);
        this.$testimonialCards.splice(this.cardCount - 1, 0, repeatableCard);
        cardToRepeat.parentNode.insertBefore(
          repeatableCard,
          cardToRepeat.nextElementSibling.nextElementSibling,
        );
      }
    }
  }

  /**
   *   Clones a testimonial card
   */
  @autobind
  createRepeatableCard(cardToRepeat: HTMLDivElement): HTMLDivElement {
    const repeatableCard = document.createElement("div");
    for (let index = 0; index < cardToRepeat.classList.length; index++) {
      repeatableCard.classList.add(cardToRepeat.classList[index]);
    }
    const outerHtml = cardToRepeat.outerHTML.toString();
    let innerHtml = outerHtml.substr(outerHtml.indexOf(">") + 1);
    innerHtml = innerHtml.substr(0, innerHtml.lastIndexOf("</div>"));
    repeatableCard.innerHTML = innerHtml;
    repeatableCard.style.height = cardToRepeat.style.height;
    repeatableCard.style.width = cardToRepeat.style.width;
    return repeatableCard;
  }

  /**
   *   Removes all testimonial cards added for desktop view if resizing causes a switch to mobile view
   */
  @autobind
  removeCards(): void {
    if (this.repeatedCardCount === 1) {
      const index = this.cardCount - 3;
      const card = this.$testimonialCards[index];
      this.$testimonialCards.splice(index, 1);
      card.remove();
    } else {
      if (this.repeatedCardCount === 2) {
        let index = this.cardCount - 2;
        let card = this.$testimonialCards[index];
        this.$testimonialCards.splice(index, 1);
        card.remove();
        index = this.cardCount - 2;
        card = this.$testimonialCards[index];
        this.$testimonialCards.splice(index, 1);
        card.remove();
      }
    }
  }

  /**
   *   Determines if flickity is needed based on desktop or mobile view and number of testimonial cards
   */
  @autobind
  isDraggable(): boolean {
    if (this.isMobileView) {
      return !(this.cardCount < 2);
    } else {
      return this.cardCount > 3;
    }
  }

  /**
   *   Handles resizing event by determining if additional testimonial cards need to be created to keep
   *   number of cards divisible by 3 for desktop, determining if flickity is needed, and setting the
   *   height (all cards have height of tallest card) and width (up to maximum width 410px) to fit in available
   *   available screen real estate
   */
  @autobind
  handleResize(): void {
    this.manageRepeatableCards();

    if (!this.hasResized) {
      this.$testimonialCardsContainer.classList.remove("hide");
      this.$testimonialCardsContainer.classList.add("show");
      this.hasResized = true;
    }

    this.setCardSizes();

    if (this.isDraggable()) {
      this.flickity = new Flickity(this.$testimonialCardsContainer, {
        cellAlign: "center",
        freeScroll: false,
        freeScrollFriction: 0.03,
        prevNextButtons: true,
        pageDots: true,
        contain: true,
        wrapAround: true,
        groupCells: this.isMobileView ? 1 : 3,
      });
      this.flickity.setGallerySize(false);
      this.flickity.maxCellHeight = this.flickityMaxCellHeight;
      this.flickity.setGallerySize(true);
      this.flickity.reloadCells();
      this.flickity.resize();
    } else {
      this.stopDraggable();
    }
  }

  /**
   *   Sets the testimonial cards container width to hold three (for desktop) or one (for mobile) card based on the available screen
   *   real estate (considering min and max values) and then sizes each card width to fit the appropriate number of cards into the
   *   cards container.  It then determines the largest card height based on the card width and content and sets all cards to that
   *   height.
   */
  setCardSizes(): void {
    if (this.cardCount < 1) {
      return;
    }
    const cardSeparatorsWidth =
      this.cardCount === 3
        ? 2 * this.pixels.cardSeparator
        : this.cardCount === 2
          ? this.pixels.cardSeparator
          : 0;
    let maxCardHeight = 0;
    let containerWidth = this.containerWidth;
    if (this.isMobileView) {
      this.resetCardSideMargins();
      containerWidth = Math.min(
        this.screenWidth - this.pixels.flickityButtonWidth,
        this.pixels.maxCardWidth,
      );
    } else {
      this.setCardSideMargins();
      if (this.cardCount > this.maxCardsVisibleAtOnce) {
        containerWidth = Math.min(
          this.screenWidth -
            this.pixels.flickityButtonWidth -
            this.pixels.cardSeparator * 2,
          1270,
        );
      } else {
        const containerMaxWidth = this.screenWidth - cardSeparatorsWidth - 40;
        containerWidth =
          this.cardCount === 3
            ? Math.min(containerMaxWidth, 1270)
            : this.cardCount === 2
              ? Math.min(containerMaxWidth, 840)
              : Math.min(containerMaxWidth, this.pixels.maxCardWidth);
      }
    }
    this.$testimonialCardsContainer.style.minWidth = `${containerWidth}px`;
    this.$testimonialCardsContainer.style.width = `${containerWidth}px`;
    this.$testimonialCardsContainer.style.maxWidth = `${containerWidth}px`;
    this.$testimonialCardsContainer.style.marginLeft = `${Math.floor((this.screenWidth - containerWidth) / 2)}px`;

    let cardWidth = this.pixels.minCardWidth;
    if (this.isMobileView) {
      cardWidth = Math.min(this.pixels.maxCardWidth, containerWidth);
    } else {
      if (this.cardCount > this.maxCardsVisibleAtOnce) {
        cardWidth = Math.min(
          this.pixels.maxCardWidth,
          Math.floor(
            (containerWidth - this.pixels.cardSeparator * 2) /
              this.maxCardsVisibleAtOnce,
          ),
        );
      } else {
        cardWidth = Math.min(
          this.pixels.maxCardWidth,
          Math.floor((containerWidth - cardSeparatorsWidth) / this.cardCount),
        );
      }
    }

    for (let index = 0; index < this.cardCount; index++) {
      const item = this.$testimonialCards[index];
      item.style.minWidth = `${cardWidth}px`;
      item.style.width = `${cardWidth}px`;
      item.style.maxWidth = `${cardWidth}px`;
      item.style.height = "auto";
      maxCardHeight = Math.max(
        item.getBoundingClientRect().height,
        maxCardHeight,
      );
    }

    if (maxCardHeight < 1) {
      return;
    }

    for (let index = 0; index < this.cardCount; index++) {
      const card = this.$testimonialCards[index];
      card.style.height = `${maxCardHeight}px`;
      const cardContainerElements = card.getElementsByClassName(
        this.classes.testimonialCardContainer,
      );
      if (cardContainerElements !== null && cardContainerElements.length > 0) {
        const cardContainer = cardContainerElements[0] as HTMLDivElement;
        const cardContentElements = cardContainer.getElementsByClassName(
          this.classes.testimonialCardContent,
        );
        if (cardContentElements !== null && cardContentElements.length > 0) {
          const cardContent = cardContentElements[0] as HTMLDivElement;
          const heightDifference =
            cardContainer.offsetHeight - cardContent.offsetHeight;
          if (heightDifference > 1) {
            cardContent.style.paddingTop = `${Math.floor(heightDifference / 2)}px`;
          }
        }
      }
    }

    this.flickityMaxCellHeight = maxCardHeight;
  }

  /**
   *   Sets the spacing between testimonial cards for desktop view as up to three cards can
   *   display at a time
   */
  @autobind
  setCardSideMargins() {
    // ***   Less than or equal to three cards
    if (this.cardCount === 0) {
      return;
    } else {
      if (this.cardCount <= this.maxCardsVisibleAtOnce) {
        this.$testimonialCards[0].style.marginLeft = this.noSeparatorMargin;
        this.$testimonialCards[0].style.marginRight = this.noSeparatorMargin;

        switch (this.cardCount) {
          case 2:
            this.$testimonialCards[1].style.marginLeft =
              this.standardSeparatorMargin;
            this.$testimonialCards[1].style.marginRight =
              this.noSeparatorMargin;
            break;
          case 3:
            this.$testimonialCards[1].style.marginLeft =
              this.standardSeparatorMargin;
            this.$testimonialCards[1].style.marginRight =
              this.standardSeparatorMargin;
            this.$testimonialCards[2].style.marginLeft = this.noSeparatorMargin;
            this.$testimonialCards[2].style.marginRight =
              this.noSeparatorMargin;
            break;
        }

        return;
      }
    }

    // ***   Greater than three cards
    for (let index = 0; index < this.cardCount; index++) {
      const card = this.$testimonialCards[index];
      const indexWithinGroup =
        (index + this.maxCardsVisibleAtOnce) % this.maxCardsVisibleAtOnce;
      switch (indexWithinGroup) {
        case 1:
          card.style.marginLeft = this.standardSeparatorMargin;
          card.style.marginRight = this.standardSeparatorMargin;
          break;
        default:
          card.style.marginLeft = this.noSeparatorMargin;
          card.style.marginRight = this.noSeparatorMargin;
          break;
      }
    }
  }

  /**
   *   Removes the spacing between testimonial cards for mobile view as only one card
   *   is displayed at a time
   */
  @autobind
  resetCardSideMargins() {
    for (let index = 0; index < this.cardCount; index++) {
      this.$testimonialCards[index].style.marginLeft = this.noSeparatorMargin;
      this.$testimonialCards[index].style.marginRight = this.noSeparatorMargin;
    }
  }

  /**
   *   Remove flickity when no longer needed
   */
  stopDraggable(): void {
    if (this.flickity !== null && this.flickity !== undefined) {
      this.flickity.destroy();
    }
  }

  /**
   *   Remove listeners and stop listening for resize event
   */
  tearDown(): void {
    document.removeEventListener(this.events.resize, this.handleResize);
  }
}
