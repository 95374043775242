/* tslint:disable */
/*! modernizr 3.0.0-alpha.4 (Custom Build) | MIT *
 * http://modernizr.com/download/#-csscolumns-csstransforms-csstransforms3d-cssvwunit-flexbox-inlinesvg-touchevents !*/
!(function (e, n, t) {
  function r(e, n) {
    return typeof e === n;
  }
  function s() {
    var e, n, t, s, o, i, a;
    for (var f in C) {
      if (
        ((e = []),
        (n = C[f]),
        n.name &&
          (e.push(n.name.toLowerCase()),
          n.options && n.options.aliases && n.options.aliases.length))
      )
        for (t = 0; t < n.options.aliases.length; t++)
          e.push(n.options.aliases[t].toLowerCase());
      for (s = r(n.fn, "function") ? n.fn() : n.fn, o = 0; o < e.length; o++)
        (i = e[o]),
          (a = i.split(".")),
          1 === a.length
            ? (Modernizr[a[0]] = s)
            : (!Modernizr[a[0]] ||
                Modernizr[a[0]] instanceof Boolean ||
                (Modernizr[a[0]] = new Boolean(Modernizr[a[0]])),
              (Modernizr[a[0]][a[1]] = s)),
          y.push((s ? "" : "no-") + a.join("-"));
    }
  }
  function o(e) {
    var n = S.className,
      t = Modernizr._config.classPrefix || "";
    if ((x && (n = n.baseVal), Modernizr._config.enableJSClass)) {
      var r = new RegExp("(^|\\s)" + t + "no-js(\\s|$)");
      n = n.replace(r, "$1" + t + "js$2");
    }
    Modernizr._config.enableClasses &&
      ((n += " " + t + e.join(" " + t)),
      x ? (S.className.baseVal = n) : (S.className = n));
  }
  function i() {
    return "function" != typeof n.createElement
      ? n.createElement(arguments[0])
      : x
        ? n.createElementNS.call(n, "http://www.w3.org/2000/svg", arguments[0])
        : n.createElement.apply(n, arguments);
  }
  function a() {
    var e = n.body;
    return e || ((e = i(x ? "svg" : "body")), (e.fake = !0)), e;
  }
  function f(e, t, r, s) {
    var o,
      f,
      u,
      l,
      d = "modernizr",
      c = i("div"),
      p = a();
    if (parseInt(r, 10))
      for (; r--; )
        (u = i("div")), (u.id = s ? s[r] : d + (r + 1)), c.appendChild(u);
    return (
      (o = i("style")),
      (o.type = "text/css"),
      (o.id = "s" + d),
      (p.fake ? p : c).appendChild(o),
      p.appendChild(c),
      o.styleSheet
        ? (o.styleSheet.cssText = e)
        : o.appendChild(n.createTextNode(e)),
      (c.id = d),
      p.fake &&
        ((p.style.background = ""),
        (p.style.overflow = "hidden"),
        (l = S.style.overflow),
        (S.style.overflow = "hidden"),
        S.appendChild(p)),
      (f = t(c, e)),
      p.fake
        ? (p.parentNode.removeChild(p), (S.style.overflow = l), S.offsetHeight)
        : c.parentNode.removeChild(c),
      !!f
    );
  }
  function u(e, n) {
    return !!~("" + e).indexOf(n);
  }
  function l(e) {
    return e
      .replace(/([a-z])-([a-z])/g, function (e, n, t) {
        return n + t.toUpperCase();
      })
      .replace(/^-/, "");
  }
  function d(e, n) {
    return function () {
      return e.apply(n, arguments);
    };
  }
  function c(e, n, t) {
    var s;
    for (var o in e)
      if (e[o] in n)
        return t === !1
          ? e[o]
          : ((s = n[e[o]]), r(s, "function") ? d(s, t || n) : s);
    return !1;
  }
  function p(e) {
    return e
      .replace(/([A-Z])/g, function (e, n) {
        return "-" + n.toLowerCase();
      })
      .replace(/^ms-/, "-ms-");
  }
  function m(n, r) {
    var s = n.length;
    if ("CSS" in e && "supports" in e.CSS) {
      for (; s--; ) if (e.CSS.supports(p(n[s]), r)) return !0;
      return !1;
    }
    if ("CSSSupportsRule" in e) {
      for (var o = []; s--; ) o.push("(" + p(n[s]) + ":" + r + ")");
      return (
        (o = o.join(" or ")),
        f(
          "@supports (" + o + ") { #modernizr { position: absolute; } }",
          function (e) {
            return "absolute" == getComputedStyle(e, null).position;
          },
        )
      );
    }
    return t;
  }
  function v(e, n, s, o) {
    function a() {
      d && (delete A.style, delete A.modElem);
    }
    if (((o = r(o, "undefined") ? !1 : o), !r(s, "undefined"))) {
      var f = m(e, s);
      if (!r(f, "undefined")) return f;
    }
    for (var d, c, p, v, h, g = ["modernizr", "tspan"]; !A.style; )
      (d = !0), (A.modElem = i(g.shift())), (A.style = A.modElem.style);
    for (p = e.length, c = 0; p > c; c++)
      if (
        ((v = e[c]),
        (h = A.style[v]),
        u(v, "-") && (v = l(v)),
        A.style[v] !== t)
      ) {
        if (o || r(s, "undefined")) return a(), "pfx" == n ? v : !0;
        try {
          A.style[v] = s;
        } catch (y) {}
        if (A.style[v] != h) return a(), "pfx" == n ? v : !0;
      }
    return a(), !1;
  }
  function h(e, n, t, s, o) {
    var i = e.charAt(0).toUpperCase() + e.slice(1),
      a = (e + " " + P.join(i + " ") + i).split(" ");
    return r(n, "string") || r(n, "undefined")
      ? v(a, n, s, o)
      : ((a = (e + " " + E.join(i + " ") + i).split(" ")), c(a, n, t));
  }
  function g(e, n, r) {
    return h(e, t, t, n, r);
  }
  var y = [],
    C = [],
    w = {
      _version: "3.0.0-alpha.4",
      _config: {
        classPrefix: "",
        enableClasses: !0,
        enableJSClass: !0,
        usePrefixes: !0,
      },
      _q: [],
      on: function (e, n) {
        var t = this;
        setTimeout(function () {
          n(t[e]);
        }, 0);
      },
      addTest: function (e, n, t) {
        C.push({ name: e, fn: n, options: t });
      },
      addAsyncTest: function (e) {
        C.push({ name: null, fn: e });
      },
    },
    Modernizr = function () {};
  (Modernizr.prototype = w), (Modernizr = new Modernizr());
  var S = n.documentElement,
    x = "svg" === S.nodeName.toLowerCase(),
    b = w._config.usePrefixes ? " -webkit- -moz- -o- -ms- ".split(" ") : [];
  w._prefixes = b;
  var _ = "CSS" in e && "supports" in e.CSS,
    T = "supportsCSS" in e;
  Modernizr.addTest("supports", _ || T),
    Modernizr.addTest("inlinesvg", function () {
      var e = i("div");
      return (
        (e.innerHTML = "<svg/>"),
        "http://www.w3.org/2000/svg" ==
          ("undefined" != typeof SVGRect &&
            e.firstChild &&
            e.firstChild.namespaceURI)
      );
    });
  var k = (w.testStyles = f);
  Modernizr.addTest("touchevents", function () {
    var t;
    if ("ontouchstart" in e || (e.DocumentTouch && n instanceof DocumentTouch))
      t = !0;
    else {
      var r = [
        "@media (",
        b.join("touch-enabled),("),
        "heartz",
        ")",
        "{#modernizr{top:9px;position:absolute}}",
      ].join("");
      k(r, function (e) {
        t = 9 === e.offsetTop;
      });
    }
    return t;
  }),
    k("#modernizr { width: 50vw; }", function (n) {
      var t = parseInt(e.innerWidth / 2, 10),
        r = parseInt(
          (e.getComputedStyle ? getComputedStyle(n, null) : n.currentStyle)
            .width,
          10,
        );
      Modernizr.addTest("cssvwunit", r == t);
    });
  var z = "Moz O ms Webkit",
    P = w._config.usePrefixes ? z.split(" ") : [];
  w._cssomPrefixes = P;
  var E = w._config.usePrefixes ? z.toLowerCase().split(" ") : [];
  w._domPrefixes = E;
  var j = { elem: i("modernizr") };
  Modernizr._q.push(function () {
    delete j.elem;
  });
  var A = { style: j.elem.style };
  Modernizr._q.unshift(function () {
    delete A.style;
  }),
    (w.testAllProps = h),
    (w.testAllProps = g),
    Modernizr.addTest("flexbox", g("flexBasis", "1px", !0)),
    (function () {
      Modernizr.addTest("csscolumns", function () {
        var e = !1,
          n = g("columnCount");
        try {
          (e = !!n) && (e = new Boolean(e));
        } catch (t) {}
        return e;
      });
      for (
        var e,
          n,
          t = [
            "Width",
            "Span",
            "Fill",
            "Gap",
            "Rule",
            "RuleColor",
            "RuleStyle",
            "RuleWidth",
            "BreakBefore",
            "BreakAfter",
            "BreakInside",
          ],
          r = 0;
        r < t.length;
        r++
      )
        (e = t[r].toLowerCase()),
          (n = g("column" + t[r])),
          ("breakbefore" === e || "breakafter" === e || "breakinside" == e) &&
            (n = n || g(t[r])),
          Modernizr.addTest("csscolumns." + e, n);
    })(),
    Modernizr.addTest("csstransforms", function () {
      return (
        -1 === navigator.userAgent.indexOf("Android 2.") &&
        g("transform", "scale(1)", !0)
      );
    }),
    Modernizr.addTest("csstransforms3d", function () {
      var e = !!g("perspective", "1px", !0),
        n = Modernizr._config.usePrefixes;
      if (e && (!n || "webkitPerspective" in S.style)) {
        var t;
        Modernizr.supports
          ? (t = "@supports (perspective: 1px)")
          : ((t = "@media (transform-3d)"),
            n && (t += ",(-webkit-transform-3d)")),
          (t +=
            "{#modernizr{left:9px;position:absolute;height:5px;margin:0;padding:0;border:0}}"),
          k(t, function (n) {
            e = 9 === n.offsetLeft && 5 === n.offsetHeight;
          });
      }
      return e;
    }),
    s(),
    o(y),
    delete w.addTest,
    delete w.addAsyncTest;
  for (var N = 0; N < Modernizr._q.length; N++) Modernizr._q[N]();
  e.Modernizr = Modernizr;
})(window, document);
/* tslint:enable*/
