import { autobind } from "core-decorators";
import { setSessionCookie, getCookie } from "../globals/functions";

const ids = {
  NavigationBlockId: "navigation-block-id",
  NavigationBlockAlertId: "navigation-block-alert-id",
  NavigationBlockAlertCloseButtonId: "navigation-block-alert-close-button-id",
  NavigationBlockDesktopSubMenusContainerId:
    "navigation-block-desktop-sub-menus-container-id",
  NavBlockSpacer: ".nav-block-spacer",
};

export class NavigationBlockAlert {
  $navigationBlockAlertCloseButton: HTMLElement;
  $navigationBlockAlert: HTMLElement;
  $navigationBlockDesktopSubMenusContainer: HTMLDivElement;
  $navigationBlock: HTMLDivElement;
  $NavBlockSpacer: HTMLElement;

  constructor() {
    this.$navigationBlock = document.getElementById(
      ids.NavigationBlockId,
    ) as HTMLDivElement;
    this.$navigationBlockAlertCloseButton = document.getElementById(
      ids.NavigationBlockAlertCloseButtonId,
    );
    this.$navigationBlockAlert = document.getElementById(
      ids.NavigationBlockAlertId,
    );
    this.$navigationBlockDesktopSubMenusContainer = document.getElementById(
      ids.NavigationBlockDesktopSubMenusContainerId,
    ) as HTMLDivElement;
    this.$NavBlockSpacer = document.querySelector(
      ids.NavBlockSpacer,
    ) as HTMLElement;

    this.init();
    this.bindEvents();
  }

  @autobind
  init(): void {
    if (!getCookie("umpq_home_alert")) {
      if (this.$navigationBlockAlert) {
        this.$navigationBlockAlert.classList.remove("hidden");
        this.setDesktopSubMenusContainerTop();
      }
    }
  }

  @autobind
  bindEvents(): void {
    if (this.$navigationBlockAlertCloseButton) {
      this.$navigationBlockAlertCloseButton.addEventListener(
        "click",
        this.closeNavigationBlockAlert,
      );
    }
  }

  @autobind
  closeNavigationBlockAlert(): void {
    if (this.$navigationBlockAlert) {
      this.$navigationBlockAlert.classList.add("hidden");
      this.$navigationBlockAlert.style.height = "0";
      this.setDesktopSubMenusContainerTop();
    }
    setSessionCookie("umpq_home_alert", "1");
    this.$NavBlockSpacer.style.height =
      this.$navigationBlock.clientHeight + "px";
  }

  @autobind
  setDesktopSubMenusContainerTop() {
    if (this.$navigationBlockDesktopSubMenusContainer !== null) {
      let navigationHeight =
        this.$navigationBlock.getBoundingClientRect().height;
      if (navigationHeight < 61) {
        navigationHeight = 61;
      }

      this.$navigationBlockDesktopSubMenusContainer.style.top =
        navigationHeight + "px";
    }
  }
}
