import { autobind } from "core-decorators";
import { EventBus } from "../globals/emitter";
import { GLOBAL_CONSTANTS } from "../globals/constants";

export class Dropdown {
  $el: HTMLSelectElement;
  type: string;

  constructor(el: HTMLSelectElement) {
    this.$el = el;
    this.type = (this.$el.dataset as any).filterType;

    this.bindEvents();
  }

  bindEvents() {
    this.$el.addEventListener("change", this.handleMouseClick);
  }

  @autobind
  handleMouseClick(e: MouseEvent) {
    e.preventDefault();
    const value = this.$el.value;
    EventBus.fire(GLOBAL_CONSTANTS.EVENTS.APPLY_FILTER, {
      type: this.type,
      tag: value,
    });
  }

  @autobind
  tearDown() {
    this.$el.removeEventListener("change", this.handleMouseClick);
  }
}
