// ReSharper disable ElidedTrailingElement
import { AutoSideNav } from "../blocks-and-views/auto-side-nav";
import { ComparisonTable } from "../blocks-and-views/comparison-table";
import { LoanOfficerCTA } from "../blocks-and-views/loan-officer-cta";
import { AssociateLocationSearch } from "../blocks-and-views/associate-location-search";
import { SideBySide } from "../blocks-and-views/side-by-side";
import { ProductComparisonTable } from "../blocks-and-views/product-comparison-table";
import { TabbedFeatured } from "../blocks-and-views/tabbed-featured-block";

import { MortgageCalculator } from "../calculators/mortgage-calculator";
import { VehicleCalculator } from "../calculators/vehicle-calculator";

import { Choices } from "../effects/animation/choices";
import { EventAnimation } from "../effects/animation/event-animation";
import { PageLoadAnimation } from "../effects/animation/page-load-animation";
import { ScrollAnimation } from "../effects/animation/scroll-animation";

import { HomeAlert } from "../effects/navigation/home-alert";
import { NavMenu } from "../effects/navigation/nav-menu";
import { NavMobileMenu } from "../effects/navigation/nav-mobile-menu";
import { ShyNav } from "../effects/navigation/shy-nav";
import { Stepper } from "../effects/navigation/stepper";

import { DraggableCards } from "../effects/scrolling/draggable-cards";
import { Parallax } from "../effects/scrolling/parallax";
import { ScrollTo } from "../effects/scrolling/scrollTo";
import { StickyContent } from "../effects/scrolling/sticky-content";
import { TestimonialCards } from "../effects/scrolling/testimonial-cards";

import { CrosslinkGrid } from "../effects/display/crosslink-grid";
import { Drawer } from "../effects/display/drawer";
import { Flippable } from "../effects/display/flippable";

import { Dropdown } from "../elements/dropdown";
import { Form } from "../elements/form";
import { Input } from "../elements/input";

import { BusinessBankingLogin } from "../login/business-banking-login";
import { HomeLogin } from "../login/home-login";
import { Login } from "../login/login";
import { PersonalBankingLogin } from "../login/personal-banking-login";

import { Map } from "../map";
import { Yext } from "../map/yext/yext";

import { Podcast } from "../media/podcast";
import { SocialShare } from "../media/social-share";
import { VideoBackground } from "../media/video-background";
import { YoutubeVideo } from "../media/youtube-video";

import { BlogRollSearch } from "../searches/blog-roll-search";
import { Filter } from "../searches/filter";
import { IndependentSearch } from "../searches/independent-search";
import { InlineSearchBar } from "../searches/inline-search-bar";
import { Search } from "../searches/search";
import { ModalForm } from "../blocks-and-views/modal-form";
import { EloquaForm } from "../blocks-and-views/eloqua-form";

import { NavigationBlock } from "../navigation-block/navigation-block";
import { NavigationBlockAlert } from "../navigation-block/navigation-block-alert";
import { NavigationBlockMenu } from "../navigation-block/navigation-block-menu";
import { NavigationBlockMobileMenu } from "../navigation-block/navigation-block-mobile-menu";

interface IModulesInterface {
  name: string;
  class: string;
  source: any;
}

/**
 * @desc This is a simple way to pair
 * all useable modules with a DOM class and a
 * human-readible name (just in case we need to access instances later).
 * This file is imported into app.ts in order to automatically instantiate
 * all of these modules on page load for each instance of the provided class.
 * Your module will ONLY be loaded if you put it in here OR explicitly instantiate
 * it in app.ts (only for special cases).
 */
export const modules: Array<IModulesInterface> = [
  {
    name: "ComparisonTable",
    class: ".js-comparison-table",
    source: ComparisonTable,
  },
  {
    name: "LoanOfficerCTA",
    class: ".js-lo-cta",
    source: LoanOfficerCTA,
  },
  {
    name: "SideBySide",
    class: ".js-side-by-side",
    source: SideBySide,
  },
  {
    name: "AutoSideNav",
    class: ".js-auto-side-nav",
    source: AutoSideNav,
  },
  {
    name: "ProductComparisonTable",
    class: ".js-product-comparison-table",
    source: ProductComparisonTable,
  },
  {
    name: "TabbedFeatured",
    class: ".js-tab-headers",
    source: TabbedFeatured,
  },
  {
    name: "MortgageCalculator",
    class: ".js-mortgage-calculator",
    source: MortgageCalculator,
  },
  {
    name: "VehicleCalculator",
    class: ".js-vehicle-calculator",
    source: VehicleCalculator,
  },
  {
    name: "Choices",
    class: ".js-choices",
    source: Choices,
  },
  {
    name: "Event-Animation",
    class: ".js-event-animate",
    source: EventAnimation,
  },
  {
    name: "PageLoadAnimation",
    class: ".js-page-load-animation",
    source: PageLoadAnimation,
  },
  {
    name: "Scroll-Animation",
    class: ".js-scroll-animate",
    source: ScrollAnimation,
  },
  {
    name: "CrosslinkGrid",
    class: ".js-crosslink-grid",
    source: CrosslinkGrid,
  },
  {
    name: "Drawer",
    class: ".js-drawer",
    source: Drawer,
  },
  {
    name: "Flippable",
    class: ".js-flippable",
    source: Flippable,
  },
  {
    name: "HomeAlert",
    class: ".js-home-alert",
    source: HomeAlert,
  },
  {
    name: "NavMenu",
    class: ".navigation",
    source: NavMenu,
  },
  {
    name: "NavMobileMenu",
    class: ".js-nav-mobile-menu",
    source: NavMobileMenu,
  },
  {
    name: "ShyNav",
    class: ".js-shy-nav",
    source: ShyNav,
  },
  {
    name: "Stepper",
    class: ".js-stepper",
    source: Stepper,
  },
  {
    name: "DraggableCards",
    class: ".js-draggable-cards",
    source: DraggableCards,
  },
  {
    name: "Parallax",
    class: ".js-parallax",
    source: Parallax,
  },
  {
    name: "ScrollTo",
    class: ".js-scroll-to",
    source: ScrollTo,
  },
  {
    name: "StickyContent",
    class: ".js-sticky-content",
    source: StickyContent,
  },
  {
    name: "TestimonialCards",
    class: ".js-associate-testimonials",
    source: TestimonialCards,
  },
  {
    name: "Dropdown",
    class: ".js-dropdown",
    source: Dropdown,
  },
  {
    name: "Form",
    class: ".js-form",
    source: Form,
  },
  {
    name: "Input",
    class: ".js-input",
    source: Input,
  },
  {
    name: "BusinessBankingLogin",
    class: ".js-business-banking-login-form",
    source: BusinessBankingLogin,
  },
  {
    name: "HomeLogin",
    class: ".js-home-login-form",
    source: HomeLogin,
  },
  {
    name: "Login",
    class: ".js-login",
    source: Login,
  },
  {
    name: "PersonalBankingLogin",
    class: ".js-personal-banking-login-form",
    source: PersonalBankingLogin,
  },
  {
    name: "Map",
    class: ".js-map",
    source: Map,
  },
  {
    name: "Yext",
    class: ".js-yext",
    source: Yext,
  },
  {
    name: "Podcast",
    class: ".js-podcast",
    source: Podcast,
  },
  {
    name: "SocialShare",
    class: ".js-social-share",
    source: SocialShare,
  },
  {
    name: "VideoBackground",
    class: ".js-video-background",
    source: VideoBackground,
  },
  {
    name: "YoutubeVideo",
    class: ".js-youtube",
    source: YoutubeVideo,
  },
  {
    name: "AssociateLocationSearch",
    class: ".js-associate-location-search",
    source: AssociateLocationSearch,
  },
  {
    name: "BlogRollSearch",
    class: ".js-blog-roll-search",
    source: BlogRollSearch,
  },
  {
    name: "Filter",
    class: ".js-filter",
    source: Filter,
  },
  {
    name: "IndependentSearch",
    class: ".js-independent-search",
    source: IndependentSearch,
  },
  {
    name: "InlineSearchBar",
    class: ".js-inline-search-bar",
    source: InlineSearchBar,
  },
  {
    name: "Search",
    class: ".js-search",
    source: Search,
  },
  {
    name: "ModalForm",
    class: ".js-modal-form-block",
    source: ModalForm,
  },
  {
    name: "EloquaForm",
    class: ".js-eloqua-form-block",
    source: EloquaForm,
  },
  {
    name: "NavigationBlock",
    class: ".js-navigation-block",
    source: NavigationBlock,
  },
  {
    name: "NavigationBlockAlert",
    class: ".js-navigation-block-alert",
    source: NavigationBlockAlert,
  },
  {
    name: "NavigationBlockMenu",
    class: ".js-navigation-block-menu",
    source: NavigationBlockMenu,
  },
  {
    name: "TabbedFeatured",
    class: ".js-tab-headers",
    source: TabbedFeatured,
  },
  {
    name: "NavigationBlockMobileMenu",
    class: ".js-navigation-block-mobile-menu",
    source: NavigationBlockMobileMenu,
  },
];
