import { TweenLite, gsap, CSSPlugin } from "gsap";
import { IAnimation } from "./i-animation";
import { GLOBAL_CONSTANTS } from "../../globals/constants";
import { EventBus } from "../../globals/emitter";

gsap.registerPlugin(CSSPlugin);

/**
 * @desc Factory function that allows us to simply generate
 * an animation for both directions.
 * @param {HTMLElement} el - The thing we want to animate
 * @param {Object} forward - Tween parameters to animate from resting state to.
 * @param {Object} reverse - Tween parameters and initial state we want to set the animation to.
 */
function generateAnimation(
  el: HTMLElement,
  forward: Object,
  reverse: Object,
  duration = GLOBAL_CONSTANTS.ANIMATION_TIMEOUT,
  ease = "power1",
): IAnimation {
  TweenLite.set(el, reverse);
  const timing = duration / 1000;
  return {
    forward: (delay = 0) => {
      return new Promise((resolve): void => {
        TweenLite.to(el, timing, {
          ...forward,
          delay,
          ease,
          onComplete: () => {
            return resolve(0);
          },
        });
      });
    },
    reverse: (delay = 0) => {
      return new Promise((resolve): void => {
        TweenLite.to(el, timing, {
          ...reverse,
          delay,
          ease,
          onComplete: () => {
            return resolve(0);
          },
        });
      });
    },
    reset: () => {
      return new Promise((resolve): void => {
        TweenLite.set(el, reverse);
        return resolve(0);
      });
    },
  };
}

/**
 * @desc Animation that animates opacity from 0 - 1 and position from below to resting.
 * @param {HTMLElement} el - Node to animate
 */
export const fadeInDown = (el: HTMLElement): IAnimation => {
  return generateAnimation(
    el,
    { y: 0, opacity: 1 },
    { y: -GLOBAL_CONSTANTS.ANIMATION.OFFSET, opacity: 0 },
  );
};

/**
 * @desc Animation that animates opacity from 0 - 1 and position from above to resting.
 * @param {HTMLElement} el - Node to animate
 */
export const fadeInUp = (el: HTMLElement): IAnimation => {
  return generateAnimation(
    el,
    { transform: "translate3d(0, 0, 0)", opacity: "1" },
    { transform: "translate3d(0, 50px, 0)", opacity: "0" },
  );
};

/**
 * @desc Animation that animates opacity from 0 - 1 and position from above to resting.
 * @param {HTMLElement} el - Node to animate
 */
export const fadeOutUp = (el: HTMLElement): IAnimation => {
  return generateAnimation(
    el,
    { transform: "translate3d(0, -50px, 0)", opacity: "0" },
    { transform: "translate3d(0, 0, 0)", opacity: "1" },
  );
};

/**
 * @desc Animation that animates opacity from 0 - 1 and position from above to resting.
 * @param {HTMLElement} el - Node to animate
 */
export const moveUp = (el: HTMLElement): IAnimation => {
  return generateAnimation(el, { y: 0 }, { y: 50 });
};

/**
 * @desc Animation that animates opacity from 0 - 1 and position from above to resting.
 * @param {HTMLElement} el - Node to animate
 */
export const moveLeft = (
  el: HTMLElement,
  startPos: number,
  endPos: number,
): IAnimation => {
  return generateAnimation(
    el,
    { x: endPos },
    { x: startPos },
    675,
    "power1.out",
  );
};

/**
 * @desc Animation that animates opacity from 0 - 1 and position from above to resting.
 * @param {HTMLElement} el - Node to animate
 */
export const moveRight = (
  el: HTMLElement,
  startPos: number,
  endPos: number,
): IAnimation => {
  return generateAnimation(
    el,
    { x: endPos },
    { x: startPos },
    675,
    "power1.out",
  );
};

/**
 * @desc Animation that animates opacity from 0 - 1 and position from above to resting.
 * @param {HTMLElement} el - Node to animate
 */
export const fadeInUpSlow = (el: HTMLElement): IAnimation => {
  return generateAnimation(
    el,
    { transform: "translate3d(0, 0, 0)", opacity: "1" },
    { transform: "translate3d(0, 50px, 0)", opacity: "0" },
    675,
    "power1",
  );
};

/**
 * @desc Animation that animates opacity from 0 - 1 and position from above to resting.
 * @param {HTMLElement} el - Node to animate
 */
export const fadeInUpSlowChoices = (el: HTMLElement): IAnimation => {
  return generateAnimation(
    el,
    { transform: "translate3d(0, 0, 0)", opacity: "1" },
    { transform: "translate3d(0, 10px, 0)", opacity: "0" },
    675,
    "power1",
  );
};

/**
 * @desc Animation that animates opacity from 0 - 1.
 * @param {HTMLElement} el - Node to animate
 */
export const fadeIn = (el: HTMLElement): IAnimation => {
  return generateAnimation(el, { opacity: 1 }, { opacity: 0 });
};

/**
 * @desc Animation that animates opacity from 0 - 1.
 * @param {HTMLElement} el - Node to animate
 */
export const fadeInImg = (el: HTMLElement): IAnimation => {
  return generateAnimation(el, { opacity: 1 }, { opacity: 0 }, 1500);
};

/**
 * @desc Animate to height 100%.
 * @param {HTMLElement} el - Node to animate
 */
export const fullHeight = (el: HTMLElement): IAnimation => {
  return generateAnimation(el, { height: "100%" }, { height: 0 });
};

/**
 * @desc Animation that animates opacity from 0 - 1.
 * @param {HTMLElement} el - Node to animate
 */
export const edgeLeft = (el: HTMLElement): IAnimation => {
  return generateAnimation(el, { x: "-25%" }, { x: "-50%" });
};

export const marginTop = (el: HTMLElement, amount: string): IAnimation => {
  const style = window.getComputedStyle(el);
  const currentMargin = style.marginTop;
  return generateAnimation(
    el,
    { marginTop: amount },
    { marginTop: currentMargin },
  );
};

/**
 * @desc Animate background position up.
 * @param {HTMLElement} el - Node to animate
 */
export const moveBgUp = (el: HTMLElement): IAnimation => {
  return generateAnimation(
    el,
    { backgroundPosition: "50% 100%" },
    { backgroundPosition: "50% 50%" },
  );
};

export const scale = (el: HTMLElement): IAnimation => {
  return generateAnimation(
    el,
    { transform: "scale(1.1)" },
    { transform: "scale(1)" },
  );
};

export const scaleDown = (el: HTMLElement): IAnimation => {
  return generateAnimation(
    el,
    { transform: "scale(1)" },
    { transform: "scale(1.05)" },
  );
};

export const scaleDownChoices = (el: HTMLElement): IAnimation => {
  return generateAnimation(
    el,
    { transform: "scale(1)" },
    { transform: "scale(1.05)" },
    675,
    "power1.out",
  );
};

/**
 * @desc Animate to a specific height
 * @param {HTMLElement} el - Element to animate.
 * @param {Number} height - number to animate the element's height to.
 */
export const toHeight = (
  el: HTMLElement,
  height: number | string = 500,
): IAnimation => {
  const currHeight = el.getBoundingClientRect().height;
  return generateAnimation(el, { height }, { height: currHeight });
};

/**
 * @desc Animation that animates opacity from 1 - 0.
 * @param {HTMLElement} el - Node to animate
 */
export const fadeOut = (el: HTMLElement): IAnimation => {
  return generateAnimation(el, { opacity: 0 }, { opacity: 1 });
};

/**
 * @desc Animation that animates opacity from 1 - 0.
 * @param {HTMLElement} el - Node to animate
 */
export const fadeOutImg = (el: HTMLElement): IAnimation => {
  return generateAnimation(el, { opacity: 0 }, { opacity: 1 });
};

/**
 * @desc Animate rotation of an element by 180 degrees.
 * @param {HTMLElement} el - Node to animate
 */
export const rotate = (el: HTMLElement): IAnimation => {
  return generateAnimation(el, { rotation: 180 }, { rotation: 0 });
};

/**
 * @desc Animate line-height of an element from a large number to default.
 * @param {HTMLElement} el - Node to animate
 */
export const lineHeight = (el: HTMLElement): IAnimation => {
  const style = window.getComputedStyle(el);
  const dest = parseFloat(style.getPropertyValue("line-height"));
  const starting = dest + dest * 0.1;
  el.style.transition = "none";

  const resize = () => {
    el.style.removeProperty("line-height");
  };

  EventBus.on(GLOBAL_CONSTANTS.EVENTS.RESIZE, resize);

  return generateAnimation(
    el,
    { "line-height": `${dest}px` },
    { "line-height": `${starting}px` },
    GLOBAL_CONSTANTS.ANIMATION_TIMEOUT,
    "power1.none",
  );
};

/**
 * @desc Animate an element to a specific position.
 * @param {HTMLElement} el - Node to animate
 * @param {String} startPosition - position to start at.
 * @param {String} endPosition - position to end at.
 */
export const moveTo = (
  el: HTMLElement,
  startPosition: string,
  endPosition: string,
): IAnimation => {
  return generateAnimation(el, { top: endPosition }, { top: startPosition });
};

/**
 * @desc Export animates in a typed object so we can access them
 * by key dynamically later.
 */
export const animationDirectory: { [key: string]: Function } = {
  fadeIn,
  fadeInImg,
  fadeInDown,
  fadeInUp,
  fadeOut,
  fadeOutImg,
  fadeOutUp,
  fadeInUpSlow,
  fadeInUpSlowChoices,
  fullHeight,
  lineHeight,
  moveUp,
  moveLeft,
  moveRight,
};
