import { autobind } from "core-decorators";
import { getCookie } from "../../globals/functions";

const yextMyBranch = "myBranch";
const yextStoreName = "storeName";

export class Yext {
  $element: HTMLElement;
  $myBranch: string;
  $storeName: string;

  constructor(element: HTMLElement) {
    this.$element = element;
    this.$myBranch = getCookie(yextMyBranch);
    this.$storeName = getCookie(yextStoreName);

    this.init();
  }

  @autobind
  init() {
    // console.log('Yext cookie myBranch: ' + this.$myBranch);
    // console.log('Yext cookie storeName: ' + this.$storeName);
  }
}
