import { autobind } from "core-decorators";
import { EventBus } from "../../globals/emitter";
import { Stepper } from "../navigation/stepper";
import { GLOBAL_CONSTANTS } from "../../globals/constants";
import { TweenLite, Power1 } from "gsap";
import {
  fadeOut,
  fadeOutImg,
  fadeIn,
  fadeInImg,
  fadeInUpSlowChoices,
  scaleDownChoices,
  moveLeft,
  moveRight,
} from "./animations";
import { IAnimation } from "./i-animation";

const CONSTANTS = {
  THEMES: {
    PERSONAL: "personal",
    BUSINESS: "business",
    COMMERCIAL: "commercial",
    WEALTH: "wealth",
    DEFAULT: "default",
  },
  DIRECTIONS: {
    LEFT: "left",
    RIGHT: "right",
  },
  CLASSES: {
    BG: ".js-choices__bg",
    BODY: ".js-body",
    TRIGGER: ".js-choices__trigger",
    BODY_COPY: ".js-choices__body",
    STEPPER: ".choices__welcome",
    BGEDGE: ".choices__bg-container",
    IMG_HEADER: ".choices__bg",
    CHOSEN_PATH: "choosen-path",
  },
  DISTANCE: {
    LEFT: -75,
    RIGHT: 75,
  },
};

/**
 * @desc Simple component that shows / hides content
 */
export class Choices {
  $el: HTMLElement;
  $bg: HTMLElement;
  $content: HTMLElement;
  $triggers: Array<HTMLElement>;
  $headerImages: Array<HTMLElement>;
  stepper: Stepper;
  $bodyCopy: HTMLElement;
  style = "";
  edgeAnimation: IAnimation;
  bgAnimation: IAnimation;
  headlineAnimation: IAnimation;
  bodyCopyAnimation: IAnimation;
  buttonsAnimation: IAnimation;

  $stepContainer: HTMLElement;
  $bgEdge: HTMLElement;

  constructor(el: HTMLElement) {
    this.$el = el;
    this.$triggers = Array.from(
      this.$el.querySelectorAll(CONSTANTS.CLASSES.TRIGGER),
    ) as Array<HTMLElement>;
    this.$headerImages = Array.from(
      this.$el.querySelectorAll(CONSTANTS.CLASSES.IMG_HEADER),
    ) as Array<HTMLElement>;
    this.$bodyCopy = this.$el.querySelector(
      CONSTANTS.CLASSES.BODY_COPY,
    ) as HTMLElement;
    this.$bg = this.$el.querySelector(CONSTANTS.CLASSES.BG) as HTMLElement;
    this.$content = document.querySelector(
      CONSTANTS.CLASSES.BODY,
    ) as HTMLElement;
    this.stepper = new Stepper(this.$el, this.onProceed);
    this.$stepContainer = this.$el.querySelector(
      CONSTANTS.CLASSES.STEPPER,
    ) as HTMLElement;
    this.$bgEdge = this.$el.querySelector(
      CONSTANTS.CLASSES.BGEDGE,
    ) as HTMLElement;
    this.bodyCopyAnimation = fadeInUpSlowChoices(this.$bodyCopy);
    this.bgAnimation = scaleDownChoices(this.$bg);

    TweenLite.set(this.$bg, {
      x: "0%",
      y: "0px",
      opacity: "0",
    });

    this.bindEvents();
  }

  bindEvents() {
    this.bindHoverEvents();
    EventBus.on(
      GLOBAL_CONSTANTS.EVENTS.PRIORITY_FINISHED,
      this.kickoffLoadAnimation,
    );
  }

  @autobind
  async kickoffLoadAnimation() {
    TweenLite.to(
      this.$bg,
      GLOBAL_CONSTANTS.ANIMATION.SLOW_ANIMATION_DURATION / 1000,
      {
        y: 0,
        opacity: 1,
        ease: Power1.easeOut,
      },
    );
    this.bodyCopyAnimation.forward(0.3);
    this.bgAnimation.forward(0.3);
  }

  @autobind
  bindHoverEvents() {
    this.$triggers.forEach((item) => {
      item.addEventListener("mouseover", this.handleMouseOver);
      item.addEventListener("mouseout", this.handleMouseOut);
    });
  }

  unbindHoverEvents() {
    this.$triggers.forEach((item) => {
      item.removeEventListener("mouseover", this.handleMouseOver);
      item.removeEventListener("mouseout", this.handleMouseOut);
    });
  }

  getStyle(e: MouseEvent) {
    const tar = e.currentTarget as HTMLElement;
    const { style } = tar.dataset as any;

    return style;
  }

  @autobind
  handleMouseOver(e: MouseEvent) {
    const style = this.getStyle(e);

    this.style = style;
    const x = this.style === "0%";
    TweenLite.to(this.$bg, 1, {
      x,
      ease: Power1.easeOut,
    });
  }

  @autobind
  handleMouseOut() {
    this.style = "";
    TweenLite.to(this.$bg, 1, {
      x: "0%",
      ease: Power1.easeOut,
    });
  }

  @autobind
  async onProceed(
    $prev: HTMLElement,
    $next: HTMLElement,
    final?: string,
    element?: HTMLElement,
  ) {
    if (final) {
      const href = element.querySelector("a").href;
      this.handleFinal(href, $prev);
      return;
    }
    if (!$next) {
      return;
    } else if (!$prev) {
      $next.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
      return;
    }

    const { key } = $next.dataset;

    if (key === this.stepper.start) {
      if (this.style === CONSTANTS.THEMES.PERSONAL) {
        moveLeft($prev, 0, CONSTANTS.DISTANCE.LEFT).forward(0);
        this.moveMainStepChoises(
          CONSTANTS.DISTANCE.RIGHT,
          0,
          CONSTANTS.DIRECTIONS.LEFT,
          0.3,
        );
      } else if (this.style === CONSTANTS.THEMES.BUSINESS) {
        moveLeft($prev, 0, CONSTANTS.DISTANCE.LEFT).forward(0);
        this.moveMainStepChoises(
          CONSTANTS.DISTANCE.RIGHT,
          0,
          CONSTANTS.DIRECTIONS.LEFT,
          0.3,
        );
      } else if (this.style === CONSTANTS.THEMES.COMMERCIAL) {
        moveRight($prev, 0, CONSTANTS.DISTANCE.RIGHT).forward(0);
        this.moveMainStepChoises(
          CONSTANTS.DISTANCE.LEFT,
          0,
          CONSTANTS.DIRECTIONS.RIGHT,
          0.3,
        );
      } else if (this.style === CONSTANTS.THEMES.WEALTH) {
        moveRight($prev, 0, CONSTANTS.DISTANCE.RIGHT).forward(0);
        this.moveMainStepChoises(
          CONSTANTS.DISTANCE.LEFT,
          0,
          CONSTANTS.DIRECTIONS.RIGHT,
          0.3,
        );
      }

      this.bindHoverEvents();
      this.$el.classList.remove(this.style);

      this.$stepContainer.classList.remove(CONSTANTS.CLASSES.CHOSEN_PATH);
      this.$bgEdge.classList.remove(CONSTANTS.CLASSES.CHOSEN_PATH);
      this.$headerImages.forEach((item) => {
        if (!item.classList.contains(CONSTANTS.THEMES.DEFAULT)) {
          TweenLite.set(item, { x: "0%", y: "0px", opacity: "0" });
        } else {
          fadeInImg(item).forward(0);
        }
      });
    } else {
      if (this.style === CONSTANTS.THEMES.PERSONAL) {
        this.moveMainStepChoises(
          0,
          CONSTANTS.DISTANCE.RIGHT,
          CONSTANTS.DIRECTIONS.RIGHT,
          0,
        );
      } else if (this.style === CONSTANTS.THEMES.BUSINESS) {
        this.moveMainStepChoises(
          0,
          CONSTANTS.DISTANCE.RIGHT,
          CONSTANTS.DIRECTIONS.RIGHT,
          0,
        );
      } else if (this.style === CONSTANTS.THEMES.COMMERCIAL) {
        this.moveMainStepChoises(
          0,
          CONSTANTS.DISTANCE.LEFT,
          CONSTANTS.DIRECTIONS.LEFT,
          0,
        );
      } else if (this.style === CONSTANTS.THEMES.WEALTH) {
        this.moveMainStepChoises(
          0,
          CONSTANTS.DISTANCE.LEFT,
          CONSTANTS.DIRECTIONS.LEFT,
          0,
        );
      }
      const prevKey = $prev.dataset["key"];
      if (prevKey === this.stepper.start) {
        if (this.style === CONSTANTS.THEMES.PERSONAL) {
          moveRight($next, CONSTANTS.DISTANCE.LEFT, 0).forward(0.3);
        } else if (this.style === CONSTANTS.THEMES.BUSINESS) {
          moveRight($next, CONSTANTS.DISTANCE.LEFT, 0).forward(0.3);
        } else if (this.style === CONSTANTS.THEMES.COMMERCIAL) {
          moveLeft($next, CONSTANTS.DISTANCE.RIGHT, 0).forward(0.3);
        } else if (this.style === CONSTANTS.THEMES.WEALTH) {
          moveLeft($next, CONSTANTS.DISTANCE.RIGHT, 0).forward(0.3);
        }

        this.$headerImages.forEach((item) => {
          if (item.classList.contains(this.style)) {
            fadeInImg(item).forward(0);
          } else if (item.classList.contains(CONSTANTS.THEMES.DEFAULT)) {
            fadeOutImg(item).forward(0);
          }
        });
      }

      this.unbindHoverEvents();
      this.$el.classList.add(this.style);
      this.$stepContainer.classList.add(CONSTANTS.CLASSES.CHOSEN_PATH);
      this.$bgEdge.classList.add(CONSTANTS.CLASSES.CHOSEN_PATH);
    }

    await fadeOut($prev).forward(0);

    $prev.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
    $next.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
    fadeIn($next).forward(0);
  }

  /**
   * @desc If we're on the last step, meaning we want to depart
   * from this decision-tree and into a new page, we fire this function.
   * We fade out old content, ajax new content in (with fade).
   * @param {HTMLElement} target - Element to fade out.
   * @param {string} screen - Target page to load.
   */
  @autobind
  async handleFinal(screen: string, $prev: HTMLElement) {
    if (screen === "blank") {
      return;
    }
    if (this.style === CONSTANTS.THEMES.PERSONAL) {
      moveRight($prev, 0, CONSTANTS.DISTANCE.RIGHT).forward(0);
    } else if (this.style === CONSTANTS.THEMES.BUSINESS) {
      moveRight($prev, 0, CONSTANTS.DISTANCE.RIGHT).forward(0);
    } else if (this.style === CONSTANTS.THEMES.COMMERCIAL) {
      moveLeft($prev, 0, CONSTANTS.DISTANCE.LEFT).forward(0);
    } else if (this.style === CONSTANTS.THEMES.WEALTH) {
      moveLeft($prev, 0, CONSTANTS.DISTANCE.LEFT).forward(0);
    }
    await fadeOut(this.$el).forward(0);

    (window as any).location.href = screen;
  }

  moveMainStepChoises(
    startPos: number,
    endPos: number,
    direction: string,
    forward: number,
  ) {
    if (direction === CONSTANTS.DIRECTIONS.LEFT) {
      moveLeft(this.$bodyCopy, startPos, endPos).forward(forward);
    } else if (direction === CONSTANTS.DIRECTIONS.RIGHT) {
      moveRight(this.$bodyCopy, startPos, endPos).forward(forward);
    }
  }
}
