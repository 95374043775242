import { autobind } from "core-decorators";
import { EventBus } from "../globals/emitter";
import { fadeIn } from "../effects/animation/animations";
import { GLOBAL_CONSTANTS } from "../globals/constants";
import { checkStatus } from "../globals/functions";
import { LazyLoader } from "../elements/lazy-loader";
import { Podcast } from "../media/podcast";
import "whatwg-fetch";

declare var gtm: any;

const constants = {
  classes: {
    container: ".js-blog-roll-container",
    dropDown: ".js-dropdown",
    item: ".js-filter-item",
    showMore: ".js-blog-roll-show-more",
    dropMenuContainer: ".js-drop-menu__container",
    noResults: ".no-results-found",
  },
  ids: {
    categoryTag: "CategoryTag",
    mediaTag: "MediaTag",
  },
  types: {
    category: "category",
    media: "media",
  },
};

/**
 * BlogRollSearch - class that will filter blog entries based on selected filter types
 */
export class BlogRollSearch {
  $el: HTMLElement;
  $container: HTMLElement;
  $dropMenuContainer: HTMLElement;
  $categoryTagElement: HTMLSelectElement;
  $mediaTagElement: HTMLSelectElement;
  $items: Array<HTMLAnchorElement>;
  $showMore: HTMLElement;
  $noResults: HTMLElement;
  lazy: LazyLoader;
  numberOfItemsToSkip = 0;
  maxNumberOfItems = 9;
  mediaTag = "";
  mediaTagValue = "";
  categoryTag = "";
  categoryTagValue = "";

  $podcasts: Array<Podcast> = [];

  /**
   * @desc constructor - grab relevant DOM elements and kickoff element bind
   * @param {HTMLElement} el - Containing element for the filter
   */
  constructor(el: HTMLElement) {
    this.$el = el;
    this.$container = this.$el.querySelector(
      constants.classes.container,
    ) as HTMLElement;
    this.getFilterDropDownElements();
    this.getFilterDropDownValues();
    this.$showMore = this.$el.querySelector(
      constants.classes.showMore,
    ) as HTMLElement;
    this.$noResults = this.$el.querySelector(
      constants.classes.noResults,
    ) as HTMLElement;
    this.lazy = new LazyLoader();
    this.bindEvents();
  }

  /**
   * @desc bindEvents - Loop through all filters and add event listeners to
   * all filter triggers
   */
  bindEvents() {
    EventBus.on(GLOBAL_CONSTANTS.EVENTS.APPLY_FILTER, this.implementSearch);
    if (this.$showMore) {
      this.$showMore.addEventListener("click", this.showMore);
    }
  }

  /**
   * @desc implementSearch - Mouse event that fires when a user selects a filter,
   * causes the application to check the type of filter and then re-run
   * filtering out logic.
   * trigger - click of Category or Media drop-downs
   * @param { string } type - type of filter clicked (category or media)
   * @param { string } tag - value of filter clicked (category or media value)
   */
  @autobind
  implementSearch() {
    this.getFilterDropDownValues();
    this.numberOfItemsToSkip = 0;
    if (this.$showMore) {
      this.$showMore.classList.remove("hidden");
    }
    this.applyFilters();
  }

  /**
   * @desc applyFilters - Run two different filter types, and then sort based on
   * selected filters / sort types.
   */
  @autobind
  applyFilters() {
    const url = this.$container.dataset["getUrl"];
    const getUrl = `${url}&mediaTag=${this.mediaTag}&categoryTag=${this.categoryTag}&numberOfItemsToSkip=${this.numberOfItemsToSkip}`;

    if (this.$showMore) {
      this.$showMore.classList.add("hidden");
    }

    fetch(getUrl, { method: "GET" })
      .then(checkStatus)
      .then((response: any) => {
        response.text().then(async (html: string) => {
          const div = document.createElement("div");
          div.insertAdjacentHTML("beforeend", html);
          this.$items = new Array<HTMLAnchorElement>();
          const items = div.querySelectorAll(constants.classes.item);

          if (items.length === 0) {
            this.$noResults.classList.remove("hidden");
          }
          if (items.length > 0) {
            this.$noResults.classList.add("hidden");
          }

          for (let index = 0; index < items.length; index++) {
            this.$items.push(items[index] as HTMLAnchorElement);
          }

          // cleanup container when doing a new search
          if (this.numberOfItemsToSkip === 0) {
            this.$container.innerHTML = "";
          }

          // append items one by one to run animations
          const len =
            this.$items.length > this.maxNumberOfItems
              ? this.maxNumberOfItems
              : this.$items.length;
          for (let i = 0; i < len; i++) {
            const item = this.$items[i];
            const podcastElements = item.getElementsByClassName("podcast");
            if (
              podcastElements !== null &&
              typeof podcastElements !== undefined &&
              podcastElements.length > 0
            ) {
              const podcast = podcastElements[0] as HTMLDivElement;
              this.$podcasts.push(new Podcast(podcast));
            }
            this.$container.appendChild(item);
            fadeIn(item).forward(i * 0.15);
          }
          const totalNumberOfItems = document.getElementsByClassName(
            constants.classes.item.substr(1),
          ).length;
          gtm.search.blog.json(
            totalNumberOfItems.toString(),
            this.numberOfItemsToSkip.toString(),
            this.categoryTagValue,
            this.mediaTagValue,
          );

          // hide Show More button when we don't have other results to retrieve from server
          if (
            this.$items.length > this.maxNumberOfItems &&
            this.$showMore &&
            this.$showMore.classList.contains("hidden")
          ) {
            this.$showMore.classList.remove("hidden");
          }
          if (
            this.$items.length <= this.maxNumberOfItems &&
            this.$showMore &&
            !this.$showMore.classList.contains("hidden")
          ) {
            this.$showMore.classList.add("hidden");
          }
          this.lazy.refreshImageArray();

          EventBus.fire(GLOBAL_CONSTANTS.EVENTS.ADJUST_GRID);
        });
      })
      .catch((error: any) => {
        gtm.events.json(
          "search-blog",
          "category:" + this.categoryTag + "|media:" + this.mediaTag,
          "api blog search error - " + error,
        );
        console.log("request failed", error);
      });
  }

  @autobind
  getFilterDropDownElements() {
    this.$dropMenuContainer = this.$el.querySelector(
      constants.classes.dropMenuContainer,
    ) as HTMLElement;
    if (this.$dropMenuContainer == null) {
      return;
    }

    const filterElements = this.$dropMenuContainer.getElementsByClassName(
      constants.classes.dropDown.substr(1),
    );
    if (filterElements == null || filterElements.length < 1) {
      return;
    }

    for (let index = 0; index < filterElements.length; index++) {
      const dropDownElement = filterElements[index] as HTMLSelectElement;
      if (dropDownElement == null) {
        continue;
      }

      const dropDownId = dropDownElement.getAttribute("id");
      if (dropDownId == null || dropDownId.length < 1) {
        continue;
      }

      switch (dropDownId) {
        case constants.ids.categoryTag:
          this.$categoryTagElement = dropDownElement;
          break;
        case constants.ids.mediaTag:
          this.$mediaTagElement = dropDownElement;
          break;
      }
    }
  }

  @autobind
  getFilterDropDownValues() {
    this.mediaTag = "";
    this.mediaTagValue = "";
    this.categoryTag = "";
    this.categoryTagValue = "";

    if (
      this.$mediaTagElement != null &&
      this.$mediaTagElement.selectedIndex >= 0
    ) {
      const selectedMediaOption =
        this.$mediaTagElement.options[this.$mediaTagElement.selectedIndex];
      if (
        selectedMediaOption.value != null &&
        selectedMediaOption.value.length > 0
      ) {
        this.mediaTagValue = gtm.utilities.sanitize(selectedMediaOption.value);
        this.mediaTag = encodeURIComponent(this.mediaTagValue);
      } else {
        this.mediaTagValue = "All Media";
      }
    }

    if (
      this.$categoryTagElement != null &&
      this.$categoryTagElement.selectedIndex >= 0
    ) {
      const selectedCategoryOption =
        this.$categoryTagElement.options[
          this.$categoryTagElement.selectedIndex
        ];
      if (
        selectedCategoryOption.value != null &&
        selectedCategoryOption.value.length > 0
      ) {
        this.categoryTagValue = gtm.utilities.sanitize(
          selectedCategoryOption.value,
        );
        this.categoryTag = encodeURIComponent(this.categoryTagValue);
      } else {
        this.categoryTagValue = "All Categories";
      }
    }
  }

  @autobind
  showMore() {
    this.numberOfItemsToSkip += this.maxNumberOfItems;
    this.applyFilters();
  }

  /**
   * @desc tearDown - remove filter events
   */
  tearDown() {
    EventBus.off(GLOBAL_CONSTANTS.EVENTS.APPLY_FILTER, this.implementSearch);
  }
}
