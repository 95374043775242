import { autobind } from "core-decorators";

const CLASSES = {
  WRAPPER: ".js-video-background",
};

export class VideoBackground {
  $el: HTMLElement;
  $wrappers: Array<HTMLElement>;

  constructor(el: HTMLElement) {
    if (!el) {
      return;
    }
    this.$el = el;
    this.$wrappers = Array.from(
      document.querySelectorAll(CLASSES.WRAPPER),
    ) as Array<HTMLElement>;

    for (const wrapper of this.$wrappers) {
      this.buildVideoBackground(wrapper);
    }
  }

  @autobind
  buildVideoBackground(w: HTMLElement): void {
    const videobg = w.dataset.videobg;
    const video = document.createElement("video");
    video.classList.add("video-background");
    video.setAttribute("loop", "");
    video.setAttribute("autoplay", "");
    video.setAttribute("muted", "");
    const source = document.createElement("source");
    source.setAttribute("src", videobg);
    source.setAttribute("type", "video/mp4");
    video.appendChild(source);
    w.appendChild(video);
    video.muted = true;
    video.autoplay = true;
  }
}
