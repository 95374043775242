import { autobind } from "core-decorators";
import { GLOBAL_CONSTANTS } from "../globals/constants";
import { fadeOut, fadeIn } from "../effects/animation/animations";
import { Input } from "./input";

const CLASSES = {
  STEP: ".js-form-step",
  SUBMIT: ".js-form-submit",
  EMAIL: ".js-form-email",
  NAME: ".js-form-name",
  BACK: ".js-form-back",
  MSG: ".js-form-usermsg",
  ERRORS: ".js-form-errors",
};

/**
 * @desc Basic form class that
 * handles email validation and parses
 * form data.
 */
export class Form {
  $el: HTMLElement;
  $submit: HTMLElement;
  $steps: Array<HTMLElement>;
  $email: HTMLInputElement;
  $name: HTMLInputElement;
  $msg: HTMLElement;
  $back: HTMLElement;
  emailInput: Input;
  current = 0;
  userName = "";
  passing = false;

  /**
   * @desc constructor - Set local variables to form elements and bind events triggered.
   * @param  {HTMLElement} el: HTMLElement parent form element.
   */
  constructor(el: HTMLElement) {
    this.$el = el;
    this.$submit = this.$el.querySelector(CLASSES.SUBMIT) as HTMLElement;
    this.$steps = Array.from(
      this.$el.querySelectorAll(CLASSES.STEP),
    ) as Array<HTMLElement>;
    this.$email = this.$el.querySelector(CLASSES.EMAIL) as HTMLInputElement;
    this.$name = this.$el.querySelector(CLASSES.NAME) as HTMLInputElement;
    this.$msg = this.$el.querySelector(CLASSES.MSG) as HTMLElement;
    this.$back = this.$el.querySelector(CLASSES.BACK) as HTMLElement;

    this.$steps[this.current].classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
    this.emailInput = new Input(this.$email);

    this.bindEvents();
  }

  /**
   * @desc bindEvents - Bind events for submitting the form / email validation.
   */
  bindEvents(): void {
    this.$submit.addEventListener("click", this.parseForm);

    if (this.$back) {
      this.$back.addEventListener("click", this.handleBack);
    }
  }

  /**
   * @desc handleBack - Go back to the last item in your browsers history.
   */
  handleBack(): void {
    window.history.back();
  }

  /**
   * @desc parseForm - Store the form data and do something with it.
   */
  @autobind
  parseForm(e: MouseEvent): void {
    e.preventDefault();
    this.passing = this.emailInput.passing;
    // initiated = this.emailInput.hasInitiated;

    if (this.passing) {
      this.$msg.innerText = this.$name.querySelector("input").value;

      this.proceed();

      // This is mimicking an ajax call,
      // this needs to be replaced with some real code.
      setTimeout(() => {
        this.proceed();
      }, 2000);
    } else {
      this.emailInput.validateEmail();
    }
  }

  /**
   * @desc async proceed - Handles animation between form steps
   */
  async proceed(): Promise<void> {
    const previous = this.$steps[this.current];
    await fadeOut(previous).forward(0);
    previous.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
    this.current++;
    const active = this.$steps[this.current];
    active.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
    await fadeIn(active).forward(0);
  }

  /**
   * @desc validateEmail - Take an email string and check for it's validity
   * using regex
   * @param  {string} email: Email string to test
   * @return {Boolean} Whether or not the string value is an email or not.
   */
  validateEmail(email: string): boolean {
    const regx =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regx.test(email);
  }
}
