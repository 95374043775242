// this file has been changed to:
// - prevent null reference exception error when video button is not added
// - add/remove a z-index so background is visible on IE for full width callout
import { autobind, debounce } from "core-decorators";
import { GLOBAL_CONSTANTS } from "../globals/constants";
import { EventBus } from "../globals/emitter";
import { seconds, loadYouTubeApi } from "../globals/functions";
import { fadeOut, fadeIn } from "../effects/animation/animations";

const CLASSES = {
  BUTTON: ".js-youtube-video-button",
  BUTTON_CIRCLE: ".js-youtube-video-button-circle",
  CONTENT: ".js-youtube-content",
  HOVER: ".js-video-hover",
  MUTED: "video--is-muted",
  PAUSE: ".js-youtube-pause",
  PAUSED: "video--is-paused",
  PLAYING: "video--is-playing",
  PROGRESS: ".js-youtube-progress-bar",
  PROGRESS_TIME: ".js-youtube-progress-time",
  PROGRESS_TOTAL: ".js-youtube-progress-total",
  TEXT_CONTENT: ".js-video__text-content",
  VIDEO: ".js-youtube-video",
  VIDEO_BG: ".js-youtube-bg",
  VIDEO_APPEND: ".js-youtube-video-append",
  VIDEO_CONTROLS: ".js-video-controls",
  VIDEO_MUTE: ".js-video-mute",
  VIDEO_RESET: ".js-video-reset",
};

/**
 * Class to be attached to a div
 * to create a youtube iframe and
 * associated controls
 */
export class YoutubeVideo {
  $el: HTMLElement;
  $video: HTMLElement;
  $videoAppend: HTMLElement;
  $videoControlsArr: Array<HTMLElement>;
  $videoHoverArr: Array<HTMLElement>;
  $videoMute: HTMLElement;
  $videoReset: HTMLElement;
  $pause: HTMLElement;
  $progress: HTMLElement;
  $progressTimeArr: Array<HTMLElement>;
  $progressTotalArr: Array<HTMLElement>;
  $playButton: HTMLElement;
  $videoBg: HTMLElement;
  $wrapper: HTMLElement;
  $content: HTMLElement;
  $textContent: HTMLElement;
  $circleButton: HTMLElement;
  interval: TimeRanges;
  player: YT.Player;
  videoId: string;
  isVideoPlaying: boolean;
  isVideoLoaded: boolean;
  isVideoShowing: boolean;
  timer: any;

  /**
   * @param {HTMLElement} el - container to attach map to
   * @desc Parse all relevant divs, and kickoff youtube build.
   * @desc This file was modified to prevent js error when video
   * button is not added
   */
  constructor(el: HTMLElement) {
    this.$el = el;
    this.$video = this.$el.querySelector(CLASSES.VIDEO) as HTMLElement;
    this.$videoAppend = this.$el.querySelector(
      CLASSES.VIDEO_APPEND,
    ) as HTMLElement;

    if (this.$videoAppend) {
      this.$videoControlsArr = Array.from(
        this.$el.querySelectorAll(CLASSES.VIDEO_CONTROLS),
      ) as Array<HTMLElement>;
      this.$videoHoverArr = Array.from(
        this.$el.querySelectorAll(CLASSES.HOVER),
      ) as Array<HTMLElement>;
      this.$videoMute = this.$el.querySelector(
        CLASSES.VIDEO_MUTE,
      ) as HTMLElement;
      this.$videoReset = this.$el.querySelector(
        CLASSES.VIDEO_RESET,
      ) as HTMLElement;
      this.$videoBg = this.$el.querySelector(CLASSES.VIDEO_BG) as HTMLElement;
      this.videoId = (this.$videoAppend.dataset as any).videoid;
      this.$pause = this.$el.querySelector(CLASSES.PAUSE) as HTMLElement;
      this.$progress = this.$el.querySelector(CLASSES.PROGRESS) as HTMLElement;
      this.$progressTimeArr = Array.from(
        this.$el.querySelectorAll(CLASSES.PROGRESS_TIME),
      ) as Array<HTMLElement>;
      this.$progressTotalArr = Array.from(
        this.$el.querySelectorAll(CLASSES.PROGRESS_TOTAL),
      ) as Array<HTMLElement>;
      this.$playButton = this.$el.querySelector(CLASSES.BUTTON) as HTMLElement;
      this.$circleButton = this.$el.querySelector(
        CLASSES.BUTTON_CIRCLE,
      ) as HTMLElement;
      this.$content = this.$el.querySelector(CLASSES.CONTENT) as HTMLElement;
      this.$textContent = this.$el.querySelector(
        CLASSES.TEXT_CONTENT,
      ) as HTMLElement;
      this.isVideoPlaying = false;
      this.isVideoLoaded = false;
      this.isVideoShowing = false;

      this.init();
    }
  }

  /**
   * @desc Initialize module when youtube api is ready
   */
  init(): void {
    this.$videoBg.style.zIndex = "1";
    loadYouTubeApi().then(this.initializeYouTubePlayer);
  }

  /**
   * @desc Bind play/pause events for video
   * Ensure the video is paused if modal is opened
   */
  @autobind
  bindEvents(): void {
    EventBus.on(GLOBAL_CONSTANTS.EVENTS.MODAL_OPEN, this.pauseVideo);
    this.$pause.addEventListener("click", this.pauseVideo);
    this.$videoReset.addEventListener("click", this.resetVideo);
    this.$videoReset.addEventListener("mousemove", this.handleMouseMove);
    this.$playButton.addEventListener("click", this.onPlayVideo);
    this.$videoHoverArr.forEach((element: HTMLElement) => {
      element.addEventListener("mousemove", this.handleMouseMove);
      element.addEventListener("click", this.handleMouseMove);
    });
    this.$videoMute.addEventListener("click", this.toggleMute);
    window.addEventListener("keyup", this.keyUpHandler);
  }

  /**
   * @desc Remove listeners and stop listening for resize in the Event bus
   */
  tearDown(): void {
    this.detachListeners();
    EventBus.off(GLOBAL_CONSTANTS.EVENTS.MODAL_OPEN, this.pauseVideo);
  }

  /**
   * @desc Detach listeners.
   */
  detachListeners(): void {
    this.$pause.removeEventListener("click", this.pauseVideo);
    this.$videoReset.removeEventListener("click", this.resetVideo);
    this.$videoReset.removeEventListener("mousemove", this.handleMouseMove);
    this.$playButton.removeEventListener("click", this.onPlayVideo);
    this.$videoHoverArr.forEach((element: HTMLElement) => {
      element.removeEventListener("mousemove", this.handleMouseMove);
    });
    this.$videoMute.removeEventListener("click", this.toggleMute);
    window.removeEventListener("keyup", this.keyUpHandler);
  }

  /**
   * @desc keyUpHandler - When user hits escape, reset video.
   * @param {KeyboardEvent} event - keyboard event.
   */
  @autobind
  keyUpHandler(event: KeyboardEvent): void {
    const key = event.keyCode;
    if (this.isVideoShowing) {
      if (key === GLOBAL_CONSTANTS.KEY_CODES.ESCAPE) {
        this.resetVideo(null);
      }
    }
  }

  /**
   * @desc Initialize the youTube player.
   */
  @autobind
  initializeYouTubePlayer(): void {
    this.player = new (window as any).YT.Player(this.$videoAppend, {
      videoId: this.videoId,
      playerVars: {
        showinfo: 0,
        rel: 0,
        controls: 0,
        modestbranding: 1,
        color: "white",
        enablejsapi: 1,
        version: 3,
      },
      events: {
        onReady: this.videoOnReady,
        onStateChange: this.videoStateChange,
      },
    });
  }

  /**
   * @desc When the youtube api says video is ready to play
   * bind click events and set ready const.
   */
  @autobind
  videoOnReady(): void {
    this.bindEvents();
    this.isVideoLoaded = true;

    this.$progressTotalArr.forEach((element: HTMLElement) => {
      element.innerText = seconds(this.player.getDuration());
    });
  }

  /**
   * @desc As video plays check to see if it is finished to reset
   * or continually update the progress bar display.
   * @param e - Youtube event data
   */
  @autobind
  videoStateChange(e: any): void {
    if (e.data === 0) {
      this.resetVideo(null);
      clearInterval(this.timer);
    } else if (e.data === YT.PlayerState.PLAYING) {
      this.progressBar();
      this.playState();
      this.isVideoPlaying = true;
    } else if (e.data === YT.PlayerState.PAUSED) {
      this.pauseState();
      this.isVideoPlaying = false;
    }
  }

  /**
   * @desc On click of play button, play the video
   * @param {Event} e - Mouse Click Event
   */
  @autobind
  onPlayVideo(e: Event): void {
    e.preventDefault();

    if (!this.isVideoPlaying) {
      this.playVideo();
    }

    this.isVideoShowing = true;
  }

  /**
   * @desc Add class to wrapper letting all children know
   * the video is playing. Set player const to true
   */
  @autobind
  playVideo(): void {
    this.player.playVideo();
  }

  /**
   * @desc set visual state of video player to playing
   */
  @autobind
  async playState(): Promise<void> {
    if (this.$textContent) {
      this.$textContent.classList.add(CLASSES.PLAYING);
      this.$textContent.classList.add("hidden"); // *** For IE
    } else if (this.$circleButton) {
      fadeOut(this.$circleButton).forward(0);
    }
    fadeOut(this.$videoBg).forward(0);
    this.$videoBg.style.zIndex = "";
    this.showControls();
    this.autoHideControls();
    this.$el.classList.add(CLASSES.PLAYING);
    this.$el.classList.remove(CLASSES.PAUSED);
    this.$playButton.classList.add("hidden"); // *** For IE
  }

  /**
   * @desc As video plays progress bar and time display updates
   * on a setInterval.
   */
  @autobind
  progressBar(): void {
    const playerTotalTime = this.player.getDuration();

    this.timer = setInterval(() => {
      const playerCurrentTime = this.player.getCurrentTime();
      const playerTimeDifference = (playerCurrentTime / playerTotalTime) * 100;

      this.$progress.style.width = playerTimeDifference + "%";
      this.$progressTimeArr.forEach((element: HTMLElement) => {
        element.innerText = seconds(playerCurrentTime);
      });
    }, 1000);
  }

  /**
   * @desc If video is playing and user taps the pause button,
   * pause video. Classes added or removed to animate pause/play icon.
   * @param {Event} e - Mouse Click Event
   */
  @autobind
  pauseVideo(e: Event): void {
    if (this.isVideoPlaying) {
      if (e) {
        e.preventDefault();
      }
      this.player.pauseVideo();
    } else if (e) {
      this.playVideo();
      this.$el.classList.remove(CLASSES.PAUSED);
    }
  }

  /**
   * @desc set visual state of video player to paused
   */
  @autobind
  pauseState(): void {
    this.$el.classList.add(CLASSES.PAUSED);
    this.showControls();
  }

  /**
   * @desc Toggles audio mute on YT player on or off.
   */
  @autobind
  toggleMute(): void {
    if (this.player.isMuted()) {
      this.player.unMute();
      this.$el.classList.remove(CLASSES.MUTED);
    } else {
      this.player.mute();
      this.$el.classList.add(CLASSES.MUTED);
    }
  }

  /**
   * @desc If video is playing and mouse isn't moving, hide video controls.
   * Classes added or removed to hide/show controls.
   */
  @autobind
  handleMouseMove(): void {
    if (this.isVideoPlaying) {
      this.showControls();
      this.autoHideControls();
    }
  }

  /**
   * @desc hides player overlay controls after mouse stops moving.
   */
  @debounce(1500)
  autoHideControls(): void {
    if (this.isVideoPlaying) {
      this.hideControls();
    }
  }

  /**
   * @desc hide overlay player controls
   * includes progress bar, time, mute, and close
   */
  @autobind
  hideControls(): void {
    this.$videoControlsArr.forEach((element: HTMLElement) => {
      element.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
    });
  }

  /**
   * @desc show overlay player controls
   * includes progress bar, time, mute, and close
   */
  @autobind
  showControls(): void {
    this.$videoControlsArr.forEach((element: HTMLElement) => {
      element.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
    });
  }

  /**
   * @desc When video is complete, reset the video to its original state.
   * and reset all timers and text.
   */
  @autobind
  async resetVideo(e: Event): Promise<void> {
    if (e) {
      e.preventDefault();
    }

    if (this.isVideoPlaying) {
      this.pauseVideo(null);
    }

    this.$el.classList.remove(CLASSES.PLAYING);
    this.$el.classList.remove(CLASSES.PAUSED);
    this.$playButton.classList.remove("hidden"); // *** For IE
    this.$videoBg.style.zIndex = "1";
    await fadeIn(this.$videoBg).forward(0);
    this.player.getDuration();
    this.player.seekTo(0, true);
    clearInterval(this.timer);
    this.$progress.style.width = "0%";
    this.$progressTimeArr.forEach((element: HTMLElement) => {
      element.innerText = "0:00";
    });
    this.isVideoShowing = false;
    if (this.$textContent) {
      this.$textContent.classList.remove(CLASSES.PLAYING);
      this.$textContent.classList.remove("hidden"); // *** For IE
    } else if (this.$circleButton) {
      fadeIn(this.$circleButton).forward(0);
    }
  }
}
