import { autobind } from "core-decorators";
import { animationDirectory } from "./animations";
import { EventBus } from "../../globals/emitter";
import { GLOBAL_CONSTANTS } from "../../globals/constants";
import { IAnimation } from "./i-animation";

/**
 * @desc Reusable class used to trigger an
 * animation on scroll for a specific HTMLElement.
 */
export class ScrollAnimation {
  $el: HTMLElement;
  fn: IAnimation;
  initialDelay = 0;
  forward = false;
  reverse = true;
  isPriorityAndFinished = true;
  isRunningAnimation = false;
  /**
   * @desc Grab the HTMLElement and the type of animation
   * @param {HTMLElement} el - The element to attach the animation to.
   */
  constructor(el: HTMLElement) {
    this.$el = el;
    const { animType, isPriority, animInitialDelay } = this.$el.dataset as any;
    const animation = animationDirectory[animType]
      ? animType
      : GLOBAL_CONSTANTS.ANIMATION.DEFAULT;
    this.fn = animationDirectory[animation](this.$el);

    if (animInitialDelay) {
      this.initialDelay = parseFloat(animInitialDelay) * 1000;
    }

    if (isPriority) {
      document.querySelector(".js-body").classList.remove("unloaded");
      this.isPriorityAndFinished = false;
      setTimeout(() => {
        this.kickoff();
      }, 500);
    } else {
      EventBus.on(GLOBAL_CONSTANTS.EVENTS.PRIORITY_FINISHED, this.kickoff);
    }
  }

  @autobind
  kickoff() {
    setTimeout(this.handleScroll, this.initialDelay);
    this.bindEvents();
  }

  /**
   * @desc Listen for the global scroll event.
   */
  bindEvents() {
    EventBus.on(GLOBAL_CONSTANTS.EVENTS.SCROLL, this.handleScroll);
  }

  /**
   * @desc On scroll we determine if the position of our
   * element is inside the middle of the screen, if so it triggers
   * the proper animation.
   */
  @autobind
  async handleScroll() {
    if (this.isRunningAnimation) {
      return;
    }
    this.isRunningAnimation = true;
    const top = this.$el.getBoundingClientRect().top;
    const delay = parseFloat((this.$el.dataset as any).animDelay) || 0;
    const viewport = window.innerHeight;
    const position = top;
    if (position <= viewport && !this.forward) {
      await this.fn.forward(delay);
      if (!this.isPriorityAndFinished) {
        this.isPriorityAndFinished = true;
        EventBus.fire(GLOBAL_CONSTANTS.EVENTS.PRIORITY_FINISHED);
      }
      this.forward = true;
      this.reverse = false;
    } else if (position >= viewport && !this.reverse) {
      await this.fn.reset();
      this.forward = false;
      this.reverse = true;
    }

    this.isRunningAnimation = false;
  }

  tearDown() {
    EventBus.off(GLOBAL_CONSTANTS.EVENTS.SCROLL, this.handleScroll);
  }
}
