import { autobind } from "core-decorators";
import { isMobile, setCookie } from "../globals/functions";

declare var gtm: any;

const CLASSES = {
  LOGIN_LINK: ".js-login-link",
  BUSINESS_FORM: ".js-login-business-form",
  COMMERCIAL_FORM: ".js-login-commercial-form",
  PERSONAL_FORM: ".js-login-personal-form",
  ACTION_LOGIN_LINK: ".js-action-login-link",
};

export class Login {
  $el: HTMLElement;
  $links: NodeListOf<HTMLElement>;
  $businessForm: HTMLFormElement;
  $personalForm: HTMLFormElement;
  $commercialForm: HTMLFormElement;
  $actionLogonLinks: NodeListOf<HTMLElement>;

  constructor(el: HTMLElement) {
    this.$el = el;
    this.$personalForm = this.$el.querySelector(
      CLASSES.PERSONAL_FORM,
    ) as HTMLFormElement;
    this.$businessForm = this.$el.querySelector(
      CLASSES.BUSINESS_FORM,
    ) as HTMLFormElement;
    this.$commercialForm = this.$el.querySelector(
      CLASSES.COMMERCIAL_FORM,
    ) as HTMLFormElement;
    this.$links = this.$el.querySelectorAll(
      CLASSES.LOGIN_LINK,
    ) as NodeListOf<HTMLElement>;
    this.$actionLogonLinks = this.$el.querySelectorAll(
      CLASSES.ACTION_LOGIN_LINK,
    ) as NodeListOf<HTMLElement>;
    this.init();
    this.bindEvents();
  }

  @autobind
  init(): void {
    if (isMobile()) {
      this.$commercialForm.action = this.$commercialForm.dataset.mobileAction;
    }
  }

  @autobind
  bindEvents(): void {
    this.$personalForm.addEventListener("submit", this.loginCallback);
    this.$businessForm.addEventListener("submit", this.loginCallback);
    this.$commercialForm.addEventListener("submit", this.loginCallback);

    for (const link of this.$links) {
      link.addEventListener("click", this.createLoginCookie);
    }

    for (const input of this.$personalForm.querySelectorAll(
      "input",
    ) as NodeListOf<HTMLInputElement>) {
      input.addEventListener("keydown", this.removeInputError);
    }

    for (const input of this.$businessForm.querySelectorAll(
      "input",
    ) as NodeListOf<HTMLInputElement>) {
      input.addEventListener("keydown", this.removeInputError);
    }

    for (const input of this.$commercialForm.querySelectorAll(
      "input",
    ) as NodeListOf<HTMLInputElement>) {
      input.addEventListener("keydown", this.removeInputError);
    }

    for (const actionLogonLink of this.$actionLogonLinks) {
      actionLogonLink.addEventListener("click", this.setActionLoginFormFocus);
    }
  }

  @autobind
  loginCallback(ev: Event): void {
    ev.preventDefault();
    const form = ev.currentTarget as HTMLFormElement;
    const inputs = form.querySelectorAll(
      "input",
    ) as NodeListOf<HTMLInputElement>;
    if (inputs) {
      const dataKey = form.getAttribute("data-key");
      const formName =
        dataKey === "business"
          ? "Business Banking Login"
          : "Personal Banking Login";
      const errors = this.validateFields(inputs);
      if (
        errors === null ||
        typeof errors === "undefined" ||
        errors.length < 1
      ) {
        gtm.forms.json(formName, form.id, "1", "", "TRUE", "TRUE");
        let element = formName;
        let id = form.id;
        const buttons = form.querySelectorAll(
          "button",
        ) as NodeListOf<HTMLButtonElement>;
        if (buttons && buttons.length > 0) {
          const button = buttons[0];
          element = button.innerText;
          id = button.id;
        }
        gtm.navigation.formSubmitJson(
          element,
          form.action,
          document.baseURI,
          id,
        );
        this.createLoginCookie(ev);
        form.submit();
      } else {
        gtm.forms.json(
          formName,
          form.id,
          "1",
          "form-validation-error",
          "FALSE",
          "FALSE",
        );
        for (let index = 0; index < errors.length; index++) {
          const error = JSON.parse(JSON.stringify(errors[index]));
          gtm.events.json(error.id, error.type, error.text);
        }
      }
    }
  }

  @autobind
  validateFields(inputs: NodeListOf<HTMLInputElement>): Array<{}> {
    const errors = new Array<{}>();

    for (const input of inputs) {
      if (input.value === "") {
        input.parentElement.classList.add("-has-errors");
        let errorText = "Required";
        const errorDiv = input.nextElementSibling as HTMLElement;
        if (errorDiv != null && errorDiv.innerText.length > 0) {
          errorText = errorDiv.innerText;
        }
        errors.push({
          id: "form-field",
          type: input.placeholder,
          text: errorText,
        });
      }
    }
    return errors;
  }

  @autobind
  createLoginCookie(ev: Event): void {
    const tar = ev.currentTarget as HTMLElement;
    setCookie("umpq_login_type", tar.dataset.key);
  }

  @autobind
  removeInputError(ev: Event): void {
    const element = ev.currentTarget as HTMLElement;
    element.parentElement.classList.remove("-has-errors");
  }

  @autobind
  setActionLoginFormFocus(ev: Event): void {
    const target = (ev.target || ev.srcElement) as HTMLElement;
    if (target == null) {
      return;
    }
    const dataValue = target.getAttribute("data-value");
    if (dataValue == null || dataValue.trim().length < 1) {
      return;
    }
    let focusElements: HTMLCollectionOf<Element>;
    switch (dataValue.toLowerCase()) {
      case "login-personal":
        focusElements = document.getElementsByClassName(
          "js-login-personal-form-focus",
        );
        break;
      case "login-business":
        focusElements = document.getElementsByClassName(
          "js-login-business-form-focus",
        );
        break;
      case "login-commercial":
        focusElements = document.getElementsByClassName(
          "js-login-commercial-form-focus",
        );
        break;
      default:
        return;
    }
    if (focusElements != null && focusElements.length > 0) {
      const focusElement = focusElements[0] as HTMLElement;
      if (focusElement != null) {
        focusElement.focus();
      }
    }
  }
}
