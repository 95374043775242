import { EventBus } from "../../globals/emitter";
import { GLOBAL_CONSTANTS } from "../../globals/constants";
import { autobind } from "core-decorators";
import { TweenLite } from "gsap";

/**
 * @desc ScrollTo - Scroll the window to an element.
 */
export class ScrollTo {
  $el: HTMLElement;
  $content: HTMLElement;
  event: String;

  /**
   * @desc Grab the HTMLElement and the type of animation
   * @param {HTMLElement} el - The element to attach the animation to.
   */
  constructor(el: HTMLElement) {
    this.$el = el;
    const { scrollToEvent } = this.$el.dataset as any;
    this.event = scrollToEvent;

    this.bindEvents();
  }

  /**
   * @desc Listen for the global scroll event.
   */
  bindEvents() {
    EventBus.on(this.event, this.scrollToEl);
  }

  /**
   * @desc scroll to desired element on event.
   */
  @autobind
  scrollToEl() {
    EventBus.fire(GLOBAL_CONSTANTS.EVENTS.STOP_STICKY_NAV);

    TweenLite.delayedCall(0.5, () => {
      window.scrollTo({
        top: this.$el.getBoundingClientRect().top + window.scrollY,
        behavior: "smooth",
      });
      console.log(this.$el.getBoundingClientRect().top + window.scrollY);
    });
  }
}
