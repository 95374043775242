// This file has been modified:
// - added a method to check if browser is IE/Edge
// - for IE disabled background for range track (styled from css)
import playerjs from "player.js";
import { EventBus } from "../globals/emitter";
import { seconds, isMobile } from "../globals/functions";
import { autobind, debounce } from "core-decorators";
import { GLOBAL_CONSTANTS } from "../globals/constants";

const CLASSES = {
  PODCAST: "podcast__iframe",
  PLAY_BTN: ".js-podcast-play",
  PAUSE_BTN: ".js-podcast-pause",
  TIME: ".js-podcast-time",
  RANGE: ".js-podcast-range",
  CONTAINER: ".js-podcast__iframe-container",
  SPINNER: ".podcast__range-spinner",
  LoadContainer: ".js-podcast-load-container",
  LoadButton: ".js-podcast-load-button",
  LoadRange: ".js-podcast-load-range",
  PlayContainer: ".js-podcast-play-container",
};

/**
 * @desc A custom podcast player
 */
export class Podcast {
  $el: HTMLElement;
  $iFrame: HTMLIFrameElement;
  $iFrameContainer: HTMLElement;
  $playBtn: HTMLElement;
  $pauseBtn: HTMLElement;
  $time: HTMLElement;
  $range: HTMLInputElement;
  $spinner: HTMLElement;
  $isIe: boolean;
  player: typeof playerjs;
  url = "";
  hasAudio = true;
  clicked = false;
  isLoaded = false;
  playing = false;
  duration = 0;
  track: Object;
  audioEnded = false;
  loaderTimeout: number;
  loaderTimeLimit = 30000;

  $loadContainer: HTMLElement;
  $loadButton: HTMLElement;
  $loadRange: HTMLInputElement;
  $playContainer: HTMLElement;

  /**
   * @desc constructor - Grab the related URL and kickoff the player / bind elements + events.
   * @param  {HTMLElement} el: Parent podcast element
   */
  constructor(el: HTMLElement) {
    this.$el = el;
    this.$iFrameContainer = this.$el.querySelector(
      CLASSES.CONTAINER,
    ) as HTMLElement;
    this.$playBtn = this.$el.querySelector(CLASSES.PLAY_BTN) as HTMLElement;
    this.$pauseBtn = this.$el.querySelector(CLASSES.PAUSE_BTN) as HTMLElement;
    this.$time = this.$el.querySelector(CLASSES.TIME) as HTMLElement;
    this.$range = this.$el.querySelector(CLASSES.RANGE) as HTMLInputElement;
    this.$spinner = this.$el.querySelector(CLASSES.SPINNER) as HTMLInputElement;
    this.$isIe = this.isIEorEdge();
    this.url = (this.$el.dataset as any).url;

    this.$loadContainer = this.$el.querySelector(
      CLASSES.LoadContainer,
    ) as HTMLElement;
    this.$loadButton = this.$el.querySelector(
      CLASSES.LoadButton,
    ) as HTMLElement;
    this.$loadRange = this.$el.querySelector(
      CLASSES.LoadRange,
    ) as HTMLInputElement;
    this.$playContainer = this.$el.querySelector(
      CLASSES.PlayContainer,
    ) as HTMLElement;

    this.$loadButton.addEventListener("click", this.initialLoad);
    this.$loadRange.addEventListener("click", this.initialLoad);
  }

  /**
   * @desc initialLoad - swap out load container for player container and load/play podcast
   */
  @autobind
  initialLoad(mouseEvent: MouseEvent): void {
    mouseEvent.preventDefault();
    mouseEvent.stopPropagation();
    this.$loadContainer.classList.add("-disabled");
    this.$playContainer.classList.remove("-disabled");
    this.$spinner.classList.remove("-disabled");
    this.init();
  }

  /**
   * @desc init - load player content and play
   */
  @autobind
  init(): void {
    if (this.isLoaded) {
      return;
    }

    this.$iFrame = document.createElement("iFrame") as HTMLIFrameElement;
    this.$iFrame.src = this.url;
    this.$iFrame.classList.add(CLASSES.PODCAST);
    this.$iFrame.setAttribute("allow", "autoplay");
    this.$iFrameContainer.appendChild(this.$iFrame);
    this.isLoaded = true;
    this.$range.setAttribute("percentage", "0");
    this.player = new playerjs.Player(this.$iFrame);

    this.startLoad();

    this.player.on("ready", () => {
      this.buildGradient(0);
      this.player.getDuration((value: any) => {
        const loadRange =
          this.$loadRange.value === null ||
          isNaN(Number(this.$loadRange.value)) ||
          Number(this.$loadRange.value) > 99
            ? 0
            : Math.min(Number(this.$loadRange.value), 99) / 100;
        this.duration = value;
        const duration =
          this.duration === null || isNaN(Number(this.duration))
            ? 0
            : Number(this.duration);
        this.player.setCurrentTime(loadRange * duration);
        this.$range.value = loadRange.toString();
        this.$range.setAttribute("percentage", loadRange.toString());
        this.$time.innerText = "0:00/" + seconds(value);
        this.$playBtn.classList.remove("-disabled");
        this.$range.classList.remove("-disabled");
        this.$spinner.classList.add("-disabled");
        clearTimeout(this.loaderTimeout);
      });
      this.bindEvents();
      const mouseEvent = document.createEvent("MouseEvent");
      mouseEvent.initEvent("click", false, false);
      this.handleTogglePlay(mouseEvent);
    });
  }

  /**
   * @desc bindEvents - bind events for audio scrub, mouse pause / play.
   */
  @autobind
  bindEvents() {
    this.$playBtn.addEventListener("click", this.handleTogglePlay);
    this.$pauseBtn.addEventListener("click", this.handleTogglePlay);
    this.player.on(playerjs.EVENTS.TIMEUPDATE, this.updateTime);

    if (this.player.supports("event", "ended")) {
      this.player.addEventListener("ended", this.resetAudio);
    }

    this.player.on(playerjs.EVENTS.END, this.updateTime);
    if (isMobile()) {
      this.$range.addEventListener("touchend", this.handleRangeTouchEnd);
      this.$range.addEventListener("touchmove", this.handleRangeMove);
    } else {
      this.$range.addEventListener("click", this.handleRangeClick);
      this.$range.addEventListener("mouseup", this.handleRangeMouseUp);
    }
    EventBus.on(GLOBAL_CONSTANTS.EVENTS.MODAL_OPEN, this.handleModalOpen);
  }

  isIEorEdge(): boolean {
    if (/MSIE 10/i.test(navigator.userAgent)) {
      // This is internet explorer 10
      return true;
    }

    if (
      /MSIE 9/i.test(navigator.userAgent) ||
      /rv:11.0/i.test(navigator.userAgent)
    ) {
      // This is internet explorer 9 or 11
      return true;
    }

    if (/Edge\/\d./i.test(navigator.userAgent)) {
      // This is Microsoft Edge
      return true;
    }

    return false;
  }

  /**
   *  @desc buildGradient - set background gradient of the range scrubber
   */
  buildGradient(percent: number): void {
    if (!this.$isIe) {
      const { LIGHTGREEN, DARKGREEN } = GLOBAL_CONSTANTS.COLORS;
      this.$range.style.background = `linear-gradient(to right, ${DARKGREEN} 0%, ${DARKGREEN} ${percent}%, ${LIGHTGREEN} ${percent + 0.0001}%, ${LIGHTGREEN} 100%)`;
    }
  }

  /**
   *  @desc handleTogglePlay - toggle pause / play of audio
   */
  @autobind
  handleTogglePlay(e: MouseEvent): void {
    e.preventDefault();
    e.stopPropagation();
    this.togglePlay();
  }

  /**
   *  @desc handleModalOpen - Pause video if playing when a modal opens.
   */
  @autobind
  handleModalOpen(): void {
    if (this.playing) {
      this.togglePlay();
    }
  }

  /**
   * @desc togglePlay - Toggle audio on and off
   */
  @autobind
  togglePlay(): void {
    if (this.playing) {
      this.player.pause();
    } else {
      this.player.play();
    }
    this.playing = !this.playing;
    this.$el.classList.toggle(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
    this.audioEnded = false;
  }

  @autobind
  startLoad(): void {
    this.loaderTimeout = window.setTimeout(() => {
      this.$time.innerText = "unavailable";
      this.$spinner.classList.add("-disabled");
    }, this.loaderTimeLimit);
  }

  /**
   * @desc resetAudio - Return the audio to the beginning along w/ the controls.
   */
  @debounce(300)
  resetAudio() {
    if (this.playing) {
      this.togglePlay();
    }

    this.player.setCurrentTime(0);
    this.$range.value = "0";
    this.$time.innerText = seconds(0);
    this.$range.setAttribute("percentage", "0");
    this.audioEnded = false;
  }

  /**
   * @desc updateTime - update time counter / range percentage.
   */
  @autobind
  updateTime(data: any): void {
    let { seconds: _seconds, duration } = data;
    if (_seconds == null || _seconds === "undefined") {
      _seconds = 0;
    }
    if (duration == null || duration === "undefined") {
      duration = 0;
    }

    let percentagePlayed = 0;
    if (duration !== 0 && _seconds !== 0) {
      percentagePlayed = _seconds / duration;
    }

    const percentPlayed = percentagePlayed * 100;
    this.$time.innerText = seconds(_seconds) + "/" + seconds(duration);
    this.buildGradient(percentPlayed);

    if (!this.clicked) {
      this.$range.value = percentPlayed.toString();
    }

    if (percentagePlayed >= 0.9999 && !this.audioEnded) {
      this.audioEnded = true;
      setTimeout(() => {
        this.handleRangeEnd();
      }, 1000);
    }
  }

  /**
   * @desc setTrackToRangeValue - set position of the audio
   * player based on the percentage of the range input.
   */
  @debounce(300)
  setTrackToRangeValue(): void {
    setTimeout(() => {
      const percentage = this.$range.getAttribute("percentage");
      const percentageNumber = parseFloat(percentage);
      const seconds = this.duration * percentageNumber;
      this.player.setCurrentTime(seconds);

      if (!this.playing && percentageNumber < 100) {
        this.player.play();
        this.playing = true;
        this.$el.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
      }
    }, 0);
  }

  /**
   * @desc handleRangeClick - When user triggers a mousedown event, start tracking their location / update position based on the percentage of the mouse.
   */
  @autobind
  handleRangeClick(event: any): void {
    event.preventDefault();

    this.clicked = true;
    this.setTrackToRangeValue();
    if (isMobile()) {
      this.$range.addEventListener("touchend", this.handleRangeTouchEnd);
      this.$range.addEventListener("touchmove", this.handleRangeMove);
    } else {
      this.$range.addEventListener("click", this.handleRangeMove);
    }
  }

  /**
   * @desc handleRangeMove - Update audio position to the position of the range input.
   */
  @autobind
  handleRangeMove(e: any): void {
    // <------------------------------------------------------------------------
    const rect = e.target.getBoundingClientRect();
    const value =
      (e.changedTouches[0].clientX - rect.left) / (rect.right - rect.left);
    this.$range.setAttribute("percentage", value + "");
    this.buildGradient(value);
  }

  @autobind
  handleRangeTouchEnd(e: any) {
    event.preventDefault();
    const rect = e.target.getBoundingClientRect();
    const value =
      (e.changedTouches[0].clientX - rect.left) / (rect.right - rect.left);
    this.$range.setAttribute("percentage", value + "");
    this.clicked = true;
    this.setTrackToRangeValue();
  }

  @autobind
  handleRangeMouseUp(e: any) {
    const rect = e.target.getBoundingClientRect();
    const value = (e.clientX - rect.left) / (rect.right - rect.left);
    this.$range.setAttribute("percentage", value + "");
  }

  /**
   * @desc handleRangeEnd - Remove created event listeners for mouseup, mousemove and alow the updateTimer function to push it's percentage to the input range.
   */
  @autobind
  handleRangeEnd(): void {
    this.clicked = false;
    this.$range.setAttribute("percentage", "0");
    this.playing = false;
    this.player.setCurrentTime(0);
    this.$range.value = "0";
    this.$el.classList.toggle(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
    if (isMobile()) {
      this.$range.removeEventListener("touchend", this.handleRangeTouchEnd);
      this.$range.removeEventListener("touchmove", this.handleRangeMove);
    } else {
      this.$range.removeEventListener("click", this.handleRangeMove);
    }
  }

  @autobind
  tearDown() {
    this.$playBtn.removeEventListener("click", this.handleTogglePlay);
    this.player.off("timeupdate", this.updateTime);
    this.$range.removeEventListener("touchend", this.handleRangeTouchEnd);
    this.$range.removeEventListener("touchmove", this.handleRangeMove);
    this.$range.removeEventListener("click", this.handleRangeClick);
    this.$range.removeEventListener("mouseup", this.handleRangeMouseUp);
  }
}
