import { autobind } from "core-decorators";
import { animationDirectory } from "./animations";
import { EventBus } from "../../globals/emitter";
import { GLOBAL_CONSTANTS } from "../../globals/constants";
import { IAnimation } from "./i-animation";

/**
 * @desc Reusable class used to trigger an
 * animation on scroll for a specific HTMLElement.
 */
export class EventAnimation {
  $el: HTMLElement;
  fn: IAnimation;
  delay: number;
  startEvent: String;
  endEvent: String;
  resetEvent: String;

  /**
   * @desc Grab the HTMLElement and the type of animation
   * @param {HTMLElement} el - The element to attach the animation to.
   */
  constructor(el: HTMLElement) {
    this.$el = el;
    const {
      animType,
      animDelay,
      animStartEvent,
      animEndEvent,
      animResetEvent,
    } = this.$el.dataset as any;
    const animation = animationDirectory[animType]
      ? animType
      : GLOBAL_CONSTANTS.ANIMATION.DEFAULT;
    this.fn = animationDirectory[animation](this.$el);
    this.delay = parseFloat(animDelay);
    this.startEvent = animStartEvent;
    this.endEvent = animEndEvent;
    this.resetEvent = animResetEvent;

    this.bindEvents();
  }

  /**
   * @desc Listen for the global animation events.
   */
  @autobind
  bindEvents() {
    if (this.startEvent) {
      EventBus.on(this.startEvent, this.play);
    }

    if (this.endEvent) {
      EventBus.on(this.endEvent, this.reverse);
    }

    if (this.resetEvent) {
      EventBus.on(this.resetEvent, this.reset);
    }
  }

  /**
   * @desc When event is fired, play forward animation
   */
  @autobind
  async play() {
    await this.fn.forward(this.delay);
    EventBus.fire("PLAY_STOPPED");
  }

  /**
   * @desc When event is fired, play reverse animation
   */
  @autobind
  async reverse() {
    await this.fn.reverse(this.delay);
  }

  /**
   * @desc When event is fired, reset animation
   */
  @autobind
  async reset() {
    await this.fn.reset();
  }

  tearDown() {
    EventBus.off(this.startEvent, this.play);
    EventBus.off(this.endEvent, this.reverse);
  }
}
