import { autobind } from "core-decorators";

/**
 * @desc Class to be utilized wherever we
 * want to trap values on an input.
 */
export class SocialShare {
  $el: HTMLElement;
  $shareButtons: NodeListOf<HTMLElement>;

  /**
   * @desc Set element to class.
   * Grab events to be used later.
   * @param {HTMLElement} el - Element to bind stepper to.
   */
  constructor(el: HTMLElement) {
    this.$el = el;
    this.$shareButtons = this.$el.querySelectorAll(
      '.js-social-share a:not([href^="mailto:"])',
    ) as NodeListOf<HTMLElement>;

    this.bindEvents();
  }

  /**
   * @desc Set up events for each step / listen
   * for reset event.
   */
  @autobind
  bindEvents() {
    for (let i = 0; i < this.$shareButtons.length; i++) {
      this.$shareButtons[i].addEventListener("click", this.openWindow);
    }
  }

  @autobind
  openWindow(ev: Event) {
    ev.preventDefault();
    const target = ev.currentTarget as HTMLAnchorElement;
    const shareSrc = target.href;

    const url = shareSrc + "?" + this.serializeUrl(target.dataset);

    window.open(
      url,
      "",
      "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600",
    );
    return false;
  }

  serializeUrl(obj: any) {
    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p) && p !== "allowLeavingDomain") {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    }
    return str.join("&");
  }
}
