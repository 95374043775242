import { autobind } from "core-decorators";
import { TweenLite } from "gsap";
import { EventBus } from "../../globals/emitter";
import { GLOBAL_CONSTANTS } from "../../globals/constants";

declare var gtm: any;

const constants = {
  classes: {
    target: ".js-drawer-target",
    trigger: ".js-drawer-trigger",
    drawerOpen: "-drawer-open",
  },
  datasets: {
    altText: "altText",
    drawerInitiallyOpen: "drawerInitiallyOpen",
  },
};

/**
 * @desc Simple component that shows / hides content
 */
export class Drawer {
  $el: HTMLElement;
  $trigger: HTMLElement;
  $target: HTMLElement;
  replaceText: string;
  open = false;

  constructor(el: HTMLElement) {
    this.$el = el;
    this.$trigger = this.$el.querySelector(
      constants.classes.trigger,
    ) as HTMLElement;
    this.$target = this.$el.querySelector(
      constants.classes.target,
    ) as HTMLElement;

    const dsAltText = this.$trigger.dataset[constants.datasets.altText];
    if (dsAltText !== null && dsAltText !== undefined && dsAltText.length > 0) {
      this.replaceText = dsAltText;
    }

    const dsDrawerInitiallyOpen =
      this.$trigger.dataset[constants.datasets.drawerInitiallyOpen];
    const drawerInitiallyOpen =
      dsDrawerInitiallyOpen !== null &&
      dsDrawerInitiallyOpen !== undefined &&
      dsDrawerInitiallyOpen.toLowerCase() === "true";

    if (drawerInitiallyOpen) {
      this.setTriggerText();
      this.openDrawer();
      this.open = true;
    } else {
      TweenLite.to(this.$target, 0.5, { height: "0", overflow: "hidden" });
    }

    this.bindEvents();
  }

  /**
   * @desc Bind click events on the trigger
   */
  bindEvents(): void {
    this.$trigger.addEventListener("click", this.toggleDrawer);
  }

  /**
   * @desc Animate the drawer open / close
   * @param {Event} e - Mouse click on trigger
   */
  @autobind
  toggleDrawer(e: Event): void {
    e.preventDefault();
    this.setTriggerText();

    const element = e.currentTarget as HTMLAnchorElement;

    if (!this.open) {
      gtm.navigation.createJson(
        gtm.utilities.sanitize(this.$trigger.innerText),
        element.href,
        "expand",
        "FALSE",
        "",
        "",
        "",
        document.baseURI,
        element.id,
        "question",
      );
      this.openDrawer();
    } else {
      gtm.navigation.createJson(
        gtm.utilities.sanitize(this.$trigger.innerText),
        element.href,
        "collapse",
        "FALSE",
        "",
        "",
        "",
        document.baseURI,
        element.id,
        "question",
      );
      const { drawerClose } = this.$trigger.dataset;
      if (drawerClose) {
        EventBus.fire(drawerClose);
      }

      TweenLite.to(this.$target, 0.5, { height: "0" });
      this.$trigger.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
      this.$el.classList.remove(constants.classes.drawerOpen);
    }

    this.open = !this.open;
  }

  @autobind
  setTriggerText() {
    if (this.replaceText) {
      const newText = this.$trigger.innerText;
      this.$trigger.innerText = this.replaceText;
      this.replaceText = newText;
    }
  }

  @autobind
  openDrawer() {
    TweenLite.set(this.$target, { height: "auto", overflow: "hidden" });
    this.$trigger.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
    this.$el.classList.add(constants.classes.drawerOpen);
  }

  /**
   * @desc Remove click events on triggers
   */
  tearDown(): void {
    this.$trigger.removeEventListener("click", this.toggleDrawer);
  }
}
