import { autobind } from "core-decorators";

const classes = {
  CloseAll: ".js-close-all",
  Closed: "-closed",
  CloseNavigationMobileGroup: ".js-exit-menu",
  ExpandAll: ".js-expand-all",
  HideElement: "hide-mobile-menu-element",
  MobileLinkButton: ".js-mobile-link-button",
  MobileMenuDrawer: ".js-mobile-menu-drawer",
  Open: "-open",
  ShowElement: "show-mobile-menu-element",
};

const ids = {
  MobileMenuGroupsContainer: "mobile-menu-groups-container-id",
  NavigationMobileDropMenuContainer: "navigation-mobile-drop-menu-container-id",
};

export class NavMobileMenu {
  $Element: HTMLElement;
  $CloseNavigationMobileGroup: HTMLElement;
  $MobileLinkButtons: Array<HTMLButtonElement>;
  $MobileMenuDrawers: Array<HTMLElement>;
  $MobileMenuGroupsContainer: HTMLDivElement;
  $NavigationMobileDropMenuContainer: HTMLDivElement;

  $ExpandAll: HTMLElement;
  $CloseAll: HTMLElement;

  /**
   * @desc Set element to class variables
   * @param {HTMLElement} element - Element to bind module to.
   */
  constructor(element: HTMLElement) {
    if (!element) {
      return;
    }

    this.$Element = element;
    this.$CloseNavigationMobileGroup = document.querySelector(
      classes.CloseNavigationMobileGroup,
    ) as HTMLElement;
    this.$MobileMenuGroupsContainer = document.getElementById(
      ids.MobileMenuGroupsContainer,
    ) as HTMLDivElement;
    this.$MobileLinkButtons = Array.from(
      this.$Element.querySelectorAll(classes.MobileLinkButton),
    ) as Array<HTMLButtonElement>;
    this.$MobileMenuDrawers = Array.from(
      this.$Element.querySelectorAll(classes.MobileMenuDrawer),
    ) as Array<HTMLDivElement>;
    this.$NavigationMobileDropMenuContainer = document.getElementById(
      ids.NavigationMobileDropMenuContainer,
    ) as HTMLDivElement;
    this.$ExpandAll = document.querySelector(classes.ExpandAll) as HTMLElement;
    this.$CloseAll = document.querySelector(classes.CloseAll) as HTMLElement;

    this.bindEvents();
  }

  /**
   * @desc Set up events for each menu group
   */
  bindEvents(): void {
    this.$CloseNavigationMobileGroup.addEventListener(
      "click",
      this.handleMobileMenuOpenClose,
    );

    if (this.$MobileLinkButtons) {
      this.$MobileLinkButtons.forEach((item: HTMLButtonElement) => {
        item.addEventListener("click", this.openOrCloseMobileNavGroup);
      });

      if (this.$ExpandAll) {
        this.$ExpandAll.addEventListener(
          "click",
          this.expandAllMobileMenuGroups,
        );
      }

      if (this.$CloseAll) {
        this.$CloseAll.addEventListener("click", this.closeAllMobileMenuGroups);
      }
    }
  }

  @autobind
  closeAllMobileMenuGroups(): void {
    if (this.$ExpandAll) {
      this.$ExpandAll.classList.remove(classes.Closed);
    }
    if (this.$CloseAll) {
      this.$CloseAll.classList.add(classes.Closed);
    }

    if (this.$MobileLinkButtons) {
      this.$MobileLinkButtons.forEach((item: HTMLButtonElement) => {
        const mobileNavGroupDrawer = item.nextElementSibling as HTMLDivElement;
        if (mobileNavGroupDrawer) {
          mobileNavGroupDrawer.classList.remove(classes.ShowElement);
          mobileNavGroupDrawer.classList.add(classes.HideElement);
        }
        item.classList.remove(classes.Open);
      });
    }
  }

  @autobind
  closeNavigationMobileGroup(): void {
    if (this.$NavigationMobileDropMenuContainer) {
      this.$NavigationMobileDropMenuContainer.classList.remove(
        classes.ShowElement,
      );
      this.$NavigationMobileDropMenuContainer.classList.add(
        classes.HideElement,
      );
    }
  }

  @autobind
  expandAllMobileMenuGroups(): void {
    if (this.$ExpandAll) {
      this.$ExpandAll.classList.add(classes.Closed);
    }
    if (this.$CloseAll) {
      this.$CloseAll.classList.remove(classes.Closed);
    }

    if (this.$MobileLinkButtons) {
      this.$MobileLinkButtons.forEach((item: HTMLButtonElement) => {
        const mobileNavGroupDrawer = item.nextElementSibling as HTMLDivElement;
        if (mobileNavGroupDrawer) {
          mobileNavGroupDrawer.classList.remove(classes.HideElement);
          mobileNavGroupDrawer.classList.add(classes.ShowElement);
        }
        item.classList.add(classes.Open);
      });
    }
  }

  @autobind
  handleMobileMenuOpenClose(): void {
    this.closeAllMobileMenuGroups();

    if (this.$CloseNavigationMobileGroup) {
      if (this.$CloseNavigationMobileGroup.classList.contains(classes.Closed)) {
        this.closeNavigationMobileGroup();
      } else {
        this.openNavigationMobileGroup();
      }
    }
  }

  @autobind
  openNavigationMobileGroup(): void {
    if (this.$NavigationMobileDropMenuContainer) {
      this.$NavigationMobileDropMenuContainer.classList.remove(
        classes.HideElement,
      );
      this.$NavigationMobileDropMenuContainer.classList.add(
        classes.ShowElement,
      );
    }
  }

  @autobind
  openOrCloseMobileNavGroup(event: Event): void {
    const button = event.currentTarget as HTMLButtonElement;
    if (!button) {
      return;
    }
    const mobileNavGroupDrawer = button.nextElementSibling as HTMLDivElement;
    if (button.classList.contains(classes.Open)) {
      button.classList.remove(classes.Open);
      if (mobileNavGroupDrawer) {
        mobileNavGroupDrawer.classList.remove(classes.ShowElement);
        mobileNavGroupDrawer.classList.add(classes.HideElement);
      }
    } else {
      button.classList.add(classes.Open);
      if (mobileNavGroupDrawer) {
        mobileNavGroupDrawer.classList.remove(classes.HideElement);
        mobileNavGroupDrawer.classList.add(classes.ShowElement);
      }
    }

    let openCount = 0;
    let totalCount = 0;

    if (this.$MobileLinkButtons) {
      this.$MobileLinkButtons.forEach((item: HTMLButtonElement) => {
        totalCount++;
        if (item.classList.contains(classes.Open)) {
          openCount++;
        } else {
          const mobileNavGroupDrawer =
            item.nextElementSibling as HTMLDivElement;
          if (
            mobileNavGroupDrawer &&
            mobileNavGroupDrawer.children.length < 1
          ) {
            openCount++;
          }
        }
      });
    }

    if (this.$ExpandAll && this.$CloseAll) {
      if (openCount < totalCount) {
        this.$ExpandAll.classList.remove(classes.Closed);
        this.$CloseAll.classList.add(classes.Closed);
      } else {
        this.$CloseAll.classList.remove(classes.Closed);
        this.$ExpandAll.classList.add(classes.Closed);
      }
    }
  }
}
