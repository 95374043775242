import { autobind } from "core-decorators";
import { numberize } from "../globals/functions";
import { EventBus } from "../globals/emitter";
import { Calculator } from "../globals/calculator";

const SELECTORS = {
  OPTION: ".js-calc-option",
  INPUT: ".js-calc-input",
  TOTAL_AMOUNT: "#total-amount",
  DOWN_PAYMENT: "#down-payment",
  RATE: ".js-calc-rate",
};

const EVENTS = {
  CALCULATE: "vehicle-calculate",
  RESULT: "calculator-result",
};

/**
 * @desc VehicleCalculator class provides the inputs and the equation for the vehicle calculator
 */
export class VehicleCalculator {
  $element: HTMLElement;
  optionArray: Array<HTMLInputElement>;
  inputArray: Array<HTMLInputElement>;
  $totalAmount: HTMLInputElement;
  $downPayment: HTMLInputElement;
  $calcRate: HTMLElement;
  currentRate: string;
  currentDivider: string;
  currentAmount: string;
  currentDown: string;
  calculator: Calculator;

  constructor(el: HTMLElement) {
    this.$element = el;
    this.optionArray = Array.from(
      this.$element.querySelectorAll(SELECTORS.OPTION),
    ) as Array<HTMLInputElement>;
    this.inputArray = Array.from(
      this.$element.querySelectorAll(SELECTORS.INPUT),
    ) as Array<HTMLInputElement>;
    this.$totalAmount = this.$element.querySelector(
      SELECTORS.TOTAL_AMOUNT,
    ) as HTMLInputElement;
    this.$downPayment = this.$element.querySelector(
      SELECTORS.DOWN_PAYMENT,
    ) as HTMLInputElement;
    this.$calcRate = this.$element.querySelector(SELECTORS.RATE) as HTMLElement;

    this.calculator = new Calculator(
      this.$element,
      this.optionArray,
      this.inputArray,
      EVENTS.CALCULATE,
    );

    this.init();
  }

  init(): void {
    this.setRates();

    EventBus.on(EVENTS.CALCULATE, this.setRates);
  }

  /**
   * @desc set current values for rate, and divider
   * Then run the calculation
   */
  @autobind
  setRates(): void {
    this.optionArray.forEach((input: HTMLInputElement) => {
      if (input.checked) {
        this.currentRate = input.dataset["rate"];
        this.currentDivider = input.value;
        this.$calcRate.innerText = `${this.currentRate}%`;
      }
    });

    this.calculate();
  }

  /**
   * @desc calculate the current loan numbers
   * Calculates the monthly payment on a loan based on form inputs
   */
  @autobind
  calculate(): void {
    if (this.$totalAmount.value === "" || this.$downPayment.value === "") {
      return;
    }

    const downPayment = numberize(this.$downPayment.value);
    const borrowAmount = numberize(this.$totalAmount.value);
    const term = parseFloat(this.currentDivider) as number;
    const rate = parseFloat(this.currentRate) as number;
    const principal = borrowAmount - downPayment;
    const monthlyInterest = rate / 100 / 12;
    const payments = term;

    let totalMonthlyPayment = Math.ceil(
      (principal * monthlyInterest * Math.pow(1 + monthlyInterest, payments)) /
        (Math.pow(1 + monthlyInterest, payments) - 1),
    );

    if (totalMonthlyPayment < 0 || downPayment >= borrowAmount) {
      totalMonthlyPayment = 0;
    }

    EventBus.fire(EVENTS.RESULT, totalMonthlyPayment);
  }

  /**
   * @desc Remove calculate event from eventbus
   */
  tearDown(): void {
    EventBus.on(EVENTS.CALCULATE, this.setRates);
  }
}
