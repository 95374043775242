import { autobind } from "core-decorators";
import { getCookie, setCookie, deleteCookie } from "../globals/functions";

declare var gtm: any;

const CLASSES = {
  FORM_LOGIN: ".js-home-login-form",
  USERNAME: ".js-login-username",
  COMPANYID: ".js-login-companyid",
  REMEMBER_ME: ".js-remember-me",
};

/**
 * @desc Simple component that shows / hides content
 */
export class HomeLogin {
  $loginForm: HTMLFormElement;
  $username: HTMLInputElement;
  $companyid: HTMLInputElement;
  $rememberMe: HTMLInputElement;
  cookieLoginName = "umpq_pb_login_id";
  cookieCompanyName = "umpq_bb_company_id";
  cookieUserName = "umpq_bb_user_id";
  isCompanyLogin = false;

  constructor(el: HTMLElement) {
    this.$loginForm = el as HTMLFormElement;
    this.$rememberMe = this.$loginForm.querySelector(
      CLASSES.REMEMBER_ME,
    ) as HTMLInputElement;
    this.$username = this.$loginForm.querySelector(
      CLASSES.USERNAME,
    ) as HTMLInputElement;
    this.$companyid = this.$loginForm.querySelector(
      CLASSES.COMPANYID,
    ) as HTMLInputElement;
    if (this.$companyid) {
      this.isCompanyLogin = true;
    }

    this.init();
    this.bindEvents();
  }

  @autobind
  init() {
    if (this.isCompanyLogin) {
      const cookieCoValue = getCookie(this.cookieCompanyName);
      this.$companyid.value = cookieCoValue;
      let cookieUserValue = "";

      if (this.$username) {
        cookieUserValue = getCookie(this.cookieUserName);
        this.$username.value = cookieUserValue;
      }

      if (
        this.$rememberMe &&
        cookieCoValue.length > 0 &&
        cookieUserValue.length > 0
      ) {
        this.$rememberMe.checked = true;
      }
    } else {
      if (this.$username) {
        const cookieLoginValue = getCookie(this.cookieLoginName);
        this.$username.value = cookieLoginValue;
        if (this.$rememberMe && cookieLoginValue.length > 0) {
          this.$rememberMe.checked = true;
        }
      }
    }
  }

  @autobind
  bindEvents(): void {
    this.$loginForm.addEventListener("submit", this.loginCallback);

    for (const input of this.$loginForm.querySelectorAll(
      "input",
    ) as NodeListOf<HTMLInputElement>) {
      input.addEventListener("keydown", this.removeInputError);
    }
  }

  @autobind
  loginCallback(ev: Event): void {
    ev.preventDefault();
    const form = ev.currentTarget as HTMLFormElement;
    const inputs = form.querySelectorAll(
      "input",
    ) as NodeListOf<HTMLInputElement>;
    if (inputs) {
      const formName =
        inputs[0].id.substr(0, 8) === "business"
          ? "Home Login Business Banking"
          : "Home Login Personal Banking";
      const errors = this.validateFields(inputs);
      if (
        errors === null ||
        typeof errors === "undefined" ||
        errors.length < 1
      ) {
        gtm.forms.json(formName, form.id, "1", "", "TRUE", "TRUE");
        let element = formName;
        let id = form.id;
        const buttons = form.querySelectorAll(
          "button",
        ) as NodeListOf<HTMLButtonElement>;
        if (buttons && buttons.length > 0) {
          const button = buttons[0];
          element = button.innerText;
          id = button.id;
        }
        gtm.navigation.formSubmitJson(
          element,
          form.action,
          document.baseURI,
          id,
        );
        this.createRememberMeCookie();
        form.submit();
      } else {
        gtm.forms.json(
          formName,
          form.id,
          "1",
          "form-validation-error",
          "FALSE",
          "FALSE",
        );
        for (let index = 0; index < errors.length; index++) {
          const error = JSON.parse(JSON.stringify(errors[index]));
          gtm.events.json(error.id, error.type, error.text);
        }
      }
    }
  }

  @autobind
  validateFields(inputs: NodeListOf<HTMLInputElement>): Array<{}> {
    const errors = new Array<{}>();

    for (const input of inputs) {
      if (input.value === "") {
        input.parentElement.classList.add("-has-errors");
        let errorText = "Required";
        const errorDiv = input.nextElementSibling as HTMLElement;
        if (errorDiv != null && errorDiv.innerText.length > 0) {
          errorText = errorDiv.innerText;
        }
        errors.push({
          id: "form-field",
          type: input.placeholder,
          text: errorText,
        });
      }
    }
    return errors;
  }

  @autobind
  createRememberMeCookie(): void {
    if (this.$rememberMe) {
      if (this.$rememberMe.checked) {
        if (this.isCompanyLogin) {
          if (this.$companyid.value) {
            setCookie(this.cookieCompanyName, this.$companyid.value);
          }
          if (this.$username && this.$username.value) {
            setCookie(this.cookieUserName, this.$username.value);
          }
        } else {
          if (this.$username && this.$username.value) {
            setCookie(this.cookieLoginName, this.$username.value);
          }
        }
      } else {
        if (this.isCompanyLogin) {
          deleteCookie(this.cookieUserName);
          deleteCookie(this.cookieCompanyName);
        } else {
          deleteCookie(this.cookieLoginName);
        }
      }
    }
  }

  @autobind
  removeInputError(ev: Event): void {
    const element = ev.currentTarget as HTMLElement;
    element.parentElement.classList.remove("-has-errors");
  }
}
