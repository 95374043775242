import { autobind } from "core-decorators";
import { getCookie, setCookie, deleteCookie } from "../globals/functions";

declare var gtm: any;

const constants = {
  classes: {
    loginIdElement: ".js-pb-login-id",
    rememberMe: ".js-pb-remember-me",
    hasErrors: "-has-errors",
  },
  cookies: {
    cookieLoginId: "umpq_pb_login_id",
  },
  gtm: {
    formName: "Personal Banking Login",
    formError: "form-validation-error",
  },
};

/**
 * @desc Simple component that shows / hides content
 */
export class PersonalBankingLogin {
  loginFormElement: HTMLFormElement;
  loginIdElement: HTMLInputElement;
  rememberMe: HTMLInputElement;
  loginButtonElement: HTMLButtonElement;

  constructor(element: HTMLElement) {
    this.loginFormElement = element as HTMLFormElement;
    this.loginIdElement = this.loginFormElement.querySelector(
      constants.classes.loginIdElement,
    ) as HTMLInputElement;
    this.rememberMe = this.loginFormElement.querySelector(
      constants.classes.rememberMe,
    ) as HTMLInputElement;

    const buttons = this.loginFormElement.querySelectorAll(
      "button",
    ) as NodeListOf<HTMLButtonElement>;
    if (buttons && buttons.length > 0) {
      this.loginButtonElement = buttons[0];
    }

    this.init();
    this.bindEvents();
  }

  @autobind
  init() {
    if (this.loginIdElement) {
      const cookieValue = getCookie(constants.cookies.cookieLoginId);
      this.loginIdElement.value = cookieValue;

      if (this.rememberMe && cookieValue.length > 0) {
        this.rememberMe.checked = true;
      }
    }
  }

  @autobind
  bindEvents(): void {
    this.loginFormElement.addEventListener("submit", this.loginCallback);
    if (this.loginIdElement) {
      this.loginIdElement.addEventListener("keydown", this.removeInputError);
    }
  }

  @autobind
  loginCallback(event: Event): void {
    event.preventDefault();
    if (!this.loginFormElement) {
      this.loginFormElement = event.currentTarget as HTMLFormElement;
      this.loginIdElement = this.loginFormElement.querySelector(
        constants.classes.loginIdElement,
      ) as HTMLInputElement;
      this.rememberMe = this.loginFormElement.querySelector(
        constants.classes.rememberMe,
      ) as HTMLInputElement;
    }

    if (this.loginIdElement) {
      const errors = this.validateFields();
      if (errors === null || errors === undefined || errors.length < 1) {
        gtm.forms.json(
          constants.gtm.formName,
          this.loginFormElement.id,
          "1",
          "",
          "TRUE",
          "TRUE",
        );
        const id =
          this.loginButtonElement && this.loginButtonElement.id
            ? this.loginButtonElement.id
            : this.loginFormElement.id;
        const element =
          this.loginButtonElement && this.loginButtonElement.innerText
            ? this.loginButtonElement.innerText
            : constants.gtm.formName;
        gtm.navigation.formSubmitJson(
          element,
          this.loginFormElement.action,
          document.baseURI,
          id,
        );
        this.createRememberMeCookie();
        this.loginFormElement.submit();
      } else {
        gtm.forms.json(
          constants.gtm.formName,
          this.loginFormElement.id,
          "1",
          constants.gtm.formError,
          "FALSE",
          "FALSE",
        );
        for (let index = 0; index < errors.length; index++) {
          const error = JSON.parse(JSON.stringify(errors[index]));
          gtm.events.json(error.id, error.type, error.text);
        }
      }
    }
  }

  @autobind
  validateFields(): Array<{}> {
    const errors = new Array<{}>();

    if (this.loginIdElement) {
      if (this.loginIdElement.value.length < 1) {
        this.loginIdElement.parentElement.classList.add(
          constants.classes.hasErrors,
        );
        let errorText = "Required";
        const errorDiv = this.loginIdElement.nextElementSibling as HTMLElement;
        if (
          errorDiv !== null &&
          errorDiv !== undefined &&
          errorDiv.innerText.length > 0
        ) {
          errorText = errorDiv.innerText;
        }
        errors.push({
          id: "form-field",
          type: this.loginIdElement.placeholder,
          text: errorText,
        });
      }
    }

    return errors;
  }

  @autobind
  createRememberMeCookie(): void {
    if (this.rememberMe) {
      if (this.rememberMe.checked) {
        if (
          this.loginIdElement &&
          this.loginIdElement.value &&
          this.loginIdElement.value.length > 0
        ) {
          setCookie(constants.cookies.cookieLoginId, this.loginIdElement.value);
        }
      } else {
        deleteCookie(constants.cookies.cookieLoginId);
      }
    }
  }

  @autobind
  removeInputError(event: Event): void {
    const element = event.currentTarget as HTMLElement;
    element.parentElement.classList.remove(constants.classes.hasErrors);
  }
}
