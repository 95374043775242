import { autobind } from "core-decorators";
declare var grecaptcha: any;

const classes = {
  eloquaFormContainer: ".js-eloqua-form-container",
  eloquaForm: ".elq-form",
  submitbutton: ".target-submit-placeholder",
  submissionMessage: ".js-submission_message",
};

const formIds = new Map<string, string>();

function handleDocumentLoad(b: HTMLFormElement, a: string) {
  getElqFormSubmissionToken(b, a);
  processLastFormField(b);
}

function getElqFormSubmissionToken(g: HTMLFormElement, c: string) {
  if (g && g.children.namedItem("elqFormSubmissionToken")) {
    const f = g.action;
    let a = getHostName(f);
    a = "https://" + a + "/e/formsubmittoken?elqSiteID=" + c;
    if (a) {
      fetch(a, { method: "GET" })
        .then((response) => response.text())
        .then((data) => {
          if (data !== null) {
            g.parentElement.children[0].setAttribute("hidden", "hidden");
            (
              g.children.namedItem("elqFormSubmissionToken") as HTMLInputElement
            ).value = data;
          } else {
            (
              g.children.namedItem("elqFormSubmissionToken") as HTMLInputElement
            ).value = "";
          }
        })
        .catch((e) => {
          g.parentElement.children[0].removeAttribute("hidden");
          console.log(e);
        });
    } else {
      g.parentElement.children[0].setAttribute("hidden", "hidden");
      (
        g.children.namedItem("elqFormSubmissionToken") as HTMLInputElement
      ).value = "";
    }
  }
}

function getHostName(b: string) {
  if (typeof window.URL === "function") {
    return new window.URL(b).hostname;
  } else {
    const a = b.match("/://(www[0-9]?.)?(.[^/:]+)/i");
    if (
      a !== null &&
      a.length > 2 &&
      typeof a[2] === "string" &&
      a[2].length > 0
    ) {
      return a[2];
    } else {
      return null;
    }
  }
}

function processLastFormField(form: HTMLFormElement) {
  if (form.querySelector("#elq-FormLastRow")) {
    const lastFormField = form.querySelector("#elq-FormLastRow") as HTMLElement;
    lastFormField.style.display = "none";
  }
}

function getHiddenValues(hiddenFields: NodeListOf<any>) {
  let result = "";
  for (let i = 0; i < hiddenFields.length; i++) {
    const inputField = hiddenFields[i] as HTMLFormElement;
    if (inputField.name === "last_conversion_page") {
      inputField.value = window.location.href.split("/[?#]/")[0].split("#")[0];
    }
    result +=
      inputField.name +
      "=" +
      (inputField.value == null ? "" : inputField.value) +
      "&";
  }
  return result;
}

function getCheckboxValues(checkboxFields: NodeListOf<any>, form: HTMLElement) {
  let result = "";
  const seen = new Set<string>();

  for (let i = 0; i < checkboxFields.length; i++) {
    const current = checkboxFields[i] as HTMLFormElement;
    const checkboxGroup = (form as HTMLFormElement).querySelectorAll(
      "input[type=checkbox]" + "[name=" + current.name + "]:checked",
    );
    if (checkboxGroup.length > 1) {
      const checkbox = checkboxGroup[0] as HTMLFormElement;
      if (!seen.has(checkbox.name)) {
        const groupName = checkbox.name;
        seen.add(groupName);
        result += groupName + "=";
        for (let y = 0; y < checkboxGroup.length; y++) {
          const value = checkboxGroup[y] as HTMLFormElement;
          result +=
            (value.value == null ? "" : value.value) +
            (y < checkboxGroup.length - 1 ? "," : "&");
        }
      }
    } else if (checkboxGroup.length === 1) {
      const checkbox = checkboxGroup[0] as HTMLFormElement;
      if (!seen.has(checkbox.name)) {
        seen.add(checkbox.name);
        result +=
          checkbox.name +
          "=" +
          (checkbox.value == null ? "" : checkbox.value + "&");
      }
    }
  }
  return result;
}

function getRadioValues(radioFields: NodeListOf<any>) {
  let result = "";
  for (let i = 0; i < radioFields.length; i++) {
    const value = radioFields[i] as HTMLFormElement;
    result += value.name + "=" + (value.value == null ? "" : value.value) + "&";
  }
  return result;
}

function getTextValues(textFields: NodeListOf<any>) {
  let result = "";
  for (let i = 0; i < textFields.length; i++) {
    const value = textFields[i] as HTMLFormElement;
    result +=
      value.name +
      "=" +
      (value.value == null ? "" : value.value.replace(/&/g, "")) +
      "&";
  }
  return result;
}

function getTextAreaValues(textAreaFields: HTMLCollectionOf<any>) {
  let result = "";
  for (let i = 0; i < textAreaFields.length; i++) {
    const value = textAreaFields[i] as HTMLFormElement;
    result +=
      value.name +
      "=" +
      (value.value == null ? "" : value.value.replace(/&/g, "")) +
      "&";
  }
  return result;
}

function sanitizeErrorMsg(unsanitizedMsg: String) {
  const msgs = unsanitizedMsg.split("\\n");
  let result = "";
  for (let i = 0; i < msgs.length; i++) {
    result += msgs[i] + "\n";
  }
  return result;
}

function getSelectValues(selectFields: HTMLCollectionOf<any>) {
  let result = "";
  const seen = new Set<string>();
  for (let i = 0; i < selectFields.length; i++) {
    const options = (selectFields[i] as HTMLFormElement).selectedOptions;
    const values = Array.from(options).map(({ value }) => value);
    if (values.length > 1) {
      if (!seen.has((values[0] as HTMLFormElement).name)) {
        const groupName = (selectFields[i] as HTMLFormElement).name;
        seen.add(groupName);
        result += groupName + "=";
        for (let y = 0; y < values.length; y++) {
          const value = values[y] as HTMLFormElement;
          result +=
            (value == null ? "" : value) + (y < values.length - 1 ? "," : "&");
        }
      }
    } else if (values.length === 1) {
      const value = values[0] as HTMLFormElement;
      result +=
        (selectFields[i] as HTMLFormElement).name +
        "=" +
        (value == null ? "" : value + "&");
    }
  }
  return result;
}

function buildBlindFormURL(e: string) {
  let result = "";

  const form = document.getElementById(e);
  // getting the checkboxes
  const checkboxFields = form.querySelectorAll("input[type=checkbox]");
  const checkboxResult = getCheckboxValues(checkboxFields, form);
  result += checkboxResult;
  // the radio buttons
  const radioFields = form.querySelectorAll("input[type=radio]:checked");
  const radioResult = getRadioValues(radioFields);
  result += radioResult;
  // the simple text boxes
  const textFields = form.querySelectorAll("input[type=text]");
  const textResult = getTextValues(textFields);
  result += textResult;
  // the paragrah text areas
  const textAreaFields = form.getElementsByTagName("textarea");
  const textAreaResult = getTextAreaValues(textAreaFields);
  result += textAreaResult;
  // and the multiple picklist
  const selectFields = form.getElementsByTagName("select");
  const selectResult = getSelectValues(selectFields);
  result += selectResult;
  // we get all the hidden fields
  const hiddenFields = form.querySelectorAll("input[type=hidden]");
  const hiddenResult = getHiddenValues(hiddenFields);
  result += hiddenResult;

  return result;
}

function submitEloquaForm(sanitizedFormInfo: string, form: HTMLFormElement) {
  fetch(formIds.get(form.id) + "?" + sanitizedFormInfo, {
    method: "POST",
  })
    .then((response) => {
      form.hidden = true;
      form.reset();
      (
        form.parentElement.querySelector(
          classes.submissionMessage,
        ) as HTMLDivElement
      ).hidden = false;
      if (response.status === 200) {
        form.parentElement.children[0].setAttribute("hidden", "hidden");
        console.log("form submitted successfully!\n");
      } else {
        console.log("oops! Something didn't go right!");
        alert("oops! Something didn't go right!");
      }
    })
    .catch((e) => {
      console.log("something went wrong with the submission! e:" + e);
    });
}

function captchaV3(e: any, sanitizedFormInfo: string, form: HTMLFormElement) {
  e.preventDefault();
  grecaptcha.ready(() => {
    grecaptcha
      .execute(form.parentElement.getAttribute("data-v3-clientkey"), {
        action: "submit",
      })
      .then((token: any) => {
        const data = {
          token,
          response: token,
          recaptchaToken: token,
          block:
            form.parentElement.children[form.parentElement.children.length - 2]
              .id,
        };

        fetch(window.location.origin + "/api/FormSubmission/submit", {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success === true) {
              form.parentElement.children[0].setAttribute("hidden", "hidden");
              submitEloquaForm(sanitizedFormInfo, form);
              console.log("v3 captcha verification passed", data);
            } else {
              captchaV2(e, sanitizedFormInfo, form);
              console.log("v3 captcha verification failed", data);
            }
          })
          .catch((e) => {
            console.log("something went wrong with the submission! e:" + e);
          });
      });
  });
}

function captchaV2(e: any, sanitizedFormInfo: string, form: HTMLFormElement) {
  const captcha_placeholder = document.createElement("div");
  captcha_placeholder.setAttribute("id", e.target.id + "_v2captcha");
  captcha_placeholder.setAttribute("class", "captcha_center");
  captcha_placeholder.setAttribute("align", "center");
  form
    .querySelectorAll(".row:last-child")
    [
      form.querySelectorAll(".row:last-child").length - 1
    ].before(captcha_placeholder);
  grecaptcha.ready(() => {
    grecaptcha.render(document.getElementById(e.target.id + "_v2captcha"), {
      callback: () => {
        submitEloquaForm(sanitizedFormInfo, form);
        grecaptcha.reset();
        console.log("v2 verification passed");
      },
      sitekey: form.parentElement.getAttribute("data-v2-clientkey"),
    });
  });
}

function validateNumeric(
  focusOutElement: HTMLFormElement,
  form: HTMLFormElement,
): number {
  let errors = 0;
  if (focusOutElement != null) {
    const message = document.createElement("pre");
    message.id = "elq-error-msg-numeric";
    message.classList.add("eloqua-error-message");
    message.classList.add("eloqua-error-message-numeric");
    message.classList.add("error-msg-formatted");
    message.textContent =
      focusOutElement.parentElement.hasAttribute("data-validation-msg") === true
        ? sanitizeErrorMsg(
            focusOutElement.parentElement.getAttribute("data-validation-msg"),
          )
        : "Please provide a numeric value";

    if (
      !focusOutElement.value ||
      isNaN(Number(focusOutElement.value.replace(/,/g, "")))
    ) {
      if (
        focusOutElement.parentElement.getElementsByClassName(
          "eloqua-error-message",
        ).length === 0
      ) {
        focusOutElement.parentElement.appendChild(message);
      }
      errors++;
    } else {
      if (
        focusOutElement.parentElement.getElementsByClassName(
          "eloqua-error-message",
        ).length === 1 &&
        focusOutElement.parentElement.getElementsByClassName(
          "eloqua-error-message-numeric",
        ).length === 1
      ) {
        focusOutElement.parentElement.removeChild(
          focusOutElement.parentElement.getElementsByClassName(
            "eloqua-error-message-numeric",
          )[0],
        );
      }
    }
    return errors;
  }

  const elements = form.querySelectorAll('[class*="eloqua-required-numeric"]');
  for (let i = 0; i < elements.length; i++) {
    const formElement = elements[i].firstChild as HTMLFormElement;
    const message = document.createElement("pre");
    message.id = "elq-error-msg-numeric";
    message.classList.add("eloqua-error-message");
    message.classList.add("eloqua-error-message-numeric");
    message.classList.add("error-msg-formatted");
    message.textContent =
      formElement.parentElement.hasAttribute("data-validation-msg") === true
        ? sanitizeErrorMsg(
            formElement.parentElement.getAttribute("data-validation-msg"),
          )
        : "Please provide a numeric value";

    if (
      !formElement.value ||
      isNaN(Number(formElement.value.replace(/,/g, "")))
    ) {
      if (
        formElement.parentElement.getElementsByClassName("eloqua-error-message")
          .length === 0
      ) {
        formElement.parentElement.appendChild(message);
      }
      errors++;
    } else {
      if (
        formElement.parentElement.getElementsByClassName("eloqua-error-message")
          .length === 1 &&
        formElement.parentElement.getElementsByClassName(
          "eloqua-error-message-numeric",
        ).length === 1
      ) {
        formElement.parentElement.removeChild(
          formElement.parentElement.getElementsByClassName(
            "eloqua-error-message-numeric",
          )[0],
        );
      }
    }
  }
  return errors;
}

function validateRequired(
  focusOutElement: HTMLFormElement,
  form: HTMLFormElement,
): number {
  let errors = 0;
  if (focusOutElement != null) {
    const message = document.createElement("pre");
    message.id = "elq-error-msg-req";
    message.classList.add("eloqua-error-message");
    message.classList.add("eloqua-error-message-required");
    message.classList.add("error-msg-formatted");
    message.textContent =
      focusOutElement.parentElement.hasAttribute("data-validation-msg") === true
        ? sanitizeErrorMsg(
            focusOutElement.parentElement.getAttribute("data-validation-msg"),
          )
        : "This field is required";
    // regular textboxes
    if (
      focusOutElement.type === "text" ||
      focusOutElement.type === "textarea"
    ) {
      const characterCount = focusOutElement.value.length;
      if (characterCount === 0) {
        if (
          focusOutElement.parentElement.getElementsByClassName(
            "eloqua-error-message",
          ).length === 0
        ) {
          focusOutElement.parentElement.appendChild(message);
        }
        errors++;
      } else {
        if (
          focusOutElement.parentElement.getElementsByClassName(
            "eloqua-error-message",
          ).length === 1 &&
          focusOutElement.parentElement.getElementsByClassName(
            "eloqua-error-message-required",
          ).length === 1
        ) {
          focusOutElement.parentElement.removeChild(
            focusOutElement.parentElement.getElementsByClassName(
              "eloqua-error-message-required",
            )[0],
          );
        }
      }
    }
    // single picklist and multiple picklist
    else if (
      focusOutElement.type === "select-one" ||
      focusOutElement.type === "select-multiple"
    ) {
      const optionsSelected =
        focusOutElement.querySelectorAll("option:checked").length;
      if (optionsSelected < 1) {
        if (
          focusOutElement.parentElement.getElementsByClassName(
            "eloqua-error-message",
          ).length === 0
        ) {
          focusOutElement.parentElement.appendChild(message);
        }
        errors++;
      } else {
        if (
          focusOutElement.parentElement.getElementsByClassName(
            "eloqua-error-message",
          ).length === 1 &&
          focusOutElement.parentElement.getElementsByClassName(
            "eloqua-error-message-required",
          ).length === 1
        ) {
          focusOutElement.parentElement.removeChild(
            focusOutElement.parentElement.getElementsByClassName(
              "eloqua-error-message-required",
            )[0],
          );
        }
      }
    }
    return errors;
  }

  const elements = form.getElementsByClassName("eloqua-required-input");
  for (let i = 0; i < elements.length; i++) {
    const formElement = elements[i].firstChild as HTMLFormElement;
    const message = document.createElement("pre");
    message.id = "elq-error-msg-req";
    message.classList.add("eloqua-error-message");
    message.classList.add("eloqua-error-message-required");
    message.classList.add("error-msg-formatted");
    message.textContent =
      formElement.parentElement.hasAttribute("data-validation-msg") === true
        ? sanitizeErrorMsg(
            formElement.parentElement.getAttribute("data-validation-msg"),
          )
        : "This field is required";

    // regular textboxes
    if (formElement.type === "text" || formElement.type === "textarea") {
      const characterCount = formElement.value.length;
      if (characterCount === 0) {
        if (
          elements[i].getElementsByClassName("eloqua-error-message").length ===
          0
        ) {
          elements[i].appendChild(message);
        }
        errors++;
      } else {
        if (
          elements[i].getElementsByClassName("eloqua-error-message").length ===
            1 &&
          elements[i].getElementsByClassName("eloqua-error-message-required")
            .length === 1
        ) {
          elements[i].removeChild(
            elements[i].getElementsByClassName(
              "eloqua-error-message-required",
            )[0],
          );
        }
      }
    }
    // single picklist and multiple picklist
    else if (
      formElement.type === "select-one" ||
      formElement.type === "select-multiple"
    ) {
      const optionsSelected =
        formElement.querySelectorAll("option:checked").length;
      if (optionsSelected < 1) {
        if (
          elements[i].getElementsByClassName("eloqua-error-message").length ===
          0
        ) {
          elements[i].appendChild(message);
        }
        errors++;
      } else {
        if (
          elements[i].getElementsByClassName("eloqua-error-message").length ===
            1 &&
          elements[i].getElementsByClassName("eloqua-error-message-required")
            .length === 1
        ) {
          elements[i].removeChild(
            elements[i].getElementsByClassName(
              "eloqua-error-message-required",
            )[0],
          );
        }
      }
    }
  }

  // the logic for radio and checked buttons is different
  errors += validateReqRadioFields(
    null,
    form.querySelectorAll("input[type=radio]"),
  );
  errors += validateReqCheckedFields(
    null,
    form.querySelectorAll("input[type=checkbox]"),
  );

  return errors;
}

function validateForm(form: HTMLFormElement): Number {
  let errors = 0;

  errors += validateRequired(null, form);
  errors += validateUrl(null, form);
  errors += validateNumericRanges(null, form);
  errors += validateValidDates(null, form);
  errors += validateHtml(null, form);
  errors += validateEmail(null, form);
  errors += validateCharacterLength(null, form);
  errors += validateNumeric(null, form);

  return errors;
}

function validateNumericRanges(
  focusOutElement: HTMLFormElement,
  form: HTMLFormElement,
): number {
  let errors = 0;
  if (focusOutElement != null) {
    const message = document.createElement("pre");
    message.id = "elq-error-msg-numeric-length";
    message.classList.add("eloqua-error-message");
    message.classList.add("eloqua-error-message-numeric-length");
    message.classList.add("error-msg-formatted");
    message.textContent =
      focusOutElement.parentElement.hasAttribute("data-validation-msg") === true
        ? sanitizeErrorMsg(
            focusOutElement.parentElement.getAttribute("data-validation-msg"),
          )
        : "Invalid numeric value";

    const rangeRequirements = focusOutElement.parentElement.className.match(
      /eloqua-required-numeric-range-(\d+)-(\d+)/,
    );
    const max = Number(rangeRequirements[2]);
    const min = Number(rangeRequirements[1]);
    if (
      isNaN(Number(focusOutElement.value)) ||
      Number(focusOutElement.value) < min ||
      Number(focusOutElement.value) > max
    ) {
      if (
        focusOutElement.parentElement.getElementsByClassName(
          "eloqua-error-message",
        ).length === 0
      ) {
        focusOutElement.parentElement.appendChild(message);
      }
      errors++;
    } else {
      if (
        focusOutElement.parentElement.getElementsByClassName(
          "eloqua-error-message",
        ).length === 1 &&
        focusOutElement.parentElement.getElementsByClassName(
          "eloqua-error-message-numeric-length",
        ).length === 1
      ) {
        focusOutElement.parentElement.removeChild(
          focusOutElement.parentElement.getElementsByClassName(
            "eloqua-error-message-numeric-length",
          )[0],
        );
      }
    }
    return errors;
  }

  const elements = form.querySelectorAll(
    '[class*="eloqua-required-numeric-range"]',
  );
  for (let i = 0; i < elements.length; i++) {
    const formElement = elements[i].firstChild as HTMLFormElement;
    const message = document.createElement("pre");
    message.id = "elq-error-msg-numeric-length";
    message.classList.add("eloqua-error-message");
    message.classList.add("eloqua-error-message-numeric-length");
    message.classList.add("error-msg-formatted");
    message.textContent =
      formElement.parentElement.hasAttribute("data-validation-msg") === true
        ? sanitizeErrorMsg(
            formElement.parentElement.getAttribute("data-validation-msg"),
          )
        : "Invalid numeric value";

    const rangeRequirements = formElement.parentElement.className.match(
      /eloqua-required-numeric-range-(\d+)-(\d+)/,
    );
    const max = Number(rangeRequirements[2]);
    const min = Number(rangeRequirements[1]);
    if (
      isNaN(Number(formElement.value)) ||
      Number(formElement.value) < min ||
      Number(formElement.value) > max
    ) {
      if (
        formElement.parentElement.getElementsByClassName("eloqua-error-message")
          .length === 0
      ) {
        formElement.parentElement.appendChild(message);
      }
      errors++;
    } else {
      if (
        formElement.parentElement.getElementsByClassName("eloqua-error-message")
          .length === 1 &&
        formElement.parentElement.getElementsByClassName(
          "eloqua-error-message-numeric-length",
        ).length === 1
      ) {
        formElement.parentElement.removeChild(
          formElement.parentElement.getElementsByClassName(
            "eloqua-error-message-numeric-length",
          )[0],
        );
      }
    }
  }
  return errors;
}

function validateValidDates(
  focusOutElement: HTMLFormElement,
  form: HTMLFormElement,
): number {
  let errors = 0;
  if (focusOutElement != null) {
    const message = document.createElement("pre");
    message.id = "elq-error-msg-valid-date";
    message.classList.add("eloqua-error-message");
    message.classList.add("eloqua-error-message-valid-date");
    message.classList.add("error-msg-formatted");
    message.textContent =
      focusOutElement.parentElement.hasAttribute("data-validation-msg") === true
        ? sanitizeErrorMsg(
            focusOutElement.parentElement.getAttribute("data-validation-msg"),
          )
        : "Value must be a valid date (MM/DD/YY or MM/DD/YYYY)";

    if (
      focusOutElement.value.match(
        /^(?:[0][0-9]|[1][0-2])\/(?:[0][1-9]|[1-2][0-9]|[3][0-1])\/(?:\d{4}|\d{2})$/,
      ) === null
    ) {
      if (
        focusOutElement.parentElement.getElementsByClassName(
          "eloqua-error-message",
        ).length === 0
      ) {
        focusOutElement.parentElement.appendChild(message);
      }
      errors++;
    } else {
      if (
        focusOutElement.parentElement.getElementsByClassName(
          "eloqua-error-message",
        ).length === 1 &&
        focusOutElement.parentElement.getElementsByClassName(
          "eloqua-error-message-valid-date",
        ).length === 1
      ) {
        focusOutElement.parentElement.removeChild(
          focusOutElement.parentElement.getElementsByClassName(
            "eloqua-error-message-valid-date",
          )[0],
        );
      }
    }
    return errors;
  }

  const elements = form.getElementsByClassName("eloqua-required-valid-date");
  for (let i = 0; i < elements.length; i++) {
    const formElement = elements[i].firstChild as HTMLFormElement;
    const message = document.createElement("pre");
    message.id = "elq-error-msg-valid-date";
    message.classList.add("eloqua-error-message");
    message.classList.add("eloqua-error-message-valid-date");
    message.classList.add("error-msg-formatted");
    message.textContent =
      formElement.parentElement.hasAttribute("data-validation-msg") === true
        ? sanitizeErrorMsg(
            formElement.parentElement.getAttribute("data-validation-msg"),
          )
        : "Value must be a valid date (MM/DD/YY or MM/DD/YYYY)";

    if (
      formElement.value.match(
        /^(?:[0][0-9]|[1][0-2])\/(?:[0][1-9]|[1-2][0-9]|[3][0-1])\/(?:\d{4}|\d{2})$/,
      ) === null
    ) {
      if (
        formElement.parentElement.getElementsByClassName("eloqua-error-message")
          .length === 0
      ) {
        formElement.parentElement.appendChild(message);
      }
      errors++;
    } else {
      if (
        formElement.parentElement.getElementsByClassName("eloqua-error-message")
          .length === 1 &&
        formElement.parentElement.getElementsByClassName(
          "eloqua-error-message-valid-date",
        ).length === 1
      ) {
        formElement.parentElement.removeChild(
          formElement.parentElement.getElementsByClassName(
            "eloqua-error-message-valid-date",
          )[0],
        );
      }
    }
  }
  return errors;
}

function validateCharacterLength(
  focusOutElement: HTMLFormElement,
  form: HTMLFormElement,
): number {
  let errors = 0;
  if (focusOutElement != null) {
    const message = document.createElement("pre");
    message.id = "elq-error-msg-length";
    message.classList.add("eloqua-error-message");
    message.classList.add("eloqua-error-message-length");
    message.classList.add("error-msg-formatted");
    message.textContent =
      focusOutElement.parentElement.hasAttribute("data-validation-msg") === true
        ? sanitizeErrorMsg(
            focusOutElement.parentElement.getAttribute("data-validation-msg"),
          )
        : "Invalid length for field value";

    const lengthRequirements = focusOutElement.parentElement.className.match(
      /eloqua-required-length-(\d+)-(\d+)/,
    );
    const max = Number(lengthRequirements[2]);
    const min = Number(lengthRequirements[1]);
    if (
      focusOutElement.value.length < min ||
      focusOutElement.value.length > max
    ) {
      if (
        focusOutElement.parentElement.getElementsByClassName(
          "eloqua-error-message",
        ).length === 0
      ) {
        focusOutElement.parentElement.appendChild(message);
      }
      errors++;
    } else {
      if (
        focusOutElement.parentElement.getElementsByClassName(
          "eloqua-error-message",
        ).length === 1 &&
        focusOutElement.parentElement.getElementsByClassName(
          "eloqua-error-message-length",
        ).length === 1
      ) {
        focusOutElement.parentElement.removeChild(
          focusOutElement.parentElement.getElementsByClassName(
            "eloqua-error-message-length",
          )[0],
        );
      }
    }
    return errors;
  }

  const elements = form.querySelectorAll('[class*="eloqua-required-length"]');
  for (let i = 0; i < elements.length; i++) {
    const formElement = elements[i].firstChild as HTMLFormElement;
    const message = document.createElement("pre");
    message.id = "elq-error-msg-length";
    message.classList.add("eloqua-error-message");
    message.classList.add("eloqua-error-message-length");
    message.classList.add("error-msg-formatted");
    message.textContent =
      formElement.parentElement.hasAttribute("data-validation-msg") === true
        ? sanitizeErrorMsg(
            formElement.parentElement.getAttribute("data-validation-msg"),
          )
        : "Invalid length for field value";
    const lengthRequirements = formElement.parentElement.className.match(
      /eloqua-required-length-(\d+)-(\d+)/,
    );
    const max = Number(lengthRequirements[2]);
    const min = Number(lengthRequirements[1]);
    if (formElement.value.length < min || formElement.value.length > max) {
      if (
        formElement.parentElement.getElementsByClassName("eloqua-error-message")
          .length === 0
      ) {
        formElement.parentElement.appendChild(message);
      }
      errors++;
    } else {
      if (
        formElement.parentElement.getElementsByClassName("eloqua-error-message")
          .length === 1 &&
        formElement.parentElement.getElementsByClassName(
          "eloqua-error-message-length",
        ).length === 1
      ) {
        formElement.parentElement.removeChild(
          formElement.parentElement.getElementsByClassName(
            "eloqua-error-message-length",
          )[0],
        );
      }
    }
  }
  return errors;
}

function validateHtml(
  focusOutElement: HTMLFormElement,
  form: HTMLFormElement,
): number {
  let errors = 0;
  if (focusOutElement != null) {
    const emailRegex = new RegExp("(<([^>]{0,10})>)", "ig");
    const message = document.createElement("pre");
    message.id = "elq-error-msg-html";
    message.classList.add("eloqua-error-message");
    message.classList.add("eloqua-error-message-html");
    message.classList.add("error-msg-formatted");
    message.textContent =
      focusOutElement.parentElement.hasAttribute("data-validation-msg") === true
        ? sanitizeErrorMsg(
            focusOutElement.parentElement.getAttribute("data-validation-msg"),
          )
        : "Value must not contain any HTML";

    if (emailRegex.test(focusOutElement.value)) {
      if (
        focusOutElement.parentElement.getElementsByClassName(
          "eloqua-error-message",
        ).length === 0
      ) {
        focusOutElement.parentElement.appendChild(message);
      }
      errors++;
    } else {
      if (
        focusOutElement.parentElement.getElementsByClassName(
          "eloqua-error-message",
        ).length === 1 &&
        focusOutElement.parentElement.getElementsByClassName(
          "eloqua-error-message-html",
        ).length === 1
      ) {
        focusOutElement.parentElement.removeChild(
          focusOutElement.parentElement.getElementsByClassName(
            "eloqua-error-message-html",
          )[0],
        );
      }
    }
    return errors;
  }

  const elements = form.querySelectorAll("input[type=text],textarea");
  for (let i = 0; i < elements.length; i++) {
    const formElement = elements[i] as HTMLFormElement;
    const emailRegex = new RegExp("(<([^>]{0,10})>)", "ig");
    const message = document.createElement("pre");
    message.id = "elq-error-msg-html";
    message.classList.add("eloqua-error-message");
    message.classList.add("eloqua-error-message-html");
    message.classList.add("error-msg-formatted");
    message.textContent =
      formElement.parentElement.hasAttribute("data-validation-msg") === true
        ? formElement.parentElement.getAttribute("data-validation-msg")
        : "Value must not contain any HTML";

    if (emailRegex.test(formElement.value)) {
      if (
        formElement.parentElement.getElementsByClassName("eloqua-error-message")
          .length === 0
      ) {
        formElement.parentElement.appendChild(message);
      }
      errors++;
    } else {
      if (
        formElement.parentElement.getElementsByClassName("eloqua-error-message")
          .length === 1 &&
        formElement.parentElement.getElementsByClassName(
          "eloqua-error-message-html",
        ).length === 1
      ) {
        formElement.parentElement.removeChild(
          formElement.parentElement.getElementsByClassName(
            "eloqua-error-message-html",
          )[0],
        );
      }
    }
  }
  return errors;
}

function validateEmail(
  focusOutElement: HTMLFormElement,
  form: HTMLFormElement,
): number {
  let errors = 0;
  if (focusOutElement != null) {
    const message = document.createElement("pre");
    message.id = "elq-error-msg-email";
    message.classList.add("eloqua-error-message");
    message.classList.add("eloqua-error-message-email");
    message.classList.add("error-msg-formatted");
    message.textContent =
      focusOutElement.parentElement.hasAttribute("data-validation-msg") === true
        ? sanitizeErrorMsg(
            focusOutElement.parentElement.getAttribute("data-validation-msg"),
          )
        : "A valid email address is required";

    if (
      !String(focusOutElement.value)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
    ) {
      if (
        focusOutElement.parentElement.getElementsByClassName(
          "eloqua-error-message",
        ).length === 0
      ) {
        focusOutElement.parentElement.appendChild(message);
      }
      errors++;
    } else {
      if (
        focusOutElement.parentElement.getElementsByClassName(
          "eloqua-error-message",
        ).length === 1 &&
        focusOutElement.parentElement.getElementsByClassName(
          "eloqua-error-message-email",
        ).length === 1
      ) {
        focusOutElement.parentElement.removeChild(
          focusOutElement.parentElement.getElementsByClassName(
            "eloqua-error-message-email",
          )[0],
        );
      }
    }
    return errors;
  }

  const elements = form.getElementsByClassName("eloqua-required-email");
  for (let i = 0; i < elements.length; i++) {
    const formElement = elements[i].firstChild as HTMLFormElement;
    const message = document.createElement("pre");
    message.id = "elq-error-msg-email";
    message.classList.add("eloqua-error-message");
    message.classList.add("eloqua-error-message-email");
    message.classList.add("error-msg-formatted");
    message.textContent =
      formElement.parentElement.hasAttribute("data-validation-msg") === true
        ? sanitizeErrorMsg(
            formElement.parentElement.getAttribute("data-validation-msg"),
          )
        : "A valid email address is required";

    if (
      !String(formElement.value)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
    ) {
      if (
        formElement.parentElement.getElementsByClassName("eloqua-error-message")
          .length === 0
      ) {
        formElement.parentElement.appendChild(message);
      }
      errors++;
    } else {
      if (
        formElement.parentElement.getElementsByClassName("eloqua-error-message")
          .length === 1 &&
        formElement.parentElement.getElementsByClassName(
          "eloqua-error-message-email",
        ).length === 1
      ) {
        formElement.parentElement.removeChild(
          formElement.parentElement.getElementsByClassName(
            "eloqua-error-message-email",
          )[0],
        );
      }
    }
  }
  return errors;
}

function validateUrl(
  focusOutElement: HTMLFormElement,
  form: HTMLFormElement,
): number {
  let errors = 0;

  if (focusOutElement != null) {
    const urlRegex = new RegExp(
      "(telnet|ftp|https?)://(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9].|[a-z0-9].)+[a-z]{2,63}",
      "i",
    );
    const message = document.createElement("pre");
    message.id = "elq-error-msg-url";
    message.classList.add("eloqua-error-message");
    message.classList.add("eloqua-error-message-url");
    message.classList.add("error-msg-formatted");
    message.textContent =
      focusOutElement.parentElement.hasAttribute("data-validation-msg") === true
        ? sanitizeErrorMsg(
            focusOutElement.parentElement.getAttribute("data-validation-msg"),
          )
        : "Value must not contain any URL's";

    if (urlRegex.test(focusOutElement.value)) {
      if (
        focusOutElement.parentElement.getElementsByClassName(
          "eloqua-error-message",
        ).length === 0
      ) {
        focusOutElement.parentElement.appendChild(message);
      }
      errors++;
    } else {
      if (
        focusOutElement.parentElement.getElementsByClassName(
          "eloqua-error-message",
        ).length === 1 &&
        focusOutElement.parentElement.getElementsByClassName(
          "eloqua-error-message-url",
        ).length === 1
      ) {
        focusOutElement.parentElement.removeChild(
          focusOutElement.parentElement.getElementsByClassName(
            "eloqua-error-message-url",
          )[0],
        );
      }
    }
    return errors;
  }

  const elements = form.querySelectorAll("input[type=text],textarea");
  for (let i = 0; i < elements.length; i++) {
    const formElement = elements[i] as HTMLFormElement;
    const urlRegex = new RegExp(
      "(telnet|ftp|https?)://(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9].|[a-z0-9].)+[a-z]{2,63}",
      "i",
    );
    const message = document.createElement("pre");
    message.id = "elq-error-msg-url";
    message.classList.add("eloqua-error-message");
    message.classList.add("eloqua-error-message-url");
    message.classList.add("error-msg-formatted");
    message.textContent =
      formElement.parentElement.hasAttribute("data-validation-msg") === true
        ? sanitizeErrorMsg(
            formElement.parentElement.getAttribute("data-validation-msg"),
          )
        : "Value must not contain any URL's";

    if (urlRegex.test(formElement.value)) {
      if (
        formElement.parentElement.getElementsByClassName("eloqua-error-message")
          .length === 0
      ) {
        formElement.parentElement.appendChild(message);
      }
      errors++;
    } else {
      if (
        formElement.parentElement.getElementsByClassName("eloqua-error-message")
          .length === 1 &&
        formElement.parentElement.getElementsByClassName(
          "eloqua-error-message-url",
        ).length === 1
      ) {
        formElement.parentElement.removeChild(
          formElement.parentElement.getElementsByClassName(
            "eloqua-error-message-url",
          )[0],
        );
      }
    }
  }
  return errors;
}

function validateReqCheckedFields(
  focusOutElement: HTMLFormElement,
  reqCheckedFields: NodeListOf<any>,
): number {
  let errors = 0;
  const seen = new Set<string>();

  if (focusOutElement != null) {
    const message = document.createElement("pre");
    message.id = "elq-error-msg-req";
    message.classList.add("eloqua-error-message");
    message.classList.add("eloqua-error-message-required");
    message.classList.add("error-msg-formatted");

    if (!seen.has(focusOutElement.name)) {
      seen.add(focusOutElement.name);
      // single checkboxes
      if (
        focusOutElement.parentElement.classList.contains(
          "single-checkbox-row",
        ) &&
        focusOutElement.parentElement.classList.contains(
          "eloqua-required-input",
        )
      ) {
        if (focusOutElement.checked === false) {
          if (
            focusOutElement.parentElement.parentElement.getElementsByClassName(
              "eloqua-error-message",
            ).length === 0
          ) {
            message.textContent =
              focusOutElement.parentElement.parentElement.hasAttribute(
                "data-validation-msg",
              ) === true
                ? sanitizeErrorMsg(
                    focusOutElement.parentElement.parentElement.getAttribute(
                      "data-validation-msg",
                    ),
                  )
                : "This field is required";
            focusOutElement.parentElement.parentElement.appendChild(message);
          }
          errors++;
        } else {
          if (
            focusOutElement.parentElement.parentElement.getElementsByClassName(
              "eloqua-error-message",
            ).length === 1 &&
            focusOutElement.parentElement.parentElement.getElementsByClassName(
              "eloqua-error-message-required",
            ).length === 1
          ) {
            focusOutElement.parentElement.parentElement.removeChild(
              focusOutElement.parentElement.parentElement.getElementsByClassName(
                "eloqua-error-message-required",
              )[0],
            );
          }
        }
      }
      // checkbox group
      else if (
        focusOutElement.parentElement.parentElement.classList.contains(
          "eloqua-required-input",
        )
      ) {
        const checkedGroup =
          focusOutElement.parentElement.parentElement.querySelectorAll(
            "input[name=" + focusOutElement.name + "]:checked",
          ).length;
        if (checkedGroup < 1) {
          if (
            focusOutElement.parentElement.parentElement.getElementsByClassName(
              "eloqua-error-message",
            ).length === 0
          ) {
            message.textContent =
              focusOutElement.parentElement.parentElement.hasAttribute(
                "data-validation-msg",
              ) === true
                ? sanitizeErrorMsg(
                    focusOutElement.parentElement.parentElement.getAttribute(
                      "data-validation-msg",
                    ),
                  )
                : "This field is required";
            focusOutElement.parentElement.parentElement.appendChild(message);
          }
          errors++;
        } else {
          if (
            focusOutElement.parentElement.parentElement.getElementsByClassName(
              "eloqua-error-message",
            ).length === 1 &&
            focusOutElement.parentElement.parentElement.getElementsByClassName(
              "eloqua-error-message-required",
            ).length === 1
          ) {
            focusOutElement.parentElement.parentElement.removeChild(
              focusOutElement.parentElement.parentElement.getElementsByClassName(
                "eloqua-error-message-required",
              )[0],
            );
          }
        }
      }
    }
    return errors;
  }

  for (let i = 0; i < reqCheckedFields.length; i++) {
    const value = reqCheckedFields[i] as HTMLFormElement;
    const message = document.createElement("pre");
    message.id = "elq-error-msg-req";
    message.classList.add("eloqua-error-message");
    message.classList.add("eloqua-error-message-required");
    message.classList.add("error-msg-formatted");

    if (!seen.has(value.name)) {
      seen.add(value.name);
      // single checkboxes
      if (
        value.parentElement.classList.contains("single-checkbox-row") &&
        value.parentElement.classList.contains("eloqua-required-input")
      ) {
        if (value.checked === false) {
          if (
            value.parentElement.parentElement.getElementsByClassName(
              "eloqua-error-message",
            ).length === 0
          ) {
            message.textContent =
              value.parentElement.parentElement.hasAttribute(
                "data-validation-msg",
              ) === true
                ? sanitizeErrorMsg(
                    value.parentElement.parentElement.getAttribute(
                      "data-validation-msg",
                    ),
                  )
                : "This field is required";
            value.parentElement.parentElement.appendChild(message);
          }
          errors++;
        } else {
          if (
            value.parentElement.parentElement.getElementsByClassName(
              "eloqua-error-message",
            ).length === 1 &&
            value.parentElement.parentElement.getElementsByClassName(
              "eloqua-error-message-required",
            ).length === 1
          ) {
            value.parentElement.parentElement.removeChild(
              value.parentElement.parentElement.getElementsByClassName(
                "eloqua-error-message-required",
              )[0],
            );
          }
        }
      }
      // checkbox group
      else if (
        value.parentElement.parentElement.classList.contains(
          "eloqua-required-input",
        )
      ) {
        const checkedGroup = value.parentElement.parentElement.querySelectorAll(
          "input[name=" + value.name + "]:checked",
        ).length;
        if (checkedGroup < 1) {
          if (
            value.parentElement.parentElement.getElementsByClassName(
              "eloqua-error-message",
            ).length === 0
          ) {
            message.textContent =
              value.parentElement.parentElement.hasAttribute(
                "data-validation-msg",
              ) === true
                ? sanitizeErrorMsg(
                    value.parentElement.parentElement.getAttribute(
                      "data-validation-msg",
                    ),
                  )
                : "This field is required";
            value.parentElement.parentElement.appendChild(message);
          }
          errors++;
        } else {
          if (
            value.parentElement.parentElement.getElementsByClassName(
              "eloqua-error-message",
            ).length === 1 &&
            value.parentElement.parentElement.getElementsByClassName(
              "eloqua-error-message-required",
            ).length === 1
          ) {
            value.parentElement.parentElement.removeChild(
              value.parentElement.parentElement.getElementsByClassName(
                "eloqua-error-message-required",
              )[0],
            );
          }
        }
      }
    }
  }
  return errors;
}

function validateReqRadioFields(
  focusOutElement: HTMLFormElement,
  reqRadioFields: NodeListOf<any>,
): number {
  let errors = 0;
  const seen = new Set<string>();

  if (focusOutElement != null) {
    const message = document.createElement("pre");
    message.id = "elq-error-msg-req";
    message.classList.add("eloqua-error-message");
    message.classList.add("eloqua-error-message-required");
    message.classList.add("error-msg-formatted");

    if (!seen.has(focusOutElement.name)) {
      if (
        focusOutElement.parentElement.parentElement.classList.contains(
          "eloqua-required-input",
        )
      ) {
        seen.add(focusOutElement.name);
        const radioButtons =
          focusOutElement.parentElement.parentElement.querySelectorAll(
            "input[name=" + focusOutElement.name + "]:checked",
          ).length;
        if (radioButtons < 1) {
          if (
            focusOutElement.parentElement.parentElement.getElementsByClassName(
              "eloqua-error-message",
            ).length === 0
          ) {
            message.textContent =
              focusOutElement.parentElement.parentElement.hasAttribute(
                "data-validation-msg",
              ) === true
                ? sanitizeErrorMsg(
                    focusOutElement.parentElement.parentElement.getAttribute(
                      "data-validation-msg",
                    ),
                  )
                : "This field is required";
            focusOutElement.parentElement.parentElement.appendChild(message);
          }
          errors++;
        } else {
          if (
            focusOutElement.parentElement.parentElement.getElementsByClassName(
              "eloqua-error-message",
            ).length === 1 &&
            focusOutElement.parentElement.parentElement.getElementsByClassName(
              "eloqua-error-message-required",
            ).length === 1
          ) {
            focusOutElement.parentElement.parentElement.removeChild(
              focusOutElement.parentElement.parentElement.getElementsByClassName(
                "eloqua-error-message-required",
              )[0],
            );
          }
        }
      }
    }
    return errors;
  }

  for (let i = 0; i < reqRadioFields.length; i++) {
    const value = reqRadioFields[i] as HTMLFormElement;
    const message = document.createElement("pre");
    message.id = "elq-error-msg-req";
    message.classList.add("eloqua-error-message");
    message.classList.add("eloqua-error-message-required");
    message.classList.add("error-msg-formatted");

    if (!seen.has(value.name)) {
      if (
        value.parentElement.parentElement.classList.contains(
          "eloqua-required-input",
        )
      ) {
        seen.add(value.name);
        const radioButtons = value.parentElement.parentElement.querySelectorAll(
          "input[name=" + value.name + "]:checked",
        ).length;
        if (radioButtons < 1) {
          if (
            reqRadioFields[
              i
            ].parentElement.parentElement.getElementsByClassName(
              "eloqua-error-message",
            ).length === 0
          ) {
            message.textContent =
              value.parentElement.parentElement.hasAttribute(
                "data-validation-msg",
              ) === true
                ? sanitizeErrorMsg(
                    value.parentElement.parentElement.getAttribute(
                      "data-validation-msg",
                    ),
                  )
                : "This field is required";
            reqRadioFields[i].parentElement.parentElement.appendChild(message);
          }
          errors++;
        } else {
          if (
            reqRadioFields[
              i
            ].parentElement.parentElement.getElementsByClassName(
              "eloqua-error-message",
            ).length === 1 &&
            reqRadioFields[
              i
            ].parentElement.parentElement.getElementsByClassName(
              "eloqua-error-message-required",
            ).length === 1
          ) {
            reqRadioFields[i].parentElement.parentElement.removeChild(
              reqRadioFields[
                i
              ].parentElement.parentElement.getElementsByClassName(
                "eloqua-error-message-required",
              )[0],
            );
          }
        }
      }
    }
  }
  return errors;
}

function attachKeyUpEventTextFields(formElements: NodeListOf<Element>): void {
  for (let i = 0; i < formElements.length; i++) {
    const element = formElements[i] as HTMLFormElement;

    element.addEventListener("focus", (e) => {
      const field = e.target as HTMLFormElement;
      if (
        field.parentElement.querySelectorAll(".eloqua-error-message").length > 0
      ) {
        field.parentElement.removeChild(
          field.parentElement.getElementsByClassName("eloqua-error-message")[0],
        );
      }
    });

    element.addEventListener("focusout", (e) => {
      const field = e.target as HTMLFormElement;

      if (field.parentElement.classList.contains("eloqua-required-input")) {
        if (validateRequired(field, null) > 0) {
          console.log("required validation");
        }
      }

      if (field.parentElement.classList.contains("eloqua-required-numeric")) {
        if (validateNumeric(field, null) > 0) {
          console.log("numeric validation");
        }
      }

      if (validateUrl(field, null) > 0) {
        console.log("url validation");
      }

      if (validateHtml(field, null) > 0) {
        console.log("html validation");
      }

      if (field.parentElement.classList.contains("eloqua-required-email")) {
        if (validateEmail(field, null) > 0) {
          console.log("email validation");
        }
      }

      if (
        field.parentElement.classList.contains("eloqua-required-valid-date")
      ) {
        if (validateValidDates(field, null) > 0) {
          console.log("valid date validation");
        }
      }

      if (
        field.parentElement.className.match(
          /eloqua-required-length-(\d+)-(\d+)/,
        )
      ) {
        if (validateCharacterLength(field, null) > 0) {
          console.log("required length validation");
        }
      }

      if (
        field.parentElement.className.match(
          /eloqua-required-numeric-range-(\d+)-(\d+)/,
        )
      ) {
        if (validateNumericRanges(field, null) > 0) {
          console.log("numeric range validation");
        }
      }
    });
  }
}

function attachKeyUpEventRadio(formElements: NodeListOf<Element>): void {
  for (let i = 0; i < formElements.length; i++) {
    const element = formElements[i] as HTMLFormElement;

    element.addEventListener("focus", (e) => {
      const field = e.target as HTMLFormElement;
      if (
        field.parentElement.parentElement.querySelectorAll(
          ".eloqua-error-message",
        ).length > 0
      ) {
        field.parentElement.parentElement.removeChild(
          field.parentElement.parentElement.getElementsByClassName(
            "eloqua-error-message",
          )[0],
        );
      }
    });

    element.addEventListener("focusout", (e) => {
      const field = e.target as HTMLFormElement;
      if (
        field.parentElement.parentElement.classList.contains(
          "eloqua-required-input",
        )
      ) {
        if (validateReqRadioFields(field, null) > 0) {
          console.log("radio button required validation");
        }
      }
    });
  }
}

function attachKeyUpEventCheckbox(formElements: NodeListOf<Element>): void {
  for (let i = 0; i < formElements.length; i++) {
    const element = formElements[i] as HTMLFormElement;

    element.addEventListener("focus", (e) => {
      const field = e.target as HTMLFormElement;
      if (
        field.parentElement.parentElement.querySelectorAll(
          ".eloqua-error-message",
        ).length > 0
      ) {
        field.parentElement.parentElement.removeChild(
          field.parentElement.parentElement.getElementsByClassName(
            "eloqua-error-message",
          )[0],
        );
      }
    });

    element.addEventListener("focusout", (e) => {
      const field = e.target as HTMLFormElement;

      if (
        field.parentElement.classList.contains("eloqua-required-input") ||
        field.parentElement.parentElement.classList.contains(
          "eloqua-required-input",
        )
      ) {
        if (validateReqCheckedFields(field, null) > 0) {
          console.log("checkbox required validation");
        }
      }
    });
  }
}

function addFieldsForSpamDetection(form: HTMLFormElement) {
  if (form.children.namedItem("elqFormSubmissionToken") == null) {
    const hiddenTokenField = document.createElement("input");
    hiddenTokenField.id = "elqFormSubmissionToken";
    hiddenTokenField.type = "hidden";
    hiddenTokenField.name = "elqFormSubmissionToken";
    hiddenTokenField.value = "";
    form.append(hiddenTokenField);
  }
}

function loadForm(): void {
  const forms = document.querySelectorAll("form.elq-form");

  for (let i = 0; i < forms.length; i++) {
    const form = forms[i] as HTMLFormElement;
    addFieldsForSpamDetection(form);
    form.id += "-" + i;
    formIds.set(form.id, form.action);
    attachKeyUpEventTextFields(
      form.querySelectorAll("input[type=text],textarea,select"),
    );
    attachKeyUpEventRadio(form.querySelectorAll("input[type=radio]"));
    attachKeyUpEventCheckbox(form.querySelectorAll("input[type=checkbox]"));
    handleDocumentLoad(
      form,
      form.parentElement.children[form.parentElement.children.length - 1].id,
    );

    const blind_form_submit = (ev: { preventDefault: () => void }) => {
      ev.preventDefault();
      if (
        validateForm(form) === 0 &&
        form.parentElement.children[0].hasAttribute("hidden")
      ) {
        const formInfo = buildBlindFormURL(form.id);
        const sanitizedFormInfo = formInfo.replace(/#/g, "");
        captchaV3(ev, sanitizedFormInfo, form);
      }
    };

    form.onsubmit = blind_form_submit;
  }
}

export class EloquaForm {
  $eloquaFormContainer: HTMLElement;
  $eloquaForm: HTMLFormElement;
  $submitButton: HTMLButtonElement;
  $submissionMessage: HTMLDivElement;

  constructor(element: HTMLDivElement) {
    this.$eloquaFormContainer = element;
    this.$eloquaForm = element.querySelector(
      classes.eloquaForm,
    ) as HTMLFormElement;
    this.$submitButton = element.querySelector(
      classes.submitbutton,
    ) as HTMLButtonElement;
    this.$submissionMessage = element.querySelector(
      classes.submissionMessage,
    ) as HTMLDivElement;

    this.bindEvents();
  }

  @autobind
  bindEvents(): void {
    window.addEventListener("load", loadForm);
  }
}
