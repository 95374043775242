import { autobind } from "core-decorators";
import { EventBus } from "../../globals/emitter";
import { GLOBAL_CONSTANTS } from "../../globals/constants";

const classes = {
  NavMenuTitles: ".js-nav-menu-title",
  NavMenuCols: ".nav-menu-columns",
  NavMenu: ".js-menu-columns",
  TrapDoor: ".trap-door",
  Desktop: ".-desktop",
  CloseMenu: ".js-exit-menu",
  SubNavLinks: ".sub-nav-links",
  MobileLink: ".js-mobile-link",
  Navigation: ".navigation",
  NavLogo: ".nav-logo-desktop",
  OptionButton: ".additional-button",
  LoginButton: ".login-button",
  SubNavTitles: ".sub-nav-title",
  NavSpacer: ".nav-spacer",
};

const constants = {
  hide: "-closed",
  hover: "-hover",
};

/**
 * @desc NavMenu
 */
export class NavMenu {
  $el: HTMLElement;
  $NavMenuTitles: Array<HTMLElement>;
  $NavMenuCols: Array<HTMLElement>;
  $NavMenu: HTMLElement;
  $TrapDoor: Array<HTMLElement>;
  $Desktop: Array<HTMLElement>;
  $CloseMenu: HTMLElement;
  $SubNavLinks: HTMLElement;
  $MobileLink: Array<HTMLElement>;
  $NavHeader: HTMLElement;
  $NavLogo: HTMLElement;
  $OptionButton: HTMLElement;
  $LoginButton: HTMLElement;
  $SubnavTitles: Array<HTMLElement>;
  $NavSpacer: HTMLElement;

  margins: number;
  submenu: number;
  logo: number;
  buttons: number;

  /**
   * @desc Set element to class variables
   * @param {HTMLElement} el - Element to bind module to.
   */
  constructor(el: HTMLElement) {
    if (!el) {
      return;
    }

    this.$el = el;
    this.$NavMenuTitles = Array.from(
      this.$el.querySelectorAll(classes.NavMenuTitles),
    ) as Array<HTMLElement>;
    this.$NavMenuCols = Array.from(
      this.$el.querySelectorAll(classes.NavMenuCols),
    ) as Array<HTMLElement>;
    this.$NavMenu = document.querySelector(classes.NavMenu) as HTMLElement;
    this.$TrapDoor = Array.from(
      this.$el.querySelectorAll(classes.TrapDoor),
    ) as Array<HTMLElement>;
    this.$Desktop = Array.from(
      this.$el.querySelectorAll(classes.Desktop),
    ) as Array<HTMLElement>;
    this.$CloseMenu = document.querySelector(classes.CloseMenu) as HTMLElement;
    this.$SubNavLinks = document.querySelector(
      classes.SubNavLinks,
    ) as HTMLElement;
    this.$MobileLink = Array.from(
      this.$el.querySelectorAll(classes.MobileLink),
    ) as Array<HTMLElement>;
    this.$NavHeader = document.querySelector(classes.Navigation) as HTMLElement;
    this.$NavLogo = document.querySelector(classes.NavLogo) as HTMLElement;
    this.$OptionButton = document.querySelector(
      classes.OptionButton,
    ) as HTMLElement;
    this.$LoginButton = document.querySelector(
      classes.LoginButton,
    ) as HTMLElement;
    this.$SubnavTitles = Array.from(
      this.$el.querySelectorAll(classes.SubNavTitles),
    ) as Array<HTMLElement>;
    this.$NavSpacer = document.querySelector(classes.NavSpacer) as HTMLElement;

    this.bindEvents();
  }
  /**
   * @desc Set up events for each menu item
   */
  bindEvents(): void {
    this.margins = GLOBAL_CONSTANTS.NAV.TOTAL_MARGINS;
    this.submenu = GLOBAL_CONSTANTS.NAV.SUB_MENU_MARGIN;
    for (const title of this.$SubnavTitles) {
      this.submenu =
        this.submenu +
        title.getBoundingClientRect().width +
        GLOBAL_CONSTANTS.NAV.SUB_MENU_MARGIN;
    }
    this.logo =
      this.$NavLogo !== null ? this.$NavLogo.getBoundingClientRect().width : 0;
    this.buttons =
      (this.$OptionButton !== null
        ? this.$OptionButton.getBoundingClientRect().width
        : 0) + this.$LoginButton.getBoundingClientRect().width;
    EventBus.on(GLOBAL_CONSTANTS.EVENTS.RESIZE, this.calcBreakpoint);
    EventBus.on(
      GLOBAL_CONSTANTS.EVENTS.PAGE_LOAD_COMPLETE,
      this.calcBreakpoint,
    );
    if (this.$NavMenuTitles) {
      this.$NavMenuTitles.forEach((item: HTMLElement) => {
        item.addEventListener("mouseover", this.handleRollOver);
      });
      if (this.$NavMenu !== null) {
        for (let j = 0; j < this.$NavMenu.children.length; j++) {
          this.$NavMenu.children[j].addEventListener(
            "mouseleave",
            this.handleRollOut,
          );
        }
      }
      EventBus.on(GLOBAL_CONSTANTS.EVENTS.SCROLL, this.handleScroll);
      EventBus.on(GLOBAL_CONSTANTS.EVENTS.RESIZE, this.hideAll);

      this.$MobileLink.forEach((item: HTMLElement) => {
        item.addEventListener("click", this.openLinks);
      });

      this.$CloseMenu.addEventListener("click", this.closeMenu);
    }
  }

  @autobind
  calcBreakpoint(): void {
    const ww = this.$NavHeader.getBoundingClientRect().width;
    const breakpoint = this.margins + this.buttons + this.logo + this.submenu;
    if (breakpoint > ww) {
      this.$NavHeader.classList.add(GLOBAL_CONSTANTS.NAV.MOBILE);
      this.$NavHeader.classList.remove(GLOBAL_CONSTANTS.NAV.DESKTOP);
    } else {
      this.$NavHeader.classList.add(GLOBAL_CONSTANTS.NAV.DESKTOP);
      this.$NavHeader.classList.remove(GLOBAL_CONSTANTS.NAV.MOBILE);
    }
    this.$NavSpacer.style.height = this.$NavHeader.clientHeight + "px";
  }

  @autobind
  handleRollOver(e: Event): void {
    e.stopPropagation();
    const $title = e.currentTarget as HTMLElement;
    const i = Number($title.dataset["menu"]);
    const $trap = this.$NavMenu.children[i] as HTMLElement;
    const $grid = this.$TrapDoor[i].children[0] as HTMLElement;

    if (
      this.$NavMenu &&
      this.$NavMenu.children &&
      this.$NavMenu.children.length > 0
    ) {
      for (let j = 0; j < this.$NavMenu.children.length; j++) {
        this.$NavMenu.children[j].classList.add(constants.hide);
      }
    }
    if (this.$NavMenuTitles) {
      this.$NavMenuTitles.forEach((item: HTMLElement) => {
        item.classList.remove(constants.hover);
      });
    }
    if ($title) {
      $title.classList.add(constants.hover);
    }
    if ($trap) {
      $trap.classList.remove(constants.hide);
    }

    this.$TrapDoor.forEach((item: HTMLElement) => {
      const h = $grid.getBoundingClientRect().height + 50;
      item.style.height = h + "px";
    });
  }
  @autobind
  handleRollOut(e: Event): void {
    e.stopPropagation();
    if (this.$NavMenuTitles) {
      this.$NavMenuTitles.forEach((item: HTMLElement) => {
        item.classList.remove(constants.hover);
      });
    }
    if (this.$TrapDoor) {
      this.$TrapDoor.forEach((item: HTMLElement) => {
        item.style.height = "0";
      });
    }
  }
  @autobind
  handleScroll(): void {
    let isDesktop = false;
    if (this.$Desktop[0] !== null) {
      isDesktop =
        window
          .getComputedStyle(this.$Desktop[0], null)
          .getPropertyValue("display") !== "none";
    }
    if (isDesktop) {
      this.$NavMenuTitles.forEach((item: HTMLElement) => {
        item.classList.remove(constants.hover);
      });
      this.$TrapDoor.forEach((item: HTMLElement) => {
        item.style.height = "0";
      });
    }
  }
  @autobind
  closeMenu(e: Event): void {
    const $button = e.currentTarget as HTMLElement;
    if ($button && $button.classList.contains(constants.hide)) {
      $button.classList.remove(constants.hide);
      if (this.$SubNavLinks) {
        this.$SubNavLinks.style.display = "inline-block";
      }
    } else {
      if ($button) {
        $button.classList.add(constants.hide);
        if (this.$SubNavLinks) {
          this.$SubNavLinks.style.display = "none";
        }
      }
    }
  }
  @autobind
  openLinks(e: Event): void {
    const $button = e.currentTarget as HTMLElement;
    if (!$button) {
      return;
    }
    const $trap = $button.nextElementSibling as HTMLElement;
    const h = window
      .getComputedStyle($trap.children[0], null)
      .getPropertyValue("height");
    if ($button.classList.contains("-open")) {
      $button.classList.remove("-open");
      if ($trap) {
        $trap.style.height = "0";
      }
    } else {
      $button.classList.add("-open");
      if ($trap) {
        $trap.style.height = h;
      }
    }
  }

  @autobind
  hideAll(): void {
    if (
      this.$NavMenu &&
      this.$NavMenu.children &&
      this.$NavMenu.children.length > 0
    ) {
      for (let j = 0; j < this.$NavMenu.children.length; j++) {
        this.$NavMenu.children[j].classList.add(constants.hide);
      }
    }
    if (this.$TrapDoor) {
      this.$TrapDoor.forEach((item: HTMLElement) => {
        item.style.height = "0";
      });
    }
  }
}
