import { autobind, debounce } from "core-decorators";
import { GLOBAL_CONSTANTS } from "../globals/constants";
import { EventBus } from "../globals/emitter";
import { moveTo } from "../effects/animation/animations";
import { IAnimation } from "../effects/animation/i-animation";
import "whatwg-fetch";

declare var gtm: any;

const constants = {
  searchInputId: ".js-search-input",
  searchResult: ".js-search-result",
  searchResults: ".js-search-results",
  searchResultsText: ".js-search-results-text",
  searchEmpty: ".js-search-empty",
  loadMore: ".js-search-load-more",
  loader: ".js-search-loader",

  positions: {
    start: "50%",
    end: "25%",
  },

  searchTypes: {
    helpCenterSearch: "help-center-search",
    standardSearch: "standard-search",
    topicsSearch: "topics-search",
  },
};

/**
 * @desc Handle search page functionality.
 */
export class Search {
  $el: HTMLElement;
  $input: HTMLInputElement;
  $textResults: HTMLElement;
  $loadMore: HTMLElement;
  $noResults: HTMLElement;
  $loader: HTMLElement;
  textResultArray: Array<HTMLElement>;
  searchInput: HTMLInputElement;
  searchResultItems: NodeListOf<HTMLElement>;
  resultArrayClone: Array<HTMLElement>;
  resultsShowing: boolean;
  resultsWrapper: HTMLElement;
  inputAnimation: IAnimation;
  query = "";
  numberOfItemsToSkip = 0;
  dataUrl = "/ajax/search";
  searchType = constants.searchTypes.standardSearch;
  searchPageType = "";
  parentCategories = "";
  childCategories = "";
  maxNumberOfResults = 15;
  excludePdf = false;
  gtmSearchType = "internal";

  constructor(el: HTMLElement) {
    this.$el = el;
    this.$input = this.$el.querySelector(
      constants.searchInputId,
    ) as HTMLInputElement;
    this.searchInput = this.$input.querySelector("input");
    this.$loadMore = this.$el.querySelector(constants.loadMore) as HTMLElement;
    this.$textResults = this.$el.querySelector(
      constants.searchResultsText,
    ) as HTMLElement;
    this.$noResults = this.$el.querySelector(
      constants.searchEmpty,
    ) as HTMLElement;
    this.$loader = this.$el.querySelector(constants.loader) as HTMLElement;
    this.resultsWrapper = this.$el.querySelector(
      constants.searchResults,
    ) as HTMLElement;
    this.inputAnimation = moveTo(
      this.$input,
      constants.positions.start,
      constants.positions.end,
    );
    this.resultsShowing = false;
    this.init();
  }

  init(): void {
    this.dataUrl = this.$el.dataset["url"] || this.dataUrl;
    this.maxNumberOfResults = this.$el.dataset["searchLimit"]
      ? parseInt(this.$el.dataset["searchLimit"], 10)
      : this.maxNumberOfResults;
    if (this.dataUrl.indexOf("HelpCenterSearch") >= 0) {
      this.searchPageType = this.$el.dataset["searchPageType"]
        ? this.$el.dataset["searchPageType"]
        : "HelpArticlePage";
      this.searchType = constants.searchTypes.helpCenterSearch;
      this.gtmSearchType = "help-center";
    } else {
      if (this.dataUrl.indexOf("topic") >= 0) {
        this.searchPageType = this.$el.dataset["searchPageType"]
          ? encodeURI(this.$el.dataset["searchPageType"])
          : "";
        this.parentCategories = this.$el.dataset["parentCategories"]
          ? encodeURI(this.$el.dataset["parentCategories"].replace(/&/g, "%26"))
          : "";
        this.childCategories = this.$el.dataset["childCategories"]
          ? encodeURI(this.$el.dataset["childCategories"].replace(/&/g, "%26"))
          : "";
        const excludePdf = this.$el.dataset["excludePdf"];
        if (excludePdf && excludePdf.toLowerCase() === "true") {
          this.excludePdf = true;
        }
        const maxNumberOfResults = this.$el.dataset["maxNumberOfResults"];
        if (maxNumberOfResults && maxNumberOfResults.match(/^-{0,1}\d+$/)) {
          this.maxNumberOfResults = +maxNumberOfResults;
          if (this.maxNumberOfResults < 1) {
            this.maxNumberOfResults = 5;
          }
        }
        this.searchType = constants.searchTypes.topicsSearch;
        this.gtmSearchType = "topics";
      }
    }

    if (this.searchPageType === null || this.searchPageType === "undefined") {
      this.searchPageType = "";
    }

    if (
      this.parentCategories === null ||
      this.parentCategories === "undefined"
    ) {
      this.parentCategories = "";
    }

    if (this.childCategories === null || this.childCategories === "undefined") {
      this.childCategories = "";
    }

    this.bindEvents();
  }

  /**
   * @desc Set up events for each step
   */
  @autobind
  bindEvents(): void {
    this.searchInput.addEventListener("keyup", this.onKeyUp);
    EventBus.on(GLOBAL_CONSTANTS.EVENTS.SEARCH.POPULATE, this.populateSearch);
    EventBus.on(GLOBAL_CONSTANTS.EVENTS.MODAL_CLOSE, this.clearInput);
  }

  /**
   * @desc hide the search results
   */
  @autobind
  hideResults(): void {
    if (this.searchResultItems) {
      for (const result of this.searchResultItems) {
        result.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
      }
    }
    this.resultsShowing = false;
    this.resultsWrapper.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
    this.$noResults.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
  }

  /**
   * @desc clear search input
   */
  @autobind
  clearInput(): void {
    this.searchInput.value = "";
    this.hideResults();
  }

  @autobind
  populateSearch(query: string) {
    this.searchInput.value = query;
    this.query = query;
    this.handleKeyPress(query);
  }

  /**
   * @desc actions called onkeyup in search input
   */
  @autobind
  onKeyUp(): void {
    const inputVal = this.searchInput.value;
    if (inputVal !== this.query) {
      this.query = inputVal;
      this.handleKeyPress(inputVal);
    }
  }

  /**
   * @desc Run query / hide old results
   * @param {string} inputVal - value to query
   */
  @debounce(700)
  handleKeyPress(inputVal: string): void {
    this.hideResults();
    if (inputVal.length > 0) {
      this.$loader.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
      this.numberOfItemsToSkip = 0;
      this.search();
    }
  }

  /**
   * @desc Query items, animate content in.
   * @param {string} query - string to search for
   */
  @autobind
  search(): void {
    let url =
      this.dataUrl +
      `?query=${this.query}&numberOfItemsToSkip=${this.numberOfItemsToSkip}`;
    if (
      this.isHelpCenterSearch() &&
      this.searchPageType !== null &&
      this.searchPageType.length > 0
    ) {
      url += `&searchPageType=${this.searchPageType}`;
    }

    if (this.isTopicsSearch()) {
      url += `&searchPageType=${this.searchPageType}&parentCategories=${this.parentCategories}&childCategories=${this.childCategories}&excludePdf=${this.excludePdf}&maxNumberOfResults=${this.maxNumberOfResults}`;
    }

    fetch(url, {
      credentials: "include",
      method: "GET",
    })
      .then(this.checkStatus)
      .then((response: any) => {
        response.text().then(async (html: string) => {
          this.$loader.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
          if (this.numberOfItemsToSkip === 0) {
            this.resultsWrapper.innerHTML = html;
          } else {
            const loadMoreDiv = this.resultsWrapper.querySelector(
              constants.loadMore,
            );
            loadMoreDiv.remove();
            this.resultsWrapper.insertAdjacentHTML("beforeend", html);
          }

          this.searchResultItems = this.$el.querySelectorAll(
            constants.searchResult,
          ) as NodeListOf<HTMLElement>;
          const filter = "query:" + this.query;

          if (this.gtmSearchType === "blog") {
            try {
              const categoryElement = document.getElementById(
                "CategoryId",
              ) as HTMLSelectElement;
              let category =
                categoryElement == null || categoryElement.selectedIndex < 0
                  ? ""
                  : categoryElement.options[categoryElement.selectedIndex]
                      .value;
              if (category == null || category.length < 1) {
                category = "All Categories";
              }
              const mediaElement = document.getElementById(
                "MediaId",
              ) as HTMLSelectElement;
              let media =
                mediaElement == null || mediaElement.selectedIndex < 0
                  ? ""
                  : mediaElement.options[mediaElement.selectedIndex].value;
              if (media == null || media.length < 1) {
                media = "All Media";
              }
              gtm.search.blog.json(
                this.searchResultItems.length.toString(),
                this.numberOfItemsToSkip.toString(),
                category,
                media,
              );
            } catch (e) {
              gtm.search.json(
                "",
                this.searchResultItems.length.toString(),
                this.gtmSearchType,
                this.numberOfItemsToSkip.toString(),
                filter,
              );
            }
          } else {
            gtm.search.json(
              this.query,
              this.searchResultItems.length.toString(),
              this.gtmSearchType,
              this.numberOfItemsToSkip.toString(),
              filter,
            );
          }

          if (this.searchResultItems.length === 0) {
            // no results
            await this.inputAnimation.reverse(0);
            this.$noResults.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
            gtm.events.json(
              "search-" + this.gtmSearchType,
              this.searchInput.placeholder,
              "zero results found",
            );
          } else {
            await this.inputAnimation.forward(0);
            this.resultsWrapper.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
            this.$noResults.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE);

            const loadMoreButton = this.$el.querySelector(
              constants.loadMore,
            ) as HTMLElement;

            if (loadMoreButton) {
              const span = this.$el.querySelector(
                constants.loadMore + " span",
              ) as HTMLElement;
              if (span && this.$el.dataset["loadMoreButtonText"]) {
                span.innerText = this.$el.dataset["loadMoreButtonText"];
              }
              this.numberOfItemsToSkip += this.maxNumberOfResults;
              loadMoreButton.addEventListener("click", this.search);
            }
          }
        });
      })
      .catch((error: any) => {
        gtm.events.json(
          "search-" + this.gtmSearchType,
          this.searchInput.placeholder,
          "api search error - " + error,
        );
        console.log("request failed", error);
      });
  }

  @autobind
  checkStatus(response: any): any {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      gtm.events.json(
        "search-" + this.gtmSearchType,
        this.searchInput.placeholder,
        "api search error - status " + response.status,
      );
      throw new Error(`Status: ${response.statusText} Response: ${response}`);
    }
  }

  isHelpCenterSearch(): boolean {
    return this.searchType === constants.searchTypes.helpCenterSearch;
  }

  isSearchSearch(): boolean {
    return this.searchType === constants.searchTypes.standardSearch;
  }

  isTopicsSearch(): boolean {
    return this.searchType === constants.searchTypes.topicsSearch;
  }

  /**
   * @desc Remove event listeners
   */
  tearDown(): void {
    this.searchInput.removeEventListener("keyup", this.onKeyUp);
    EventBus.off(GLOBAL_CONSTANTS.EVENTS.MODAL_CLOSE, this.clearInput);
  }
}
