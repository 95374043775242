import { GLOBAL_CONSTANTS } from "../../globals/constants";
import { autobind } from "core-decorators";
import { EventBus } from "../../globals/emitter";
import Flickity from "flickity";

export class DraggableCards {
  $cards: Array<HTMLElement>;
  $el: HTMLElement;
  $handle: HTMLElement;
  $wrapper: HTMLElement;

  classes = {
    buttonAndTerms: "text-card__button-and-terms",
    card: ".js-draggable-card",
    draggableAssociateCardsWrapper: ".js-draggable-associate-cards-wrapper",
    draggableMixedCardsWrapper: "js-draggable-mixed-cards-wrapper",
    draggableRelatedArticlesWrapper: ".js-draggable-related-articles-wrapper",
    draggableSimpleTextBlockCardsWrapper:
      ".js-draggable-simple-text-block-cards-wrapper",
    draggableTextCardsWrapper: ".js-draggable-text-cards-wrapper",
    draggableImageTextCardsWrapper: ".js-draggable-image-text-cards-wrapper",
    row: "row",
    rowCenter: "row--center-xs",
    showPageDots: "js-show-page-dots",
  };

  types = {
    associateCards: "associate-cards",
    imageTextCards: "image-text-cards",
    relatedArticles: "related-articles",
    simpleTextBlocks: "simple-text-blocks",
    textCards: "text-cards",
  };

  cardsWithButtonsAndTerms = false;
  flickity: typeof Flickity;
  flickityMaxCellHeight = 0;
  initialResize = true;
  padSides = 50;
  type = "";
  usingFlickity = false;
  showPageDots = false;

  constructor(el: HTMLElement) {
    this.$el = el;

    let wrapper = el.querySelector(
      this.classes.draggableTextCardsWrapper,
    ) as HTMLElement;
    if (wrapper !== null && typeof wrapper !== "undefined") {
      this.type = this.types.textCards;
    } else {
      wrapper = el.querySelector(
        this.classes.draggableImageTextCardsWrapper,
      ) as HTMLElement;
      if (wrapper !== null && typeof wrapper !== "undefined") {
        this.type = this.types.imageTextCards;
      } else {
        wrapper = el.querySelector(
          this.classes.draggableRelatedArticlesWrapper,
        ) as HTMLElement;
        if (wrapper !== null && typeof wrapper !== "undefined") {
          this.type = this.types.relatedArticles;
        } else {
          wrapper = el.querySelector(
            this.classes.draggableAssociateCardsWrapper,
          ) as HTMLElement;
          if (wrapper !== null && typeof wrapper !== "undefined") {
            this.type = this.types.associateCards;
          } else {
            wrapper = el.querySelector(
              this.classes.draggableSimpleTextBlockCardsWrapper,
            ) as HTMLElement;
            if (wrapper !== null && typeof wrapper !== "undefined") {
              this.type = this.types.simpleTextBlocks;
            } else {
              wrapper = null;
            }
          }
        }
      }
    }

    this.$wrapper = wrapper;

    const cards = this.$el.querySelectorAll(this.classes.card);

    if (cards !== null && typeof cards !== "undefined" && cards.length > 0) {
      this.$cards = new Array<HTMLElement>();
      for (let index = 0; index < cards.length; index++) {
        const card = cards[index] as HTMLElement;
        if (
          (this.isTextCards() || this.isImageTextCards()) &&
          !this.cardsWithButtonsAndTerms
        ) {
          const elements = card.getElementsByClassName(
            this.classes.buttonAndTerms,
          );
          if (
            elements !== null &&
            elements !== undefined &&
            elements.length > 0
          ) {
            this.cardsWithButtonsAndTerms = true;
          }
        }
        this.$cards.push(card);
      }
    }

    this.showPageDots = this.$el.classList.contains(this.classes.showPageDots);

    setTimeout(() => {
      this.bindEvents();
      this.init();
    }, 0);
  }

  /**
   * @desc Initialize module when 4+ cards are present
   */
  bindEvents() {
    EventBus.on(GLOBAL_CONSTANTS.EVENTS.RESIZE, this.handleResize);
    let resizeId: any;
    window.addEventListener("resize", () => {
      clearTimeout(resizeId);
      resizeId = setTimeout(() => {
        this.handleResize();
      }, 500);
    });
  }

  /**
   * @desc Initialize module when 4+ cards are present
   */
  init(): void {
    this.handleResize();
  }

  /**
   * @desc checkIfCanDrag - simple method that checks the total width of the cards and compares to the available visual real estate.
   * @return boolean - if cards are larger than space available.
   */
  @autobind
  checkIfCanDrag(): boolean {
    let totalWidth = 0;

    if (this.$cards) {
      for (let index = 0; index < this.$cards.length; index++) {
        totalWidth +=
          this.$cards[index].getBoundingClientRect().width + this.padSides;
      }
    }

    return totalWidth > this.$el.getBoundingClientRect().width;
  }

  /**
   * @desc handleResize - When page is resized, adjust flickity and card heights
   */
  @autobind
  handleResize(): void {
    if (this.initialResize) {
      this.initializeHeight();
      this.initialResize = false;
    }
    if (this.checkIfCanDrag()) {
      this.usingFlickity = true;
      this.initDraggable();
      this.setCardHeights();

      this.flickity.setGallerySize(false);
      this.flickity.maxCellHeight = this.flickityMaxCellHeight;
      this.flickity.setGallerySize(true);
    } else {
      this.setCardHeights();
      this.stopDraggable();
      if (this.usingFlickity) {
        this.usingFlickity = false;
      }
    }
  }

  /**
   * @desc Initialize Flickity instance
   */
  initDraggable() {
    if (this.showPageDots) {
      this.flickity = new Flickity(this.$wrapper, {
        cellAlign: "left",
        freeScroll: true,
        freeScrollFriction: 0.03,
        prevNextButtons: true,
        pageDots: true,
        contain: true,
      });
    } else {
      this.flickity = new Flickity(this.$wrapper, {
        cellAlign: "left",
        freeScroll: true,
        freeScrollFriction: 0.03,
        prevNextButtons: true,
        pageDots: false,
        contain: true,
      });
    }
  }

  /**
   * @desc initializeHeight - initialize height using flickity
   */
  initializeHeight() {
    this.flickity = new Flickity(this.$wrapper, {
      freeScroll: false,
      prevNextButtons: false,
      pageDots: false,
    });
    this.setCardHeights();
    this.flickity.setGallerySize(false);
    this.flickity.maxCellHeight = this.flickityMaxCellHeight;
    this.flickity.setGallerySize(true);
    this.stopDraggable();
  }

  isAssociateCards(): boolean {
    return this.type === this.types.associateCards;
  }

  isImageTextCards(): boolean {
    return this.type === this.types.imageTextCards;
  }

  isRelatedArticles(): boolean {
    return this.type === this.types.relatedArticles;
  }

  isSimpleTextBlocks(): boolean {
    return this.type === this.types.simpleTextBlocks;
  }

  isTextCards(): boolean {
    return this.type === this.types.textCards;
  }

  /**
   * @desc setCardHeights - Find the tallest card and force all the other cards to conform.
   */
  setCardHeights(): void {
    let maxCardHeight = 0;

    if (this.isRelatedArticles()) {
      maxCardHeight = this.getRelatedArticleCardsHeight();
    } else {
      if (this.isTextCards()) {
        maxCardHeight = this.getTextCardsHeight();
      } else {
        if (this.isSimpleTextBlocks()) {
          maxCardHeight = this.getSimpleTextCardsHeight();
        } else {
          if (this.isImageTextCards()) {
            maxCardHeight = this.getImageTextCardsHeight();
          }
        }
      }
    }

    if (maxCardHeight < 1) {
      maxCardHeight = this.getGenericCardsHeight();
    }

    if (maxCardHeight > 0) {
      const cardHeight = `${maxCardHeight}px`;

      for (let setIndex = 0; setIndex < this.$cards.length; setIndex++) {
        const card = this.$cards[setIndex];
        card.style.height = cardHeight;
      }

      this.flickityMaxCellHeight = this.showPageDots
        ? maxCardHeight + 48
        : maxCardHeight + 27;
    }
  }

  getGenericCardsHeight(): number {
    let maxCardHeight = 0;

    if (this.$cards) {
      for (let cardIndex = 0; cardIndex < this.$cards.length; cardIndex++) {
        const item = this.$cards[cardIndex];
        item.style.height = "auto";
        maxCardHeight = Math.max(item.clientHeight, maxCardHeight);
      }
    }

    return maxCardHeight;
  }

  getRelatedArticleCardsHeight(): number {
    let maxImageHeight = 0;
    let maxHeadSummaryHeight = 0;
    let maxReadMoreHeight = 0;
    let maxPodcastHeight = 0;
    let maxDateMediaTagHeight = 0;
    let contentPaddingTop = 0;
    let contentPaddingBottom = 0;

    if (this.$cards) {
      for (let index = 0; index < this.$cards.length; index++) {
        const item = this.$cards[index];
        item.style.height = "auto";
        const cardContainerElement = this.getFirstElementByClassName(
          item,
          "card-container",
        );
        if (cardContainerElement && cardContainerElement.children.length > 0) {
          if (maxImageHeight < 1) {
            const cardImageElement = this.getFirstElementByClassName(
              cardContainerElement,
              "js-lazy-image",
            );
            if (cardImageElement) {
              maxImageHeight = Math.max(
                cardImageElement.clientHeight,
                maxImageHeight,
              );
            } else {
              maxImageHeight = 265;
            }
          }
          const cardContentElement = this.getFirstElementByClassName(
            cardContainerElement,
            "js-card-content",
          );
          if (cardContentElement) {
            if (contentPaddingTop < 1 && contentPaddingBottom < 1) {
              const style = window.getComputedStyle(cardContentElement);
              contentPaddingTop = parseInt(
                style.paddingTop.replace(/[^\d.-]/g, ""),
                10,
              );
              contentPaddingBottom = parseInt(
                style.paddingBottom.replace(/[^\d.-]/g, ""),
                10,
              );
            }
            const cardHeadSummaryContainer = this.getFirstElementByClassName(
              cardContentElement,
              "js-card-head-summary-container",
            );
            if (cardHeadSummaryContainer) {
              maxHeadSummaryHeight = Math.max(
                cardHeadSummaryContainer.clientHeight,
                maxHeadSummaryHeight,
              );
            }
            if (maxReadMoreHeight < 1) {
              const cardReadMoreContainer = this.getFirstElementByClassName(
                cardContentElement,
                "js-read-more-container",
              );
              if (cardReadMoreContainer) {
                maxReadMoreHeight = Math.max(
                  cardReadMoreContainer.clientHeight,
                  maxReadMoreHeight,
                );
              }
            }
            if (maxPodcastHeight < 1) {
              const cardPodcastContainer = this.getFirstElementByClassName(
                cardContentElement,
                "js-podcast",
              );
              if (cardPodcastContainer) {
                maxPodcastHeight = Math.max(
                  cardPodcastContainer.clientHeight,
                  maxPodcastHeight,
                );
              }
            }
            const cardDateMediaTagContainer = this.getFirstElementByClassName(
              cardContentElement,
              "js-date-media-tag-container",
            );
            if (cardDateMediaTagContainer) {
              maxDateMediaTagHeight = Math.max(
                cardDateMediaTagContainer.clientHeight,
                maxDateMediaTagHeight,
              );
            }
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      }
    } else {
      return 0;
    }

    const maxCardHeight =
      maxImageHeight +
      maxHeadSummaryHeight +
      maxReadMoreHeight +
      maxPodcastHeight +
      maxDateMediaTagHeight +
      contentPaddingTop +
      contentPaddingBottom +
      2;
    const cardHeight = `${maxCardHeight}px`;
    const contentHeight = `${maxHeadSummaryHeight + maxReadMoreHeight + maxPodcastHeight + maxDateMediaTagHeight + contentPaddingTop + contentPaddingBottom}px`;
    const readMoreTop = `${maxImageHeight + maxHeadSummaryHeight + contentPaddingTop}px`;
    const podcastTop = `${maxImageHeight + maxHeadSummaryHeight + maxReadMoreHeight + contentPaddingTop}px`;
    const dateMediaTagTop = `${maxImageHeight + maxHeadSummaryHeight + maxReadMoreHeight + maxPodcastHeight + contentPaddingTop}px`;

    for (let index = 0; index < this.$cards.length; index++) {
      const card = this.$cards[index];
      card.style.height = cardHeight;
      const cardContent = this.getFirstElementByClassName(
        card,
        "js-card-content",
      );
      if (cardContent) {
        cardContent.style.height = contentHeight;
      }
      const cardReadMore = this.getFirstElementByClassName(
        cardContent,
        "js-read-more-container",
      );
      if (cardReadMore) {
        cardReadMore.style.position = "absolute";
        cardReadMore.style.top = readMoreTop;
      }
      const cardPodcast = this.getFirstElementByClassName(
        cardContent,
        "js-podcast",
      );
      if (cardPodcast) {
        cardPodcast.style.position = "absolute";
        cardPodcast.style.top = podcastTop;
      }
      const cardDateMediaTag = this.getFirstElementByClassName(
        card,
        "js-date-media-tag-container",
      );
      if (cardDateMediaTag) {
        cardDateMediaTag.style.position = "absolute";
        cardDateMediaTag.style.top = dateMediaTagTop;
      }
    }

    return maxCardHeight;
  }

  getTextCardsHeight(): number {
    let maxCardHeight = 0;

    if (this.$cards) {
      for (let cardIndex = 0; cardIndex < this.$cards.length; cardIndex++) {
        const item = this.$cards[cardIndex];
        item.style.height = "auto";
        maxCardHeight = Math.max(item.clientHeight, maxCardHeight);
      }

      if (this.cardsWithButtonsAndTerms) {
        maxCardHeight += 40;
      }
    }

    return maxCardHeight;
  }

  getImageTextCardsHeight(): number {
    let maxCardHeight = 0;
    if (this.$cards) {
      for (let cardIndex = 0; cardIndex < this.$cards.length; cardIndex++) {
        const item = this.$cards[cardIndex];
        item.style.height = "auto";
        maxCardHeight = Math.max(item.clientHeight, maxCardHeight);
      }
    }

    return maxCardHeight;
  }

  getSimpleTextCardsHeight(): number {
    return this.getGenericCardsHeight() + 27;
  }

  getFirstElementByClassName(
    parent: HTMLElement,
    className: string,
  ): HTMLElement {
    if (!parent || !className) {
      return null;
    }

    const childElements = parent.getElementsByClassName(className);
    if (childElements && childElements.length > 0) {
      return childElements[0] as HTMLElement;
    }

    return null;
  }

  /**
   * @desc stopDraggable - Kill flickity and drag ability
   */
  stopDraggable(): void {
    this.$wrapper.classList.add(this.classes.row);
    this.$wrapper.classList.add(this.classes.rowCenter);
    if (this.flickity !== undefined) {
      this.flickity.destroy();
    }
  }

  /**
   * @desc Remove listeners and stop listening for resize in the Event bus
   */
  tearDown(): void {
    EventBus.off(GLOBAL_CONSTANTS.EVENTS.RESIZE, this.handleResize);
  }
}
