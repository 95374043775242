/**
 * Toolkit JavaScript
 */

import { App } from "./app";

document.addEventListener("DOMContentLoaded", () => {
  (window as any).app = new App();
});

window.addEventListener("pageshow", (evt: PageTransitionEvent) => {
  if (evt.persisted) {
    location.reload();
  }
});
