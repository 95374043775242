import { autobind, debounce } from "core-decorators";
import { dollarize } from "../globals/functions";
import { GLOBAL_CONSTANTS } from "../globals/constants";
import { EventBus } from "../globals/emitter";

const SELECTORS = {
  CURRENCY: "currency",
  RESULT: ".js-result-elem",
  RESULT_AMOUNT: ".js-result-number",
};

/**
 * @desc Calculator class handles the listeners, events and passes results to calculators
 */
export class Calculator {
  $element: HTMLElement;
  optionArray: Array<HTMLInputElement>;
  inputArray: Array<HTMLInputElement>;
  calculateEvent: string;
  $resultWrapperArray: Array<HTMLElement>;
  $resultText: HTMLElement;

  /**
   * @desc Calculator class handles events and results for calculators
   * @param elem - container element for Calculator
   * @param optionArr - Array of radio inputs for calculator options
   * @param inputArr - Array of text inputs for numbers
   * @param calculateEvent - Emitter event from containing class to run calculation
   */
  constructor(
    elem: HTMLElement,
    optionArr: Array<HTMLInputElement>,
    inputArr: Array<HTMLInputElement>,
    calculateEvent: string,
  ) {
    this.$element = elem;
    this.optionArray = optionArr;
    this.inputArray = inputArr;
    this.calculateEvent = calculateEvent;
    this.$resultWrapperArray = Array.from(
      this.$element.querySelectorAll(SELECTORS.RESULT),
    ) as Array<HTMLElement>;
    this.$resultText = this.$element.querySelector(
      SELECTORS.RESULT_AMOUNT,
    ) as HTMLElement;

    this.init();
  }

  init(): void {
    this.bindEvents();

    EventBus.on("calculator-result", this.displayResult.bind(this));
  }

  /**
   * @desc Bind events to calculator elems
   */
  @autobind
  bindEvents(): void {
    this.inputArray.forEach((input: HTMLInputElement) => {
      input.addEventListener("keyup", this.fireCalculateEvent);
      input.addEventListener("focus", this.handleInputEvents);
      input.addEventListener("blur", this.handleInputEvents);
    });

    this.optionArray.forEach((option: HTMLInputElement) => {
      option.addEventListener("change", this.fireCalculateEvent);
    });
  }

  /**
   * @desc calculator input focus and blur handler
   * Determines the value in the input before formatting to number or $str,ing
   */
  @autobind
  handleInputEvents(event: Event) {
    const input = event.target as HTMLInputElement;
    const inputType = input.dataset["type"];

    if (event.type === "focus") {
      if (inputType === SELECTORS.CURRENCY && input.value === "$0") {
        input.value = "";
      }

      this.undollarizeInput(input, inputType);
    } else {
      if (inputType === SELECTORS.CURRENCY && input.value === "") {
        input.value = "0";
      }

      this.dollarizeInput(input, inputType);
    }
  }

  /**
   * @desc convert input innerText to dollar string with commas
   * @param {HTMLInputElement} input - form text input
   */
  @autobind
  dollarizeInput(input: HTMLInputElement, inputType: string): void {
    if (inputType !== SELECTORS.CURRENCY) {
      return;
    }

    const dollarValue = dollarize(input.value);

    input.value = dollarValue;
  }

  /**
   * @desc convert input innerText to number with no $ or , characters
   * @param {HTMLInputElement} input - form text input
   */
  @autobind
  undollarizeInput(input: HTMLInputElement, inputType: string): void {
    if (inputType !== SELECTORS.CURRENCY) {
      return;
    }

    const undollarValue = input.value.replace(/[$,]/g, "");

    input.value = undollarValue;
  }

  @autobind
  fireCalculateEvent(): void {
    EventBus.fire(this.calculateEvent);
  }

  /**
   * @desc display the calculated number result in the calculator
   * @param {number} result - result from calculation
   */
  @debounce(500)
  displayResult(result: number | string): void {
    this.$resultText.innerText = dollarize(result);
    this.$resultWrapperArray.forEach((element: HTMLElement) => {
      element.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
    });
  }
}
