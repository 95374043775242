import { GLOBAL_CONSTANTS } from "./constants";
import { EventBus } from "./emitter";
import { autobind } from "core-decorators";
import { getCookie } from "./functions";

declare var gtm: any;

const classes = {
  AllModalsClose: ".js-all-modals-close",
  BodyElement: ".js-body-element",
  LeavingDomainCancelButton: ".js-leaving-domain-cancel-button",
  LeavingDomainCloseButton: ".js-leaving-domain-close-button",
  LeavingDomainOkButton: ".js-leaving-domain-ok-button",
  LoginBusinessLink: ".js-login-business-link",
  LoginCommercialLink: ".js-login-commercial-link",
  LoginPersonalLink: ".js-login-personal-link",
  ModalTrigger: ".js-modal-trigger",
  SearchInput: ".js-search-input",
  SearchInputInput: ".js-input",
  SearchResults: ".js-search-results",
};

const ids = {
  AllModals: "all-modals",
  AllModalsAlert: "all-modal-alert",
  ModalLeavingDomain: "modal-leaving-domain",
  ModalLogin: "modal-login",
  ModalLoginBusiness: "modal-login-business",
  ModalLoginCommercial: "modal-login-commercial",
  ModalLoginMenu: "modal-login-menu",
  ModalLoginPersonal: "modal-login-personal",
  ModalLocations: "modal-locations",
  ModalSearch: "modal-search",
  PageContent: "page-content",
};

export class Modal {
  $BodyElement: HTMLElement;
  $ModalTriggers: Array<HTMLElement>;
  $NavigationAlertContainer: HTMLDivElement;
  $PageContent: HTMLDivElement;

  $AllModals: HTMLDivElement;
  $AllModalsAlert: HTMLDivElement;
  $AllModalsClose: HTMLElement;

  $ModalLocations: HTMLDivElement;
  $ModalLogin: HTMLDivElement;
  $ModalLoginBusiness: HTMLDivElement;
  $ModalLoginBusinessLink: HTMLAnchorElement;
  $ModalLoginCommercial: HTMLDivElement;
  $ModalLoginCommercialLink: HTMLAnchorElement;
  $ModalLoginMenu: HTMLDivElement;
  $ModalLoginPersonal: HTMLDivElement;
  $ModalLoginPersonalLink: HTMLAnchorElement;

  $ModalSearch: HTMLDivElement;
  $SearchInput: HTMLDivElement;
  $SearchInputInput: HTMLInputElement;
  $SearchResults: HTMLDivElement;

  $ModalLeavingDomain: HTMLDivElement;
  $LeavingDomainCancelButton: HTMLButtonElement;
  $LeavingDomainCloseButton: HTMLButtonElement;
  $LeavingDomainOkButton: HTMLButtonElement;

  $LeavingDomainHref: string;
  $LeavingDomainLinkText: string;
  $LeavingDomainLinkId: string;

  /**
   * @desc Constructor finds all required elements
   * @param {HTMLElement} el - Element to bind module to.
   */
  constructor(element: HTMLElement) {
    if (!element) {
      return;
    }

    this.$BodyElement = document.querySelector(
      classes.BodyElement,
    ) as HTMLBodyElement;
    this.$ModalTriggers = Array.from(
      document.querySelectorAll(classes.ModalTrigger),
    ) as Array<HTMLElement>;
    this.$PageContent = document.getElementById(
      ids.PageContent,
    ) as HTMLDivElement;
    this.$AllModals = document.getElementById(ids.AllModals) as HTMLDivElement;
    this.$AllModalsAlert = document.getElementById(
      ids.AllModalsAlert,
    ) as HTMLDivElement;
    this.$AllModalsClose = document.querySelector(
      classes.AllModalsClose,
    ) as HTMLElement;
    this.$ModalLocations = document.getElementById(
      ids.ModalLocations,
    ) as HTMLDivElement;
    this.$ModalLogin = document.getElementById(
      ids.ModalLogin,
    ) as HTMLDivElement;
    this.$ModalLoginBusiness = document.getElementById(
      ids.ModalLoginBusiness,
    ) as HTMLDivElement;
    this.$ModalLoginBusinessLink = this.$AllModals.querySelector(
      classes.LoginBusinessLink,
    ) as HTMLAnchorElement;
    this.$ModalLoginCommercial = document.getElementById(
      ids.ModalLoginCommercial,
    ) as HTMLDivElement;
    this.$ModalLoginCommercialLink = this.$AllModals.querySelector(
      classes.LoginCommercialLink,
    ) as HTMLAnchorElement;
    this.$ModalLoginMenu = document.getElementById(
      ids.ModalLoginMenu,
    ) as HTMLDivElement;
    this.$ModalLoginPersonal = document.getElementById(
      ids.ModalLoginPersonal,
    ) as HTMLDivElement;
    this.$ModalLoginPersonalLink = this.$AllModals.querySelector(
      classes.LoginPersonalLink,
    ) as HTMLAnchorElement;
    this.$ModalSearch = document.getElementById(
      ids.ModalSearch,
    ) as HTMLDivElement;
    this.$SearchResults =
      this.$ModalSearch === null
        ? null
        : (this.$ModalSearch.querySelector(
            classes.SearchResults,
          ) as HTMLDivElement);
    this.$SearchInput =
      this.$ModalSearch === null
        ? null
        : (this.$ModalSearch.querySelector(
            classes.SearchInput,
          ) as HTMLDivElement);
    this.$SearchInputInput =
      this.$ModalSearch === null
        ? null
        : (this.$ModalSearch.querySelector(
            classes.SearchInputInput,
          ) as HTMLInputElement);
    this.$ModalLeavingDomain = document.getElementById(
      ids.ModalLeavingDomain,
    ) as HTMLDivElement;
    this.$LeavingDomainCancelButton = document.querySelector(
      classes.LeavingDomainCancelButton,
    ) as HTMLButtonElement;
    this.$LeavingDomainCloseButton = document.querySelector(
      classes.LeavingDomainCloseButton,
    ) as HTMLButtonElement;
    this.$LeavingDomainOkButton = document.querySelector(
      classes.LeavingDomainOkButton,
    ) as HTMLButtonElement;

    this.getNavigationAlert();

    this.bindEvents();
  }

  /**
   * @desc Bind element events to appropriate method
   */
  bindEvents(): void {
    this.$AllModalsClose.addEventListener("click", this.closeModal);

    this.$ModalTriggers.forEach((item) => {
      item.addEventListener("click", this.handleTriggerClick);
    });

    this.$PageContent.addEventListener("click", this.linkClicked);

    this.$ModalLoginBusinessLink.addEventListener(
      "click",
      this.loginBusinessBanking,
    );
    this.$ModalLoginCommercialLink.addEventListener(
      "click",
      this.loginCommercialBanking,
    );
    this.$ModalLoginPersonalLink.addEventListener(
      "click",
      this.loginPersonalBanking,
    );

    this.$LeavingDomainCancelButton.addEventListener(
      "click",
      this.closeLeavingDomainModal,
    );
    this.$LeavingDomainCloseButton.addEventListener(
      "click",
      this.closeLeavingDomainModal,
    );
    this.$LeavingDomainOkButton.addEventListener("click", this.leavingDomainOk);
    this.$LeavingDomainOkButton.addEventListener(
      "mousedown",
      this.gtmNavigationDataLayer,
    );
  }

  /**
   * @desc When remove all applied modal classes / fire modal close event.
   */
  @autobind
  async closeModal() {
    this.$BodyElement.removeEventListener("keyup", this.onEscKeyUp);

    this.navigationAlertClose();

    this.$AllModals.classList.remove("show-modals");
    this.$AllModals.classList.add("hide-modals");
    this.$PageContent.classList.remove("hide-page-content");
    this.$PageContent.classList.add("show-page-content");

    if (this.$ModalLogin) {
      this.$ModalLogin.classList.remove("show-modals");
      this.$ModalLogin.classList.add("hide-modals");
    }

    if (this.$ModalSearch) {
      this.$ModalSearch.classList.remove("show-modals");
      this.$ModalSearch.classList.add("hide-modals");

      if (this.$SearchResults !== null) {
        this.$SearchResults.innerHTML = "";
      }

      if (this.$SearchInput !== null) {
        this.$SearchInput.style.removeProperty("top");
      }

      if (this.$SearchInputInput !== null) {
        this.$SearchInputInput.value = "";
      }
    }
  }

  getNavigationAlert(): void {
    const navigationElements =
      this.$PageContent.getElementsByClassName("navigation");
    if (
      navigationElements === null ||
      navigationElements === undefined ||
      navigationElements.length < 1
    ) {
      return;
    }
    const navigation = navigationElements[0] as HTMLDivElement;

    const navigationAlertContainer =
      navigation.getElementsByClassName("alert-container");
    if (
      navigationAlertContainer === null ||
      navigationAlertContainer === undefined ||
      navigationAlertContainer.length < 1
    ) {
      return;
    }

    this.$NavigationAlertContainer =
      navigationAlertContainer[0] as HTMLDivElement;
  }

  @autobind
  handleTriggerClick(mouseEvent: MouseEvent) {
    mouseEvent.preventDefault();
    const target = mouseEvent.currentTarget as HTMLElement;
    this.openModal(target);
  }

  navigationAlertClose(): void {
    const showAlert = !getCookie("umpq_home_alert");
    if (showAlert) {
      return;
    }

    if (
      this.$NavigationAlertContainer === null ||
      this.$NavigationAlertContainer === undefined
    ) {
      return;
    }

    if (!this.$NavigationAlertContainer.classList.contains("hidden")) {
      this.$NavigationAlertContainer.classList.add("hidden");
    }
  }

  /**
   * @desc Close modal when escape key is pressed
   * @param {KeyboardEvent} e - Key up event for esc key
   */
  @autobind
  onEscKeyUp(keyboardEvent: KeyboardEvent) {
    if (keyboardEvent.keyCode === GLOBAL_CONSTANTS.KEY_CODES.ESCAPE) {
      this.closeModal();
    }
  }

  /**
   * @desc open modal
   */
  @autobind
  openModal(modalTargetElement: HTMLElement) {
    this.$BodyElement.addEventListener("keyup", this.onEscKeyUp);
    const spacer = document.querySelector(".js-modal-spacer") as HTMLElement;
    const alert = document.querySelector(
      ".js-navigation-block-alert",
    ) as HTMLElement;
    if (alert != null && alert.clientHeight != null) {
      spacer.style.height = alert.clientHeight + "px";
    } else {
      spacer.style.height = "0px";
    }
    this.$AllModals.classList.remove("hide-modals");
    this.$AllModals.classList.add("show-modals");
    this.$PageContent.classList.remove("show-page-content");
    this.$PageContent.classList.add("hide-page-content");

    const modalTarget = modalTargetElement.dataset.modalTarget;

    switch (modalTarget) {
      case "search":
        this.$ModalSearch.classList.remove("hide-modals");
        this.$ModalSearch.classList.add("show-modals");
        if (this.$SearchInputInput !== null) {
          this.$SearchInputInput.focus();
        }
        break;
      case "locations":
        this.$ModalLocations.classList.remove("hide-modals");
        this.$ModalLocations.classList.add("show-modals");
        EventBus.fire(GLOBAL_CONSTANTS.EVENTS.MODAL_MAP_OPEN);
        break;
      case "login":
        this.toggleLogins(this.$ModalLoginMenu, true);
        break;
    }

    const showAlert = !getCookie("umpq_home_alert");

    if (!showAlert && this.$AllModalsAlert !== null) {
      this.$AllModalsAlert.classList.add("hidden");
    }
  }

  // **
  // *** Login functions
  // **
  @autobind
  toggleLogins(
    loginToShow: HTMLDivElement | undefined,
    openRootWindow: boolean = true,
  ) {
    const loginRootWindow = this.$ModalLogin;
    const loginOptions = [
      this.$ModalLoginMenu,
      this.$ModalLoginPersonal,
      this.$ModalLoginBusiness,
      this.$ModalLoginCommercial,
    ];

    const loginsToHide = loginOptions.filter((login) => loginToShow !== login);

    loginsToHide.forEach((login) => {
      login.classList.remove("show-modals");
      login.classList.add("hide-modals");
    });

    if (loginToShow) {
      loginToShow.classList.remove("hide-modals");
      loginToShow.classList.add("show-modals");
    }

    if (openRootWindow) {
      loginRootWindow.classList.remove("hide-modals");
      loginRootWindow.classList.add("show-modals");
    }
  }

  @autobind
  loginBusinessBanking() {
    this.toggleLogins(this.$ModalLoginBusiness);
  }

  @autobind
  loginCommercialBanking() {
    this.toggleLogins(this.$ModalLoginCommercial);
  }

  @autobind
  loginPersonalBanking() {
    this.toggleLogins(this.$ModalLoginPersonal);
  }

  // **
  // *** Leaving Domain functions
  // **
  @autobind
  async closeLeavingDomainModal() {
    this.$BodyElement.style.overflow = "auto";
    this.$BodyElement.removeEventListener("keyup", this.leavingDomainKeyUp);
    this.$PageContent.style.opacity = "1";
    if (this.$ModalLeavingDomain) {
      this.$ModalLeavingDomain.classList.remove("show-modals");
      this.$ModalLeavingDomain.classList.add("hide-modals");
    }
  }

  findElement(tagName: string, element: HTMLElement): HTMLElement {
    while (element) {
      if ((element.nodeName || element.tagName).toLowerCase() === tagName) {
        return element;
      }
      element = element.parentElement;
    }
    return null;
  }

  fragmentExists(fragmentName: string, href: string): boolean {
    return href.indexOf(fragmentName) >= 0;
  }

  getDomain(hostname: string): string {
    let domain = hostname;
    if (hostname !== null) {
      const parts = hostname.split(".").reverse();
      if (parts !== null && parts !== undefined && parts.length > 1) {
        domain = parts[1] + "." + parts[0];
      }
    }
    return domain;
  }

  @autobind
  gtmNavigationDataLayer() {
    gtm.navigation.createJson(
      this.$LeavingDomainLinkText,
      this.$LeavingDomainHref,
      "click",
      "TRUE",
      "",
      "",
      "",
      document.baseURI,
      this.$LeavingDomainLinkId,
      "leaving-domain",
    );
  }

  isSpecialProtocolLink(protocol: string): boolean {
    return protocol.startsWith("tel:") || protocol.startsWith("mailto:");
  }

  isSubDomainLink(linkHost: string, windowHost: string): boolean {
    return this.getDomain(linkHost) === this.getDomain(windowHost);
  }

  @autobind
  leavingDomainKeyUp(e: KeyboardEvent) {
    if (e.keyCode === GLOBAL_CONSTANTS.KEY_CODES.ESCAPE) {
      this.closeLeavingDomainModal();
    }
  }

  @autobind
  leavingDomainOk() {
    this.closeLeavingDomainModal();
    document.location.href = this.$LeavingDomainHref;
  }

  @autobind
  linkClicked(event: Event): void {
    const anchor = this.findElement(
      "a",
      event.target as HTMLElement,
    ) as HTMLAnchorElement;
    const domain = window.location.protocol + "//" + window.location.hostname;
    if (
      anchor &&
      !anchor.href.startsWith(domain) &&
      !anchor.hasAttribute("data-allow-leaving-domain") &&
      !this.isSpecialProtocolLink(anchor.protocol) &&
      !this.isSubDomainLink(anchor.hostname, domain) &&
      !this.fragmentExists("#allow-leaving-domain", anchor.href)
    ) {
      this.openLeavingDomainModal();
      event.preventDefault();
      this.$LeavingDomainHref = anchor.href;
      this.$LeavingDomainLinkText = gtm.utilities
        .sanitize(anchor.innerText)
        .trim();
      this.$LeavingDomainLinkId = anchor.id;
    }
  }

  openLeavingDomainModal() {
    this.$BodyElement.style.overflow = "hidden";
    this.$BodyElement.addEventListener("keyup", this.leavingDomainKeyUp);
    this.$PageContent.style.opacity = ".2";
    if (this.$ModalLeavingDomain) {
      this.$ModalLeavingDomain.classList.remove("hide-modals");
      this.$ModalLeavingDomain.classList.add("show-modals");
    }
  }

  /**
   * @desc unbind all events
   */
  tearDown() {
    this.$ModalTriggers.forEach((item) => {
      item.removeEventListener("click", this.handleTriggerClick);
    });

    this.$AllModalsClose.removeEventListener("click", this.closeModal);
  }
}
