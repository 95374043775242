import { autobind } from "core-decorators";
import { EventBus } from "../../globals/emitter";
import { GLOBAL_CONSTANTS } from "../../globals/constants";

const ids = {
  NavigationAlert: "navigation-alert-id",
  NavigationContainer: "navigation-container-id",
  NavigationWrapper: "navigation-wrapper-id",
  NavigationMobileDropMenuContainer: "navigation-mobile-drop-menu-container-id",
  NavigationMobileOpenCloseMenu: "navigation-mobile-open-close-menu-id",
};

/**
 * Makes the nav show / hide based on
 * scroll position / direction.
 */
export class ShyNav {
  $element: HTMLDivElement;
  $previousPageYOffset: number;
  $navAlert: HTMLDivElement;
  $navAlertHeight = 0;
  $navContainer: HTMLDivElement;
  $navWrapper: HTMLDivElement;
  $navWrapperHeight: number;
  $navMobileDropMenuContainer: HTMLDivElement;
  $navMobileDropMenuContainerHeight = 0;
  $navMobileOpenCloseMenu: HTMLButtonElement;

  /**
   * @desc Set up shy-nav with elements and bind events.
   * @param {HTMLElement} element - Top container element
   */
  constructor(element: HTMLDivElement) {
    this.$element = element;
    this.$previousPageYOffset = window.pageYOffset;

    this.$navContainer = document.getElementById(
      ids.NavigationContainer,
    ) as HTMLDivElement;
    this.$navAlert = document.getElementById(
      ids.NavigationAlert,
    ) as HTMLDivElement;
    if (
      this.$navAlert !== null &&
      this.$navAlert.getBoundingClientRect().height > 0
    ) {
      this.$navAlertHeight = this.$navAlert.getBoundingClientRect().height;
    }

    this.$navWrapper = document.getElementById(
      ids.NavigationWrapper,
    ) as HTMLDivElement;
    if (
      this.$navWrapper !== null &&
      this.$navWrapper.getBoundingClientRect().height > 0
    ) {
      this.$navWrapperHeight = this.$navWrapper.getBoundingClientRect().height;
    }

    if (this.$navWrapperHeight < 1) {
      this.$navWrapperHeight = 103;
    }

    this.$navMobileDropMenuContainer = document.getElementById(
      ids.NavigationMobileDropMenuContainer,
    ) as HTMLDivElement;
    if (
      this.$navMobileDropMenuContainer !== null &&
      this.$navMobileDropMenuContainer.getBoundingClientRect().height > 0
    ) {
      this.$navMobileDropMenuContainerHeight =
        this.$navMobileDropMenuContainer.getBoundingClientRect().height;
    }

    this.$navMobileOpenCloseMenu = document.getElementById(
      ids.NavigationMobileOpenCloseMenu,
    ) as HTMLButtonElement;

    setTimeout(() => {
      this.bindEvents();
    }, 100);
  }

  /**
   * @desc Listen for the global scroll event.
   */
  bindEvents() {
    EventBus.on(GLOBAL_CONSTANTS.EVENTS.SCROLL, this.handleScroll);
    EventBus.on(GLOBAL_CONSTANTS.EVENTS.RESIZE, this.handleScroll);
    EventBus.on(GLOBAL_CONSTANTS.EVENTS.ORIENTATION_CHANGE, () => {
      setTimeout(() => {
        this.handleScroll(true);
      }, 0);
    });
  }

  /**
   * @desc Scroll event that determines if the nav should show or hide.
   */
  @autobind
  async handleScroll(hasOrientationChanged = false) {
    if (hasOrientationChanged) {
      this.$element.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
      return;
    }

    if (this.$navAlert !== null) {
      const navAlertHeight = this.$navAlert.getBoundingClientRect().height;
      if (navAlertHeight !== this.$navAlertHeight) {
        this.$navAlertHeight = navAlertHeight;
      }
    }

    if (this.$navWrapper !== null) {
      const navWrapperHeight = this.$navWrapper.getBoundingClientRect().height;
      if (navWrapperHeight > 0 && navWrapperHeight !== this.$navWrapperHeight) {
        this.$navWrapperHeight = navWrapperHeight;
      }
    }

    if (this.$navMobileDropMenuContainer !== null) {
      const navMobileDropMenuHeight =
        this.$navMobileDropMenuContainer.getBoundingClientRect().height;
      if (navMobileDropMenuHeight !== this.$navMobileDropMenuContainerHeight) {
        this.$navMobileDropMenuContainerHeight = navMobileDropMenuHeight;
      }
    }

    const pageYOffset = window.pageYOffset;

    if (this.$previousPageYOffset === pageYOffset) {
      return;
    }

    const isScrollingUp = pageYOffset < this.$previousPageYOffset;

    if (isScrollingUp) {
      this.$element.classList.remove("hideNav");
    } else {
      this.$element.classList.add("hideNav");
    }

    this.$previousPageYOffset = pageYOffset;
  }

  tearDown() {
    EventBus.off(GLOBAL_CONSTANTS.EVENTS.RESIZE, this.handleScroll);
    EventBus.off(GLOBAL_CONSTANTS.EVENTS.SCROLL, this.handleScroll);
  }
}
