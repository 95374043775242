import { autobind } from "core-decorators";

const constants = {
  IDs: {
    StickyContentParent: "stickyContentParent",
    StickyContent: "stickyContent",
  },
};

export class StickyContent {
  containersFound: boolean;
  stickyContent: HTMLElement;
  stickyContentParent: HTMLElement;

  constructor() {
    this.getContainers();
    this.bindEvents();
  }

  @autobind
  bindEvents(): void {
    window.addEventListener("scroll", this.positionContent);
    window.addEventListener("resize", this.positionContent);
  }

  getContainers(): void {
    if (!this.stickyContentParent) {
      this.stickyContentParent = document.getElementById(
        constants.IDs.StickyContentParent,
      );
    }

    if (!this.stickyContent) {
      this.stickyContent = document.getElementById(constants.IDs.StickyContent);
    }

    if (!this.stickyContentParent || !this.stickyContent) {
      this.containersFound = false;
    } else {
      this.containersFound = true;
    }
  }

  @autobind
  positionContent(): void {
    if (!this.stickyContentParent || !this.stickyContent) {
      this.getContainers();
    }

    if (!this.containersFound) {
      return;
    }

    this.stickyContent.style.top = this.getTop() + "px";
  }

  getTop(): number {
    const highestPossibleTop = this.stickyContentParent.offsetTop;
    const windowPageYOffset = window.pageYOffset;
    if (
      windowPageYOffset === 0 ||
      this.stickyContent.clientHeight >= this.stickyContentParent.clientHeight
    ) {
      return highestPossibleTop;
    }
    const lowestPossibleTop =
      this.stickyContentParent.clientHeight +
      this.stickyContentParent.offsetTop -
      this.stickyContent.clientHeight;
    const parentClientRect = this.stickyContentParent.getBoundingClientRect();
    if (
      !(
        parentClientRect.top <= window.innerHeight &&
        parentClientRect.top + parentClientRect.height >= 0
      )
    ) {
      return lowestPossibleTop;
    }
    const parentLeftToDisplay =
      (parentClientRect.height + parentClientRect.top) /
      parentClientRect.height;
    if (parentLeftToDisplay >= 1.0) {
      return Math.round(highestPossibleTop * parentLeftToDisplay);
    }

    const windowInnerHeight = window.innerHeight;
    let top =
      windowPageYOffset +
      windowInnerHeight -
      this.stickyContent.clientHeight -
      20;
    if (top < highestPossibleTop) {
      top = Math.round(highestPossibleTop * parentLeftToDisplay);
    }

    return Math.min(top, lowestPossibleTop);
  }
}
