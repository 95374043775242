import { autobind } from "core-decorators";

const CLASSES = {
  MODAL_FORM: ".js-modal-form-block",
  MODAL_MODAL: ".js-modal-form",
  MODAL_CONTAINER: ".js-modal-form-container",
  FORM_CONTAINER: ".js-form-container",
  MODAL_OPEN: ".js-launch-modal",
  MODAL_CLOSE: ".js-close-modal",
};

export class ModalForm {
  $el: HTMLElement;
  $modalFormBlock: HTMLElement;
  $modal: HTMLElement;
  $modalContainer: HTMLElement;
  $formContainer: HTMLElement;
  $openModal: HTMLElement;
  $closeModal: HTMLElement;
  y = 0;
  $mouseDownX = 0;
  $mouseDownY = 0;
  $mouseUpX = 0;
  $mouseUpY = 0;

  constructor(el: HTMLElement) {
    this.$el = el;
    this.$modalFormBlock = this.$el.querySelector(
      CLASSES.MODAL_FORM,
    ) as HTMLElement;
    this.$modal = this.$el.querySelector(CLASSES.MODAL_MODAL) as HTMLElement;
    this.$modalContainer = this.$el.querySelector(
      CLASSES.MODAL_CONTAINER,
    ) as HTMLElement;
    this.$formContainer = this.$el.querySelector(
      CLASSES.FORM_CONTAINER,
    ) as HTMLElement;
    this.$openModal = this.$el.querySelector(CLASSES.MODAL_OPEN) as HTMLElement;
    this.$closeModal = this.$el.querySelector(
      CLASSES.MODAL_CLOSE,
    ) as HTMLElement;
    this.bindEvents();
  }

  @autobind
  bindEvents(): void {
    this.$openModal.addEventListener("click", this.launchModal);
    this.$formContainer.addEventListener("click", this.preventBuble);
    this.$closeModal.addEventListener("click", this.closeModal);
    this.$modal.addEventListener("click", this.closeModal);
    this.$modal.addEventListener("mouseup", this.captureMouseScreenPosition);
    this.$modal.addEventListener("mousedown", this.captureMouseScreenPosition);
  }

  @autobind
  launchModal(): void {
    this.$modal.classList.add("-open");
    this.$modal.style.opacity = "1";
    document
      .getElementsByClassName("js-body-element")[0]
      .appendChild(this.$modal);
    document.addEventListener("scroll", this.preventScroll);
    this.y = window.scrollY;
  }
  @autobind
  closeModal(e: MouseEvent): void {
    const mouseDownPositionInForm = this.isMousePositionInForm(
      this.$mouseDownX,
      this.$mouseDownY,
    );
    const mouseUpPositionInForm = this.isMousePositionInForm(
      this.$mouseUpX,
      this.$mouseUpY,
    );
    this.resetMousePositions(e);

    if (mouseDownPositionInForm && !mouseUpPositionInForm) {
      return;
    }

    this.$modal.style.opacity = "0";
    this.$modal.classList.remove("-open");
    const guid = this.$closeModal.dataset.id;
    document
      .getElementById("block-id-" + guid)
      .appendChild(document.getElementById("modal-id-" + guid));
    document.removeEventListener("scroll", this.preventScroll);
  }

  @autobind
  preventBuble(e: Event): void {
    e.stopPropagation();
  }

  @autobind
  preventScroll(e: Event): void {
    e.preventDefault();
    window.scrollTo(0, this.y);
  }

  @autobind
  captureMouseScreenPosition(e: MouseEvent): void {
    if (e.type === "mousedown") {
      this.$mouseDownX = e.clientX + document.body.scrollLeft;
      this.$mouseDownY = e.clientY + document.body.scrollTop;
    } else {
      this.$mouseUpX = e.clientX + document.body.scrollLeft;
      this.$mouseUpY = e.clientY + document.body.scrollTop;
    }
  }

  @autobind
  isMousePositionInForm(mouseX: number, mouseY: number): boolean {
    const formClientRect = this.$formContainer.getBoundingClientRect();

    if (
      mouseX >= formClientRect.left &&
      mouseX <= formClientRect.right &&
      mouseY >= formClientRect.top &&
      mouseY <= formClientRect.bottom
    ) {
      return true;
    }

    return false;
  }

  @autobind
  resetMousePositions(e: MouseEvent): void {
    this.$mouseDownX = e.clientX + document.body.scrollLeft;
    this.$mouseDownY = e.clientY + document.body.scrollTop;
    this.$mouseUpX = e.clientX + document.body.scrollLeft;
    this.$mouseUpY = e.clientY + document.body.scrollTop;
  }
}
