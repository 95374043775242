import { autobind } from "core-decorators";

const classes = {
  desktopEyebrowLink: ".js-desktop-eyebrow-link",
  desktopEyebrowText: ".js-desktop-eyebrow-text",
  desktopTitleText: ".js-desktop-title-text",
  hiddenEyebrowLink: ".js-eyebrow-link",
  hiddenEyebrowText: ".js-eyebrow-text",
  hiddenTitleText: ".js-title-text",
  mobileEyebrowLink: ".js-mobile-eyebrow-link",
  mobileEyebrowText: ".js-mobile-eyebrow-text",
  mobileTitleText: ".js-mobile-title-text",
};

const mobileWidth = 768;
const token = "value:=";

export class SideBySide {
  $element: HTMLElement;
  $eyebrowLinkValue: string;
  $eyebrowTextValue: string;
  $desktopEyebrowLink: HTMLAnchorElement;
  $desktopEyebrowText: HTMLElement;
  $desktopTitleText: HTMLElement;
  $mobileEyebrowLink: HTMLAnchorElement;
  $mobileEyebrowText: HTMLElement;
  $mobileTitleText: HTMLElement;
  $titleTextValue: string;

  constructor(element: HTMLElement) {
    this.$element = element;

    const hiddenEyebrowLink = element.querySelector(
      classes.hiddenEyebrowLink,
    ) as HTMLInputElement;
    this.$eyebrowLinkValue =
      hiddenEyebrowLink === null || hiddenEyebrowLink === undefined
        ? ""
        : hiddenEyebrowLink.value.trim().replace(token, "");
    const hiddenEyebrowText = element.querySelector(
      classes.hiddenEyebrowText,
    ) as HTMLInputElement;
    this.$eyebrowTextValue =
      hiddenEyebrowText === null || hiddenEyebrowText === undefined
        ? ""
        : hiddenEyebrowText.value.trim().replace(token, "");
    const hiddenTitleText = element.querySelector(
      classes.hiddenTitleText,
    ) as HTMLInputElement;
    this.$titleTextValue =
      hiddenTitleText === null || hiddenTitleText === undefined
        ? ""
        : hiddenTitleText.value.trim().replace(token, "");

    this.$mobileEyebrowLink = element.querySelector(
      classes.mobileEyebrowLink,
    ) as HTMLAnchorElement;
    this.$mobileEyebrowText = element.querySelector(
      classes.mobileEyebrowText,
    ) as HTMLElement;
    this.$mobileTitleText = element.querySelector(
      classes.mobileTitleText,
    ) as HTMLElement;

    this.$desktopEyebrowLink = element.querySelector(
      classes.desktopEyebrowLink,
    ) as HTMLAnchorElement;
    this.$desktopEyebrowText = element.querySelector(
      classes.desktopEyebrowText,
    ) as HTMLElement;
    this.$desktopTitleText = element.querySelector(
      classes.desktopTitleText,
    ) as HTMLElement;

    this.bindEvents();

    this.handleResize();
  }

  @autobind
  bindEvents(): void {
    window.addEventListener("resize", this.handleResize);
  }

  @autobind
  handleResize(): void {
    const width = this.getWidth();

    if (width <= mobileWidth) {
      if (this.$mobileEyebrowLink !== null) {
        this.$mobileEyebrowLink.href = this.$eyebrowLinkValue;
        this.$mobileEyebrowLink.text = this.$eyebrowTextValue;
      } else {
        if (this.$mobileEyebrowText !== null) {
          this.$mobileEyebrowText.innerText = this.$eyebrowTextValue;
        }
      }
      if (this.$mobileTitleText !== null) {
        if (this.$titleTextValue !== null && this.$titleTextValue.length > 0) {
          this.$mobileTitleText.innerText = this.$titleTextValue;
        } else {
          this.$mobileTitleText.remove();
          this.$mobileTitleText = null;
        }
      }

      if (this.$desktopEyebrowLink !== null) {
        this.$desktopEyebrowLink.href = "";
        this.$desktopEyebrowLink.text = "";
      } else {
        if (this.$desktopEyebrowText !== null) {
          this.$desktopEyebrowText.innerText = "";
        }
      }
      if (this.$desktopTitleText !== null) {
        this.$desktopTitleText.innerText = "desktop title";
      }
    } else {
      if (this.$desktopEyebrowLink !== null) {
        this.$desktopEyebrowLink.href = this.$eyebrowLinkValue;
        this.$desktopEyebrowLink.text = this.$eyebrowTextValue;
      } else {
        if (this.$desktopEyebrowText !== null) {
          this.$desktopEyebrowText.innerText = this.$eyebrowTextValue;
        }
      }
      if (this.$desktopTitleText !== null) {
        if (this.$titleTextValue !== null && this.$titleTextValue.length > 0) {
          this.$desktopTitleText.innerText = this.$titleTextValue;
        } else {
          this.$desktopTitleText.remove();
          this.$desktopTitleText = null;
        }
      }

      if (this.$mobileEyebrowLink !== null) {
        this.$mobileEyebrowLink.href = "";
        this.$mobileEyebrowLink.text = "";
      } else {
        if (this.$mobileEyebrowText !== null) {
          this.$mobileEyebrowText.innerText = "";
        }
      }
      if (this.$mobileTitleText !== null) {
        this.$mobileTitleText.innerHTML = "mobile title";
      }
    }
  }

  @autobind
  getWidth() {
    return Math.max(
      window.screen.width < window.innerWidth
        ? window.screen.width
        : window.innerWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth,
    );
  }
}
