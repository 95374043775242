import Flickity from "flickity";
import { EventBus } from "../globals/emitter";
import { GLOBAL_CONSTANTS } from "../globals/constants";
import { autobind } from "core-decorators";

const CLASSES = {
  WRAPPER: ".js-comparison-table__body",
  ITEMS: ".js-comparison-table__items",
  HEAD_CELL: ".js-comparison-table__head-cell",
  CELL: ".js-comparison-table__cell",
};

/**
 * @desc ComparisonTable - Class used to toggle flickity on the
 * compare table so it can be viewable on mobile.
 */
export class ComparisonTable {
  $el: HTMLElement;
  $items: Array<HTMLElement>;
  $headings: Array<HTMLElement>;
  $wrapper: HTMLElement;
  carousel: typeof Flickity;

  /**
   * constructor - Set element to class, bind events.
   * @param  {HTMLElement} el - Table parent element.
   */
  constructor(el: HTMLElement) {
    this.$el = el;
    this.$wrapper = this.$el.querySelector(CLASSES.WRAPPER) as HTMLElement;
    this.$items = Array.from(
      this.$wrapper.querySelectorAll(CLASSES.ITEMS),
    ) as Array<HTMLElement>;
    this.$headings = Array.from(
      this.$el.querySelectorAll(CLASSES.HEAD_CELL),
    ) as Array<HTMLElement>;

    this.bindEvents();
    this.handleResize();
  }

  /**
   * bindEvents - Listen for resize event and trigger cb if needed.
   */
  bindEvents() {
    EventBus.on(GLOBAL_CONSTANTS.EVENTS.RESIZE, this.handleResize);
  }

  /**
   * @desc handleResize - If the window is mobile sized, then kickoff turn on
   * flickity if
   * it doesn't already exist.
   */
  @autobind
  handleResize() {
    const isMobile = window.innerWidth <= GLOBAL_CONSTANTS.BREAKPOINTS.SMALL;
    if (isMobile && !this.carousel) {
      setTimeout(() => {
        this.carousel = new Flickity(this.$wrapper, {
          cellAlign: "left",
          freeScroll: false,
          prevNextButtons: false,
          pageDots: true,
        });
      }, 0);
    } else if (!isMobile && this.carousel) {
      this.carousel.destroy();
      this.carousel = null;
    }

    if (isMobile) {
      this.modifyCellHeights();
    }
  }

  /**
   * @desc modifyCellHeights -
   * 1 loop through all headings, find the  height.
   * 2 loop through all the paired cells, find the max height.
   * 3 make the rows match the max height of the max heights
   * 4 make the cells match the max height of the max heights
   */
  @autobind
  modifyCellHeights() {
    console.log("modifyCellHeights - headings: ", this.$headings);
    this.$headings.forEach((head, kid) => {
      console.log("head: ", head, " - key: ", kid);

      head.style.height = "auto";
      const headHeight = head.offsetHeight;

      const realMax = this.$items.reduce(
        (maxHeight: number, tableRow: HTMLElement) => {
          const els = Array.from(
            tableRow.querySelectorAll(CLASSES.CELL),
          ) as Array<HTMLElement>;
          console.log("modifyCellHeights - els: ", els);

          els[kid].style.height = "auto";
          const tdHeight = els[kid].offsetHeight;
          return Math.max(maxHeight, tdHeight);
        },
        headHeight,
      );

      head.style.height = `${realMax}px`;
      this.$items.forEach((tableRow) => {
        const els = Array.from(
          tableRow.querySelectorAll(CLASSES.CELL),
        ) as Array<HTMLElement>;
        els[kid].style.height = `${realMax}px`;
      });
    });
  }

  /**
   * @desc tearDown - remove event listeners
   */
  tearDown() {
    EventBus.off(GLOBAL_CONSTANTS.EVENTS.RESIZE, this.handleResize);

    if (this.carousel) {
      this.carousel.destroy();
    }
  }
}
