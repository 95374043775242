import { autobind } from "core-decorators";
import { numberize, dollarize } from "../globals/functions";
import { EventBus } from "../globals/emitter";
import { Calculator } from "../globals/calculator";

const SELECTORS = {
  OPTION: ".js-calc-option",
  INPUT: ".js-calc-input",
  TOTAL_AMOUNT: "#total-amount",
  DOWN_PAYMENT: "#down-payment",
  RATE: ".js-calc-rate",
  DATE: ".js-calc-date",
  PROPERTY_TAXES: ".js-calc-property-taxes",
  HOME_INSURANCE: ".js-calc-home-insurance",
  INTEREST_RATE: ".js-calc-irate",
};

const EVENTS = {
  CALCULATE: "mortgage-calculate",
  RESULT: "calculator-result",
};

/**
 * @desc MortgageCalculator class provides the inputs and the equation for the mortgage calculator
 */
export class MortgageCalculator {
  $element: HTMLElement;
  optionArray: Array<HTMLInputElement>;
  inputArray: Array<HTMLInputElement>;
  $totalAmount: HTMLInputElement;
  $downPayment: HTMLInputElement;
  $calcRate: HTMLElement;
  $calcInterestRate: HTMLElement;
  $calcDate: HTMLElement;
  $calcPropertyTaxes: HTMLElement;
  $calcHomeInsurance: HTMLElement;
  interestRate: string;
  apr: string;
  years: string;
  currentAmount: string;
  currentDown: string;
  calculator: Calculator;

  constructor(el: HTMLElement) {
    this.$element = el;
    this.optionArray = Array.from(
      this.$element.querySelectorAll(SELECTORS.OPTION),
    ) as Array<HTMLInputElement>;
    this.inputArray = Array.from(
      this.$element.querySelectorAll(SELECTORS.INPUT),
    ) as Array<HTMLInputElement>;
    this.$totalAmount = this.$element.querySelector(
      SELECTORS.TOTAL_AMOUNT,
    ) as HTMLInputElement;
    this.$downPayment = this.$element.querySelector(
      SELECTORS.DOWN_PAYMENT,
    ) as HTMLInputElement;
    this.$calcRate = this.$element.querySelector(SELECTORS.RATE) as HTMLElement;
    this.$calcInterestRate = this.$element.querySelector(
      SELECTORS.INTEREST_RATE,
    ) as HTMLElement;
    this.$calcDate = this.$element.querySelector(SELECTORS.DATE) as HTMLElement;
    this.$calcPropertyTaxes = this.$element.querySelector(
      SELECTORS.PROPERTY_TAXES,
    ) as HTMLElement;
    this.$calcHomeInsurance = this.$element.querySelector(
      SELECTORS.HOME_INSURANCE,
    ) as HTMLElement;

    this.calculator = new Calculator(
      this.$element,
      this.optionArray,
      this.inputArray,
      EVENTS.CALCULATE,
    );

    this.init();
  }

  init(): void {
    this.setRates();

    EventBus.on(EVENTS.CALCULATE, this.setRates);
  }

  /**
   * @desc set current values for rate, closing costs and divider
   * Then run the calculation
   */
  @autobind
  setRates(): void {
    this.optionArray.forEach((input: HTMLInputElement) => {
      if (input.checked) {
        this.interestRate = input.dataset.interestRate;
        this.apr = input.dataset.apr;
        this.years = input.value;

        this.$calcRate.innerText = `${this.apr}%`;

        if (this.$calcDate) {
          this.$calcDate.innerText = `${input.dataset.effectiveDate}`;
        }
        if (this.$calcInterestRate) {
          this.$calcInterestRate.innerText = `${this.interestRate}%`;
        }
        if (this.$calcPropertyTaxes) {
          this.$calcPropertyTaxes.innerText = dollarize(this.propertyTaxes());
        }
        if (this.$calcHomeInsurance) {
          this.$calcHomeInsurance.innerText = dollarize(this.homeInsurance());
        }
      }
    });

    this.calculateMortgage();
  }

  @autobind
  loanAmount(): number {
    return this.totalAmount() - this.downPayment();
  }

  @autobind
  totalAmount(): number {
    let totalAmount = numberize(this.$totalAmount.value);
    if (isNaN(totalAmount) || this.$totalAmount.value.length === 0) {
      totalAmount = numberize(this.$totalAmount.placeholder);
    }
    return totalAmount;
  }

  @autobind
  downPayment(): number {
    let downPayment = numberize(this.$downPayment.value);
    if (isNaN(downPayment) || this.$downPayment.value.length === 0) {
      downPayment = numberize(this.$downPayment.placeholder);
    }
    return downPayment;
  }

  @autobind()
  propertyTaxes(): number {
    if (this.totalAmount() < 0) {
      return 0;
    }
    return Math.ceil(0.0125 * this.totalAmount());
  }

  @autobind()
  homeInsurance(): number {
    if (this.totalAmount() < 0) {
      return 0;
    }
    return Math.ceil(0.0025 * this.totalAmount());
  }

  @autobind
  round(n: number): number {
    return Math.round(n * 100) / 100;
  }

  /**
   * @desc calculate the current mortgage numbers
   * Calculates the monthly payment on a mortgage based on form inputs
   */
  @autobind
  calculateMortgage(): void {
    const yearsNumber = parseInt(this.years, 10);

    const periodicInterestRate = parseFloat(this.interestRate) / (12 * 100);
    const numberOfPayments = yearsNumber * 12;
    const discountFactor =
      (Math.pow(1 + periodicInterestRate, numberOfPayments) - 1) /
      (periodicInterestRate *
        Math.pow(1 + periodicInterestRate, numberOfPayments));
    let monthlyPayment = Math.ceil(this.loanAmount() / discountFactor);

    if (monthlyPayment < 0 || this.downPayment() >= this.totalAmount()) {
      monthlyPayment = 0;
    }

    const result = this.$element.querySelector(
      ".js-result-number",
    ) as HTMLElement;
    result.innerText = dollarize(monthlyPayment);
  }

  /**
   * @desc Remove calculate event from eventbus
   */
  tearDown(): void {
    EventBus.off(EVENTS.CALCULATE, this.setRates);
  }
}
