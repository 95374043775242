import { autobind } from "core-decorators";
import { GLOBAL_CONSTANTS } from "../../globals/constants";
import { EventBus } from "../../globals/emitter";
import { Input } from "../../elements/input";

const constants = {
  CLASSES: {
    STEP_ID: ".js-stepper-item",
    NEXT_ID: ".js-stepper-next",
    BACK_ID: ".js-stepper-back",
  },
  START: "step-0",
};

/**
 * @desc Component used to quickly
 * show different states within
 * the application.
 */
export class Stepper {
  $el: HTMLElement;
  $current: HTMLElement;
  $backs: Array<HTMLElement>;
  $next: Array<HTMLElement>;
  $steps: Array<HTMLElement>;
  history: Array<String>;
  active: string;
  theme: string;
  input: Input;
  onProceed: Function;
  start = constants.START;
  canReset = true;

  /**
   * @desc Set element to class variables
   * @param {HTMLElement} el - Element to bind stepper to.
   */
  constructor(el: HTMLElement, onProceed?: Function, noReset?: boolean) {
    this.$el = el;
    this.active = constants.START;
    this.history = [];
    this.onProceed = onProceed;

    const { scope, backEvent }: DOMStringMap = el.dataset;
    const scopeStr = `[data-scope="${scope}"]`;
    this.canReset = !noReset;
    this.$next = Array.from(
      this.$el.querySelectorAll(`${constants.CLASSES.NEXT_ID}${scopeStr}`),
    ) as Array<HTMLElement>;
    this.$steps = Array.from(
      this.$el.querySelectorAll(`${constants.CLASSES.STEP_ID}${scopeStr}`),
    ) as Array<HTMLElement>;
    this.$backs = Array.from(
      this.$el.querySelectorAll(constants.CLASSES.BACK_ID),
    ) as Array<HTMLElement>;

    if (backEvent) {
      EventBus.on(backEvent, this.goBack);
    }
    this.bindEvents();
    this.activate();
  }

  /**
   * @desc Set up events for each step / listen
   * for reset event.
   */
  @autobind
  bindEvents(): void {
    this.$next.forEach((item: HTMLElement) => {
      item.addEventListener("click", this.jumpToBranch);
    });

    if (this.$backs) {
      this.$backs.forEach((item) => {
        item.addEventListener("click", this.goBack);
      });
    }

    if (this.canReset) {
      EventBus.on(GLOBAL_CONSTANTS.EVENTS.MODAL_CLOSE, this.reset);
    }
    EventBus.on(GLOBAL_CONSTANTS.EVENTS.STEPPER.SKIP, (index: string) => {
      this.active = index;
      this.activate();
    });
  }

  /**
   * @desc Display active decision tree along with back button if relevant.
   */
  @autobind
  activate(): void {
    const previous = this.$current;
    this.$current = this.getStepByValue(this.active);

    if (this.$current === previous) {
      return;
    }
    if (this.onProceed) {
      this.onProceed(previous, this.$current);
      return;
    }
    if (previous) {
      previous.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
    }
    if (!this.$current) {
      return;
    }

    if (this.$backs && this.active !== constants.START) {
      this.$backs.forEach((item) =>
        item.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE),
      );
    } else if (this.$backs) {
      this.$backs.forEach((item) =>
        item.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE),
      );
    }

    this.$current.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE);

    const { theme }: DOMStringMap = this.$current.dataset;

    if (theme) {
      this.$el.classList.remove(this.theme);
      this.theme = theme;
      this.$el.classList.add(this.theme);
    }
  }

  /**
   * @desc Find and return step by string value.
   * @param {string} value - value of item we want to find for
   * step names
   * @return {HTMLElement} Found element.
   */
  @autobind
  getStepByValue(value: string): HTMLElement {
    return this.$steps.filter((item: HTMLElement) => {
      return (item.dataset as any).key === value;
    })[0];
  }

  /**
   * @desc Display next branch in tree after selecting
   * and option
   * @param {Event} e - MouseClickEvent from user selected answer
   */
  @autobind
  jumpToBranch(e: Event): void {
    const tar: HTMLElement = e.currentTarget as HTMLElement;
    const {
      value,
      final,
      event,
      noProceed,
      localStructure,
      required,
    }: DOMStringMap = tar.dataset;

    if (required) {
      const parent = tar.parentElement;
      const input = parent.querySelector("input") as HTMLInputElement;
      if (!this.input) {
        this.input = new Input(input, true);
      }

      this.input.hasInitiated = true;
      this.input.validate();
      if (!this.input.passing) {
        return;
      }
    }

    if (event) {
      const evt = localStructure ? event + localStructure : event;
      EventBus.fire(evt);
    }
    if (final) {
      e.preventDefault();
      e.stopPropagation();
      this.onProceed(this.$current, null, final, e.currentTarget);
      return;
    }
    if (noProceed) {
      return;
    } else {
      e.preventDefault();
    }

    this.history.push(this.active);
    this.active = value;
    this.activate();
  }

  /**
   * @desc Return to previous answer
   * @param {Event} e - Mouse click event to trigger and return the
   * user back to previous tree.
   */
  @autobind
  goBack(e?: Event): void {
    if (e) {
      e.preventDefault();
    }
    const temp = this.history.pop() as string;
    this.active = temp ? temp : constants.START;
    EventBus.fire(GLOBAL_CONSTANTS.EVENTS.STEPPER.BACK);

    if (this.active === constants.START) {
      EventBus.fire(GLOBAL_CONSTANTS.EVENTS.STEPPER.RESTARTED);
    }
    this.activate();
  }

  /**
   * @desc Reset to the first step
   */
  @autobind
  reset(): void {
    this.active = constants.START;
    this.history = [];
    this.activate();
  }

  /**
   * @desc unbind all events
   */
  tearDown(): void {
    this.$next.forEach((item: HTMLElement) => {
      item.removeEventListener("click", this.jumpToBranch);
    });

    if (this.$backs) {
      this.$backs.forEach((item) => {
        item.removeEventListener("click", this.goBack);
      });
    }

    EventBus.off(GLOBAL_CONSTANTS.EVENTS.MODAL_CLOSE, this.reset);
  }
}
