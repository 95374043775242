import { autobind } from "core-decorators";
import { GLOBAL_CONSTANTS } from "../../globals/constants";
import { EventBus } from "../../globals/emitter";

/**
 * @desc Adds class to items that are js-flippable
 * to trigger css rotation.
 */
export class Flippable {
  $el: HTMLElement;
  $front: HTMLElement;
  $triggers: Array<HTMLElement>;

  /**
   * @desc Grab container / triggers for flip.
   * @param {HTMLElement} el - Element that we want to flip
   */
  constructor(el: HTMLElement) {
    this.$el = el;
    this.$triggers = Array.from(
      this.$el.querySelectorAll(".js-flippable-trigger"),
    ) as Array<HTMLElement>;
    this.$front = this.$el.querySelector(".js-flippable__front") as HTMLElement;

    this.onResize();
    this.bindEvents();
  }

  /**
   * @desc Make triggers actionable
   */
  bindEvents() {
    this.$triggers.forEach((i) => i.addEventListener("click", this.flip));
    EventBus.on(GLOBAL_CONSTANTS.EVENTS.RESIZE, this.onResize);
  }

  /**
   * @desc Resize event.
   */
  @autobind
  onResize() {
    setTimeout(() => {
      this.$el.style.height = "auto";
      this.$el.style.height = `${this.$front.getBoundingClientRect().height}px`;
    }, 0);
  }

  /**
   * @desc Toggle the flippable to be active;
   * @param {MouseEvent} e - Mouse click event
   */
  @autobind
  flip(e: MouseEvent) {
    e.preventDefault();
    this.$el.classList.toggle(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
  }

  /**
   * @desc Unbing clicks on triggers
   */
  @autobind
  tearDown() {
    this.$triggers.forEach((i) => i.removeEventListener("click", this.flip));
  }
}
