import { autobind, debounce } from "core-decorators";
import { EventBus } from "../globals/emitter";
import { isMobile, validateEmail } from "../globals/functions";
import { GLOBAL_CONSTANTS } from "../globals/constants";

const CLASSES = {
  ERRORS: ".js-form-errors",
};

/**
 * @desc Class to be utlized wherever we
 * want to trap values on an input.
 */
export class Input {
  $el: HTMLInputElement;
  value: string;
  keyupEvent: string;
  clearEvent: string;
  focusEvent: string;
  isEmail: string;
  scope: string;
  passing = true;
  required = false;
  hasInitiated = false;

  /**
   * @desc Set element to class.
   * Grab events to be used later.
   * @param {HTMLElement} el - Element to bind stepper to.
   */
  constructor(el: HTMLInputElement, required?: Boolean) {
    this.$el = el;

    const { keyupEvent, clearEvent, focusEvent, scope, isEmail } = this.$el
      .dataset as any;

    if (keyupEvent) {
      this.keyupEvent = scope ? keyupEvent + scope : keyupEvent;
    }
    if (clearEvent) {
      this.clearEvent = scope ? clearEvent + scope : clearEvent;
    }
    if (focusEvent) {
      this.focusEvent = focusEvent;
    }
    if (isEmail) {
      this.isEmail = isEmail;
      this.validate();
    }

    if (required) {
      this.required = true;
      this.passing = false;
    }
    this.resizeInput();
    this.bindEvents();
  }

  /**
   * @desc Set up events for each step / listen
   * for reset event.
   */
  @autobind
  bindEvents() {
    this.$el.addEventListener("focus", this.handleFocus);
    if (this.keyupEvent) {
      this.$el.addEventListener("keyup", this.onKeyUp);
    }
    if (this.isEmail) {
      this.$el.addEventListener("keyup", this.handleEmailKeyup);
    }
    if (this.clearEvent) {
      EventBus.on(this.clearEvent, this.clearInput);
    }
    if (this.focusEvent && !isMobile()) {
      EventBus.on(this.focusEvent, this.focusInput);
    }
    EventBus.on(GLOBAL_CONSTANTS.EVENTS.RESIZE, this.resizeInput);
  }

  @autobind
  resizeInput() {
    const placeholder = this.$el.getAttribute("placeholder");

    if (placeholder) {
      this.$el.setAttribute("size", placeholder.length.toString());
    }
  }

  @autobind
  focusInput() {
    setTimeout(() => {
      this.$el.focus();
    }, 100);
  }

  validate() {
    if (this.required) {
      this.validateEmpty();
    }

    if (this.isEmail) {
      this.validateEmail();
    }

    // add other possible forms of validation here.
  }

  @autobind
  handleFocus() {
    this.hasInitiated = true;
    this.$el.removeEventListener("onfocus", this.handleFocus);
  }

  /**
   * @param {Event} e - Keyboard Event
   * @desc Fire an event when user
   * types inside the input.
   */
  @autobind
  onKeyUp(e: Event) {
    const { value } = e.currentTarget as HTMLInputElement;

    EventBus.fire(this.keyupEvent, value);
  }

  @autobind
  handleEmailKeyup() {
    this.debouncedValidate();
  }

  @debounce(2000)
  debouncedValidate() {
    this.validateEmail();
  }

  validateEmpty() {
    const val = this.$el.value;

    this.passing = val.length > 0;

    if (this.hasInitiated) {
      this.displayError(GLOBAL_CONSTANTS.ERRORS.REQUIRED);
    }
  }

  @autobind
  displayError(msg: string) {
    const parent = this.$el.parentElement;
    if (this.passing) {
      parent.classList.remove(GLOBAL_CONSTANTS.CLASSES.ERRORS);
    } else {
      parent.classList.add(GLOBAL_CONSTANTS.CLASSES.ERRORS);
      parent.querySelector(CLASSES.ERRORS).innerHTML = msg;
    }
  }

  validateEmail() {
    const val = this.$el.value;
    this.passing = validateEmail(val);

    if (this.hasInitiated) {
      this.displayError(GLOBAL_CONSTANTS.ERRORS.EMAIL);
    }
  }

  /**
   * @desc Clears out the input value, to be
   * called from an Emitter.
   */
  @autobind
  clearInput() {
    this.$el.value = "";
  }

  /**
   * @desc Remove event listeners
   */
  tearDown() {
    this.$el.removeEventListener("keyup", this.onKeyUp);

    if (this.clearEvent) {
      EventBus.off(this.clearEvent, this.clearInput);
    }
  }
}
