import { autobind } from "core-decorators";
import { setSessionCookie, getCookie } from "../../globals/functions";

const CLASSES = {
  CLOSE: ".js-alert-close",
  CONTENT: ".modal__content",
  NAVIGATION: ".nav-wrapper",
  NAVSPACER: ".nav-spacer",
};

/**
 * @desc Simple component that shows / hides content
 */
export class HomeAlert {
  $el: HTMLElement;
  $closeButton: HTMLElement;
  $modelContent: Array<HTMLElement>;

  $Navigation: HTMLElement;
  $NavSpacer: HTMLElement;

  constructor(el: HTMLElement) {
    this.$el = el;
    this.$closeButton = this.$el.querySelector(CLASSES.CLOSE) as HTMLElement;
    this.$modelContent = Array.from(
      document.querySelectorAll(CLASSES.CONTENT),
    ) as Array<HTMLElement>;
    this.$Navigation = document.querySelector(
      CLASSES.NAVIGATION,
    ) as HTMLElement;
    this.$NavSpacer = document.querySelector(CLASSES.NAVSPACER) as HTMLElement;

    this.init();
    this.bindEvents();
  }

  @autobind
  init(): void {
    if (!getCookie("umpq_home_alert")) {
      this.$el.classList.remove("hidden");
    }
    const alertHeight = this.$el.getBoundingClientRect().bottom;
    if (alertHeight > 0) {
      for (let i = 1; i < this.$modelContent.length; i++) {
        const key = this.$modelContent[i].parentElement.dataset.key;
        if (key === "locations" || key === "login") {
          this.$modelContent[i].style.marginTop = alertHeight + 32 + "px";
          this.$modelContent[i].style.marginBottom = "20px";
        } else if (key === "login") {
          this.$modelContent[i].style.marginTop = alertHeight + "px";
        }
      }
    }
  }

  @autobind
  bindEvents(): void {
    this.$closeButton.addEventListener("click", this.closeAlert);
  }

  @autobind
  closeAlert(): void {
    this.$el.classList.add("hidden");
    setSessionCookie("umpq_home_alert", "1");
    for (let i = 1; i < this.$modelContent.length; i++) {
      const key = this.$modelContent[i].parentElement.dataset.key;
      if (key === "locations" || key === "login") {
        this.$modelContent[i].style.marginTop = "0px";
        this.$modelContent[i].style.marginBottom = "0px";
      }
    }
    if (
      this.$Navigation != null &&
      this.$Navigation.clientHeight != null &&
      this.$NavSpacer != null
    ) {
      this.$NavSpacer.style.height = this.$Navigation.clientHeight + "px";
    }
    const spacer = document.querySelector(".js-modal-spacer") as HTMLElement;
    const alert = document.querySelector(
      ".js-navigation-block-alert",
    ) as HTMLElement;
    if (alert != null && alert.clientHeight != null && spacer != null) {
      spacer.style.height = alert.clientHeight + "px";
    }
  }
}
