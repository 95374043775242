import { autobind } from "core-decorators";
import { EventBus } from "../globals/emitter";
import { fadeIn } from "../effects/animation/animations";
import { GLOBAL_CONSTANTS } from "../globals/constants";
import { checkStatus } from "../globals/functions";
import { LazyLoader } from "../elements/lazy-loader";
import "whatwg-fetch";

declare var gtm: any;

const classes = {
  Container: ".js-filter-container",
  Item: ".js-filter-item",
  ShowMore: ".js-content-hub-load-more",
};

const defaults = {
  Media: "",
  Content: "",
  Sort: "",
  _Key: "",
};

/**
 * Filter - class that will hide / show content based on selected filter types
 */
export class Filter {
  $el: HTMLElement;
  $container: HTMLElement;
  $items: Array<HTMLElement>;
  $showMore: HTMLElement;
  lazy: LazyLoader;
  numberOfItemsToSkip = 0;
  maxNumberOfItems = 9;
  media = defaults.Media;
  content = defaults.Content;
  sort = defaults.Sort;

  /**
   * @desc constructor - grab relevant DOM elements and kickoff element bind
   * @param {HTMLElement} el - Containing element for the filter
   */
  constructor(el: HTMLElement) {
    this.$el = el;
    this.$container = this.$el.querySelector(classes.Container) as HTMLElement;
    this.$showMore = this.$el.querySelector(classes.ShowMore) as HTMLElement;
    this.lazy = new LazyLoader();
    this.bindEvents();
  }

  /**
   * @desc bindEvents - Loop through all filters and add event listeners to
   * all filter triggers
   */
  bindEvents() {
    EventBus.on(GLOBAL_CONSTANTS.EVENTS.APPLY_FILTER, this.implementFilter);
    if (this.$showMore) {
      this.$showMore.addEventListener("click", this.showMore);
    }
  }

  /**
   * @desc implementFilter - Mouse event that fires when a user selects a filter,
   * causes the application to check the type of filter and then re-run
   * filtering out logic.
   * @param {MouseEvent} e - event
   */
  @autobind
  implementFilter({ type, tag }: any) {
    tag = encodeURIComponent(tag);
    if (type === "media") {
      this.media = tag === defaults._Key ? defaults.Media : tag;
      this.content = urlParam("categoryTag");
    } else if (type === "content") {
      this.media = urlParam("mediaTag");
      this.content = tag === defaults._Key ? defaults.Content : tag;
    } else {
      this.sort = tag === defaults._Key ? defaults.Sort : tag;
    }
    this.numberOfItemsToSkip = 0;
    if (this.$showMore) {
      this.$showMore.classList.remove("hidden");
    }
    this.applyFilters();
  }

  /**
   * @desc applyFilters - Run two different filter types, and then sort based on
   * selected filters / sort types.
   */
  @autobind
  applyFilters() {
    const url = this.$container.dataset["getUrl"];
    const getUrl = `${url}?mediaTag=${this.media}&categoryTag=${this.content}&numberOfItemsToSkip=${this.numberOfItemsToSkip}`;
    const shareUrl = `?mediaTag=${this.media}&categoryTag=${this.content}`;
    if (this.$showMore) {
      this.$showMore.classList.add("hidden");
    }
    window.history.pushState({}, "blog", shareUrl);
    fetch(getUrl, { method: "GET" })
      .then(checkStatus)
      .then((response: any) => {
        response.text().then(async (html: string) => {
          const div = document.createElement("div");
          div.insertAdjacentHTML("beforeend", html);
          this.$items = Array.from(
            div.querySelectorAll(classes.Item),
          ) as Array<HTMLElement>;

          if (this.numberOfItemsToSkip === 0) {
            // cleanup container when doing a new search
            this.$container.innerHTML = "";
          }

          // append items one by one to run animations
          const len =
            this.$items.length > this.maxNumberOfItems
              ? this.maxNumberOfItems
              : this.$items.length;
          for (let i = 0; i < len; i++) {
            const item = this.$items[i];
            this.$container.appendChild(item);
            fadeIn(item).forward(i * 0.15);
          }

          const totalNumberOfItems = document.getElementsByClassName(
            classes.Item.substr(1),
          ).length;

          try {
            const categoryElement = document.getElementById(
              "CategoryId",
            ) as HTMLSelectElement;
            let category =
              categoryElement == null || categoryElement.selectedIndex < 0
                ? ""
                : categoryElement.options[categoryElement.selectedIndex].value;
            if (category == null || category.length < 1) {
              category = "All Categories";
            }
            const mediaElement = document.getElementById(
              "MediaId",
            ) as HTMLSelectElement;
            let media =
              mediaElement == null || mediaElement.selectedIndex < 0
                ? ""
                : mediaElement.options[mediaElement.selectedIndex].value;
            if (media == null || media.length < 1) {
              media = "All Media";
            }
            gtm.search.blog.json(
              totalNumberOfItems.toString(),
              this.numberOfItemsToSkip.toString(),
              category,
              media,
            );
          } catch (e) {
            const filter =
              "query:|category:" +
              gtm.utilities.sanitize(this.content) +
              "|media:" +
              gtm.utilities.sanitize(this.media);
            gtm.search.json(
              "",
              totalNumberOfItems.toString(),
              "blog",
              this.numberOfItemsToSkip.toString(),
              filter,
            );
          }

          // hide Show More button when we don't have other results to retrieve from server
          if (
            this.$items.length > this.maxNumberOfItems &&
            this.$showMore &&
            this.$showMore.classList.contains("hidden")
          ) {
            this.$showMore.classList.remove("hidden");
          }
          if (
            this.$items.length <= this.maxNumberOfItems &&
            this.$showMore &&
            !this.$showMore.classList.contains("hidden")
          ) {
            this.$showMore.classList.add("hidden");
          }
          this.lazy.refreshImageArray();

          EventBus.fire(GLOBAL_CONSTANTS.EVENTS.STOP_STICKY_NAV);
          EventBus.fire(GLOBAL_CONSTANTS.EVENTS.ADJUST_GRID);
          setTimeout(() => {
            EventBus.fire(GLOBAL_CONSTANTS.EVENTS.START_STICKY_NAV);
          }, 100);
        });
      })
      .catch((error: any) => {
        gtm.events.json(
          "search-blog",
          "category:" + this.content + "|media:" + this.media,
          "api blog search error - " + error,
        );
        console.log("request failed", error);
      });
  }

  @autobind
  showMore() {
    this.media = urlParam("mediaTag");
    this.content = urlParam("categoryTag");
    this.numberOfItemsToSkip += this.maxNumberOfItems;
    this.applyFilters();
  }

  /**
   * @desc tearDown - remove filter events
   */
  tearDown() {
    EventBus.off(GLOBAL_CONSTANTS.EVENTS.APPLY_FILTER, this.implementFilter);
  }
}

function urlParam(name: string) {
  const results = new RegExp("[?&]" + name + "=([^&#]*)").exec(
    window.location.href,
  );
  if (results == null) {
    return "";
  } else {
    const param = decodeURI(results[1]).toString() || "";
    return param.replace("&", "%26");
  }
}
