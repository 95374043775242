import GoogleMapsLoader from "google-maps";
import { autobind, debounce } from "core-decorators";
import { GLOBAL_CONSTANTS } from "../globals/constants";
import { EventBus } from "../globals/emitter";
import { style } from "./umpqua-map-style";
import {
  fadeOut,
  fadeIn,
  fadeInUp,
  moveUp,
} from "../effects/animation/animations";
import { isMobile } from "../globals/functions";

declare var gtm: any;

const CLASSES = {
  CONTAINER: ".js-map-container",
  POPUP: ".js-map-popup",
  POPUP_CONTENT: ".js-map-popup-content",
  POPUP_HEADER_CONTAINER: ".js-map-popup-header-container",
  POPUP_HEADER_CONTENT: ".js-map-header-content",
  POPUP_BACK: ".js-popup-back",
  POPUP_BACK_TEXT: ".js-popup-back-text",
  POPUP_LIST_ITEM: ".js-map-popup-list-item",
  POPUP_LIST_ITEM_CLICKABLE: ".js-map-popup-list-item-clickable",
  POPUP_INNER: ".js-map-popup-inner",
  FILTERS: ".js-map-filter",
  TITLE: ".js-map-title",
  LOADER: ".js-map-loader",
  MAP_HOLDER: ".js-map-holder",
  MAP_HOLDER_DETAIL: ".js-map-holder-detail",
  CONFIG: ".js-map-config",
  SEARCH_BUTTON: ".js-map-search",
  INPUT: ".js-map-input",
  FIRST_STEP: ".map-search__step",
  MAP_STEP: ".js-step-map",
  MAP_DIV: ".js-map-div",
  ERROR_CONTAINER: ".js-form-errors",
  STYLED: {
    POPUP_OPEN: "map--popup-open",
    POPUP_DETAIL_OPEN: "map__popup-detail-open",
  },
  SHOW_STORE_ADDRESS: ".js-show-store-address",
  SHOW_MAILING_ADDRESS: ".js-show-mailing-address",
  DETAIL_ADDRESS_TYPE_DIV: ".js-detail-address-type-div",
  DETAIL_ADDRESS_SWITCH: ".js-detail-address-switch",
  LIST_ITEM_MAILING_ADDRESS_ANCHOR: "js-list-item-mailing-address-anchor",
  LIST_MAILING_ADDRESS_LEGEND: ".js-list-mailing-address-legend",
};

const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const mapZoomDefault = 14;

/**
 * Class to be attached to a div
 * to create an interactive google map
 */
export class Map {
  $el: HTMLElement;
  $container: HTMLElement;
  $popup: HTMLElement;
  $popupContent: HTMLElement;
  $popupInner: HTMLElement;
  $popupBack: HTMLElement;
  $popupBackText: HTMLElement;
  $popupHeaderContainer: HTMLElement;
  $popupContainer: HTMLElement;
  $filters: Array<HTMLElement>;
  activeFilters: Array<string>;
  $mapHolder: HTMLElement;
  $mapHolderDetail: HTMLElement;
  $loader: HTMLElement;
  $config: HTMLElement;
  $searchButton: HTMLElement;
  $mapDiv: HTMLElement;
  $firstStep: HTMLElement;
  $stepMap: HTMLElement;
  $input: HTMLInputElement;
  $errorContainer: HTMLElement;
  map: google.maps.Map;
  google: typeof GoogleMapsLoader;
  geocoder: google.maps.Geocoder;
  address: string;
  activeMarker: any;
  activeDetail: boolean;
  mailingAddress = false;
  anyMailingAddresses = false;
  iconUrl: string;
  markers: Array<any>;
  listElements: Array<any>;
  animateListElements: Array<HTMLElement>;
  clickableListElements: Array<HTMLElement>;
  loaded = false;
  $showStoreAddress: HTMLElement;
  $showMailingAddress: HTMLElement;
  $detailAddressTypeSection: HTMLElement;
  $detailAddressSwitch: HTMLInputElement;
  $listMailingAddressLegend: HTMLElement;

  geocoderResult: google.maps.GeocoderResult;
  locations: Array<any>;
  detailLocation: any;
  holidayMessage: string = null;

  detailTemplate: string;
  dailyHoursTemplate: string;
  featureTemplate: string;
  detailHeaderAddressTemplate: string;
  detailHeaderAdditionsTemplate: string;
  headerTemplate: string;
  listTemplate: string;
  listItemTemplate: string;

  $atm: HTMLInputElement;
  $openNow: HTMLInputElement;
  $openSaturdays: HTMLInputElement;
  $driveUpWindow: HTMLInputElement;
  $lobbyOpen: HTMLInputElement;
  $byAppointment: HTMLInputElement;

  validStateInitials = ["CA", "ID", "OR", "NV", "WA"];
  searchAttempts = 0;
  addressEntered = "";
  appointmentUrlTitle = "";

  /**
   * @param {HTMLElement} el - container to attach map to
   * @desc Parse all relevant divs, and kickoff map build.
   */
  constructor(el: HTMLElement) {
    this.$el = el;
    this.iconUrl = (this.$el.dataset as any).iconUrl;
    this.$container = this.$el.querySelector(CLASSES.CONTAINER) as HTMLElement;
    this.$popup = this.$el.querySelector(CLASSES.POPUP) as HTMLElement;
    this.$popupContent = this.$el.querySelector(
      CLASSES.POPUP_CONTENT,
    ) as HTMLElement;
    this.$popupInner = this.$el.querySelector(
      CLASSES.POPUP_INNER,
    ) as HTMLElement;
    this.$popupBack = this.$el.querySelector(CLASSES.POPUP_BACK) as HTMLElement;
    this.$mapHolder = this.$el.querySelector(CLASSES.MAP_HOLDER) as HTMLElement;
    this.$mapHolderDetail = this.$el.querySelector(
      CLASSES.MAP_HOLDER_DETAIL,
    ) as HTMLElement;
    this.$popupBackText = this.$popupBack.querySelector(
      CLASSES.POPUP_BACK_TEXT,
    ) as HTMLElement;
    this.$popupHeaderContainer = this.$el.querySelector(
      CLASSES.POPUP_HEADER_CONTAINER,
    ) as HTMLElement;
    this.$loader = this.$el.querySelector(CLASSES.LOADER) as HTMLElement;
    this.$filters = Array.from(
      this.$el.querySelectorAll(CLASSES.FILTERS),
    ) as Array<HTMLElement>;
    this.$config = this.$el.querySelector(CLASSES.CONFIG) as HTMLElement;
    this.$searchButton = this.$el.querySelector(
      CLASSES.SEARCH_BUTTON,
    ) as HTMLElement;
    this.$input = this.$el.querySelector(CLASSES.INPUT) as HTMLInputElement;
    this.$firstStep = this.$el.querySelector(CLASSES.FIRST_STEP) as HTMLElement;
    this.$stepMap = this.$el.querySelector(CLASSES.MAP_STEP) as HTMLElement;
    this.$mapDiv = this.$el.querySelector(CLASSES.MAP_DIV) as HTMLElement;
    this.$errorContainer = this.$el.querySelector(
      CLASSES.ERROR_CONTAINER,
    ) as HTMLElement;
    this.$showStoreAddress = this.$el.querySelector(
      CLASSES.SHOW_STORE_ADDRESS,
    ) as HTMLElement;
    this.$showMailingAddress = this.$el.querySelector(
      CLASSES.SHOW_MAILING_ADDRESS,
    ) as HTMLElement;
    this.$detailAddressTypeSection = this.$el.querySelector(
      CLASSES.DETAIL_ADDRESS_TYPE_DIV,
    ) as HTMLElement;
    this.$detailAddressSwitch = this.$el.querySelector(
      CLASSES.DETAIL_ADDRESS_SWITCH,
    ) as HTMLInputElement;
    this.$listMailingAddressLegend = this.$el.querySelector(
      CLASSES.LIST_MAILING_ADDRESS_LEGEND,
    ) as HTMLElement;
    this.appointmentUrlTitle = this.$config.dataset["appointmentUrlTitle"];

    this.markers = [];
    this.activeFilters = [];

    if (document.querySelector(".map-block")) {
      this.mapLoader();
    }

    EventBus.on(GLOBAL_CONSTANTS.EVENTS.MODAL_MAP_OPEN, this.onModalOpen);
    EventBus.on(GLOBAL_CONSTANTS.EVENTS.MODAL_CLOSE, this.onModalClose);

    this.init();
    this.bindEvents();
  }

  init(): void {
    this.detailTemplate = (
      document.querySelector("#mapDetail") as HTMLElement
    ).innerText;
    this.dailyHoursTemplate = (
      document.querySelector("#mapDailyHours") as HTMLElement
    ).innerText;
    this.featureTemplate = (
      document.querySelector("#mapFeature") as HTMLElement
    ).innerText;
    this.listTemplate = (
      document.querySelector("#mapList") as HTMLElement
    ).innerText;
    this.listItemTemplate = (
      document.querySelector("#mapListItem") as HTMLElement
    ).innerText;
    this.headerTemplate = (
      document.querySelector("#mapListHeader") as HTMLElement
    ).innerText;
    this.detailHeaderAddressTemplate = (
      document.querySelector("#mapDetailHeaderAddress") as HTMLElement
    ).innerText;
    this.detailHeaderAdditionsTemplate = (
      document.querySelector("#mapDetailHeaderAdditions") as HTMLElement
    ).innerText;
    this.$atm = this.$el.querySelector(".js-atm") as HTMLInputElement;
    this.$openNow = this.$el.querySelector(".js-open-now") as HTMLInputElement;
    this.$openSaturdays = this.$el.querySelector(
      ".js-open-sat",
    ) as HTMLInputElement;
    this.$driveUpWindow = this.$el.querySelector(
      ".js-drive-up",
    ) as HTMLInputElement;
    this.$lobbyOpen = this.$el.querySelector(
      ".js-lobby-open",
    ) as HTMLInputElement;
    this.$byAppointment = this.$el.querySelector(
      ".js-by-appointment",
    ) as HTMLInputElement;
  }

  /**
   * @desc Bind back event for popup
   */
  @autobind
  bindEvents(): void {
    this.$input.addEventListener("keyup", this.updateValues);
    this.$searchButton.addEventListener("click", this.search);
    this.$popupBack.addEventListener("click", this.popupBack);
    this.$showStoreAddress.addEventListener("click", this.showStoreAddress);
    this.$showMailingAddress.addEventListener("click", this.showMailingAddress);
    this.$detailAddressSwitch.addEventListener(
      "click",
      this.showMailingOrStoreAddress,
    );
    this.$filters.forEach((el) => {
      el.querySelector("input").addEventListener("change", this.toggleFilter);
    });
  }

  @autobind
  mapLoader() {
    const options: google.maps.MapOptions = {
      center: { lat: 45.545305, lng: -122.6710529 },
      zoom: mapZoomDefault,
      mapTypeControl: false,
      scrollwheel: true,
      fullscreenControl: false,
      streetViewControl: false,
      zoomControl: true,
      styles: style,
    };
    GoogleMapsLoader.KEY = "";
    GoogleMapsLoader.LIBRARIES = ["geometry", "places"];
    GoogleMapsLoader.load((google: any) => {
      this.google = google;
      this.map = new google.maps.Map(this.$container, options);
      this.loaded = true;
      this.google.maps.event.trigger(this.$container, "resize");
    });
  }

  @autobind
  onModalClose() {
    this.$mapDiv.classList.remove(CLASSES.STYLED.POPUP_DETAIL_OPEN);
    this.$mapDiv.classList.remove(CLASSES.STYLED.POPUP_OPEN);
    this.$mapHolder.appendChild(this.$container);
  }

  @autobind
  onModalOpen() {
    if (!this.loaded) {
      this.mapLoader();
    }
  }

  @autobind
  toggleFilter() {
    this.removeMarkers();
    this.updateMap();
  }

  /**
   * @param {Event} e - Mouse Click Event
   * @desc Determines if the locations list needs to be built,
   * if so build it, if not we need to bring the user to the
   * beginning of the map flow.
   */
  @autobind
  popupBack(e: Event): void {
    e.preventDefault();
    const buttonElement = e.currentTarget as HTMLButtonElement;
    const buttonText =
      buttonElement !== null &&
      typeof buttonElement !== "undefined" &&
      buttonElement.innerText.trim().length > 0
        ? buttonElement.innerText.trim()
        : "Start Over/Go Back";
    const buttonId =
      buttonElement !== null && typeof buttonElement !== "undefined"
        ? buttonElement.id
        : "";
    gtm.navigation.createJson(
      buttonText,
      document.baseURI,
      "click",
      "FALSE",
      "",
      "",
      "",
      document.baseURI,
      buttonId,
      "back",
    );

    this.mailingAddress = false;

    if (this.activeDetail) {
      this.setupListPopup();
      this.activeMarker.ref.setIcon(this.activeMarker.options.og);
      this.activeMarker = null;
      if (isMobile()) {
        this.$mapHolder.appendChild(this.$container);
        fadeIn(this.$container).forward(0);
      }
    } else {
      if (this.map) {
        this.map.setZoom(mapZoomDefault);
      }
      this.$firstStep.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
      this.$stepMap.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
      this.removeMarkers();
      this.unbindListElementEvents();
      this.$el.classList.remove(CLASSES.STYLED.POPUP_OPEN);
      this.$input.value = "";
      this.address = "";
      if (isMobile()) {
        this.$mapHolder.appendChild(this.$container);
        fadeIn(this.$container).forward(0);
      }
    }
  }

  /**
   * @desc Removes references to the gmap markers,
   * deletes events.
   */
  @autobind
  removeMarkers() {
    this.google.maps.event.clearListeners(this.map, "mouseover");
    this.google.maps.event.clearListeners(this.map, "mouseout");
    this.google.maps.event.clearListeners(this.map, "click");
    this.markers.forEach((marker) => {
      marker.ref.setMap(null);
    });
    this.markers = [];
  }

  /**
   * @param {String} val - Value to come from an Emitter event.
   * @desc Set the address for the map to search around.
   */
  @autobind
  updateValues(): void {
    this.address = this.$input.value;
  }

  @autobind
  search(e: Event): void {
    if (!this.$errorContainer.classList.contains("hidden")) {
      this.$errorContainer.classList.add("hidden");
    }

    this.updateValues();

    if (!this.address || this.address.length === 0) {
      e.preventDefault();
      return;
    }

    this.searchAttempts = 0;
    this.addressEntered = this.address;
    this.textSearch();

    e.preventDefault();
  }

  /**
   * @desc Tell google to look for the geocode for the address
   * provided.
   */
  @autobind
  textSearch(): void {
    this.searchAttempts++;
    this.showLoader();

    const geocoderBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(32.324276, -124.541016),
      new google.maps.LatLng(48.864715, -110.126953),
    );

    this.geocoder = new this.google.maps.Geocoder();
    this.geocoder.geocode(
      {
        address: this.address,
        componentRestrictions: {
          country: "USA",
        },
        bounds: geocoderBounds,
      },
      this.onGeocoderResults,
    );
  }

  @autobind
  showLoader() {
    this.$loader.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
  }

  /**
   * @param {Array} results - List of locations closest to the address provided.
   * @param {Object} status - status info of the request.
   */
  @autobind
  onGeocoderResults(
    results: Array<google.maps.GeocoderResult>,
    status: google.maps.GeocoderStatus,
  ): void {
    if (status === this.google.maps.GeocoderStatus.OK) {
      this.geocoderResult = results[0];
      const addressComponents = this.geocoderResult.address_components;
      if (addressComponents !== null && addressComponents.length > 0) {
        let validState = false;

        for (let index = addressComponents.length - 1; index >= 0; index--) {
          const addressComponent = addressComponents[index];
          if (
            this.validStateInitials.indexOf(
              addressComponent.short_name.toLocaleUpperCase(),
            ) < 0
          ) {
            continue;
          }
          validState = true;
          break;
        }
        if (!validState) {
          if (this.address.endsWith(" nv usa")) {
            this.$errorContainer.classList.remove("hidden");
          } else {
            if (this.address.endsWith(" or usa")) {
              this.address = this.address.replace(" or usa", " wa usa");
            } else if (this.address.endsWith(" wa usa")) {
              this.address = this.address.replace(" wa usa", " ca usa");
            } else if (this.address.endsWith(" ca usa")) {
              this.address = this.address.replace(" ca usa", " id usa");
            } else if (this.address.endsWith(" id usa")) {
              this.address = this.address.replace(" id usa", " nv usa");
            } else {
              this.address += " or usa";
            }

            if (this.searchAttempts < 7) {
              this.textSearch();
            } else {
              this.$errorContainer.classList.remove("hidden");
            }
          }

          return;
        }
      }

      this.$stepMap.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
      this.$firstStep.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
      this.updateMap();
    } else {
      if (this.searchAttempts < 2 || this.address.endsWith(" nv usa")) {
        this.$errorContainer.classList.remove("hidden");
      } else {
        if (this.address.endsWith(" or usa")) {
          this.address = this.address.replace(" or usa", " wa usa");
        } else if (this.address.endsWith(" wa usa")) {
          this.address = this.address.replace(" wa usa", " ca usa");
        } else if (this.address.endsWith(" ca usa")) {
          this.address = this.address.replace(" ca usa", " id usa");
        } else if (this.address.endsWith(" id usa")) {
          this.address = this.address.replace(" id usa", " nv usa");
        } else {
          this.address += " or usa";
        }

        if (this.searchAttempts < 7) {
          this.textSearch();
        } else {
          this.$errorContainer.classList.remove("hidden");
        }

        return;
      }
    }
  }

  @autobind
  updateMap(): void {
    const foundLocation = this.geocoderResult.geometry.location;
    this.map.setCenter(foundLocation);
    this.queryLocations(foundLocation).then((response: any) => {
      response.json().then(async (response: any) => {
        this.locations = response.locations;
        this.holidayMessage = response.holidayMessage;
        gtm.search.json(
          this.address,
          this.locations.length.toString(),
          "location",
          "0",
          this.gtmFormatSearchFilters(),
        );
        this.gtmLocationDataLayer();
        await this.addMarkers();
      });
    });
  }

  /**
   * @param {Array} results - Locations of all the search results for the DISTANCE
   * search made
   * @param {Object} status - status info of the request.
   * @desc Set up markers for each location, add them to the global array,
   * create events for each maker.
   */
  @autobind
  async addMarkers(): Promise<any> {
    await this.setupListPopup();
    this.locations.forEach((item) => {
      const marker = new this.google.maps.Marker({
        title: item.storeNumber,
        map: this.map,
        position: { lat: item.latitude, lng: item.longitude },
        icon: `${this.iconUrl}/marker.png`,
      });

      this.markers.push({
        ref: marker,
        options: {
          place_id: item.storeNumber,
          hovericon: `${this.iconUrl}/marker-hover.png`,
          og: `${this.iconUrl}/marker.png`,
          active: false,
        },
      });

      this.google.maps.event.addListener(marker, "mouseover", () => {
        if (this.activeMarker && this.activeMarker.ref === marker) {
          return false;
        }
        const pluckedMarker = this.pluckMarker(marker);
        marker.setIcon(pluckedMarker.options.hovericon);
        return false;
      });

      this.google.maps.event.addListener(marker, "mouseout", () => {
        if (this.activeMarker && this.activeMarker.ref === marker) {
          return false;
        }
        const pluckedMarker = this.pluckMarker(marker);
        marker.setIcon(pluckedMarker.options.og);
        return false;
      });

      this.google.maps.event.addListener(marker, "click", () => {
        if (this.activeMarker && this.activeMarker.ref === marker) {
          return false;
        }
        const pluckedMarker = this.pluckMarker(marker);
        if (this.activeMarker) {
          this.activeMarker.ref.setIcon(pluckedMarker.options.og);
        }
        this.activateLocation(pluckedMarker, item);
        return false;
      });
    });
  }

  /**
   * @desc bind event for list elements in the info popup.
   */
  @autobind
  bindListElementEvents() {
    this.listElements.forEach((el: HTMLElement) => {
      el.addEventListener("mouseout", this.hoverCorrespondingMarker);
      el.addEventListener("mouseover", this.hoverCorrespondingMarker);
    });

    this.clickableListElements.forEach((el) => {
      el.addEventListener("click", this.loadMarkerDetails);
    });

    const mailingAddressAnchors = this.$el.getElementsByClassName(
      CLASSES.LIST_ITEM_MAILING_ADDRESS_ANCHOR,
    );
    for (let i = 0; i < mailingAddressAnchors.length; i++) {
      const element = mailingAddressAnchors[i];
      if (!element.classList.contains("hidden")) {
        element.addEventListener("click", this.showDetailMailingAddress);
      }
    }
  }

  /**
   * @param {Event} e - Mouseclick event for list items.
   * @desc Pair the current marker with a place and start
   * activating it's location for the popup.
   */
  @autobind
  loadMarkerDetails(e?: Event, placeIdValue?: string) {
    let placeId: string;
    if (e) {
      const tar = e.currentTarget as HTMLElement;
      placeId = (tar.dataset as any).placeId;
    } else {
      placeId = placeIdValue;
    }
    const marker = this.pluckMarker(null, placeId);
    const place = this.locations.find((item) => {
      return item.storeNumber === placeId;
    });
    this.activateLocation(marker, place);
  }

  /**
   * @param {Object} marker - information related to the marker we want.
   * @param {Object} detail - extra information not provided inside the marker
   * but still pertaining to the same location.
   * @desc Set the users preference to the location
   * the marker represents. Generate related information.
   */
  activateLocation(marker: any, detail: any) {
    this.activeMarker = marker;
    this.panTo(marker.ref.position);
    this.activeMarker.ref.setIcon(marker.options.hovericon);
    this.activeDetail = true;
    this.detailLocation = detail;
    this.setupDetailPopup(detail);
  }

  @autobind
  showDetailMailingAddress(event: Event): void {
    event.preventDefault();
    this.mailingAddress = true;
    this.loadMarkerDetails(event);
  }

  /**
   * Unbind events for list elements in the info popup.
   */
  @autobind
  unbindListElementEvents() {
    if (this.listElements && this.listElements.length > 0) {
      this.listElements.forEach((el: HTMLElement) => {
        el.removeEventListener("mouseout", this.hoverCorrespondingMarker);
        el.removeEventListener("mouseover", this.hoverCorrespondingMarker);
        el.removeEventListener("click", this.loadMarkerDetails);
      });

      this.clickableListElements.forEach((el) => {
        el.removeEventListener("click", this.loadMarkerDetails);
      });
    }

    this.removeListMailingAddressAnchors();
  }

  @autobind
  queryLocations(loc: google.maps.LatLng): Promise<any> {
    return fetch("/api/v1/locations", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        latitude: loc.lat(),
        longitude: loc.lng(),
        atm: this.$atm.checked,
        openNow: this.$openNow.checked,
        openSaturdays: this.$openSaturdays.checked,
        driveUpWindow: this.$driveUpWindow.checked,
        lobbyOpen: this.$lobbyOpen.checked,
        byAppointment: this.$byAppointment.checked,
        date: new Date(),
      }),
    })
      .then(this.checkStatus)
      .catch((error: any) => {
        gtm.events.json(
          "search-locations",
          this.gtmFormatSearchFilters(),
          "api locations search error - " + error,
        );
        console.log("request failed", error);
      });
  }

  @autobind
  checkStatus(response: any): any {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      throw new Error(`Status: ${response.statusText} Response: ${response}`);
    }
  }

  @autobind
  gtmFormatSearchFilters(): string {
    const address =
      this.address && this.address.length > 0
        ? gtm.utilities.sanitize(this.address)
        : "";
    const atm = this.$atm.checked ? "TRUE" : "FALSE";
    const openNow = this.$openNow.checked ? "TRUE" : "FALSE";
    const openSaturday = this.$openSaturdays.checked ? "TRUE" : "FALSE";
    const driveUp = this.$driveUpWindow.checked ? "TRUE" : "FALSE";
    const lobbyOpen = this.$lobbyOpen.checked ? "TRUE" : "FALSE";
    const byAppointment = this.$byAppointment.checked ? "TRUE" : "FALSE";
    const filtered =
      atm === "TRUE" ||
      openNow === "TRUE" ||
      openSaturday === "TRUE" ||
      driveUp === "TRUE" ||
      lobbyOpen === "TRUE" ||
      byAppointment === "TRUE"
        ? "TRUE"
        : "FALSE";

    return (
      "query:" +
      address +
      "|filter:" +
      filtered +
      "|atm:" +
      atm +
      "|openNow:" +
      openNow +
      "|openSaturday:" +
      openSaturday +
      "|driveUp:" +
      driveUp +
      "|lobbyOpen:" +
      lobbyOpen +
      "|byAppointment:" +
      byAppointment
    );
  }

  @autobind
  gtmLocationDataLayer(
    locationId = "",
    locationName = "",
    locationAddress1 = "",
    locationAddress2 = "",
    locationCity = "",
    locationState = "",
    locationZip = "",
    locationPhone = "",
  ): void {
    const address =
      this.address && this.address.length > 0
        ? gtm.utilities.sanitize(this.address)
        : "";
    const atm = this.$atm.checked ? "TRUE" : "FALSE";
    const openNow = this.$openNow.checked ? "TRUE" : "FALSE";
    const openSaturday = this.$openSaturdays.checked ? "TRUE" : "FALSE";
    const driveUp = this.$driveUpWindow.checked ? "TRUE" : "FALSE";
    const lobbyOpen = this.$lobbyOpen.checked ? "TRUE" : "FALSE";
    const byAppointment = this.$byAppointment.checked ? "TRUE" : "FALSE";
    const filtered =
      atm === "TRUE" ||
      openNow === "TRUE" ||
      openSaturday === "TRUE" ||
      driveUp === "TRUE" ||
      lobbyOpen === "TRUE" ||
      byAppointment === "TRUE"
        ? "TRUE"
        : "FALSE";

    let locationAddress =
      locationAddress1 !== null && typeof locationAddress1 !== "undefined"
        ? gtm.utilities.sanitize(locationAddress1)
        : "";
    if (
      locationAddress2 !== null &&
      typeof locationAddress2 !== "undefined" &&
      locationAddress2.length > 0
    ) {
      locationAddress += ", " + gtm.utilities.sanitize(locationAddress2);
    }
    if (
      locationCity !== null &&
      typeof locationCity !== "undefined" &&
      locationCity.length > 0
    ) {
      locationAddress += ", " + gtm.utilities.sanitize(locationCity);
    }
    if (
      locationState !== null &&
      typeof locationState !== "undefined" &&
      locationState.length > 0
    ) {
      locationAddress += ", " + gtm.utilities.sanitize(locationState);
    }
    if (
      locationZip !== null &&
      typeof locationZip !== "undefined" &&
      locationZip.length > 0
    ) {
      locationAddress += " " + gtm.utilities.sanitize(locationZip);
    }

    gtm.locations.json(
      address,
      filtered,
      atm,
      openNow,
      openSaturday,
      driveUp,
      this.locations.length.toString(),
      locationId,
      locationName,
      locationAddress,
      locationCity,
      locationState,
      locationZip,
      locationPhone,
      lobbyOpen,
      byAppointment,
    );
  }

  /**
   * @desc Populate list of locations in the info popup.  1
   */
  async setupListPopup() {
    if (
      this.$mapDiv.classList.contains(CLASSES.STYLED.POPUP_DETAIL_OPEN) &&
      isMobile()
    ) {
      fadeOut(this.$container).forward(0);
    }
    await fadeOut(this.$popupInner).forward(0);

    this.$popupBackText.innerHTML = this.$config.dataset["startOver"];
    this.$popupHeaderContainer.innerHTML = this.buildListHeader();
    this.$popupContent.innerHTML = this.buildListContent();

    this.listElements = Array.from(
      this.$popupContent.querySelectorAll(CLASSES.POPUP_LIST_ITEM_CLICKABLE),
    ) as Array<HTMLElement>;
    this.animateListElements = Array.from(
      this.$popupContent.querySelectorAll(CLASSES.POPUP_LIST_ITEM),
    ) as Array<HTMLElement>;
    this.clickableListElements = Array.from(
      this.$popupContent.querySelectorAll(CLASSES.POPUP_LIST_ITEM_CLICKABLE),
    ) as Array<HTMLElement>;
    this.bindListElementEvents();
    this.activeDetail = false;

    if (!this.$detailAddressTypeSection.classList.contains("hidden")) {
      this.$detailAddressTypeSection.classList.add("hidden");
    }

    this.anyMailingAddresses = false;
    if (this.locations && this.locations.length > 0) {
      for (let index = 0; index < this.locations.length; index++) {
        if (!this.hasMailingAddress(this.locations[index])) {
          continue;
        }
        this.anyMailingAddresses = true;
        break;
      }
    }

    if (this.anyMailingAddresses) {
      if (this.$listMailingAddressLegend.classList.contains("hidden")) {
        this.$listMailingAddressLegend.classList.remove("hidden");
      }
    } else {
      if (!this.$listMailingAddressLegend.classList.contains("hidden")) {
        this.$listMailingAddressLegend.classList.add("hidden");
      }
    }

    this.$popupContent.style.transition = "none";
    this.$mapDiv.classList.remove(CLASSES.STYLED.POPUP_DETAIL_OPEN);
    if (!this.$el.classList.contains(CLASSES.STYLED.POPUP_OPEN)) {
      this.$el.classList.add(CLASSES.STYLED.POPUP_OPEN);
    }
    this.$loader.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE);

    // Each item is staggered with a 2.5% of it's distance from index 0.
    this.animateListElements.forEach((item, key) => {
      moveUp(item).forward(key * 0.025);
    });
    await fadeIn(this.$popupInner).forward(0);
    this.$popupContent.style.removeProperty("transition");
  }

  /**
   * @param {Object} result - Information pertaining to a location
   * @desc Set up the description view of the popup which is connected
   * to a location.
   */
  @autobind
  async setupDetailPopup(location: any) {
    this.unbindListElementEvents();
    await fadeOut(this.$popupInner).forward(0);
    this.$popupBackText.innerHTML = this.$config.dataset["backText"];
    this.$popupContent.style.transition = "none";
    this.$popupHeaderContainer.innerHTML = this.buildDetailHeader(location);
    this.$popupContent.innerHTML = this.buildDetailContent(location);
    if (this.mailingAddress) {
      this.showMailingAddress();
    } else {
      this.showStoreAddress();
    }

    if (!this.$listMailingAddressLegend.classList.contains("hidden")) {
      this.$listMailingAddressLegend.classList.add("hidden");
    }

    if (this.hasMailingAddress(location)) {
      if (this.$detailAddressTypeSection.classList.contains("hidden")) {
        this.$detailAddressTypeSection.classList.remove("hidden");
      }
    } else {
      if (!this.$detailAddressTypeSection.classList.contains("hidden")) {
        this.$detailAddressTypeSection.classList.add("hidden");
      }
    }

    this.$popup.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
    this.$mapDiv.classList.add(CLASSES.STYLED.POPUP_DETAIL_OPEN);
    if (isMobile()) {
      fadeInUp(this.$container).forward(0);
      this.$mapHolderDetail.appendChild(this.$container);
      this.$popup.scrollTop = 0;
      this.map.panTo(
        new google.maps.LatLng(location.latitude, location.longitude),
      );
    }
    await fadeInUp(this.$popupInner).forward(0);
    this.$popupContent.style.removeProperty("transition");
  }

  /**
   * @param {Event} e - mouseout mouseover event on list item
   * @desc Highlight or unhighlight a maker if a user hovers
   * the list in the information window.
   */
  @autobind
  hoverCorrespondingMarker(e: Event) {
    const tar = e.currentTarget as HTMLElement;
    const { placeId } = tar.dataset;
    const marker = this.pluckMarker(null, placeId);
    if (marker) {
      const iconType =
        e.type === "mouseover" ? marker.options.hovericon : marker.options.og;
      marker.ref.setIcon(iconType);
      this.panTo(marker.ref.position);
    }
  }

  /**
   * @param {google.maps.Marker} marker - Google map marker to find
   * @param {string} id - Fallback if marker isn't passed, using place_id
   * @desc Find marker based on place id (hidden under title)
   * @return {any}
   */
  pluckMarker(marker: google.maps.Marker, id?: string): any {
    const title = marker ? marker.getTitle() : id;
    const foundMarker = this.markers.find((item) => {
      return item.options.place_id === title;
    });
    return foundMarker;
  }

  /**
   * @desc Delay the onset of the google maps pan
   * to a location.
   */
  @debounce(300)
  panTo(loc: google.maps.LatLng) {
    let newLoc: any;
    if (window.outerWidth >= GLOBAL_CONSTANTS.BREAKPOINTS.MEDIUM) {
      newLoc = this.offsetCenter(loc, 200, 0);
    } else if (window.outerWidth >= GLOBAL_CONSTANTS.BREAKPOINTS.SMALL) {
      newLoc = this.offsetCenter(loc, 225, 0);
    } else {
      newLoc = this.offsetCenter(loc, 0, 0);
    }
    this.map.panTo(newLoc);
  }

  /**
   * @param {Object} latlng - lat / lng object
   * @param {Number} offsetx - Amount to offset x position
   * @param {Number} offsety - Amount to offset y position
   * @desc Calculate the position to set the center of the map based on
   * the offset of a location.
   */
  offsetCenter(
    latlng: google.maps.LatLng,
    offsetx: number,
    offsety: number,
  ): google.maps.LatLng {
    const scale = Math.pow(2, this.map.getZoom());

    const worldCoordinateCenter = this.map
      .getProjection()
      .fromLatLngToPoint(latlng);
    const pixelOffset = new this.google.maps.Point(
      offsetx / scale || 0,
      offsety / scale || 0,
    );

    const worldCoordinateNewCenter = new this.google.maps.Point(
      worldCoordinateCenter.x - pixelOffset.x,
      worldCoordinateCenter.y + pixelOffset.y,
    );

    const newCenter = this.map
      .getProjection()
      .fromPointToLatLng(worldCoordinateNewCenter);

    return newCenter;
  }

  @autobind
  buildMapAddress(location: any): string {
    return encodeURIComponent(
      `Umpqua Bank ${location.addressLine1} ${location.city} ${location.state} ${location.zip}`,
    );
  }

  @autobind
  buildHoursForToday(loc: any): any {
    const weekday = new Date().getDay();
    return this.buildHoursForDay(loc.hours[weekday]);
  }

  @autobind
  buildHoursForDay(day: any): any {
    return day.closed
      ? "Closed"
      : `${day.openHour || "[empty]"} - ${day.closedHour || "[empty]"}`;
  }

  @autobind
  buildDetailContent(location: any): string {
    return this.detailTemplate
      .replace("{{StoreHoursText}}", this.$config.dataset["storeHoursText"])
      .replace("{{FeaturesText}}", this.$config.dataset["featuresText"])
      .replace("{{dailyHours}}", this.buildDailyHoursList(location))
      .replace("{{features}}", this.buildFeatures(location));
  }

  @autobind
  buildDailyHoursList(location: any): string {
    let dailyHours = "";
    for (let i = 0; i < location.hours.length; i++) {
      const dailyHoursStr = this.buildHoursForDay(location.hours[i]);

      dailyHours += this.dailyHoursTemplate
        .replace("{{weekDay}}", weekDays[i])
        .replace("{{hours}}", dailyHoursStr);
    }
    return dailyHours;
  }

  @autobind
  buildFeatures(location: any): string {
    let features = "";
    features += location.atm
      ? this.featureTemplate.replace("{{name}}", "ATM")
      : "";
    features += location.driveUpWindow
      ? this.featureTemplate.replace("{{name}}", "Drive-up Window")
      : "";
    features += location.lobbyOpen
      ? this.featureTemplate.replace("{{name}}", "Lobby Open")
      : "";
    features += location.byAppointment
      ? this.featureTemplate.replace("{{name}}", "By Appointment")
      : "";
    features += location.freshCoffee
      ? this.featureTemplate.replace("{{name}}", "Fresh Coffee")
      : "";
    features += location.cookieFriday
      ? this.featureTemplate.replace("{{name}}", "Cookie Friday")
      : "";

    if (!this.isHoliday()) {
      features += location.openNow
        ? this.featureTemplate.replace("{{name}}", "Open Now")
        : "";
    }

    features += !location.hours[6].closed
      ? this.featureTemplate.replace("{{name}}", "Open Saturday")
      : "";
    return features;
  }

  @autobind
  buildListHeader(): string {
    return this.headerTemplate
      .replace("{{ResultText}}", this.$config.dataset["resultText"])
      .replace("{{address}}", this.addressEntered);
  }

  @autobind
  buildListContent(): string {
    let listItems = "";
    for (const location of this.locations) {
      listItems += this.buildListItem(location);
    }

    return this.listTemplate.replace("{{listItems}}", listItems);
  }

  @autobind
  buildListItem(location: any): string {
    const hasAppointmentUrl =
      location.appointmentUrl !== null && location.appointmentUrl.length > 0;
    const locationStoreNumber =
      location.storeNumber !== null ? location.storeNumber : this.getRandom();

    return this.listItemTemplate
      .replace("{{storeNumber}}", location.storeNumber)
      .replace(
        "{{addressLine1}}",
        location.addressLine1 && location.addressLine1.trim().length > 0
          ? location.addressLine1 + "<br />"
          : "",
      )
      .replace(
        "{{addressLine2}}",
        location.addressLine2 && location.addressLine2.trim().length > 0
          ? location.addressLine2 + "<br />"
          : "",
      )
      .replace("{{city}}", location.city)
      .replace("{{state}}", location.state)
      .replace("{{zip}}", location.zip)
      .replace("{{mapAddress}}", this.buildMapAddress(location))
      .replace(
        "{{hiddenMailingAddressAnchor}}",
        this.hasMailingAddress(location) ? "" : "hidden",
      )
      .replace("{{mailingAddressAnchorStoreNumber}}", location.storeNumber)
      .replace("{{listMailingAddressEnvelopeIdSuffix}}", locationStoreNumber)
      .replace(
        "{{GetDirectionsText}}",
        this.$config.dataset["getDirectionsText"],
      )
      .replace("{{listDirectionsIdSuffix}}", locationStoreNumber)
      .replace(
        "{{hours}}",
        (location.alert && location.alert.length > 0) || this.isHoliday()
          ? ""
          : this.buildHoursForToday(location),
      )
      .replace("{{alert}}", location.alert || "")
      .replace(
        "{{appointmentUrl}}",
        hasAppointmentUrl ? location.appointmentUrl : "",
      )
      .replace("{{appointmentUrlTitle}}", this.appointmentUrlTitle)
      .replace("{{listAppointmentIdSuffix}}", locationStoreNumber)
      .replace("{{hideAppointmentUrl}}", hasAppointmentUrl ? "" : "hidden")
      .replace("{{storePhoneNumber}}", location.phoneNumber)
      .replace("{{storePhoneNumberLink}}", location.phoneNumber)
      .replace("{{listStorePhoneNumberIdSuffix}}", locationStoreNumber)
      .replace("{{hideStorePhoneNumber}}", hasAppointmentUrl ? "hidden" : "");
  }

  @autobind
  buildDetailHeader(location: any): string {
    const detailHeader = this.mailingAddress
      ? this.buildDetailHeaderMailingAddress(location)
      : this.buildDetailHeaderPhysicalAddress(location);

    return detailHeader + "\r\n" + this.buildDetailHeaderAdditions(location);
  }

  @autobind
  buildDetailHeaderPhysicalAddress(location: any): string {
    const detailHeaderPhysicalAddress = this.detailHeaderAddressTemplate
      .toString()
      .replace("{{address}}", location)
      .replace(
        "{{addressLine1}}",
        location.addressLine1 ? location.addressLine1 + "<br />" : "",
      )
      .replace(
        "{{addressLine2}}",
        location.addressLine2 ? location.addressLine2 + "<br />" : "",
      )
      .replace("{{city}}", location.city)
      .replace("{{state}}", location.state)
      .replace("{{zip}}", location.zip);

    return detailHeaderPhysicalAddress;
  }

  @autobind
  buildDetailHeaderMailingAddress(location: any): string {
    const mailingAddress = this.detailHeaderAddressTemplate.toString();

    if (
      location.mailingAddressLine1 == null ||
      location.mailingAddressLine1.trim().length < 1
    ) {
      return mailingAddress
        .replace(
          "{{addressLine1}}",
          location.addressLine1 ? location.addressLine1 + "<br />" : "",
        )
        .replace(
          "{{addressLine2}}",
          location.addressLine2 ? location.addressLine2 + "<br />" : "",
        )
        .replace("{{city}}", location.city ? location.city : "")
        .replace("{{state}}", location.state ? location.state : "")
        .replace("{{zip}}", location.zip ? location.zip : "");
    }

    const city =
      location.mailingAddressCity == null ||
      location.mailingAddressCity.trim().length < 1
        ? location.City
        : location.mailingAddressCity;

    const state =
      location.mailingAddressState == null ||
      location.mailingAddressState.trim().length < 1
        ? location.State
        : location.mailingAddressState;

    const zip =
      location.mailingAddressZip == null ||
      location.mailingAddressZip.trim().length < 1
        ? location.Zip
        : location.mailingAddressZip;

    return mailingAddress
      .replace(
        "{{addressLine1}}",
        location.mailingAddressLine1
          ? location.mailingAddressLine1 + "<br />"
          : "",
      )
      .replace(
        "{{addressLine2}}",
        location.mailingAddressLine2
          ? location.mailingAddressLine2 + "<br />"
          : "",
      )
      .replace("{{city}}", city ? city : "")
      .replace("{{state}}", state ? state : "")
      .replace("{{zip}}", zip ? zip : "");
  }

  @autobind
  buildDetailHeaderAdditions(location: any): string {
    const hasAppointmentUrl =
      location.appointmentUrl !== null && location.appointmentUrl.length > 0;
    const locationStoreNumber =
      location.storeNumber !== null ? location.storeNumber : this.getRandom();

    return this.detailHeaderAdditionsTemplate
      .toString()
      .replace(
        "{{GetDirectionsText}}",
        this.$config.dataset["getDirectionsText"],
      )
      .replace("{{phone}}", location.phoneNumber)
      .replace("{{phoneLink}}", location.phoneNumber)
      .replace("{{storePhoneNumberDetailIdSuffix}}", locationStoreNumber)
      .replace("{{directionsDetailIdSuffix}}", locationStoreNumber)
      .replace("{{mapAddress}}", this.buildMapAddress(location))
      .replace("{{alert}}", location.alert || "")
      .replace("{{holidayAlert}}", this.holidayMessage || "")
      .replace(
        "{{appointmentUrl}}",
        hasAppointmentUrl ? location.appointmentUrl : "",
      )
      .replace("{{appointmentUrlTitle}}", this.appointmentUrlTitle)
      .replace("{{detailAppointmentIdSuffix}}", locationStoreNumber)
      .replace("{{hideAppointmentUrl}}", hasAppointmentUrl ? "" : "hidden");
  }

  @autobind
  showMailingOrStoreAddress(): void {
    if (this.$detailAddressSwitch.checked) {
      this.showMailingAddress();
    } else {
      this.showStoreAddress();
    }
  }

  @autobind
  showStoreAddress(): void {
    const location = this.detailLocation;
    this.gtmLocationDataLayer(
      location.storeNumber,
      location.addressLine1,
      location.addressLine1,
      location.addressLine2,
      location.city,
      location.state,
      location.zip,
      location.phoneNumber,
    );
    this.mailingAddress = false;
    this.$popupHeaderContainer.innerHTML = this.buildDetailHeader(location);
    this.$detailAddressSwitch.checked = false;
    this.$showStoreAddress.style.fontWeight = "bold";
    this.$showMailingAddress.style.fontWeight = "normal";
  }

  @autobind
  showMailingAddress(): void {
    const location = this.detailLocation;
    const mailingAddressLine1 =
      location.mailingAddressLine1 == null ||
      location.mailingAddressLine1.trim().length < 1
        ? location.addressLine1
        : location.mailingAddressLine1;
    const mailingAddressLine2 =
      location.mailingAddressLine2 == null ||
      location.mailingAddressLine2.trim().length < 1
        ? location.addressLine2
        : location.mailingAddressLine2;
    const city =
      location.mailingAddressCity == null ||
      location.mailingAddressCity.trim().length < 1
        ? location.City
        : location.mailingAddressCity;
    const state =
      location.mailingAddressState == null ||
      location.mailingAddressState.trim().length < 1
        ? location.State
        : location.mailingAddressState;
    const zip =
      location.mailingAddressZip == null ||
      location.mailingAddressZip.trim().length < 1
        ? location.Zip
        : location.mailingAddressZip;

    this.gtmLocationDataLayer(
      location.storeNumber,
      location.addressLine1,
      mailingAddressLine1,
      mailingAddressLine2,
      city,
      state,
      zip,
      location.phoneNumber,
    );
    this.mailingAddress = true;
    this.$popupHeaderContainer.innerHTML = this.buildDetailHeader(location);
    this.$detailAddressSwitch.checked = true;
    this.$showStoreAddress.style.fontWeight = "normal";
    this.$showMailingAddress.style.fontWeight = "bold";
  }

  @autobind
  hasMailingAddress(location: any): boolean {
    if (
      location.mailingAddressLine1 == null ||
      location.mailingAddressLine1.trim().length < 1
    ) {
      return false;
    }

    const city =
      location.mailingAddressCity == null ||
      location.mailingAddressCity.trim().length < 1
        ? location.City
        : location.mailingAddressCity;

    const state =
      location.mailingAddressState == null ||
      location.mailingAddressState.trim().length < 1
        ? location.State
        : location.mailingAddressState;

    const zip =
      location.mailingAddressZip == null ||
      location.mailingAddressZip.trim().length < 1
        ? location.Zip
        : location.mailingAddressZip;

    if (
      location.mailingAddressLine1 !== location.addressLine1 ||
      location.mailingAddressLine2 !== location.addressLine2 ||
      city !== location.city ||
      state !== location.state ||
      zip !== location.zip
    ) {
      return true;
    }

    return false;
  }

  isHoliday(): boolean {
    return this.holidayMessage && this.holidayMessage.length > 0;
  }

  /**
   * @desc Remove event listeners
   */
  tearDown(): void {
    this.unbindListElementEvents();
    this.$popupBack.removeEventListener("click", this.popupBack);
    this.$showStoreAddress.removeEventListener("click", this.showStoreAddress);
    this.$showMailingAddress.removeEventListener(
      "click",
      this.showMailingAddress,
    );
    this.$detailAddressSwitch.removeEventListener(
      "click",
      this.showMailingOrStoreAddress,
    );
    this.$filters.forEach((el) => {
      el.querySelector("input").removeEventListener(
        "change",
        this.toggleFilter,
      );
    });

    this.removeListMailingAddressAnchors();
  }

  getRandom(): string {
    return Math.floor(Math.random() * Math.random() * Date.now()).toString();
  }

  @autobind
  removeListMailingAddressAnchors(): void {
    const mailingAddressAnchors = this.$el.getElementsByClassName(
      CLASSES.LIST_ITEM_MAILING_ADDRESS_ANCHOR,
    );
    for (let i = 0; i < mailingAddressAnchors.length; i++) {
      const element = mailingAddressAnchors[i];
      if (!element.classList.contains("hidden")) {
        element.removeEventListener("click", this.showDetailMailingAddress);
      }
    }
  }
}
