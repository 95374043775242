import "../third-party/modernizr-custom";
import { autobind } from "core-decorators";
import { modules } from "./module-directory";
import { LazyLoader } from "../elements/lazy-loader";
import { Modal } from "../globals/modal";
import { EventBus } from "../globals/emitter";
import { GLOBAL_CONSTANTS } from "../globals/constants";
import { hasClipPath } from "../globals/functions";
import Paramalama from "paramalama";

interface IModuleInstance {
  name: string;
  ref: Array<any>;
}

/**
 * @desc Master class that 'controls' the flow
 * and instantiation of all modules.
 */
export class App {
  moduleInstances: Array<IModuleInstance>;
  lazy: LazyLoader;
  modal: Modal;
  fabricator: boolean;
  /**
   * @desc Load all possible modules if relevant and
   * assign them to an internal array for later use.
   */
  constructor() {
    setTimeout(() => {
      this.fabricator = document.body.classList[0] === "fabricator";

      if (!this.fabricator) {
        document.body.scrollTop = 0;
      }

      this.lazy = new LazyLoader();

      this.moduleInstances = this.getInstances();
      this.modal = new Modal(
        document.querySelector(".js-modal") as HTMLElement,
      );
      if (!hasClipPath()) {
        document.body.classList.add(GLOBAL_CONSTANTS.CLASSES.NO_CLIP);
      }

      this.bindEvents();
      const params = Paramalama(window.location.href);
      EventBus.fire(GLOBAL_CONSTANTS.EVENTS.PAGE_LOAD_COMPLETE);

      if (params.modal) {
        if (params.modalType === "map") {
          const location = params.location;

          EventBus.fire(GLOBAL_CONSTANTS.EVENTS.STEPPER.SKIP, "step-1");
          EventBus.fire(GLOBAL_CONSTANTS.EVENTS.MAP.LOAD_LOCATION, location);
        }
      }
    }, 0);
  }

  /**
   * @desc Create all instances of classes that are paired with
   * javascript components.
   * @return {Array<IModuleInstance>} Array of all module instances.
   */
  getInstances(): Array<IModuleInstance> {
    return modules.map((module) => {
      const elements: Array<HTMLElement> = Array.from(
        document.querySelectorAll(module.class) as NodeListOf<HTMLElement>,
      );
      const references = elements.map((el) => {
        return new module.source(el);
      });

      return {
        name: module.name,
        ref: references,
      };
    });
  }

  /**
   * @desc Bind global window events and an event to reload all components
   */
  bindEvents(): void {
    window.addEventListener("scroll", () => {
      EventBus.fire(GLOBAL_CONSTANTS.EVENTS.SCROLL);
    });
    window.addEventListener("resize", () => {
      EventBus.fire(GLOBAL_CONSTANTS.EVENTS.RESIZE);
    });
    window.addEventListener("orientationchange", () => {
      EventBus.fire(GLOBAL_CONSTANTS.EVENTS.ORIENTATION_CHANGE);
    });

    EventBus.on("refresh", this.refresh);
  }

  /**
   * @desc Tear down all event listeners and
   * refresh all components.
   */
  @autobind
  refresh(): void {
    this.tearDown();
    this.lazy.refreshImageArray();
    this.moduleInstances = this.getInstances();
  }

  /**
   * @desc Remove all event listeners for
   * all existing components
   */
  tearDown(): void {
    this.moduleInstances.forEach((item) => {
      item.ref.forEach((ref) => {
        if (ref.teardown) {
          ref.teardown();
        }
      });
    });
  }
}
