import { autobind } from "core-decorators";
import { EventBus } from "../globals/emitter";
import { GLOBAL_CONSTANTS } from "../globals/constants";

const classes = {
  featureTooltipModal: ".js-feature-tooltip-modal",
  featureTooltipModalContent: ".js-feature-tooltip-modal-content",
  featureTooltip: ".js-feature-tooltip",
  mobileFeatureValuesRowShowHideIcon:
    ".js-mobile-feature-values-show-hide-icon",
  mobileFeatureProductValueRow: ".js-mobile-feature-product-value-row",
  mobileFeatureValuesRowHidden: "mobile-feature-values-row-hidden",
  mobileFeatureValuesRowShown: "mobile-feature-values-row-shown",
  mobileFeatureProductValueRowShow: "mobile-feature-value-row-show",
  mobileFeatureProductValueRowHide: "mobile-feature-value-row-hide",
  mobileOpenCloseTitle: ".js-mobile-open-close-title",
  tableContainter: ".js-table-desktop-container",
  mobileContainter: ".js-mobile-desktop-container",
  circleArrowNext: ".js-circle-arrow-next",
  circleArrowPrevious: ".js-circle-arrow-previous",
  circleMobileArrowNext: ".js-circle-mobile-arrow-next",
  circleMobileArrowPrevious: ".js-circle-mobile-arrow-previous",
  mobileHeaderContainer: ".js-mobile-header-container",
  tooltipModalClose: ".js-tooltip-modal-close",
  tableHeader: ".js-table-header",
  stickyHeader: ".js-sticky-header",
  stickyArrowDesktopPrevious: ".js-sticky-arrow-desktop-previous",
  stickyArrowDesktopNext: ".js-sticky-arrow-desktop-next",
  stickyHeaderDesktop: ".js-sticky-header-desktop",
  stickyArrowMobilePrevious: ".js-sticky-arrow-mobile-previous",
  stickyArrowMobileNext: ".js-sticky-arrow-mobile-next",
  stickyHeaderMobile: ".js-sticky-header-mobile",
};

const events = {
  click: "click",
};
const constants = {
  time: 300,
  mobileMargins: 51,
};

/**
 */
export class ProductComparisonTable {
  $productComparisonTable: HTMLDivElement;
  $featureTooltipModal: HTMLDivElement;
  $featureTooltipModalContent: HTMLDivElement;
  $featureTooltips: NodeListOf<Element>;
  $mobileFeatureValuesRowShowHideIcon: NodeListOf<Element>;
  $mobileFeatureProductValueRows: NodeListOf<HTMLElement>;
  $mobileOpenCloseTitle: NodeListOf<Element>;
  $tableContainter: HTMLElement;
  $mobileContainter: HTMLElement;
  $tooltipModalClose: HTMLElement;
  $mobileHeaderContainer: HTMLElement;
  $circleArrowNext: HTMLButtonElement;
  $circleArrowPrevious: HTMLButtonElement;
  $circleMobileArrowNext: HTMLButtonElement;
  $circleMobileArrowPrevious: HTMLButtonElement;
  $productCount: number;
  $featureCount: number;
  $productCurrent: number;
  $productMobileCount: number;
  $productMobileCurrent: number;
  $tableHeader: HTMLElement;
  $stickyHeader: HTMLElement;
  $stickyArrowDesktopPrevious: HTMLButtonElement;
  $stickyArrowDesktopNext: HTMLButtonElement;
  $stickyHeaderDesktop: HTMLElement;
  moveSkickyHeader = true;
  $stickyArrowMobilePrevious: HTMLButtonElement;
  $stickyArrowMobileNext: HTMLButtonElement;
  $stickyHeaderMobile: HTMLElement;

  /**
   * constructor - Set element to class, bind events.
   * @param  {HTMLElement} element - Table parent element.
   */
  constructor(element: HTMLDivElement) {
    this.$productComparisonTable = element;
    if (this.$productComparisonTable) {
      this.$featureTooltipModal = this.$productComparisonTable.querySelector(
        classes.featureTooltipModal,
      ) as HTMLDivElement;
      this.$featureTooltips = this.$productComparisonTable.querySelectorAll(
        classes.featureTooltip,
      );
      if (this.$featureTooltipModal) {
        this.$featureTooltipModalContent =
          this.$featureTooltipModal.querySelector(
            classes.featureTooltipModalContent,
          ) as HTMLDivElement;
      }
      this.$mobileFeatureValuesRowShowHideIcon =
        this.$productComparisonTable.querySelectorAll(
          classes.mobileFeatureValuesRowShowHideIcon,
        );
      this.$mobileFeatureProductValueRows =
        this.$productComparisonTable.querySelectorAll(
          classes.mobileFeatureProductValueRow,
        );
      this.$mobileOpenCloseTitle =
        this.$productComparisonTable.querySelectorAll(
          classes.mobileOpenCloseTitle,
        );
      this.$tableContainter = this.$productComparisonTable.querySelector(
        classes.tableContainter,
      ) as HTMLElement;
      this.$mobileContainter = this.$productComparisonTable.querySelector(
        classes.mobileContainter,
      ) as HTMLElement;
      this.$mobileHeaderContainer = this.$productComparisonTable.querySelector(
        classes.mobileHeaderContainer,
      ) as HTMLElement;
      this.$circleArrowNext = this.$productComparisonTable.querySelector(
        classes.circleArrowNext,
      ) as HTMLButtonElement;
      this.$circleArrowPrevious = this.$productComparisonTable.querySelector(
        classes.circleArrowPrevious,
      ) as HTMLButtonElement;
      this.$circleMobileArrowNext = this.$productComparisonTable.querySelector(
        classes.circleMobileArrowNext,
      ) as HTMLButtonElement;
      this.$circleMobileArrowPrevious =
        this.$productComparisonTable.querySelector(
          classes.circleMobileArrowPrevious,
        ) as HTMLButtonElement;
      this.$stickyArrowDesktopNext = this.$productComparisonTable.querySelector(
        classes.stickyArrowDesktopNext,
      ) as HTMLButtonElement;
      this.$stickyArrowDesktopPrevious =
        this.$productComparisonTable.querySelector(
          classes.stickyArrowDesktopPrevious,
        ) as HTMLButtonElement;
      this.$productCount = parseInt(
        this.$productComparisonTable.getAttribute("data-products"),
        10,
      );
      this.$featureCount = parseInt(
        this.$productComparisonTable.getAttribute("data-features"),
        10,
      );
      this.$productCurrent = 1;
      this.$productMobileCurrent = 1;
      this.$tooltipModalClose = this.$productComparisonTable.querySelector(
        classes.tooltipModalClose,
      ) as HTMLElement;
      this.$tableHeader = this.$productComparisonTable.querySelector(
        classes.tableHeader,
      ) as HTMLElement;
      this.$stickyHeader = this.$productComparisonTable.querySelector(
        classes.stickyHeader,
      ) as HTMLElement;
      this.$stickyHeaderDesktop = this.$productComparisonTable.querySelector(
        classes.stickyHeaderDesktop,
      ) as HTMLElement;
      this.$stickyArrowMobilePrevious =
        this.$productComparisonTable.querySelector(
          classes.stickyArrowMobilePrevious,
        ) as HTMLButtonElement;
      this.$stickyArrowMobileNext = this.$productComparisonTable.querySelector(
        classes.stickyArrowMobileNext,
      ) as HTMLButtonElement;
      this.$stickyHeaderMobile = this.$productComparisonTable.querySelector(
        classes.stickyHeaderMobile,
      ) as HTMLElement;
    }
    this.bindEvents();
  }

  /**
   */
  bindEvents() {
    if (this.$productComparisonTable) {
      for (let index = 0; index < this.$featureTooltips.length; index++) {
        const featureTooltip = this.$featureTooltips[index];
        featureTooltip.addEventListener(events.click, this.showFeatureTooltip);
      }
      this.$tooltipModalClose.addEventListener(
        events.click,
        this.hideFeatureTooltip,
      );
      this.$featureTooltipModal.addEventListener(
        events.click,
        this.hideFeatureTooltip,
      );
      for (let index = 0; index < this.$mobileOpenCloseTitle.length; index++) {
        const $featureValuesRowShowHide = this.$mobileOpenCloseTitle[index];
        $featureValuesRowShowHide.addEventListener(
          events.click,
          this.manageMobileFeatureValuesRow,
        );
      }
      if (this.$productCount > 3) {
        this.$circleArrowNext.addEventListener(
          events.click,
          this.advanceColumns,
        );
        this.$circleArrowPrevious.addEventListener(
          events.click,
          this.recedeColumns,
        );
        this.$circleMobileArrowNext.addEventListener(
          events.click,
          this.advanceMobileColumns,
        );
        this.$circleMobileArrowPrevious.addEventListener(
          events.click,
          this.recedeMobileColumns,
        );
        this.$stickyArrowDesktopNext.addEventListener(
          events.click,
          this.advanceColumns,
        );
        this.$stickyArrowDesktopPrevious.addEventListener(
          events.click,
          this.recedeColumns,
        );
        this.$stickyArrowMobileNext.addEventListener(
          events.click,
          this.advanceMobileColumns,
        );
        this.$stickyArrowMobilePrevious.addEventListener(
          events.click,
          this.recedeMobileColumns,
        );
      } else {
        this.$circleArrowNext.style.display = "none";
        this.$circleArrowPrevious.style.display = "none";
        this.$circleMobileArrowNext.style.display = "none";
        this.$circleMobileArrowPrevious.style.display = "none";
      }
    }
    // const cellHeaderWidth = this.$mobileHeaderContainer.querySelectorAll('.header-product')[0].clientWidth;
    // const totalWidth = cellHeaderWidth * this.$productCount + constants.mobileMargins;
    // for (let i = 0; i < this.$featureCount; i++) {
    // const cellParent = this.$mobileFeatureProductValueRows[i];
    // cellParent.style.width = totalWidth + 'px';
    // }
    EventBus.on(GLOBAL_CONSTANTS.EVENTS.SCROLL, this.headerRepositionOnScroll);
  }

  /*
   */
  @autobind
  headerRepositionOnScroll() {
    const inView = this.isScrolledIntoView();
    if (inView) {
      this.$tableHeader.classList.add("in-scroll-window");
      if (this.moveSkickyHeader) {
        document.body.prepend(this.$stickyHeader);
        this.moveSkickyHeader = false;
      }
      this.$stickyHeader.classList.add("show-header");
    } else {
      this.$tableHeader.classList.remove("in-scroll-window");
      this.$stickyHeader.classList.remove("show-header");
    }
  }

  isScrolledIntoView() {
    const rect = this.$productComparisonTable.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom;
    const scrollDistance = this.$tableContainter.scrollLeft;
    const scrollDistanceMobile = this.$mobileContainter.scrollLeft;
    this.$stickyHeaderDesktop.scrollLeft = scrollDistance;
    this.$stickyHeaderMobile.scrollLeft = scrollDistanceMobile;
    return elemTop < 0 && elemBottom > 0;
  }

  @autobind
  showFeatureTooltip(event: Event) {
    const element = event.currentTarget as HTMLDivElement;
    this.$featureTooltipModalContent.innerHTML = element.dataset["tooltip"];
    this.$featureTooltipModal.style.visibility = "visible";
    document.body.prepend(this.$featureTooltipModal);
  }
  @autobind
  hideFeatureTooltip(event: Event) {
    const element = event.currentTarget as HTMLDivElement;
    this.$featureTooltipModalContent.innerHTML = element.dataset["tooltip"];
    this.$featureTooltipModal.style.visibility = "hidden";
  }

  /**
   */
  @autobind
  manageMobileFeatureValuesRow(event: Event) {
    const mobileShowHideTitle = event.currentTarget as HTMLElement;
    const mobileShowHideIcon = mobileShowHideTitle.querySelector(
      classes.mobileFeatureValuesRowShowHideIcon,
    ) as HTMLElement;
    if (
      !mobileShowHideIcon ||
      !mobileShowHideIcon.id ||
      mobileShowHideIcon.id.length < 1
    ) {
      return;
    }
    const mobileFeatureRowIndex = this.getMobileFeatureRowIndex(
      mobileShowHideIcon.id,
    );
    if (mobileFeatureRowIndex < 0) {
      return;
    }
    const mobileFeatureProductValueRow = this.$mobileFeatureProductValueRows[
      mobileFeatureRowIndex
    ] as HTMLDivElement;
    if (!mobileFeatureProductValueRow) {
      return;
    }

    if (
      mobileShowHideIcon.classList.contains(
        classes.mobileFeatureValuesRowHidden,
      )
    ) {
      mobileShowHideIcon.classList.remove(classes.mobileFeatureValuesRowHidden);
      mobileShowHideIcon.classList.add(classes.mobileFeatureValuesRowShown);
      mobileFeatureProductValueRow.classList.remove(
        classes.mobileFeatureProductValueRowHide,
      );
      mobileFeatureProductValueRow.classList.add(
        classes.mobileFeatureProductValueRowShow,
      );
    } else {
      mobileShowHideIcon.classList.remove(classes.mobileFeatureValuesRowShown);
      mobileShowHideIcon.classList.add(classes.mobileFeatureValuesRowHidden);
      mobileFeatureProductValueRow.classList.remove(
        classes.mobileFeatureProductValueRowShow,
      );
      mobileFeatureProductValueRow.classList.add(
        classes.mobileFeatureProductValueRowHide,
      );
    }
  }

  /**
   */
  @autobind
  getMobileFeatureRowIndex(mobileFeatureRowId: string): number {
    if (!mobileFeatureRowId) {
      return -1;
    }
    const rowIdIndex = mobileFeatureRowId.lastIndexOf("-");
    if (rowIdIndex < mobileFeatureRowId.length) {
      const rowIndex = mobileFeatureRowId.substring(rowIdIndex + 1);
      const rowIndexNumber = parseInt(rowIndex, 10);

      if (isNaN(rowIndexNumber)) {
        return -1;
      } else {
        if (rowIndexNumber < this.$mobileFeatureProductValueRows.length) {
          return rowIndexNumber;
        } else {
          return -1;
        }
      }
    }
  }
  @autobind
  advanceColumns(): void {
    const cellWidth = this.$tableContainter
      .querySelectorAll("tr")[0]
      .querySelectorAll("th")[1].offsetWidth;
    if (this.$productCurrent < this.$productCount) {
      const makeVisibleTarget = this.$productCurrent + 3;
      const makeHiddenTarget = this.$productCurrent;
      // DESKTOP HEADER
      this.$tableContainter.scrollBy({
        left: cellWidth,
        top: 0,
        behavior: "smooth",
      });
      this.$circleArrowPrevious.disabled = true;
      this.$circleArrowNext.disabled = true;
      const desktopMakeVisible = this.$tableContainter.querySelector(
        ".desktop-" + makeVisibleTarget,
      );
      const desktopMakeHidden = this.$tableContainter.querySelector(
        ".desktop-" + makeHiddenTarget,
      );
      const desktopNoBorder = this.$tableContainter.querySelector(".no-border");
      this.columnClassChange(
        true,
        desktopMakeVisible,
        desktopMakeHidden,
        desktopNoBorder,
      );

      // STICKY DESKTOP HEADER
      this.$stickyHeaderDesktop.scrollBy({
        left: cellWidth,
        top: 0,
        behavior: "smooth",
      });
      this.$stickyArrowDesktopPrevious.disabled = true;
      this.$stickyArrowDesktopNext.disabled = true;
      const stickyDesktopMakeVisible = this.$stickyHeaderDesktop.querySelector(
        ".sticky-desktop-" + makeVisibleTarget,
      );
      const stickyDesktopMakeHidden = this.$stickyHeaderDesktop.querySelector(
        ".sticky-desktop-" + makeHiddenTarget,
      );
      const stickyDesktopNoBorder =
        this.$stickyHeaderDesktop.querySelector(".no-border");
      this.columnClassChange(
        true,
        stickyDesktopMakeVisible,
        stickyDesktopMakeHidden,
        stickyDesktopNoBorder,
      );

      // DESKTOP BODY
      const desktopBodyMakeVisible = this.$tableContainter.querySelectorAll(
        ".desktop-body-" + makeVisibleTarget,
      );
      const desktopBodyMakeHidden = this.$tableContainter.querySelectorAll(
        ".desktop-body-" + makeHiddenTarget,
      );

      for (let i = 0; i < this.$featureCount; i++) {
        this.columnClassChange(
          true,
          desktopBodyMakeVisible[i],
          desktopBodyMakeHidden[i],
          desktopBodyMakeHidden[i],
        );
      }
      const myTimeout = setTimeout(this.resetadvancingButtons, constants.time);
      this.$productCurrent++;
      console.log(myTimeout);
    }
  }
  @autobind
  recedeColumns(): void {
    const cellWidth = this.$tableContainter
      .querySelectorAll("tr")[0]
      .querySelectorAll("th")[1].offsetWidth;
    if (this.$productCurrent > 1) {
      this.$productCurrent--;
      const makeVisibleTarget = this.$productCurrent;
      const makeHiddenTarget = this.$productCurrent + 3;

      // DESKTOP HEADER
      this.$tableContainter.scrollBy({
        left: -cellWidth,
        top: 0,
        behavior: "smooth",
      });
      this.$stickyHeaderDesktop.scrollBy({
        left: -cellWidth,
        top: 0,
        behavior: "smooth",
      });
      this.$circleArrowPrevious.disabled = true;
      this.$circleArrowNext.disabled = true;
      const desktopMakeHidden = this.$tableContainter.querySelector(
        ".desktop-" + makeHiddenTarget,
      );
      const desktopMakeVisible = this.$tableContainter.querySelector(
        ".desktop-" + makeVisibleTarget,
      );
      const desktopNoBorder = this.$tableContainter.querySelector(".no-border");
      this.columnClassChange(
        false,
        desktopMakeVisible,
        desktopMakeHidden,
        desktopNoBorder,
      );

      // STICKY DESKTOP HEADER
      this.$stickyArrowDesktopPrevious.disabled = true;
      this.$stickyArrowDesktopNext.disabled = true;
      const stickyDesktopMakeHidden = this.$stickyHeader.querySelector(
        ".sticky-desktop-" + makeHiddenTarget,
      );
      const stickyDesktopMakeVisible = this.$stickyHeader.querySelector(
        ".sticky-desktop-" + makeVisibleTarget,
      );
      const stickyDesktopNoBorder =
        this.$stickyHeader.querySelector(".no-border");
      this.columnClassChange(
        false,
        stickyDesktopMakeVisible,
        stickyDesktopMakeHidden,
        stickyDesktopNoBorder,
      );

      // DESKTOP BODY
      const noBorderTarget = this.$productCurrent + 1;
      const desktopBodyMakeHidden = this.$tableContainter.querySelectorAll(
        ".desktop-body-" + makeHiddenTarget,
      );
      const desktopBodyMakeVisible = this.$tableContainter.querySelectorAll(
        ".desktop-body-" + makeVisibleTarget,
      );
      const desktopNoBorderTarget = this.$tableContainter.querySelectorAll(
        ".desktop-body-" + noBorderTarget,
      );

      for (let i = 0; i < this.$featureCount; i++) {
        this.columnClassChange(
          false,
          desktopBodyMakeVisible[i],
          desktopBodyMakeHidden[i],
          desktopNoBorderTarget[i],
        );
      }
      const myTimeout = setTimeout(this.resetrecedingButtons, constants.time);
      console.log(myTimeout);
    }
  }
  @autobind
  resetadvancingButtons(): void {
    this.$circleArrowPrevious.disabled = false;
    this.$stickyArrowDesktopPrevious.disabled = false;
    if (this.$productCurrent !== this.$productCount - 2) {
      this.$circleArrowNext.disabled = false;
      this.$stickyArrowDesktopNext.disabled = false;
    }
  }
  @autobind
  resetrecedingButtons(): void {
    this.$circleArrowNext.disabled = false;
    this.$stickyArrowDesktopNext.disabled = false;
    if (this.$productCurrent !== 1) {
      this.$circleArrowPrevious.disabled = false;
      this.$stickyArrowDesktopPrevious.disabled = false;
    }
  }
  @autobind
  advanceMobileColumns(): void {
    const cellHeaderWidth =
      this.$mobileHeaderContainer.querySelectorAll(".header-product")[0]
        .clientWidth;
    // const totalWidth = cellHeaderWidth * this.$productCount + constants.mobileMargins;
    if (this.$productMobileCurrent < this.$productCount) {
      const makeVisibleTarget = this.$productMobileCurrent + 3;
      const makeHiddenTarget = this.$productMobileCurrent;

      // MOBILE HEADER
      this.$mobileContainter.scrollBy({
        left: cellHeaderWidth,
        top: 0,
        behavior: "smooth",
      });
      this.$mobileHeaderContainer.scrollBy({
        left: cellHeaderWidth,
        top: 0,
        behavior: "smooth",
      });
      this.$circleMobileArrowPrevious.disabled = true;
      this.$circleMobileArrowNext.disabled = true;
      const mobileMakeVisible = this.$mobileHeaderContainer.querySelector(
        ".mobile-" + makeVisibleTarget,
      );
      const mobileMakeHidden = this.$mobileHeaderContainer.querySelector(
        ".mobile-" + makeHiddenTarget,
      );
      const mobileNoBorder = mobileMakeHidden;
      this.columnClassChange(
        true,
        mobileMakeVisible,
        mobileMakeHidden,
        mobileNoBorder,
      );

      // STICKY MOBILE HEADER
      this.$stickyHeaderMobile.scrollBy({
        left: cellHeaderWidth,
        top: 0,
        behavior: "smooth",
      });
      this.$stickyArrowMobilePrevious.disabled = true;
      this.$stickyArrowMobileNext.disabled = true;
      const stickyMobileMakeVisible = this.$stickyHeaderMobile.querySelector(
        ".sticky-mobile-" + makeVisibleTarget,
      );
      const stickyMobileMakeHidden = this.$stickyHeaderMobile.querySelector(
        ".sticky-mobile-" + makeHiddenTarget,
      );
      const stickyMobileNoBorder = stickyMobileMakeHidden;
      this.columnClassChange(
        true,
        stickyMobileMakeVisible,
        stickyMobileMakeHidden,
        stickyMobileNoBorder,
      );

      // MOBILE BODY
      const mobileBodyMakeVisible = this.$mobileContainter.querySelectorAll(
        ".mobile-body-" + makeVisibleTarget,
      );
      const mobileBodyMakeHidden = this.$mobileContainter.querySelectorAll(
        ".mobile-body-" + makeHiddenTarget,
      );

      for (let i = 0; i < this.$featureCount; i++) {
        // const cellParent = this.$mobileFeatureProductValueRows[i];
        // cellParent.style.width = totalWidth + 'px';
        this.columnClassChange(
          true,
          mobileBodyMakeVisible[i],
          mobileBodyMakeHidden[i],
          mobileBodyMakeHidden[i],
        );
      }

      const myTimeout = setTimeout(
        this.resetadvancingMobileButtons,
        constants.time,
      );
      this.$productMobileCurrent++;
      console.log(myTimeout);
    }
  }
  @autobind
  recedeMobileColumns(): void {
    const cellHeaderWidth =
      this.$mobileHeaderContainer.querySelectorAll(".header-product")[0]
        .clientWidth;
    // const totalWidth = cellHeaderWidth * this.$productCount + constants.mobileMargins;
    if (this.$productMobileCurrent > 1) {
      this.$productMobileCurrent--;
      const makeVisibleTarget = this.$productMobileCurrent;
      const makeHiddenTarget = this.$productMobileCurrent + 3;
      // MOBILE HEADER
      this.$mobileContainter.scrollBy({
        left: -cellHeaderWidth,
        top: 0,
        behavior: "smooth",
      });
      this.$mobileHeaderContainer.scrollBy({
        left: -cellHeaderWidth,
        top: 0,
        behavior: "smooth",
      });
      this.$circleMobileArrowPrevious.disabled = true;
      this.$circleMobileArrowNext.disabled = true;
      const mobileMakeVisible = this.$mobileHeaderContainer.querySelector(
        ".mobile-" + makeVisibleTarget,
      );
      const mobileMakeHidden = this.$mobileHeaderContainer.querySelector(
        ".mobile-" + makeHiddenTarget,
      );
      const mobileNoBorder =
        this.$mobileHeaderContainer.querySelector(".no-border");
      this.columnClassChange(
        false,
        mobileMakeVisible,
        mobileMakeHidden,
        mobileNoBorder,
      );

      // STICKY MOBILE HEADER
      this.$stickyArrowMobilePrevious.disabled = true;
      this.$stickyArrowMobileNext.disabled = true;
      this.$stickyHeaderMobile.scrollBy({
        left: -cellHeaderWidth,
        top: 0,
        behavior: "smooth",
      });
      const stickyMobileMakeVisible = this.$stickyHeaderMobile.querySelector(
        ".sticky-mobile-" + makeVisibleTarget,
      );
      const stickyMobileMakeHidden = this.$stickyHeaderMobile.querySelector(
        ".sticky-mobile-" + makeHiddenTarget,
      );
      const stickyMobileNoBorder =
        this.$stickyHeaderMobile.querySelector(".no-border");
      this.columnClassChange(
        false,
        stickyMobileMakeVisible,
        stickyMobileMakeHidden,
        stickyMobileNoBorder,
      );

      // MOBILE BODY
      const noBorderTarget = this.$productMobileCurrent + 1;
      const mobileBodyMakeVisible = this.$mobileContainter.querySelectorAll(
        ".mobile-body-" + makeVisibleTarget,
      );
      const mobileBodyMakeHidden = this.$mobileContainter.querySelectorAll(
        ".mobile-body-" + makeHiddenTarget,
      );
      const mobileBodyNoBorderTarget = this.$mobileContainter.querySelectorAll(
        ".mobile-body-" + noBorderTarget,
      );

      for (let i = 0; i < this.$featureCount; i++) {
        // const cellParent = this.$mobileFeatureProductValueRows[i];
        // cellParent.style.width = totalWidth + 'px';
        this.columnClassChange(
          false,
          mobileBodyMakeVisible[i],
          mobileBodyMakeHidden[i],
          mobileBodyNoBorderTarget[i],
        );
      }
      const myTimeout = setTimeout(
        this.resetrecedingMobileButtons,
        constants.time,
      );
      console.log(myTimeout);
    }
  }
  @autobind
  resetadvancingMobileButtons(): void {
    this.$circleMobileArrowPrevious.disabled = false;
    this.$stickyArrowMobilePrevious.disabled = false;
    if (this.$productMobileCurrent !== this.$productCount - 2) {
      this.$circleMobileArrowNext.disabled = false;
      this.$stickyArrowMobileNext.disabled = false;
    }
  }
  @autobind
  resetrecedingMobileButtons(): void {
    this.$circleMobileArrowNext.disabled = false;
    this.$stickyArrowMobileNext.disabled = false;
    if (this.$productMobileCurrent !== 1) {
      this.$circleMobileArrowPrevious.disabled = false;
      this.$stickyArrowMobilePrevious.disabled = false;
    }
  }
  @autobind
  columnClassChange(
    direction: boolean,
    objToSee: Element,
    objToHide: Element,
    objAddBorder: Element,
  ): void {
    const see = "prod-visible";
    const hide = "prod-hidden";
    const noBorder = "no-border";
    if (objToSee) {
      objToSee.classList.add(see);
      objToSee.classList.remove(hide);
    } else {
      console.log("no obj to see");
    }
    if (objToHide) {
      objToHide.classList.add(hide);
      objToHide.classList.remove(see);
    } else {
      console.log("no obj to hide");
    }
    if (objAddBorder) {
      objAddBorder.classList.remove(noBorder);
    } else {
      console.log("no obj to add border");
    }
    if (direction) {
      if (objAddBorder.nextElementSibling) {
        objAddBorder.nextElementSibling.classList.add(noBorder);
      } else {
        console.log("no next sibling");
      }
    } else {
      if (objAddBorder.previousElementSibling) {
        objAddBorder.previousElementSibling.classList.add(noBorder);
      } else {
        console.log("no previous sibling");
      }
    }
  }
}
