import { autobind } from "core-decorators";
import {
  setCookie,
  getCookie,
  deleteCookie,
  setOneDayCookie,
} from "../globals/functions";

const CLASSES = {
  LO_APP: ".js-login-app",
  LO_CLOSE: ".js-lo-close",
};

export class LoanOfficerCTA {
  $el: HTMLElement;
  $close: NodeListOf<HTMLElement>;
  $startApp: NodeListOf<HTMLElement>;

  constructor(el: HTMLElement) {
    this.$el = el;
    this.$startApp = this.$el.querySelectorAll(
      CLASSES.LO_APP,
    ) as NodeListOf<HTMLElement>;
    this.$close = this.$el.querySelectorAll(
      CLASSES.LO_CLOSE,
    ) as NodeListOf<HTMLElement>;
    this.bindEvents();
  }

  @autobind
  bindEvents(): void {
    for (const start of this.$startApp) {
      start.addEventListener("click", this.setStartAppCookie);
    }
    for (const close of this.$close) {
      close.addEventListener("click", this.createCounterCookie);
    }
  }

  @autobind
  createCounterCookie(): void {
    let newCount = 0;
    const oldCount =
      getCookie("close_count") !== ""
        ? parseInt(getCookie("close_count"), 10)
        : 0;
    newCount = oldCount + 1;
    setCookie("close_count", newCount.toString());
    setOneDayCookie("closed", "1");
    if (newCount > 2) {
      setCookie("closed", "1");
      deleteCookie("close_count");
    }
    this.$el.style.display = "none";
  }

  @autobind
  setStartAppCookie(): void {
    alert("test cookie");
    setCookie("startapp", "1");
  }
}
