import { autobind } from "core-decorators";
import { GLOBAL_CONSTANTS } from "../globals/constants";
import { EventBus } from "../globals/emitter";

const CLASSES = {
  ICON: ".icon--search",
};

/**
 * Filter - class that will hide / show content based on selected filter types
 */
export class InlineSearchBar {
  $el: HTMLElement;
  $icon: HTMLElement;
  $input: HTMLInputElement;

  /**
   * @desc constructor - grab relevant DOM elements and kickoff element bind
   * @param {HTMLElement} el - Containing element for the filter
   */
  constructor(el: HTMLElement) {
    this.$el = el;
    this.$icon = this.$el.querySelector(CLASSES.ICON) as HTMLElement;
    this.$input = this.$el.querySelector("input") as HTMLInputElement;

    this.bindEvents();
  }

  bindEvents() {
    this.$input.addEventListener("keypress", this.handleKeyPress);
    this.$icon.addEventListener("click", this.handleIconClick);
  }

  @autobind
  submit() {
    const value = this.$input.value;

    EventBus.fire(GLOBAL_CONSTANTS.EVENTS.SEARCH.POPULATE, value);
    EventBus.fire("search-trigger");
  }

  @autobind
  handleKeyPress(e: KeyboardEvent) {
    const key = e.keyCode;

    if (key === 13) {
      this.submit();
    }
  }

  @autobind
  handleIconClick() {
    this.submit();
  }

  @autobind
  tearDown() {
    this.$input.removeEventListener("keypress", this.handleKeyPress);
    this.$icon.removeEventListener("click", this.handleIconClick);
  }
}
