import { GLOBAL_CONSTANTS } from "./constants";
import axios from "axios";

export function numWithCommas(num: number | string): string {
  const r = /\B(?=(\d{3}){0,5}(?!\d))/g;
  return num.toString().replace(r, ",");
}

export function dollarize(num: number | string): string {
  return `$${numWithCommas(num)}`;
}

export function numberize(num: number | string): number {
  if (typeof num === "string") {
    if (num === "") {
      num = 0;
    } else {
      num = parseInt(num.replace(/[$,]/g, ""), 10);
    }
  }
  return num;
}

export function seconds(num: number): string {
  const minutes = Math.floor(num / 60);
  const seconds = num - minutes * 60;
  const printSeconds =
    seconds < 10 ? "0" + Math.floor(seconds) : Math.floor(seconds);

  return `${Math.floor(minutes)}:${printSeconds}`;
}

export function isMobile(): boolean {
  return window.innerWidth <= GLOBAL_CONSTANTS.BREAKPOINTS.SMALL;
}

export function isTablet(): boolean {
  return (
    window.innerWidth > GLOBAL_CONSTANTS.BREAKPOINTS.SMALL &&
    window.innerWidth <= GLOBAL_CONSTANTS.BREAKPOINTS.MEDIUM
  );
}

export function hasClipPath(): boolean {
  const base = "clipPath";
  const prefixes = ["webkit", "moz", "ms", "o"];
  const properties = [base];
  const testElement = document.createElement("testelement");
  const attribute = "polygon(50% 0%, 0% 100%, 100% 100%)";
  let value = false;

  prefixes.forEach((item) => {
    const prefixedProperty =
      item + base.charAt(0).toUpperCase() + base.slice(1);
    properties.push(prefixedProperty);
  });

  properties.forEach((item) => {
    if ((testElement.style as any)[item] === "") {
      (testElement.style as any)[item] = attribute;
      if ((testElement.style as any)[item] !== "") {
        value = true;
      }
    }
  });

  return value;
}

export function checkStatus(response: any): any {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    console.log(response);
    throw new Error(`Status: ${response.statusText} Response: ${response}`);
  }
}

// **
// ***   Validation functions
// **
export function validateEmail(email: string): boolean {
  const regx =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regx.test(email);
}

// **
// ***   Security functions
// **
export function appendAntiForgeryToken(formData: FormData): void {
  const tokenInput = document.querySelector(
    "#__AjaxAntiForgery input[name=__RequestVerificationToken]",
  ) as HTMLInputElement;
  if (tokenInput) {
    formData.append("__RequestVerificationToken", tokenInput.value);
  }

  return null;
}

// **
// ***   Cookie functions
// **
export function deleteCookie(name: string) {
  document.cookie =
    name + "=" + ";expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

export function setSessionCookie(name: string, value: string) {
  document.cookie = name + "=" + value + ";";
}

export function setCookie(name: string, value: string) {
  const d = new Date(9999, 1, 1);
  const expires = "expires=" + d.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

export function setOneDayCookie(name: string, value: string) {
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  const expires = "expires=" + tomorrow.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

export function getCookie(cname: string): string {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

// **
// ***   YouTube functions
// **
let youTubeApiLoaded = "unloaded";
let loadingPromise: Promise<Object>;

export function loadYouTubeApi(): Promise<any> {
  // Check if the YouTube iframe API is loaded already.
  if ((window as any).YT) {
    youTubeApiLoaded = "loaded";
    return Promise.resolve();
  }

  if (youTubeApiLoaded === "loaded") {
    return Promise.resolve();
  }

  if (youTubeApiLoaded === "loading") {
    return loadingPromise;
  }

  youTubeApiLoaded = "loading";

  // Timeout if not loaded.
  setTimeout(() => {
    if (youTubeApiLoaded !== "loaded") {
      youTubeApiLoaded = "unloaded";
    }
  }, 60000);

  loadingPromise = new Promise((resolve): void => {
    (window as any).onYouTubeIframeAPIReady = () => {
      youTubeApiLoaded = "loaded";
      resolve(0);
    };

    const videoTag = document.createElement("script");
    videoTag.src = "https://www.youtube.com/iframe_api";

    const firstScriptTag = document.querySelector("script");
    firstScriptTag.parentNode.insertBefore(videoTag, firstScriptTag);
  });

  return loadingPromise;
}

// **
// ***   Data functions
// **
function api(url: string, targetName: string, data: any) {
  return axios({
    url: `${GLOBAL_CONSTANTS.SERVER_URL}/${url}`,
    method: "POST",
    headers: {
      "Content-Type": "Application/json",
    },
    data: {
      targetName,
      baseUrl: GLOBAL_CONSTANTS.BASE_URL,
      data,
    },
  });
}

export function renderComponent(component: string, data: any) {
  return api("render-component", component, data);
}

export function renderPage(page: string) {
  return api("render-page", page, null);
}
