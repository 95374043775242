import { autobind } from "core-decorators";
import { TweenLite } from "gsap";

/**
 * @desc Component that shows / hides menu links
 */
export class AutoSideNav {
  classes = {
    categoriesOpen: "-categories-open",
    categoryListItemTriggers: ".js-category-list-item-trigger",
    categoryOpen: "-category-open",
    mobileMenuTriggerOpen: "-mobile-menu-trigger-open",
    pageLinksContainers: ".js-page-links-container",
  };

  ids = {
    autoSideNavId: "auto-side-nav-id",
    menuCategoriesId: "menu-categories-id",
    mobileMenuTriggerId: "mobile-menu-trigger-id",
  };

  events = {
    click: "click",
    resize: "resize",
  };

  desktopMinimumWidth = 1025;

  $autoSideNav: HTMLDivElement;
  $categoryListItemTriggers: Array<HTMLDivElement>;
  $menuCategories: HTMLDivElement;
  $mobileMenuTrigger: HTMLDivElement;
  $pageLinksContainers: Array<HTMLDivElement>;
  $previousWidth: number;

  constructor(element: HTMLDivElement) {
    if (!element) {
      element = document.getElementById(
        this.ids.autoSideNavId,
      ) as HTMLDivElement;
      if (!element) {
        return;
      }
    }

    this.$autoSideNav = element;
    this.$categoryListItemTriggers = Array.from(
      this.$autoSideNav.querySelectorAll(this.classes.categoryListItemTriggers),
    ) as Array<HTMLDivElement>;
    this.$menuCategories = document.getElementById(
      this.ids.menuCategoriesId,
    ) as HTMLDivElement;
    this.$mobileMenuTrigger = document.getElementById(
      this.ids.mobileMenuTriggerId,
    ) as HTMLDivElement;
    this.$pageLinksContainers = Array.from(
      this.$autoSideNav.querySelectorAll(this.classes.pageLinksContainers),
    ) as Array<HTMLDivElement>;

    if (this.hasCategoryPairs()) {
      setTimeout(() => {
        this.bindEvents();
      }, 100);
    }
  }

  /**
   * @desc Bind Category List Item triggers click event toggleCategory function
   */
  bindEvents(): void {
    if (this.$mobileMenuTrigger !== null) {
      this.$mobileMenuTrigger.addEventListener(
        this.events.click,
        this.toggleMobileSideMenu,
      );
    }

    for (
      let index = 0;
      index < this.$categoryListItemTriggers.length;
      index++
    ) {
      const categoryListItemTrigger = this.$categoryListItemTriggers[
        index
      ] as HTMLDivElement;
      categoryListItemTrigger.addEventListener(
        this.events.click,
        this.toggleCategory,
      );
    }

    window.addEventListener(this.events.resize, this.handleResize);

    this.$previousWidth = Math.max(
      window.innerWidth,
      document.documentElement.clientWidth,
    );

    if (this.hasCategoryPairs()) {
      if (this.$previousWidth < this.desktopMinimumWidth) {
        setTimeout(() => {
          this.closeAllCategories();
        }, 100);
      }
    }
  }

  /**
   * @desc Show / hide the mobile auto side nav
   */
  @autobind
  toggleMobileSideMenu(event: Event): void {
    event.preventDefault();
    this.closeAllCategories();

    if (
      this.$mobileMenuTrigger !== null &&
      this.$mobileMenuTrigger !== undefined
    ) {
      if (
        this.$mobileMenuTrigger.classList.contains(
          this.classes.mobileMenuTriggerOpen,
        )
      ) {
        this.$mobileMenuTrigger.classList.remove(
          this.classes.mobileMenuTriggerOpen,
        );
        if (
          this.$menuCategories !== null &&
          this.$menuCategories !== undefined
        ) {
          TweenLite.to(this.$menuCategories, 0.5, { height: "0" });
          TweenLite.to(this.$menuCategories, 0.5, { paddingTop: "0" });
          this.$menuCategories.classList.remove(this.classes.categoriesOpen);
        }
      } else {
        this.$mobileMenuTrigger.classList.add(
          this.classes.mobileMenuTriggerOpen,
        );
        if (
          this.$menuCategories !== null &&
          this.$menuCategories !== undefined
        ) {
          this.$menuCategories.classList.add(this.classes.categoriesOpen);
          this.$menuCategories.style.paddingTop = "28px";
          TweenLite.set(this.$menuCategories, { height: "auto" });
          TweenLite.from(this.$menuCategories, 0.5, { height: "0" });
        }
      }
    }
  }

  /**
   * @desc Animate the selected Category Item open / close click event and close all other Category Items
   * @param {Event} event - Mouse click event on Category Item
   */
  @autobind
  toggleCategory(event: Event): void {
    event.preventDefault();
    if (!this.hasCategoryPairs()) {
      return;
    }

    const currentTarget = event.currentTarget as HTMLDivElement;
    const categoryItemIndex = currentTarget.dataset.categoryItemIndex;
    let selectedIndex = -1;
    if (
      categoryItemIndex !== null &&
      categoryItemIndex !== undefined &&
      categoryItemIndex.length > 0 &&
      !isNaN(parseInt(categoryItemIndex, 10))
    ) {
      selectedIndex = parseInt(categoryItemIndex, 10);
    }

    for (
      let index = 0;
      index < this.$categoryListItemTriggers.length;
      index++
    ) {
      const categoryListItemTrigger = this.$categoryListItemTriggers[index];
      const pageLinkContainer = this.$pageLinksContainers[index];
      if (index === selectedIndex) {
        if (
          categoryListItemTrigger.classList.contains(this.classes.categoryOpen)
        ) {
          categoryListItemTrigger.classList.remove(this.classes.categoryOpen);
          TweenLite.to(pageLinkContainer, 0.5, { height: "0" });
          TweenLite.set(this.$menuCategories, { height: "auto" });
        } else {
          categoryListItemTrigger.classList.add(this.classes.categoryOpen);
          TweenLite.set(pageLinkContainer, { height: "auto" });
          TweenLite.from(pageLinkContainer, 0.5, { height: "0" });
          TweenLite.set(this.$menuCategories, { height: "auto" });
        }
      } else {
        if (
          categoryListItemTrigger.classList.contains(this.classes.categoryOpen)
        ) {
          categoryListItemTrigger.classList.remove(this.classes.categoryOpen);
          TweenLite.to(pageLinkContainer, 0.5, { height: "0" });
        }
      }
    }
  }

  /**
   * @desc Validate there are valid Category List Item trigger elements
   */
  @autobind
  hasCategoryPairs(): boolean {
    return (
      this.$categoryListItemTriggers !== null &&
      this.$categoryListItemTriggers !== undefined &&
      this.$pageLinksContainers !== null &&
      this.$pageLinksContainers !== undefined &&
      this.$categoryListItemTriggers.length === this.$pageLinksContainers.length
    );
  }

  /**
   * @desc Close all Category List Item elements - for mobile which does not start with the current page
   * category open
   */
  @autobind
  closeAllCategories(): void {
    if (this.hasCategoryPairs()) {
      for (
        let index = 0;
        index < this.$categoryListItemTriggers.length;
        index++
      ) {
        const categoryListItemTrigger = this.$categoryListItemTriggers[index];
        const pageLinkContainer = this.$pageLinksContainers[index];

        if (
          categoryListItemTrigger.classList.contains(this.classes.categoryOpen)
        ) {
          categoryListItemTrigger.classList.remove(this.classes.categoryOpen);
          pageLinkContainer.style.height = "0";
        }
      }
    }
  }

  /**
   * @desc Reset categories display if going from mobile to desktop or desktop to mobile
   */
  @autobind
  handleResize(): void {
    const currentWidth = Math.max(
      window.innerWidth,
      document.documentElement.clientWidth,
    );
    if (this.$previousWidth < this.desktopMinimumWidth) {
      // *** was mobile
      if (currentWidth >= this.desktopMinimumWidth) {
        // *** mobile to desktop
        if (
          this.$mobileMenuTrigger !== null &&
          this.$mobileMenuTrigger !== undefined &&
          this.$mobileMenuTrigger.classList.contains(
            this.classes.mobileMenuTriggerOpen,
          )
        ) {
          this.$mobileMenuTrigger.classList.remove(
            this.classes.mobileMenuTriggerOpen,
          );
        }
        if (
          this.$menuCategories !== null &&
          this.$menuCategories !== undefined
        ) {
          if (
            this.$menuCategories.classList.contains(this.classes.categoriesOpen)
          ) {
            this.$menuCategories.classList.remove(this.classes.categoriesOpen);
          }
          this.$menuCategories.style.height = "auto";
          this.$menuCategories.style.paddingTop = "0";
        }
      }
    } else {
      // *** was desktop
      if (currentWidth < this.desktopMinimumWidth) {
        // *** desktop to mobile
        this.closeAllCategories();
        if (
          this.$mobileMenuTrigger !== null &&
          this.$mobileMenuTrigger !== undefined &&
          this.$mobileMenuTrigger.classList.contains(
            this.classes.mobileMenuTriggerOpen,
          )
        ) {
          this.$mobileMenuTrigger.classList.remove(
            this.classes.mobileMenuTriggerOpen,
          );
        }
        if (
          this.$menuCategories !== null &&
          this.$menuCategories !== undefined
        ) {
          if (
            this.$menuCategories.classList.contains(this.classes.categoriesOpen)
          ) {
            this.$menuCategories.classList.remove(this.classes.categoriesOpen);
          }
          this.$menuCategories.style.height = "0";
          this.$menuCategories.style.paddingTop = "0";
        }
      }
    }
    this.$previousWidth = currentWidth;
  }

  /**
   * @desc Remove click events on triggers
   */
  tearDown(): void {
    if (this.$mobileMenuTrigger) {
      this.$mobileMenuTrigger.removeEventListener(
        this.events.click,
        this.toggleMobileSideMenu,
      );
    }

    window.removeEventListener(this.events.resize, this.handleResize);

    if (this.hasCategoryPairs()) {
      for (
        let index = 0;
        index < this.$categoryListItemTriggers.length;
        index++
      ) {
        const categoryListItemTrigger = this.$categoryListItemTriggers[
          index
        ] as HTMLDivElement;
        categoryListItemTrigger.removeEventListener(
          this.events.click,
          this.toggleCategory,
        );
      }
    }
  }
}
