import { autobind } from "core-decorators";
import { EventBus } from "../../globals/emitter";
import { TweenLite } from "gsap";
import { isMobile } from "../../globals/functions";
import { GLOBAL_CONSTANTS } from "../../globals/constants";

const CLASSES = {
  CONTENT: ".js-parallax__content",
};

/**
 * @desc Parallax - move div up based on scroll until it hits the middle of the screen.
 */
export class Parallax {
  $el: HTMLElement;
  $content: HTMLElement;

  /**
   * @desc Grab the HTMLElement and the type of animation
   * @param {HTMLElement} el - The element to attach the animation to.
   */
  constructor(el: HTMLElement) {
    this.$el = el;
    this.$content = this.$el.querySelector(CLASSES.CONTENT) as HTMLElement;

    this.bindEvents();

    setTimeout(this.handleScroll, 0);
  }

  /**
   * @desc Listen for the global scroll event.
   */
  bindEvents() {
    EventBus.on(GLOBAL_CONSTANTS.EVENTS.SCROLL, this.handleScroll);
  }

  /**
   * @desc handleScroll - position content element based on scroll position.
   */
  @autobind
  handleScroll() {
    if (!this.$content) {
      return;
    }

    if (isMobile()) {
      this.$content.style.removeProperty("transform");
      return;
    }

    const { top, height } = this.$el.getBoundingClientRect();
    const viewport = window.innerHeight;
    const halfViewport = viewport / 2;

    if (top > viewport) {
      return;
    }

    // If the center of the element is below the center of the screen,
    // set the position of the element to 20% of the scroll position.
    if (top + height / 2 >= halfViewport) {
      TweenLite.set(this.$content, {
        y: (top + height / 2 - halfViewport) * 0.2,
      });
    } else {
      TweenLite.set(this.$content, {
        y: 0,
      });
    }
  }
}
