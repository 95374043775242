import { autobind } from "core-decorators";
import { EventBus } from "../globals/emitter";

const classes = {
  CloseMenu: ".js-navigation-block-exit-menu",
  Desktop: ".-desktop",
  LoginButton: ".navigation-block-desktop-login-button",
  MobileLink: ".js-mobile-link",
  Navigation: ".navigation-block",
  NavigationDesktopAdditionalButton:
    ".navigation-block-desktop-additional-button",
  NavigationDesktopHeroItem: ".navigation-block-desktop-hero-item",
  NavigationDesktopLogo: ".navigation-block-desktop-logo",
  NavigationDesktopMenuBarItem: ".navigation-block-desktop-menu-bar-item",
  NavigationDesktopOftenVisitedItem:
    ".navigation-block-desktop-sub-menu-often-visited-item",
  NavigationDesktopServicesItem:
    ".navigation-block-desktop-sub-menu-services-item",
  NavigationDesktopTrapDoor:
    ".navigation-block-desktop-sub-menu-item-trap-door",
  SubNavLinks: ".sub-nav-links",
  NavBlockSpacer: ".nav-block-spacer",
  MenuHitArea: ".js-menu-hit-area",
};

const constants = {
  Desktop: "desktop",
  Hide: "-closed",
  Hover: "-hover",
  MenuItemHover: "-menu-item-hover",
  Mobile: "mobile",
  navigationBarMargin: 36,
  totalMarginsWidth: 120,
};

const events = {
  PageLoadComplete: "ANIMATION/PAGE_LOAD_COMPLETE",
  Resize: "resize",
  Scroll: "scroll",
};

const ids = {
  NavigationBlockId: "navigation-block-id",
  NavigationBlockDesktopId: "navigation-block-desktop-id",
  NavigationBlockDesktopSubMenusId: "navigation-block-desktop-sub-menus-id",
  NavigationBlockDesktopMenuId: "navigation-block-desktop-menu-id",
};

export class NavigationBlockMenu {
  $CloseMenu: HTMLElement;
  $Desktop: HTMLElement;
  $MobileLink: Array<HTMLElement>;
  $NavigationBlock: HTMLElement;
  $NavigationBlockDesktopMenu: HTMLElement;
  $NavigationBlockDesktopMenuBarItems: Array<HTMLElement>;
  $NavigationBlockDesktopSubMenus: HTMLElement;
  $NavigationBlockDesktopMenuItems: Array<HTMLElement>;
  $NavigationBlockDesktopTrapDoors: Array<HTMLElement>;
  $SubNavLinks: HTMLElement;
  $SubNavTitles: Array<HTMLElement>;
  $NavBlockSpacer: HTMLElement;
  $MenuHitArea: HTMLElement;

  buttonsWidth: number;
  anySubMenuMenusShown = false;
  logoWidth: number;
  menuBarWidth: number = constants.navigationBarMargin;

  /**
   * @desc Set element to class variables
   * @param {HTMLElement} element - Navigation Block Container Element to bind module to.
   */
  constructor(element: HTMLElement) {
    if (!element) {
      element = document.getElementById(ids.NavigationBlockId);
      if (!element) {
        return;
      }
    }

    this.$NavigationBlock = document.getElementById(
      ids.NavigationBlockId,
    ) as HTMLDivElement;
    this.$NavigationBlockDesktopMenu = document.getElementById(
      ids.NavigationBlockDesktopMenuId,
    ) as HTMLDivElement;
    this.$NavigationBlockDesktopMenuBarItems = Array.from(
      this.$NavigationBlockDesktopMenu.querySelectorAll(
        classes.NavigationDesktopMenuBarItem,
      ),
    ) as Array<HTMLElement>;
    for (const title of this.$NavigationBlockDesktopMenuBarItems) {
      this.menuBarWidth =
        this.menuBarWidth +
        title.getBoundingClientRect().width +
        constants.navigationBarMargin;
    }

    this.$NavigationBlockDesktopSubMenus = document.getElementById(
      ids.NavigationBlockDesktopSubMenusId,
    ) as HTMLDivElement;
    this.$NavigationBlockDesktopMenuItems = Array.from(
      this.$NavigationBlockDesktopSubMenus.children,
    ) as Array<HTMLElement>;
    this.$NavigationBlockDesktopTrapDoors = Array.from(
      this.$NavigationBlockDesktopSubMenus.querySelectorAll(
        classes.NavigationDesktopTrapDoor,
      ),
    ) as Array<HTMLElement>;

    this.$Desktop = document.getElementById(
      ids.NavigationBlockDesktopId,
    ) as HTMLDivElement;
    this.$CloseMenu = document.querySelector(classes.CloseMenu) as HTMLElement;
    this.$SubNavLinks = document.querySelector(
      classes.SubNavLinks,
    ) as HTMLElement;
    this.$MobileLink = Array.from(
      element.querySelectorAll(classes.MobileLink),
    ) as Array<HTMLElement>;
    this.$NavBlockSpacer = document.querySelector(
      classes.NavBlockSpacer,
    ) as HTMLElement;
    this.$MenuHitArea = document.querySelector(
      classes.MenuHitArea,
    ) as HTMLElement;

    const navigationLogo = document.querySelector(
      classes.NavigationDesktopLogo,
    );
    this.logoWidth =
      navigationLogo !== null
        ? navigationLogo.getBoundingClientRect().width
        : 0;
    const additionalButton = document.querySelector(
      classes.NavigationDesktopAdditionalButton,
    ) as HTMLElement;
    const loginButton = document.querySelector(classes.LoginButton);
    this.buttonsWidth =
      (additionalButton !== null
        ? additionalButton.getBoundingClientRect().width
        : 0) +
      (loginButton !== null ? loginButton.getBoundingClientRect().width : 0);

    this.bindEvents();
  }
  /**
   * @desc Set up events for each menu item
   */
  bindEvents(): void {
    EventBus.on(events.Resize, this.calcBreakpoint);
    EventBus.on(events.PageLoadComplete, this.calcBreakpoint);

    if (this.$NavigationBlockDesktopMenuBarItems) {
      this.$NavigationBlockDesktopMenuBarItems.forEach((item: HTMLElement) => {
        item.addEventListener("mouseover", this.handleRollOver);
      });
      if (this.$NavigationBlockDesktopSubMenus !== null) {
        for (
          let j = 0;
          j < this.$NavigationBlockDesktopSubMenus.children.length;
          j++
        ) {
          this.$NavigationBlockDesktopSubMenus.children[j].addEventListener(
            "mouseleave",
            this.handleRollOut,
          );
        }
      }

      EventBus.on(events.Scroll, this.handleScroll);
      EventBus.on(events.Resize, this.hideDesktopSubMenus);

      this.$MobileLink.forEach((item: HTMLElement) => {
        item.addEventListener("click", this.openLinks);
      });
    }
    this.$MenuHitArea.addEventListener("mouseout", this.handleHitRollOut);
  }

  @autobind
  calcBreakpoint(): void {
    const outerWidth = this.$NavigationBlock.getBoundingClientRect().width;
    const breakpoint =
      constants.totalMarginsWidth +
      this.buttonsWidth +
      this.logoWidth +
      this.menuBarWidth;

    if (breakpoint > outerWidth) {
      this.$NavigationBlock.classList.add(constants.Mobile);
      this.$NavigationBlock.classList.remove(constants.Desktop);
    } else {
      this.$NavigationBlock.classList.add(constants.Desktop);
      this.$NavigationBlock.classList.remove(constants.Mobile);
    }
    this.$NavBlockSpacer.style.height =
      this.$NavigationBlock.clientHeight + "px";
  }

  @autobind
  handleRollOver(event: Event): void {
    event.stopPropagation();
    const navigationBlockDesktopMenuItem = event.currentTarget as HTMLElement;
    const menuBarItemIndex = Number(
      navigationBlockDesktopMenuItem.dataset["menu"],
    );

    if (
      this.$NavigationBlockDesktopMenuBarItems &&
      this.$NavigationBlockDesktopMenuBarItems.length > 0
    ) {
      for (
        let menuItemIndex = 0;
        menuItemIndex < this.$NavigationBlockDesktopMenuBarItems.length;
        menuItemIndex++
      ) {
        const navigationBlockDesktopMenuBarItem =
          this.$NavigationBlockDesktopMenuBarItems[menuItemIndex];
        if (menuItemIndex === menuBarItemIndex) {
          navigationBlockDesktopMenuBarItem.classList.add(
            constants.MenuItemHover,
          );
        } else {
          navigationBlockDesktopMenuBarItem.classList.remove(
            constants.MenuItemHover,
          );
        }
      }
    }

    if (
      this.$NavigationBlockDesktopMenuItems &&
      this.$NavigationBlockDesktopMenuItems.length > 0
    ) {
      for (
        let subMenuItemIndex = 0;
        subMenuItemIndex < this.$NavigationBlockDesktopMenuItems.length;
        subMenuItemIndex++
      ) {
        const navigationBlockDesktopSubMenuItem =
          this.$NavigationBlockDesktopMenuItems[subMenuItemIndex];
        if (subMenuItemIndex === menuBarItemIndex) {
          if (navigationBlockDesktopSubMenuItem) {
            navigationBlockDesktopSubMenuItem.classList.remove(constants.Hide);
            navigationBlockDesktopSubMenuItem.classList.add(constants.Hover);
          }
        } else {
          if (navigationBlockDesktopSubMenuItem) {
            navigationBlockDesktopSubMenuItem.classList.remove(constants.Hover);
            navigationBlockDesktopSubMenuItem.classList.add(constants.Hide);
          }
        }
      }
    }

    if (
      this.$NavigationBlockDesktopTrapDoors &&
      this.$NavigationBlockDesktopTrapDoors.length > 0
    ) {
      for (
        let trapDoorIndex = 0;
        trapDoorIndex < this.$NavigationBlockDesktopTrapDoors.length;
        trapDoorIndex++
      ) {
        const trapDoor = this.$NavigationBlockDesktopTrapDoors[trapDoorIndex];
        if (trapDoorIndex === menuBarItemIndex) {
          const servicesItem = Array.from(
            trapDoor.querySelectorAll(classes.NavigationDesktopServicesItem),
          );
          const servicesHeight: number =
            servicesItem && servicesItem.length > 0
              ? servicesItem[0].getBoundingClientRect().height + 80
              : 0;
          const oftenVisitedItem = Array.from(
            trapDoor.querySelectorAll(
              classes.NavigationDesktopOftenVisitedItem,
            ),
          );
          const oftenVisitedHeight: number =
            oftenVisitedItem && oftenVisitedItem.length > 0
              ? oftenVisitedItem[0].getBoundingClientRect().height + 83
              : 0;
          const heroItem = Array.from(
            trapDoor.querySelectorAll(classes.NavigationDesktopHeroItem),
          );
          const heroHeight: number =
            heroItem && heroItem.length > 0
              ? heroItem[0].getBoundingClientRect().height
              : 0;
          const height = Math.max(
            servicesHeight,
            oftenVisitedHeight,
            heroHeight,
          );
          trapDoor.style.height = height + "px";
          if (this.anySubMenuMenusShown) {
            trapDoor.style.transition = "";
          } else {
            trapDoor.style.transition = "height .2s ease-out";
            this.anySubMenuMenusShown = true;
          }
        } else {
          trapDoor.style.height = "0";
          trapDoor.style.transition = "";
        }
      }
    }
  }

  @autobind
  handleRollOut(e: Event): void {
    e.stopPropagation();
    this.anySubMenuMenusShown = false;

    if (
      this.$NavigationBlockDesktopMenuBarItems &&
      this.$NavigationBlockDesktopMenuBarItems.length > 0
    ) {
      for (
        let menuItemIndex = 0;
        menuItemIndex < this.$NavigationBlockDesktopMenuBarItems.length;
        menuItemIndex++
      ) {
        const navigationBlockDesktopMenuBarItem =
          this.$NavigationBlockDesktopMenuBarItems[menuItemIndex];
        navigationBlockDesktopMenuBarItem.classList.remove(
          constants.MenuItemHover,
        );
      }
    }

    this.hideDesktopSubMenus(true);
  }

  @autobind
  handleHitRollOut(e: Event): void {
    e.stopPropagation();
    this.anySubMenuMenusShown = true;

    if (
      this.$NavigationBlockDesktopMenuBarItems &&
      this.$NavigationBlockDesktopMenuBarItems.length > 0
    ) {
      for (
        let menuItemIndex = 0;
        menuItemIndex < this.$NavigationBlockDesktopMenuBarItems.length;
        menuItemIndex++
      ) {
        const navigationBlockDesktopMenuBarItem =
          this.$NavigationBlockDesktopMenuBarItems[menuItemIndex];
        navigationBlockDesktopMenuBarItem.classList.remove(
          constants.MenuItemHover,
        );
      }
    }

    this.hideDesktopSubMenus(false);
  }

  @autobind
  handleScroll(): void {
    const isDesktop = this.$NavigationBlock.classList.contains("desktop");

    if (isDesktop) {
      this.hideDesktopSubMenus(false);
    }
  }

  @autobind
  closeMenu(e: Event): void {
    const $button = e.currentTarget as HTMLElement;
    if ($button && $button.classList.contains(constants.Hide)) {
      $button.classList.remove(constants.Hide);
      if (this.$SubNavLinks) {
        this.$SubNavLinks.style.display = "inline-block";
      }
    } else {
      if ($button) {
        $button.classList.add(constants.Hide);
        if (this.$SubNavLinks) {
          this.$SubNavLinks.style.display = "none";
        }
      }
    }
  }

  @autobind
  openLinks(e: Event): void {
    const $button = e.currentTarget as HTMLElement;
    if (!$button) {
      return;
    }
    const $trap = $button.nextElementSibling as HTMLElement;
    const h = window
      .getComputedStyle($trap.children[0], null)
      .getPropertyValue("height");
    if ($button.classList.contains("-open")) {
      $button.classList.remove("-open");
      if ($trap) {
        $trap.style.height = "0";
      }
    } else {
      $button.classList.add("-open");
      if ($trap) {
        $trap.style.height = h;
      }
    }
  }

  @autobind
  hideDesktopSubMenus(resetTransition: boolean): void {
    if (this.$NavigationBlockDesktopMenuItems) {
      this.$NavigationBlockDesktopMenuItems.forEach((item: HTMLElement) => {
        item.classList.remove(constants.Hover);
        item.classList.add(constants.Hide);
      });
    }
    if (this.$NavigationBlockDesktopTrapDoors) {
      this.$NavigationBlockDesktopTrapDoors.forEach((item: HTMLElement) => {
        item.style.height = "0";
        if (resetTransition) {
          item.style.transition = "";
        }
      });
    }
  }
}
