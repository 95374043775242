import GoogleMapsLoader from "google-maps";
import { autobind } from "core-decorators";
import { EventBus } from "../globals/emitter";
import { fadeIn, fadeOut, toHeight } from "../effects/animation/animations";
import { GLOBAL_CONSTANTS } from "../globals/constants";
import { IAnimation } from "../effects/animation/i-animation";
import { DraggableCards } from "../effects/scrolling/draggable-cards";
import { EventAnimation } from "../effects/animation/event-animation";
import "whatwg-fetch";

declare var gtm: any;

const classes = {
  BackActive: "-back-active",
  FullWidth: "-full-width",
  Image: ".js-associate-search-image",
  Eyebrow: ".js-associate-search-eyebrow",
  BackButton: ".js-back",
  SearchButton: ".js-search-button",
  SearchForm: ".js-search-form",
  Results: ".js-results",
  Content: ".js-content",
  ResultsPlaceholder: ".js-results-container",
  DraggableCards: ".js-draggable-cards",
  QueryNotFoundMessage: ".js-query-not-found-message",
  RegionAnchors: ".js-search-region",
  SearchBlock: "associatelocationsearch-block",
};

/**
 * @desc An Augmented stepper that
 * will handle loading external pages /
 * animating between steps.
 */
export class AssociateLocationSearch {
  $el: HTMLElement;
  $content: HTMLElement;
  $image: HTMLElement;
  $eyebrow: HTMLElement;
  $backButton: HTMLButtonElement;
  $searchButton: HTMLButtonElement;
  $searchForm: HTMLElement;
  $searchResults: HTMLElement;
  $searchBlock: HTMLElement;
  backAnimation: IAnimation;
  $input: HTMLInputElement;
  $queryNotFoundMessage: HTMLElement;
  $resultsContainer: HTMLElement;
  $regionAnchors: NodeListOf<HTMLElement>;
  notFound: string;
  dataAjaxRoute: string;
  resultCount: number;
  gtmSearchFilters: string;
  gtmSearchValue: string;
  gtmSearchPlaceHolder: string;
  showingResults = false;

  google: typeof GoogleMapsLoader;
  geocoder: google.maps.Geocoder;

  /**
   * @desc Grab relevant steps, create a stepper instance.
   * @param {HTMLElement} el - Element that houses all the steps.
   */
  constructor(el: HTMLElement) {
    this.$el = el;
    this.$image = this.$el.querySelector(classes.Image) as HTMLElement;
    this.$eyebrow = this.$el.querySelector(classes.Eyebrow) as HTMLElement;
    this.$backButton = this.$el.querySelector(
      classes.BackButton,
    ) as HTMLButtonElement;
    this.$searchButton = this.$el.querySelector(
      classes.SearchButton,
    ) as HTMLButtonElement;
    this.$searchForm = this.$el.querySelector(
      classes.SearchForm,
    ) as HTMLElement;
    this.$searchResults = this.$el.querySelector(
      classes.Results,
    ) as HTMLElement;
    this.$searchBlock = this.$el.querySelector(
      classes.SearchBlock,
    ) as HTMLElement;
    this.$content = this.$searchResults.querySelector(
      classes.Content,
    ) as HTMLElement;
    this.$resultsContainer = this.$searchResults.querySelector(
      classes.ResultsPlaceholder,
    ) as HTMLElement;
    this.$input = this.$searchForm.querySelector("input") as HTMLInputElement;
    this.$regionAnchors = this.$searchForm.querySelectorAll(
      classes.RegionAnchors,
    ) as NodeListOf<HTMLElement>;
    this.$queryNotFoundMessage = this.$el.querySelector(
      classes.QueryNotFoundMessage,
    ) as HTMLElement;

    this.dataAjaxRoute = this.$el.getAttribute("data-ajax-route");
    if (this.dataAjaxRoute == null || this.dataAjaxRoute.trim().length < 1) {
      this.dataAjaxRoute = "/ajax/associatelocationsearch";
    }

    this.backAnimation = fadeOut(this.$backButton);
    this.bindEvents();

    GoogleMapsLoader.KEY = this.$searchButton.dataset.gma;
    if (GoogleMapsLoader.KEY) {
      GoogleMapsLoader.LIBRARIES = ["geometry", "places"];
      GoogleMapsLoader.load((google: any) => {
        this.google = google;
      });
    }
  }

  bindEvents() {
    EventBus.on(GLOBAL_CONSTANTS.EVENTS.RESIZE, this.handleHeightChange);
    if (this.$searchForm !== null) {
      this.$searchForm.addEventListener("keyup", this.checkForEnter);
    }
    this.$backButton.addEventListener("click", this.back);
    if (this.$searchButton) {
      this.$searchButton.addEventListener("click", this.searchByQuery);
    }
    for (const regionAnchor of this.$regionAnchors) {
      regionAnchor.addEventListener("click", this.searchByRegion);
    }
  }

  @autobind
  checkForEnter(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      event.preventDefault();
      if (this.$searchButton !== null) {
        this.$searchButton.click();
      }
    }
  }

  @autobind
  searchByQuery() {
    const $this = this;
    const rad = this.$input.dataset.radius ? this.$input.dataset.radius : 12450;
    const postData = {
      query: this.$input.value,
      lineOfBusiness: this.$input.dataset.lineOfBusiness,
      constructionLoan: this.$input.dataset.constructionLoan,
      useFootprint: this.$input.dataset.useFootprint,
      radius: rad,
      lat: 0,
      lng: 0,
      showPageDotsClass: this.$input.dataset.showPageDotsClass,
      tags: this.$input.dataset.tags,
    };

    if ($this.$input.dataset.useFootprint !== "True") {
      this.geocoder = new this.google.maps.Geocoder();

      const geocoderBounds = new this.google.maps.LatLngBounds(
        new this.google.maps.LatLng(32.324276, -124.541016),
        new this.google.maps.LatLng(48.864715, -110.126953),
      );

      this.geocoder.geocode(
        {
          address: this.$input.value,
          componentRestrictions: { country: "USA" },
          bounds: geocoderBounds,
        },
        (
          results: Array<google.maps.GeocoderResult>,
          status: google.maps.GeocoderStatus,
        ) => {
          if (status === $this.google.maps.GeocoderStatus.OK) {
            postData.lat = results[0].geometry.location.lat();
            postData.lng = results[0].geometry.location.lng();
          }
          $this.search(this.dataAjaxRoute, postData);
        },
      );
    } else {
      $this.search(this.dataAjaxRoute, postData);
    }
  }

  @autobind
  searchByRegion(ev: Event) {
    const anchor = ev.currentTarget as HTMLElement;
    const postData = {
      region: anchor.dataset.searchRegion,
      lineOfBusiness: anchor.dataset.lineOfBusiness,
      constructionLoan: anchor.dataset.constructionLoan,
      useFootprint: false,
      radius: 0,
      lat: 0,
      lng: 0,
    };
    this.search(this.dataAjaxRoute, postData);
  }

  /**
   * @desc search for associates and display results view
   */
  @autobind
  search(dataUrl: string, postData: any) {
    this.gtmSearchFilters = this.formatGtmSearchFilters(postData);
    this.gtmSearchValue = this.getGtmSearchValue(postData);
    this.gtmSearchPlaceHolder = this.getGtmSearchPlaceHolder(postData);
    const queryString = new URLSearchParams(postData).toString();
    fetch(dataUrl + "?" + queryString, {
      method: "POST",
      headers: {
        "Content-Type": "text/plain",
      },
      /*body: JSON.stringify(obj),*/
    })
      .then(this.checkStatus)
      .then((response: any) => {
        response
          .text()
          .then(this.replaceResults)
          .then(this.showQueryNotFoundMessage)
          .then(this.initDraggableCards)
          .then(this.showSearchResults)
          .then(this.handleHeightChange)
          .then(this.adjustPosition);
      })
      .catch((error: any) => {
        gtm.events.json(
          "search-associate",
          this.gtmSearchPlaceHolder,
          "api associate search error - error " + error,
        );
        console.log("request failed", error);
      });
  }

  @autobind
  checkStatus(response: any): any {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      gtm.events.json(
        "search-associate",
        this.gtmSearchPlaceHolder,
        "api associate search error - status " + response.status,
      );
      throw new Error(`Status: ${response.statusText} Response: ${response}`);
    }
  }

  @autobind
  replaceResults(text: any): any {
    this.$resultsContainer.innerHTML = text;
  }

  @autobind
  adjustPosition(): any {
    let docViewTop = window.scrollY;
    if (!docViewTop) {
      docViewTop = window.pageYOffset;
    }
    const searchViewTop = document.getElementsByClassName(
      classes.SearchBlock,
    )[0]
      ? document
          .getElementsByClassName(classes.SearchBlock)[0]
          .getBoundingClientRect().top
      : 0;
    const top = searchViewTop - 27;
    window.scrollTo(0, docViewTop + top);
  }

  @autobind
  showQueryNotFoundMessage(): any {
    const cards = this.$content.querySelector(
      classes.DraggableCards,
    ) as HTMLElement;
    this.notFound = cards.dataset.notFound;
    if (this.notFound === "True") {
      gtm.events.json(
        "search-associate",
        this.gtmSearchPlaceHolder,
        "zero results found",
      );
      this.$queryNotFoundMessage.removeAttribute("hidden");
    } else if (!this.$queryNotFoundMessage.dataset["hidden"]) {
      this.$queryNotFoundMessage.setAttribute("hidden", "");
    }
  }

  @autobind
  initDraggableCards(): any {
    const animatedElements = this.$content.querySelectorAll(
      ".js-event-animate",
    ) as NodeListOf<HTMLElement>;
    this.resultCount = 0;
    if (animatedElements) {
      this.resultCount = animatedElements.length;
      for (const item of animatedElements) {
        // tslint:disable
        new EventAnimation(item);
        // tslint:enable
      }
    }

    const cardsEl = this.$content.querySelector(
      classes.DraggableCards,
    ) as HTMLElement;
    if (cardsEl) {
      // tslint:disable
      new DraggableCards(cardsEl);
      // tslint:enable
    }
  }

  @autobind
  formatGtmSearchFilters(postData: any): string {
    if (postData === null || typeof postData === "undefined") {
      return "";
    }
    let query = postData.query;
    if (query === null || typeof query === "undefined") {
      query = "";
    }
    let region = postData.region;
    if (region === null || typeof region === "undefined") {
      region = "";
    }
    const searchFilters = `query:${gtm.utilities.sanitize(query)}|region:${gtm.utilities.sanitize(region)}|lineOfBusiness:${postData.lineOfBusiness}|constructionLoan:${postData.constructionLoan}|useFootprint:${postData.useFootprint}|radius:${postData.radius}|latitude:${postData.lat.toString()}|longitude:${postData.lng.toString()}`;

    return searchFilters;
  }

  @autobind
  getGtmSearchValue(postData: any): string {
    if (postData === null || typeof postData === "undefined") {
      return "";
    }
    const query = postData.query;
    if (query !== null && typeof query !== "undefined" && query.length > 0) {
      return gtm.utilities.sanitize(query);
    }
    const region = postData.region;
    if (region !== null && typeof region !== "undefined" && region.length > 0) {
      return gtm.utilities.sanitize(region);
    }

    return "";
  }

  @autobind
  getGtmSearchPlaceHolder(postData: any): string {
    if (postData === null || typeof postData === "undefined") {
      return "";
    }
    const query = postData.query;
    if (query !== null && typeof query !== "undefined") {
      if (
        this.$input !== null &&
        typeof this.$input !== "undefined" &&
        this.$input.placeholder !== null &&
        typeof this.$input.placeholder !== "undefined"
      ) {
        return gtm.utilities.sanitize(this.$input.placeholder);
      } else {
        return "";
      }
    }
    const region = postData.region;
    if (region !== null && typeof region !== "undefined" && region.length > 0) {
      return gtm.utilities.sanitize(region);
    }

    return "";
  }

  @autobind
  async back() {
    await this.hideSearchResults();
    this.$resultsContainer.innerHTML = "";
    await this.showSearchForm();
  }

  @autobind
  async showSearchForm() {
    this.showingResults = false;
    this.$searchForm.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
    this.$el.classList.remove(classes.BackActive);
    EventBus.fire(GLOBAL_CONSTANTS.EVENTS.DECISION_TREE_HIDE_CARDS);
    fadeOut(this.$image).reverse(0);
    this.$el.classList.remove(classes.FullWidth);
    this.handleHeightChange();
    if (this.$eyebrow !== null && typeof this.$eyebrow !== "undefined") {
      this.$eyebrow.style.opacity = "1";
    }
    await fadeIn(this.$searchForm).forward(0);
  }

  @autobind
  async showSearchResults() {
    this.showingResults = true;
    gtm.search.json(
      this.gtmSearchValue,
      this.resultCount,
      "associate",
      "0",
      this.gtmSearchFilters,
    );
    await this.hideSearchForm();

    this.$el.classList.add(classes.FullWidth);
    this.$searchResults.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE);

    EventBus.on("PLAY_STOPPED", () => {
      EventBus.off("PLAY_STOPPED");
      this.handleHeightChange();
    });

    this.backAnimation.reverse(0);
    this.$el.classList.add(classes.BackActive);
    await fadeIn(this.$searchResults).forward(0);
    EventBus.fire(GLOBAL_CONSTANTS.EVENTS.DECISION_TREE_SHOW_CARDS);
  }

  @autobind
  async hideSearchForm() {
    fadeOut(this.$image).forward(0);
    if (this.$eyebrow !== null && typeof this.$eyebrow !== "undefined") {
      this.$eyebrow.style.opacity = "0";
    }
    await fadeOut(this.$searchForm).forward(0);
    this.$searchForm.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
  }

  @autobind
  async hideSearchResults() {
    await this.backAnimation.forward(0);
    await fadeOut(this.$searchResults).forward(0);
    this.$searchResults.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
  }

  @autobind
  async handleHeightChange() {
    if (this.showingResults) {
      const height =
        this.$searchResults.clientHeight + this.$backButton.clientHeight;
      this.$el.style.height = `${height}px`;
      await toHeight(this.$el, height).forward(0);
    } else {
      let height = this.$searchForm.clientHeight;
      this.$el.style.removeProperty("height");
      const clientHeight = this.$el.getBoundingClientRect().height;
      height = Math.max(height, clientHeight);
      this.$el.style.height = `${height}px`;
      await toHeight(this.$el, height).forward(0);
    }
  }
}
