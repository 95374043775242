import { autobind } from "core-decorators";
import { GLOBAL_CONSTANTS } from "../../globals/constants";
import { EventBus } from "../../globals/emitter";
import { fadeInUpSlow } from "./animations";
import { IAnimation } from "./i-animation";

const CLASSES = {
  WRAPPER: ".wrapper",
  IGNORE: "js-page-load__ignore",
};

/**
 * @desc Animates content below .header in after
 * priority animations have completed.
 */
export class PageLoadAnimation {
  $el: HTMLElement;
  $items: Array<HTMLElement>;
  animations: Array<IAnimation>;

  /**
   * @desc Grab container / triggers for load in.
   * @param {HTMLElement} el - Element that contains all the wrapper elements.
   */
  constructor(el: HTMLElement) {
    this.$el = el;
    const temp = Array.from(
      this.$el.querySelectorAll(CLASSES.WRAPPER),
    ) as Array<HTMLElement>;
    this.$items = temp.filter((item) => {
      return !item.classList.contains(CLASSES.IGNORE);
    });

    this.animations = this.$items.map((item) => fadeInUpSlow(item));

    this.bindEvents();
  }

  /**
   * @desc Listen for main header animation.
   */
  bindEvents() {
    EventBus.on(GLOBAL_CONSTANTS.EVENTS.PRIORITY_FINISHED, this.animateInPage);
  }

  /**
   * @desc Animate elements in.
   */
  @autobind
  animateInPage() {
    this.animations.forEach((anim) => anim.forward(0.55));
  }

  /**
   * @desc Unbing Event listener.
   */
  @autobind
  tearDown() {
    EventBus.off(GLOBAL_CONSTANTS.EVENTS.PRIORITY_FINISHED, this.animateInPage);
  }
}
