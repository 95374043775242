import { autobind } from "core-decorators";

const classes = {
  ClosedMenu: "closed-menu",
  CloseMenuIcon: "close-menu-icon",
  HideElement: "navigation-block-mobile-menu-hide",
  MobileLinkButton: ".js-submenu-accordion",
  MobileMenuDrawer: ".js-mobile-menu-drawer",
  OpenedMenu: "opened-menu",
  OpenMenuIcon: "open-menu-icon",
  ShowElement: "navigation-block-mobile-menu-show",
};

const ids = {
  NavigationButtonMobileMenuContainer:
    "navigation-block-mobile-menu-container-id",
  NavigationBlockMobileMenuButtonIconClose:
    "navigation-block-mobile-menu-button-icon-close-id",
  NavigationBlockMobileMenuButtonIconOpen:
    "navigation-block-mobile-menu-button-icon-open-id",
  NavigationBlockMobileOpenCloseMenuButton:
    "navigation-block-mobile-open-close-menu-button-id",
};

export class NavigationBlockMobileMenu {
  $NavigationBlockMobileOpenCloseMenuButton: HTMLElement;
  $NavigationButtonMobileMenuContainer: HTMLDivElement;
  $NavigationBlockMobileMenuButtonIconClose: HTMLElement;
  $NavigationBlockMobileMenuButtonIconOpen: HTMLElement;
  $MobileLinkButtons: Array<HTMLButtonElement>;
  $MobileMenuDrawers: Array<HTMLElement>;

  /**
   * @desc Set element to class variables
   * @param {HTMLElement} element - Element to bind module to.
   */
  constructor(element: HTMLElement) {
    if (!element) {
      return;
    }

    this.$NavigationButtonMobileMenuContainer = document.getElementById(
      ids.NavigationButtonMobileMenuContainer,
    ) as HTMLDivElement;
    this.$NavigationBlockMobileOpenCloseMenuButton = document.getElementById(
      ids.NavigationBlockMobileOpenCloseMenuButton,
    ) as HTMLElement;
    this.$NavigationBlockMobileMenuButtonIconClose = document.getElementById(
      ids.NavigationBlockMobileMenuButtonIconClose,
    ) as HTMLElement;
    this.$NavigationBlockMobileMenuButtonIconOpen = document.getElementById(
      ids.NavigationBlockMobileMenuButtonIconOpen,
    ) as HTMLElement;

    this.$MobileLinkButtons = Array.from(
      element.querySelectorAll(classes.MobileLinkButton),
    ) as Array<HTMLButtonElement>;
    this.$MobileMenuDrawers = Array.from(
      element.querySelectorAll(classes.MobileMenuDrawer),
    ) as Array<HTMLDivElement>;
    this.bindEvents();
  }

  /**
   * @desc Set up events for each menu group
   */
  bindEvents(): void {
    this.$NavigationBlockMobileOpenCloseMenuButton.addEventListener(
      "click",
      this.handleMobileMenuButtonOpenClose,
    );

    if (this.$MobileLinkButtons) {
      this.$MobileLinkButtons.forEach((item: HTMLButtonElement) => {
        item.addEventListener("click", this.openOrCloseMobileNavGroup);
      });
    }
  }

  @autobind
  handleMobileMenuButtonOpenClose(): void {
    this.closeAllMobileMenuGroups();
    if (this.$NavigationBlockMobileOpenCloseMenuButton) {
      if (
        this.$NavigationBlockMobileOpenCloseMenuButton.classList.contains(
          classes.ClosedMenu,
        )
      ) {
        this.openNavigationBlockMobileMenu();
      } else {
        this.closeNavigationBlockMobileMenu();
      }
    }
  }

  @autobind
  closeNavigationBlockMobileMenu(): void {
    this.$NavigationBlockMobileOpenCloseMenuButton.classList.remove(
      classes.OpenedMenu,
    );
    this.$NavigationBlockMobileOpenCloseMenuButton.classList.add(
      classes.ClosedMenu,
    );

    if (this.$NavigationButtonMobileMenuContainer) {
      this.$NavigationButtonMobileMenuContainer.classList.remove(
        classes.ShowElement,
      );
      this.$NavigationButtonMobileMenuContainer.classList.add(
        classes.HideElement,
      );
    }
    if (this.$NavigationBlockMobileMenuButtonIconClose) {
      this.$NavigationBlockMobileMenuButtonIconClose.classList.remove(
        classes.OpenMenuIcon,
      );
      this.$NavigationBlockMobileMenuButtonIconClose.classList.add(
        classes.CloseMenuIcon,
      );
    }
    if (this.$NavigationBlockMobileMenuButtonIconOpen) {
      this.$NavigationBlockMobileMenuButtonIconOpen.classList.remove(
        classes.CloseMenuIcon,
      );
      this.$NavigationBlockMobileMenuButtonIconOpen.classList.add(
        classes.OpenMenuIcon,
      );
    }
  }

  @autobind
  openNavigationBlockMobileMenu(): void {
    this.$NavigationBlockMobileOpenCloseMenuButton.classList.remove(
      classes.ClosedMenu,
    );
    this.$NavigationBlockMobileOpenCloseMenuButton.classList.add(
      classes.OpenedMenu,
    );
    let windowHeight = window.innerHeight;
    const $alert = document.getElementById("navigation-block-alert-id");
    const $nav = document.getElementById("navigation-block-wrapper-id");
    if ($alert) {
      windowHeight -= $alert.getBoundingClientRect().height;
    }
    if ($nav) {
      windowHeight -= $nav.getBoundingClientRect().height;
    }

    if (this.$NavigationButtonMobileMenuContainer) {
      this.$NavigationButtonMobileMenuContainer.classList.remove(
        classes.HideElement,
      );
      this.$NavigationButtonMobileMenuContainer.classList.add(
        classes.ShowElement,
      );
      this.$NavigationButtonMobileMenuContainer.style.height =
        windowHeight + "px";
    }
    if (this.$NavigationBlockMobileMenuButtonIconClose) {
      this.$NavigationBlockMobileMenuButtonIconClose.classList.remove(
        classes.CloseMenuIcon,
      );
      this.$NavigationBlockMobileMenuButtonIconClose.classList.add(
        classes.OpenMenuIcon,
      );
    }
    if (this.$NavigationBlockMobileMenuButtonIconOpen) {
      this.$NavigationBlockMobileMenuButtonIconOpen.classList.remove(
        classes.OpenMenuIcon,
      );
      this.$NavigationBlockMobileMenuButtonIconOpen.classList.add(
        classes.CloseMenuIcon,
      );
    }
  }

  @autobind
  openOrCloseMobileNavGroup(event: Event): void {
    const button = event.currentTarget as HTMLButtonElement;
    const listItem = button.parentElement as HTMLElement;
    if (!button) {
      return;
    }
    if (listItem.classList.contains(classes.OpenedMenu)) {
      listItem.classList.remove(classes.OpenedMenu);
      listItem.classList.add(classes.ClosedMenu);
    } else {
      listItem.classList.remove(classes.ClosedMenu);
      listItem.classList.add(classes.OpenedMenu);
    }
  }

  @autobind
  closeAllMobileMenuGroups(): void {
    if (this.$MobileLinkButtons) {
      this.$MobileLinkButtons.forEach((item: HTMLButtonElement) => {
        const mobileNavGroupDrawer = item.nextElementSibling as HTMLDivElement;
        if (mobileNavGroupDrawer) {
          mobileNavGroupDrawer.classList.remove(classes.ShowElement);
          mobileNavGroupDrawer.classList.add(classes.HideElement);
        }
        item.classList.remove(classes.OpenedMenu);
      });
    }
  }

  @autobind
  expandAllMobileMenuGroups(): void {
    if (this.$MobileLinkButtons) {
      this.$MobileLinkButtons.forEach((item: HTMLButtonElement) => {
        const mobileNavGroupDrawer = item.nextElementSibling as HTMLDivElement;
        if (mobileNavGroupDrawer) {
          mobileNavGroupDrawer.classList.remove(classes.HideElement);
          mobileNavGroupDrawer.classList.add(classes.ShowElement);
        }
        item.classList.add(classes.OpenedMenu);
      });
    }
  }
}
