import { autobind } from "core-decorators";
import { getCookie, setCookie, deleteCookie } from "../globals/functions";

declare var gtm: any;

const constants = {
  classes: {
    userIdElement: ".js-bb-user-id",
    companyIdElement: ".js-bb-company-id",
    rememberMe: ".js-bb-remember-me",
    hasErrors: "-has-errors",
  },
  cookies: {
    cookieUserId: "umpq_bb_user_id",
    cookieCompanyId: "umpq_bb_company_id",
  },
  gtm: {
    formName: "Business Banking Login",
    formError: "form-validation-error",
  },
};

/**
 * @desc Simple component that shows / hides content
 */
export class BusinessBankingLogin {
  loginFormElement: HTMLFormElement;
  userIdElement: HTMLInputElement;
  companyIdElement: HTMLInputElement;
  rememberMe: HTMLInputElement;
  loginButtonElement: HTMLButtonElement;

  constructor(element: HTMLElement) {
    this.loginFormElement = element as HTMLFormElement;
    this.userIdElement = this.loginFormElement.querySelector(
      constants.classes.userIdElement,
    ) as HTMLInputElement;
    this.companyIdElement = this.loginFormElement.querySelector(
      constants.classes.companyIdElement,
    ) as HTMLInputElement;
    this.rememberMe = this.loginFormElement.querySelector(
      constants.classes.rememberMe,
    ) as HTMLInputElement;

    const buttons = this.loginFormElement.querySelectorAll(
      "button",
    ) as NodeListOf<HTMLButtonElement>;
    if (buttons && buttons.length > 0) {
      this.loginButtonElement = buttons[0];
    }

    this.init();
    this.bindEvents();
  }

  @autobind
  init() {
    if (this.userIdElement) {
      this.userIdElement.value = getCookie(constants.cookies.cookieUserId);
    }

    if (this.companyIdElement) {
      this.companyIdElement.value = getCookie(
        constants.cookies.cookieCompanyId,
      );
    }

    if (
      this.userIdElement &&
      this.userIdElement.value &&
      this.userIdElement.value.length > 0 &&
      this.companyIdElement &&
      this.companyIdElement.value &&
      this.companyIdElement.value.length > 0 &&
      this.rememberMe
    ) {
      this.rememberMe.checked = true;
    }
  }

  @autobind
  bindEvents(): void {
    this.loginFormElement.addEventListener("submit", this.loginCallback);
    if (this.userIdElement) {
      this.userIdElement.addEventListener("keydown", this.removeInputError);
    }
    if (this.companyIdElement) {
      this.companyIdElement.addEventListener("keydown", this.removeInputError);
    }
  }

  @autobind
  loginCallback(event: Event): void {
    event.preventDefault();
    if (!this.loginFormElement) {
      this.loginFormElement = event.currentTarget as HTMLFormElement;
      this.userIdElement = this.loginFormElement.querySelector(
        constants.classes.userIdElement,
      ) as HTMLInputElement;
      this.companyIdElement = this.loginFormElement.querySelector(
        constants.classes.companyIdElement,
      ) as HTMLInputElement;
      this.rememberMe = this.loginFormElement.querySelector(
        constants.classes.rememberMe,
      ) as HTMLInputElement;
    }

    if (this.userIdElement && this.companyIdElement) {
      const errors = this.validateFields();
      if (errors === null || errors === undefined || errors.length < 1) {
        gtm.forms.json(
          constants.gtm.formName,
          this.loginFormElement.id,
          "1",
          "",
          "TRUE",
          "TRUE",
        );
        const id =
          this.loginButtonElement && this.loginButtonElement.id
            ? this.loginButtonElement.id
            : this.loginFormElement.id;
        const element =
          this.loginButtonElement && this.loginButtonElement.innerText
            ? this.loginButtonElement.innerText
            : constants.gtm.formName;
        gtm.navigation.formSubmitJson(
          element,
          this.loginFormElement.action,
          document.baseURI,
          id,
        );
        this.createRememberMeCookies();
        this.loginFormElement.submit();
      } else {
        gtm.forms.json(
          constants.gtm.formName,
          this.loginFormElement.id,
          "1",
          constants.gtm.formError,
          "FALSE",
          "FALSE",
        );
        for (let index = 0; index < errors.length; index++) {
          const error = JSON.parse(JSON.stringify(errors[index]));
          gtm.events.json(error.id, error.type, error.text);
        }
      }
    }
  }

  @autobind
  validateFields(): Array<{}> {
    const errors = new Array<{}>();

    if (this.userIdElement) {
      if (this.userIdElement.value.length < 1) {
        this.userIdElement.parentElement.classList.add(
          constants.classes.hasErrors,
        );
        let errorText = "Required";
        const errorDiv = this.userIdElement.nextElementSibling as HTMLElement;
        if (
          errorDiv !== null &&
          errorDiv !== undefined &&
          errorDiv.innerText.length > 0
        ) {
          errorText = errorDiv.innerText;
        }
        errors.push({
          id: "form-field",
          type: this.userIdElement.placeholder,
          text: errorText,
        });
      }
    }

    if (this.companyIdElement) {
      if (this.companyIdElement.value.length < 1) {
        this.companyIdElement.parentElement.classList.add(
          constants.classes.hasErrors,
        );
        let errorText = "Required";
        const errorDiv = this.companyIdElement
          .nextElementSibling as HTMLElement;
        if (
          errorDiv !== null &&
          errorDiv !== undefined &&
          errorDiv.innerText.length > 0
        ) {
          errorText = errorDiv.innerText;
        }
        errors.push({
          id: "form-field",
          type: this.companyIdElement.placeholder,
          text: errorText,
        });
      }
    }

    return errors;
  }

  @autobind
  createRememberMeCookies(): void {
    if (this.rememberMe) {
      if (this.rememberMe.checked) {
        if (
          this.userIdElement &&
          this.userIdElement.value &&
          this.userIdElement.value.length > 0
        ) {
          setCookie(constants.cookies.cookieUserId, this.userIdElement.value);
        }
        if (
          this.companyIdElement &&
          this.companyIdElement.value &&
          this.companyIdElement.value.length > 0
        ) {
          setCookie(
            constants.cookies.cookieCompanyId,
            this.companyIdElement.value,
          );
        }
      } else {
        deleteCookie(constants.cookies.cookieUserId);
        deleteCookie(constants.cookies.cookieCompanyId);
      }
    }
  }

  @autobind
  removeInputError(event: Event): void {
    const element = event.currentTarget as HTMLElement;
    element.parentElement.classList.remove(constants.classes.hasErrors);
  }
}
