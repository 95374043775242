/**
 * This module handles the lazyloading of images based on screen size
 * If window is less than 800 it'll use the asset path in data-small
 * else it will use the asset path in data-src
 *
 * The 'js-lazy-image' class is required on either a div (background-image) or img tag.
 * To use, just call loadImages() function and pass a collection of images.
 */
declare module "ImageLoader";

import { GLOBAL_CONSTANTS } from "../globals/constants";
import ImageLoader from "../third-party/properjs-imageloader/ImageLoader.js";
import { autobind } from "core-decorators";

const CONSTANTS = {
  CLASSES: {
    IMAGE: ".js-lazy-image",
    LOADED: "lazy-image--loaded",
  },
};

export class LazyLoader {
  prop: string;
  screenWidth: number;
  $images: Array<HTMLElement>;

  constructor() {
    this.screenWidth =
      window.screen.width < window.innerWidth
        ? window.screen.width
        : window.innerWidth;
    this.prop =
      this.screenWidth <= GLOBAL_CONSTANTS.BREAKPOINTS.SMALL
        ? "data-small"
        : "data-src";
    this.refreshImageArray();
  }

  /**
   *
   * Module onImageLoadHander method, handles event
   *
   */
  onImageLoadHandler(elem: HTMLElement): boolean {
    const rect = elem.getBoundingClientRect();
    let ret = false;

    if (
      (rect.top <= window.innerHeight && rect.top >= 0) ||
      (rect.bottom <= window.innerHeight && rect.bottom >= 0)
    ) {
      ret = true;
    }

    return ret;
  }

  /**
   *
   * Fresh query to lazyload images on page
   *
   */
  loadImages(images: Array<HTMLElement>, prop: String, cb: Function) {
    return new ImageLoader({
      elements: images,
      property: prop,
      executor: cb,
    }).on("load", (element: HTMLElement) => {
      element.classList.add(CONSTANTS.CLASSES.LOADED);
    });
  }

  @autobind
  refreshImageArray() {
    this.$images = Array.from(
      document.querySelectorAll(
        CONSTANTS.CLASSES.IMAGE,
      ) as NodeListOf<HTMLElement>,
    );
    this.loadImages(this.$images, this.prop, this.onImageLoadHandler);
  }
}
