import { autobind } from "core-decorators";

const constants = {
  NoUnderline: "no-underline",
  hideTab: "hide-tab",
  tabContent: "tab-content",
};

export class TabbedFeatured {
  $TabHeadersWrapper: HTMLElement;
  $TabHeaders: Array<HTMLElement>;
  $BackgroundColor: HTMLElement;
  /**
   * @desc Set element to class variables
   * @param {HTMLElement} el - Element to bind module to.
   */
  constructor(el: HTMLElement) {
    if (!el) {
      return;
    }
    this.$TabHeaders = Array.prototype.slice.call(
      document.getElementsByClassName("js-tab-headers"),
    );

    this.bindEvents();
  }

  /**
   * @desc Set up events for each menu item
   */
  bindEvents(): void {
    if (this.$TabHeaders) {
      this.$TabHeaders.forEach((item: HTMLElement) => {
        item.addEventListener("click", this.handleRollOverTabs);
      });
    }
  }

  displaySelectedTab(tab: HTMLElement, alltabs: HTMLCollection): void {
    alltabs[Number(tab.id)].classList.remove(constants.hideTab);
  }

  hideNonselectedTabs(tab: HTMLElement, alltabs: HTMLCollection): void {
    alltabs[Number(tab.id)].classList.add(constants.hideTab);
  }

  @autobind
  handleRollOverTabs(e: Event): void {
    e.stopPropagation();
    const tabbedBlockDesktopMenuItem = e.currentTarget as HTMLElement;
    const tabItemSelected = tabbedBlockDesktopMenuItem.innerText;
    if (this.$TabHeaders && tabItemSelected) {
      for (
        let menuItemIndex = 0;
        menuItemIndex <
        tabbedBlockDesktopMenuItem.parentElement.children.length;
        menuItemIndex++
      ) {
        const tabbedBlockDesktopItem =
          tabbedBlockDesktopMenuItem.parentElement.children[menuItemIndex];

        if (
          (tabbedBlockDesktopItem as HTMLElement).innerText === tabItemSelected
        ) {
          tabbedBlockDesktopItem.classList.remove(constants.NoUnderline);
          this.displaySelectedTab(
            tabbedBlockDesktopItem as HTMLElement,
            document
              .getElementById(
                (
                  (tabbedBlockDesktopItem as Element).parentNode
                    .parentNode as Element
                ).id,
              )
              .getElementsByClassName(constants.tabContent),
          );
        } else {
          tabbedBlockDesktopItem.classList.add(constants.NoUnderline);
          this.hideNonselectedTabs(
            tabbedBlockDesktopItem as HTMLElement,
            document
              .getElementById(
                (
                  (tabbedBlockDesktopItem as Element).parentNode
                    .parentNode as Element
                ).id,
              )
              .getElementsByClassName(constants.tabContent),
          );
        }
      }
    }
  }
}
